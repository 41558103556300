import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { authenticationAtom } from "../../atoms/auth";
import { useAtom } from "jotai";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployeeDetails,
  addEmployeeGuarantors,
  selectEmployeeDetails,
} from "../../features/userSlice";
import { getEmployee, getEmployeeGuarantor } from "../../api/users/userAPI";
import { useParams } from "react-router-dom";
import { colors } from "../../assets/utils/colors";
import moment from "moment";
import { RemoveRedEye } from "@mui/icons-material";
import { Modal } from "antd";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import EmployeeGuarantors from "./EmployeeGuarantors";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const ViewEmployeeAction = (employee) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addedTime = moment(employee?.employee.created_at).format("DD-MM-YYYY");
  const updateTime = moment(employee?.employee.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Employee Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Added by :{" "}
            {employee.employee.added_by ? (
              <span>
                {employee.employee.added_by.first_name}{" "}
                {employee.employee.added_by.middle_name}{" "}
                {employee.employee.added_by.last_name}{" "}
                <span className="text-xs">
                  ({employee.employee.added_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Added at :{" "}
            {employee.employee.created_at ? <span>{addedTime}</span> : null}
          </p>
          <p>
            Updated by :{" "}
            {employee.employee.updated_by ? (
              <span>
                {employee.employee.updated_by.first_name}{" "}
                {employee.employee.updated_by.middle_name}{" "}
                {employee.employee.updated_by.last_name}{" "}
                <span className="text-xs">
                  ({employee.employee.updated_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Updated at :{" "}
            {employee.employee.updated_at ? <span>{updateTime}</span> : null}
          </p>
        </div>
      </Modal>
    </>
  );
};

const EmployeeDetails = () => {
  const [value, setValue] = useState(0);

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const fetchEmployee = async () => {
    const response = await getEmployee({ employee_id: employeeId, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployeeDetails(response.data.data));
      }
    }
  };

  const getGuarantors = async () => {
    const response = await getEmployeeGuarantor({
      employee_id: employeeId,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployeeGuarantors(response.data.data));
      }
    }
  };

  useEffect(() => {
    fetchEmployee();
    getGuarantors();
  }, [dispatch]);

  const employee = useSelector(selectEmployeeDetails);
  console.log(employee);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderPhoneNumber = (number) => {
    const phone = JSON.parse(number);

    if (typeof phone === "object") {
      return (
        <span>
          {phone?.map((phoneNumber, index) => (
            <>
              <div className="flex flex-row" key={index}>
                <div className="w-[98%] py-2 flex flex-row gap-2">
                  <p className="w-[32%]">Phone Number {index + 1} :</p>{" "}
                  <span className="text-[#F24E1E] w-[68%]">
                    {phoneNumber ? <span>{phoneNumber}</span> : null}
                  </span>
                </div>
                <div className="w-[2%]"></div>
              </div>
            </>
          ))}
        </span>
      );
    }
    if (typeof phone === "string" || typeof phone === "number") {
      return (
        <div className="flex flex-row">
          <div className="w-[98%] py-2 flex flex-row gap-2">
            <p className="w-[32%]">Phone Number :</p>{" "}
            <span className="text-[#F24E1E] w-[68%]">
              {phone ? <span>{phone}</span> : null}
            </span>
          </div>
          <div className="w-[2%]"></div>
        </div>
      );
    }
    return null;
  };

  const renderEmployeeDetails = () => {
    if (!isEmpty(employee)) {
      return (
        <Card
          sx={{
            width: 650,
            height: 560,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">EMPLOYEEE DETAILS</h3>
            <div className="pt-4">
              <div className="text-lg px-6">
                <div className="flex flex-row">
                  <div className="w-[98%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Employee Name :</p>{" "}
                    <span className="text-[#F24E1E] w-[68%]">
                      {employee.first_name ? (
                        <span>
                          {employee.first_name} {employee.middle_name}{" "}
                          {employee.last_name}
                        </span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[2%]"></div>
                </div>
                {renderPhoneNumber(employee?.phone_number)}
                <div className="flex flex-row">
                  <div className="w-[98%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Gender :</p>{" "}
                    <span className="text-[#F24E1E] w-[68%]">
                      {employee.gender ? <span>{employee.gender}</span> : null}
                    </span>
                  </div>
                  <div className="w-[2%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[98%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Designation :</p>{" "}
                    <span className="text-[#F24E1E] w-[68%]">
                      {employee.designation_name ? (
                        <span>{employee.designation_name}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[2%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[98%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Passport Number :</p>{" "}
                    <span className="text-[#F24E1E] w-[68%]">
                      {employee.passport_number ? (
                        <span>{employee.passport_number}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[2%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[98%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">License Number :</p>{" "}
                    <span className="text-[#F24E1E] w-[68%]">
                      {employee.license_number ? (
                        <span>{employee.license_number}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[2%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[98%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Salary Amount :</p>{" "}
                    <span className="text-[#F24E1E] w-[68%]">
                      {employee.salary_amount ? (
                        <span>{formatter.format(employee.salary_amount)}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[2%]"></div>
                </div>
                {user.role_name === "Admin" || user.role_name === "admin" ? (
                  <div className="flex flex-row">
                    <div className="w-[98%] py-2 flex flex-row gap-2">
                      <p className="w-[32%]">View Details :</p>{" "}
                      <p className="text-[#F24E1E] w-[68%] capitalize">
                        <ViewEmployeeAction employee={employee} />
                      </p>
                    </div>
                    <div className="w-[2%]"></div>
                  </div>
                ) : null}
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p className="text-center">Employee has no details</p>;
    }
  };

  const renderUserTabs = () => {
    return (
      <>
        <Grid container>
          <Grid item sm={12}>
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
              }}
              //   className="flex flex-row justify-between"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor={`${primary}`}
                indicatorColor="primary"
                sx={{ color: "#F24E1E" }}
              >
                <Tab label="Employee Details" {...a11yProps(0)} />
                <Tab label="Guarantors" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="flex flex-row justify-center pb-8">
                {renderEmployeeDetails()}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EmployeeGuarantors />
            </TabPanel>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container sx={{ px: 2 }}>
        <Grid item sm={12}>
          {renderUserTabs()}
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeDetails;
