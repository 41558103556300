import { CssBaseline, ThemeProvider } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllRoles } from "./api/settings/settingAPI";
import { primaryTheme } from "./assets/utils/themes";
import { addUserRoles } from "./features/settingSlice";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "./routes/App.routes";
import { Toaster } from "react-hot-toast";
import useAuth from "./hooks/useAuth";

const queryClient = new QueryClient();

// !#################### MAIN FUNC ##################
const App = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    //fetch all data
    const getRoles = async () => {
      const response = await getAllRoles({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addUserRoles(response.data.data));
        }
      }
    };

    getRoles();
  }, [dispatch]);

  return (
    <React.Fragment>
      <ThemeProvider theme={primaryTheme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <Toaster />
          <AppRoutes />
        </QueryClientProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
