import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { colors } from "../assets/utils/colors";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  Cable,
  DirectionsBus,
  LocalAtm,
  MonetizationOn,
  Person,
  Window,
  Settings,
  People,
  Inventory,
  PictureAsPdf,
} from "@mui/icons-material";

import { authenticationAtom } from "../atoms/auth"
import { useAtom } from "jotai"


// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    backgroundColor: colors.primary,
    border: "none !important",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//############# SideNavListItem #################
const SideNavListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 0,
  transition: "all ease-in-out 0.3s",
  "&::before": {
    content: '""',
    position: "absolute",
    height: 0,
    bottom: "50%",
    width: 4,
    transition: "all ease-in-out 0.3s",
    backgroundColor: colors.primary,
  },
  "& .icon": {
    minWidth: 0,
    justifyContent: "center",
    color: colors.bgColor2,
    opacity: 0.9,
    fontSize: 33,
  },
  "& .name": {
    color: colors.bgColor2,
  },
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen }) => {
  const [open] = React.useState(false);

  const AppBarHeight = styled("div")(({ theme }) => ({
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  //user access

  const [userData] = useAtom(authenticationAtom)
  //   const user = {role_name:"Truck owner"};

  const { window } = "props";
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // ################# LINKS ################
  const links = [
    {
      id: 1,
      name: "Overview",
      icon: <Window className="icon" />,
      url: "/dashboard",
      tooltip: "Overview",
    },
    {
      id: 2,
      name: "Trucks",
      icon: <DirectionsBus className="icon" />,
      url: "/trucks",
      tooltip: "Trucks",
    },
    {
      id: 3,
      name: "Trips",
      icon: <Cable className="icon" />,
      url: "/trips",
      tooltip: "Trips",
    },
    {
      id: 4,
      name: "Users",
      icon: <People className="icon" />,
      url: "/users",
      tooltip: "Users",
    },
    {
      id: 5,
      name: "Expenses",
      icon: <LocalAtm className="icon" />,
      url: "/expenses",
      tooltip: "Expenses",
    },
    {
      id: 6,
      name: "Revenue",
      icon: <MonetizationOn className="icon" />,
      url: "/revenue",
      tooltip: "Revenue",
    },
    {
      id: 6,
      name: "Inventory",
      icon: <Inventory className="icon" />,
      url: "/inventory",
      tooltip: "Inventory",
    },
    {
      id: 4,
      name: "Profile",
      icon: <Person className="icon" />,
      url: "/profile",
      tooltip: "Profile",
    },
    {
      id: 7,
      name: "Settings",
      icon: <Settings className="icon" />,
      url: "/settings",
      tooltip: "Settings",
    },
  ];

  const trackerLinks = [
    {
      id: 1,
      name: "Overview",
      icon: <Window className="icon" />,
      url: "/dashboard",
      tooltip: "Overview",
    },
    {
      id: 2,
      name: "Positions Report",
      icon: <PictureAsPdf className="icon" />,
      url: "/positions-report",
      tooltip: "Reports",
    },
    {
      id: 3,
      name: "Profile",
      icon: <Person className="icon" />,
      url: "/profile",
      tooltip: "profile",
    },
  ];

  const ownerLinks = [
    {
      id: 1,
      name: "Overview",
      icon: <Window className="icon" />,
      url: "/dashboard",
      tooltip: "Overview",
    },
    {
      id: 2,
      name: "Trucks",
      icon: <DirectionsBus className="icon" />,
      url: "/trucks",
      tooltip: "Trucks",
    },
    {
      id: 3,
      name: "Personal Expenses",
      icon: <LocalAtm className="icon" />,
      url: "/personal-expenses",
      tooltip: "Personal Expenses",
    },
    {
      id: 4,
      name: "Personal Revenues",
      icon: <MonetizationOn className="icon" />,
      url: "/personal-revenues",
      tooltip: "Personal Revenues",
    },
    {
      id: 5,
      name: "Profile",
      icon: <Person className="icon" />,
      url: "/profile",
      tooltip: "profile",
    },
  ];

  // ################# DRAWER CONTENT ################
  const drawer = () => {
    const { userInfo: user } = userData;

    return (
      <Box>
        {user?.role_name.toLowerCase() === "tracking person" && (
          <>
            {trackerLinks?.map((link) => (
              <NavLink to={link.url} key={link.id}>
                {({ isActive }) => (
                  <Tooltip title={link.tooltip} placement="right" arrow>
                    <SideNavListItem
                      disablePadding
                      sx={{
                        display: "block",
                        my: 2,
                        bgcolor: isActive && {
                          background: colors.secondary,
                        },
                        "&:hover": !isActive && {
                          transition: "all ease-in-out 0.3s",
                          "&::before": {
                            transition: "all ease-in-out 0.3s",
                            height: "100%",
                            bottom: 0,
                          },
                        },
                      }}
                    >
                      <ListItemButton
                        sx={{
                          py: 0.5,
                        }}
                      >
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText
                          className="name"
                          primary={link.name}
                          primaryTypographyProps={{
                            fontSize: 14,
                          }}
                        />
                      </ListItemButton>
                    </SideNavListItem>
                  </Tooltip>
                )}
              </NavLink>
            ))}
          </>
        )}
        {user?.role_name.toLowerCase() === "truck owner"
          && (
            <>
              {ownerLinks?.map((link) => (
                <NavLink to={link.url} key={link.id}>
                  {({ isActive }) => (
                    <Tooltip title={link.tooltip} placement="right" arrow>
                      <SideNavListItem
                        disablePadding
                        sx={{
                          display: "block",
                          my: 2,
                          bgcolor: isActive && {
                            background: colors.secondary,
                          },
                          "&:hover": !isActive && {
                            transition: "all ease-in-out 0.3s",
                            "&::before": {
                              transition: "all ease-in-out 0.3s",
                              height: "100%",
                              bottom: 0,
                            },
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            py: 0.5,
                          }}
                        >
                          <ListItemIcon>{link.icon}</ListItemIcon>
                          <ListItemText
                            className="name"
                            primary={link.name}
                            primaryTypographyProps={{
                              fontSize: 14,
                            }}
                          />
                        </ListItemButton>
                      </SideNavListItem>
                    </Tooltip>
                  )}
                </NavLink>
              ))}
            </>
          )
        }

        {(user?.role_name.toLowerCase() === "accountant" || user?.role_name.toLowerCase() === "clerk" || user?.role_name.toLowerCase() === "admin")
          && (
            <>
              {links?.map((link) => (
                <NavLink to={link.url} key={link.id}>
                  {({ isActive }) => (
                    <Tooltip title={link.tooltip} placement="right" arrow>
                      <SideNavListItem
                        disablePadding
                        sx={{
                          display: "block",
                          my: 2,
                          bgcolor: isActive && {
                            background: colors.secondary,
                          },
                          "&:hover": !isActive && {
                            transition: "all ease-in-out 0.3s",
                            "&::before": {
                              transition: "all ease-in-out 0.3s",
                              height: "100%",
                              bottom: 0,
                            },
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            py: 0.5,
                          }}
                        >
                          <ListItemIcon>{link.icon}</ListItemIcon>
                          <ListItemText
                            className="name"
                            primary={link.name}
                            primaryTypographyProps={{
                              fontSize: 14,
                            }}
                          />
                        </ListItemButton>
                      </SideNavListItem>
                    </Tooltip>
                  )}
                </NavLink>
              ))}
            </>
          )}
      </Box >
    )
  }


  return (
    <>
      {/* ##################### mobile ################# */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "70%", // Change the width of drawer in mobile
            backgroundColor: colors.primary,
            py: 4
          },
        }}
      >
        <Toolbar />
        {drawer()}
      </Drawer>

      {/* ##################### desktop ################ */}
      <DrawerDesktop
        variant="permanent"
        open={open}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        <AppBarHeight />
        <Divider />
        {drawer()}
      </DrawerDesktop>
    </>
  );
};

export default SideBar;
