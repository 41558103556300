import React, { useEffect, useState } from "react";
import tables from "../../styles/tables.module.css";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import AddPosition from "./AddPosition";
import { deleteRoutePosition, getSingleRoute } from "../../api/trips/tripsAPI";
import { addRouteDetails, selectRouteDetails } from "../../features/tripsSlice";
import EditPosition from "./EditPosition";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import moment from "moment/moment";
import { CircularProgress, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Modal } from "antd";

const DeleteRoutePositionAction = ({ position, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoutePosition = async (position) => {
    if (!isEmpty(position)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteRoutePosition({
        position_id: position?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to route position , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Route position is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={27} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete route position?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete route position with ID{" "}
            {position?.id || ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              handleRoutePosition(position);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewPostionAction = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(trip);
  const addedTime = moment(position?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(position?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        title="Route Position Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Position Description :{" "}
            {position.description ? <span>{position.description}</span> : null}
          </p>
          <br />
          <br />
          {user.role_name === "admin" || user.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {position.added_by ? (
                  <span>
                    {position.added_by.first_name}{" "}
                    {position.added_by.middle_name}{" "}
                    {position.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({position.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {position.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {position.updated_by ? (
                  <span>
                    {position.updated_by.first_name}{" "}
                    {position.updated_by.middle_name}{" "}
                    {position.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({position.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {position.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const RoutePositions = () => {
  const dispatch = useDispatch();
  const [userData] = useAtom(authenticationAtom);
  const { routeID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const getPositions = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getPositions();
  }, [dispatch]);

  const route = useSelector(selectRouteDetails);

  const { userInfo: user } = userData;

  const renderEdit = (position) => {
    return (
      <>
        <EditPosition position={position} />
      </>
    );
  };

  const renderDelete = (position) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteRoutePositionAction
              position={position}
              onSuccess={() => {
                getPositions();
              }}
            />
          </>
        );
      }
    }
  };

  const renderView = (position) => {
    return (
      <>
        <ViewPostionAction position={position} />
      </>
    );
  };

  const renderPositions = () => {
    if (!isEmpty(route)) {
      if (route.route_positions) {
        if (route.route_positions.length !== 0) {
          const positions = route.route_positions;
          return (
            <div>
              {positions.map((position, index) => {
                const num = index + 1;
                return (
                  <div
                    key={index}
                    className={`bg-white h-10 ${tables.positionTable} rounded-md text-sm my-2 mx-2`}
                  >
                    <p className="py-2 text-center">{num}</p>
                    <p className="py-2 pl-14">{position.position}</p>
                    <p className="py-2 text-center">
                      {position?.position_date}
                      {/* {moment(position.created_at).format("YYYY-MM-DD HH:mm a")} */}
                    </p>
                    <p className="py-2 pl-10">{position.description}</p>
                    <p className="flex flex-row justify-center space-x-2 pl-4">
                      {renderEdit(position)}
                      {renderDelete(position)}
                    </p>
                    <p className="text-center pl-4">{renderView(position)}</p>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <p>Route has no positions</p>
            </div>
          );
        }
      } else {
        return (
          <div className="text-center">
            <p>Route has no positions</p>
          </div>
        );
      }
    } else {
      return (
        <div className="text-center">
          <p>Route has no positions</p>
        </div>
      );
    }
  };

  const renderRoutePositions = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.positionTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Truck Position Name</p>
          <p className="py-2">Date</p>
          <p className="py-2">Truck Position Description</p>
          <p className="py-2">Actions</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderPositions()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <>
      <Grid container sx={{ px: 2, py: 2 }}>
        <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AddPosition
            onSuccess={() => {
              getPositions();
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Route Positions</h5>
            <div className="w-[100%]">{renderRoutePositions()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RoutePositions;
