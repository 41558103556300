import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { Grid } from "@mui/material";
import Orders from "./Orders";
import AddStock from "./AddStock";
import AddOrder from "./AddOrder";
import Stocks from "./Stocks";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const Inventory = () => {
  const [value, setValue] = React.useState(0);

  const [userData] = useAtom(authenticationAtom);

  const renderUserTabs = () => {
    const { userInfo: user } = userData;

    if (!isEmpty(user)) {
      if (
        user.role_name.toLowerCase() === "accountant" ||
        user.role_name.toLowerCase() === "clerk" ||
        user.role_name.toLowerCase() === "admin"
      ) {
        return (
          <div className="h-40">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: 100,
                  borderBottom: 1,
                  borderColor: "divider",
                }}
                className="flex flex-row justify-between"
              >
                <Box sx={{ maxWidth: 900 }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    textColor={`${primary}`}
                    indicatorColor="primary"
                    sx={{ color: "#F24E1E", height: 100 }}
                  >
                    <Tab label="STOCKS" {...a11yProps(0)} />
                    <Tab label="ORDERS" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <div className="w-[30%] flex flex-row gap-3 justify-end px-2">
                  <div>
                    <AddStock />
                  </div>
                  <div>
                    <AddOrder />
                  </div>
                </div>
              </Box>
              <TabPanel value={value} index={0}>
                <Stocks />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Orders />
              </TabPanel>
            </Box>
          </div>
        );
      } else {
        return <div className="text-center text-lg">UNAUTHORIZED</div>;
      }
    } else {
      return <div className="text-center text-lg">UNAUTHORIZED</div>;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        {renderUserTabs()}
      </Grid>
    </Grid>
  );
};

export default Inventory;
