import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import MonthlyTripsCard from "../cards/MonthlyTripsCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import PaidInvoicesCard from "../cards/PaidInvoicesCard";
import PaidAmountCard from "../cards/PaidAmountCard";
import { getRouteExpenses } from "../../api/trips/tripsAPI";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  addRouteExpenses,
  selectRouteExpenses,
} from "../../features/tripsSlice";
import { Box, Grid } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { addExpenseDetails } from "../../features/expensesSlice";
import { useNavigate } from "react-router-dom";

const AdminExpenses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    //fetch all trucks
    const getExpenses = async () => {
      const response = await getRouteExpenses({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addRouteExpenses(response.data.data));
        }
      }
    };

    getExpenses();
  }, [dispatch]);

  const expenses = useSelector(selectRouteExpenses);
  // console.log(expenses);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let expenseTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;

  let expenseArray = [];
  let paidExpensesArray = [];
  let unpaidExpensesArray = [];

  if (Array.isArray(expenses)) {
    expenseArray = expenses;

    if (expenses.length !== 0) {
      for (const value of expenses) {
        expenseTotalAmount += value.total_amount;
        paidTotalAmount += value.paid_amount;
      }

      unpaidTotalAmount = expenseTotalAmount - paidTotalAmount;
    }

    paidExpensesArray = expenses.filter((expense) => {
      //filtering array value
      return expense.status === "paid";
    });

    unpaidExpensesArray = expenses.filter((expense) => {
      //filtering array value
      return expense.status !== "paid";
    });
  }

  const handleExpense = (expense) => {
    //add expense data to store
    dispatch(addExpenseDetails(expense));
    navigate(`/expenses/${expense?.id}`);
  };

  const renderExpenses = () => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.routeExpenseTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Expense Type</p>
              <p className="py-2">Items</p>
              <p className="py-2">Item Price</p>
              <p className="py-2">Total Amount</p>
              <p className="py-2">Expense Supplier</p>
              <p className="py-2">Date</p>
              <p className="py-2">Status</p>
              <p className="py-2">Truck</p>
              <p className="py-2">View</p>
            </div>
            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
              {renderExpense()}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderExpense = () => {
    if (expenseArray.length !== 0) {
      return (
        <div>
          {expenseArray.map((expense, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.routeExpenseTable} rounded-md text-sm my-2 mx-1`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 pl-3 capitalize">
                  {expense.expense_type ? (
                    <span>{expense.expense_type.type_name}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-2">{expense.item_number}</p>
                <p className="py-2 pl-6">
                  {formatter.format(expense.item_price)}
                </p>
                <p className="py-2 pl-6">
                  {formatter.format(expense.total_amount)}
                </p>
                <p className="py-2 text-center">
                  {expense.supplier ? (
                    <span>{expense.supplier.supplier_name}</span>
                  ) : null}
                </p>
                <p className="py-2 text-center">
                  {expense.expense_date ? (
                    <span>{expense.expense_date}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-4">
                  {expense.status === "unpaid" ? (
                    <span>Not paid</span>
                  ) : (
                    <span className="capitalize">{expense.status}</span>
                  )}
                </p>
                <p className="py-2 text-center">
                  {expense.truck ? (
                    <span>{expense.truck.registration_number}</span>
                  ) : null}
                </p>
                <p
                  className="cursor-pointer py-2 text-center pl-4"
                  onClick={() => handleExpense(expense)}
                >
                  <RemoveRedEye
                    sx={{
                      color: colors.link,
                    }}
                  />
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no expenses</p>
        </div>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            title={"Total Expenses"}
            value={expenseArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            title={"Total Paid Expenses"}
            value={paidExpensesArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            title={"Total Unpaid Expenses"}
            value={unpaidExpensesArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <MonthlyTripsCard
            title={"Total Expenses Amount"}
            value={formatter.format(expenseTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <PaidAmountCard
            title={"Total Paid Amount"}
            value={formatter.format(paidTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <PaidInvoicesCard
            title={"Total Debt Amount"}
            value={formatter.format(unpaidTotalAmount)}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Expenses</h5>
            <div className="pb-8 w-[100%]">{renderExpenses()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminExpenses;
