import {
  CREATE_EXPENSE_PAYMENT,
  CREATE_MONTHLY_EXPENSE_PAYMENT,
  CREATE_MONTHLY_MULTISUPPLIER_PAYMENT,
  CREATE_MULTISUPPLIER_PAYMENT,
  CREATE_PERSONAL_EXPENSE,
  CREATE_SUPPLIER_TRANSFER_PAYMENT,
  CREATE_TRUCK_MONTHLY_EXPENSE,
  DELETE_EXPENSE_PAYMENT,
  DELETE_MONTHLY_EXPENSE_PAYMENT,
  DELETE_PERSONAL_EXPENSE,
  DELETE_SUPPLIER_TRANSFER_PAYMENT,
  DELETE_TRUCK_MONTHLY_EXPENSE,
  GET_ALL_EXPENSES_PAYMENTS,
  GET_ALL_MONTHLY_EXPENSES_PAYMENTS,
  GET_ALL_PERSONAL_EXPENSES,
  GET_ALL_TRUCK_MONTHLY_EXPENSES,
  GET_OWNER_PERSONAL_EXPENSE,
  GET_SINGLE_EXPENSE_PAYMENT,
  GET_SINGLE_MONTHLY_EXPENSE_PAYMENT,
  GET_SINGLE_PERSONAL_EXPENSE,
  GET_SINGLE_TRUCK_MONTHLY_EXPENSE,
  GET_SUPPLIER_TRUCK_MONTHLY_EXPENSE,
  GET_TRUCK_MONTHLY_EXPENSE,
  UPDATE_EXPENSE_PAYMENT,
  UPDATE_MONTHLY_EXPENSE_PAYMENT,
  UPDATE_PERSONAL_EXPENSE,
  UPDATE_TRUCK_MONTHLY_EXPENSE,
} from "../apiEndPoints";
import client from "../client";

export const createPersonalExpense = async ({
  amount,
  owner_id,
  supplier_id,
  expense_date,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_PERSONAL_EXPENSE}`,
    {
      amount,
      owner_id,
      supplier_id,
      expense_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllPersonalExpenses = async ({ token }) => {
  const response = await client.get(
    GET_ALL_PERSONAL_EXPENSES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSinglePersonalExpense = async ({ expense_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_PERSONAL_EXPENSE}/${expense_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getOwnerPersonalExpense = async ({ owner_id, token }) => {
  const response = await client.get(
    `${GET_OWNER_PERSONAL_EXPENSE}/${owner_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updatePersonalExpense = async ({
  amount,
  owner_id,
  supplier_id,
  expense_date,
  description,
  expense_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_PERSONAL_EXPENSE}/${expense_id}`,
    {
      amount,
      owner_id,
      supplier_id,
      expense_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deletePersonalExpense = async ({ expense_id, token }) => {
  const response = await client.delete(
    `${DELETE_PERSONAL_EXPENSE}/${expense_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createExpensePayment = async ({
  amount_paid,
  payment_date,
  description,
  expense_id,
  token,
}) => {
  const response = await client.post(
    `${CREATE_EXPENSE_PAYMENT}/${expense_id}`,
    {
      amount_paid,
      payment_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createMultiExpensePayment = async ({ expense_ids, token }) => {
  const response = await client.post(
    `${CREATE_MULTISUPPLIER_PAYMENT}`,
    {
      expense_ids,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllExpensesPayments = async ({ token }) => {
  const response = await client.get(
    GET_ALL_EXPENSES_PAYMENTS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleExpensePayment = async ({
  expense_payment_id,
  token,
}) => {
  const response = await client.get(
    `${GET_SINGLE_EXPENSE_PAYMENT}/${expense_payment_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateExpensePayment = async ({
  amount_paid,
  payment_type_id,
  payment_date,
  description,
  expense_payment_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_EXPENSE_PAYMENT}/${expense_payment_id}`,
    {
      amount_paid,
      payment_type_id,
      payment_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteExpensePayment = async ({ payment_id, token }) => {
  const response = await client.delete(
    `${DELETE_EXPENSE_PAYMENT}/${payment_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createSupplierPaymentTransfer = async ({
  from_supplier_id,
  to_supplier_id,
  amount,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_SUPPLIER_TRANSFER_PAYMENT}`,
    {
      from_supplier_id,
      to_supplier_id,
      amount,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteSupplierPaymentTransfer = async ({ transfer_id, token }) => {
  const response = await client.delete(
    `${DELETE_SUPPLIER_TRANSFER_PAYMENT}/${transfer_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createMonthlyExpense = async ({
  truck_id,
  supplier_id,
  amount,
  owner_id,
  expense_date,
  expense_name,
  month,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_TRUCK_MONTHLY_EXPENSE}`,
    {
      truck_id,
      supplier_id,
      amount,
      owner_id,
      expense_date,
      expense_name,
      month,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllMonthlyExpenses = async ({ token }) => {
  const response = await client.get(
    GET_ALL_TRUCK_MONTHLY_EXPENSES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getTruckMonthlyExpense = async ({ truck_id, token }) => {
  const response = await client.get(
    `${GET_TRUCK_MONTHLY_EXPENSE}/${truck_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSupplierTruckMonthlyExpense = async ({
  supplier_id,
  token,
}) => {
  const response = await client.get(
    `${GET_SUPPLIER_TRUCK_MONTHLY_EXPENSE}/${supplier_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateMonthlyExpense = async ({
  truck_id,
  supplier_id,
  amount,
  owner_id,
  expense_date,
  expense_name,
  month,
  description,
  expense_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_TRUCK_MONTHLY_EXPENSE}/${expense_id}`,
    {
      truck_id,
      supplier_id,
      amount,
      owner_id,
      expense_name,
      expense_date,
      month,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteMonthlyExpense = async ({ expense_id, token }) => {
  const response = await client.delete(
    `${DELETE_TRUCK_MONTHLY_EXPENSE}/${expense_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createMonthlyExpensePayment = async ({
  amount_paid,
  payment_date,
  description,
  expense_id,
  token,
}) => {
  const response = await client.post(
    `${CREATE_MONTHLY_EXPENSE_PAYMENT}/${expense_id}`,
    {
      amount_paid,
      payment_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createMultiMonthlyExpensePayment = async ({ expense_ids, token }) => {
  const response = await client.post(
    `${CREATE_MONTHLY_MULTISUPPLIER_PAYMENT}`,
    {
      expense_ids,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllMonthlyExpensesPayments = async ({ token }) => {
  const response = await client.get(
    GET_ALL_MONTHLY_EXPENSES_PAYMENTS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleMonthlyExpensePayment = async ({
  expense_payment_id,
  token,
}) => {
  const response = await client.get(
    `${GET_SINGLE_MONTHLY_EXPENSE_PAYMENT}/${expense_payment_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateMonthlyExpensePayment = async ({
  amount_paid,
  payment_type_id,
  payment_date,
  description,
  expense_payment_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_MONTHLY_EXPENSE_PAYMENT}/${expense_payment_id}`,
    {
      amount_paid,
      payment_type_id,
      payment_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteMonthlyExpensePayment = async ({ payment_id, token }) => {
  const response = await client.delete(
    `${DELETE_MONTHLY_EXPENSE_PAYMENT}/${payment_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

