import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";
import { MdAddLocationAlt } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import {
  createRoutePosition,
  getRoutePositions,
  getSingleRoute,
} from "../../api/trips/tripsAPI";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addRouteDetails,
  addRoutePositions,
  selectRouteDetails,
} from "../../features/tripsSlice";
import { isEmpty } from "lodash";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddPosition = ({ onSuccess = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [position, setPosition] = useState("");
  const [position_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { routeID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const getPositions = async () => {
    const response = await getRoutePositions({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRoutePositions(response.data.data));
      }
    }
  };

  const getRoute = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteDetails(response.data.data));
      }
    }
  };

  const route = useSelector(selectRouteDetails);

  const positionRegistration = async (e) => {
    e.preventDefault();

    if (!position) {
      notifyToastError({
        message: "Please enter position",
        icon: "❗",
      });
    } else if (!position_date) {
      notifyToastError({
        message: "Please select date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(position_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createRoutePosition({
        position,
        position_date: date,
        description,
        route_id: routeID,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setPosition("");
        setDate(null);
        setDescription("");
        getPositions();
        getRoute();

        notifyToastSuccess({
          message: "Route position is created successfully",
          icon: "✅",
        });
        onSuccess();
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => positionRegistration(e)}
        >
          SAVE POSITION
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-2">
          <p className="flex justify-center items-center pt-1">
            <MdAddLocationAlt className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">
            Add Truck Position
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new truck position to existing routes
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {route?.status === "not_started" || route?.status === "open" ? (
          <Box sx={style}>
            <div>
              <h3 className="text-center text-xl py-4">Add Truck Position</h3>
              <div>
                <div className="w-full py-2 flex justify-center">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Position"
                    variant="outlined"
                    className="w-[82%]"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
                <div className="w-full py-2 flex justify-center">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    dateLibInstance={moment.utc}
                  >
                    <DatePicker
                      label="Select date"
                      value={position_date}
                      onChange={(newValue) => setDate(newValue)}
                      className="w-[82%]"
                    />
                  </LocalizationProvider>
                </div>
                <div className="w-full py-2 flex justify-center">
                  <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    className="w-[82%]"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="w-full py-2 flex justify-center">
                  {renderButton()}
                </div>
              </div>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div>
              <h3 className="text-center text-xl py-4">Add Position Details</h3>
              <div>
                <h3 className="text-center text-base py-4">
                  Sorry! Route is closed, you can't add new position to a closed
                  route
                </h3>
              </div>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default AddPosition;
