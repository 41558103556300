import React, { useEffect, useState } from "react";
import tables from "../../styles/tables.module.css";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import AddExpense from "./AddExpense";
import { addRouteDetails, selectRouteDetails } from "../../features/tripsSlice";
import EditExpense from "./EditExpense";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { getSingleRoute, deleteRouteExpense } from "../../api/trips/tripsAPI";
import { useParams } from "react-router-dom";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import useAuth from "../../hooks/useAuth";
import { Modal } from "antd";
import moment from "moment";
import RouteExpensesPDFCard from "../cards/RouteExpensesPDFCard";

const DeleteRouteExpenseAction = ({ expense, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRouteExpense = async (expense) => {
    if (!isEmpty(expense)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteRouteExpense({
        expense_id: expense?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to route expense , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Route expense is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={27} />
            </>
          ) : (
            <>
              <IconButton
                variant="outlined"
                onClick={handleClickOpen}
                disabled={isLoading}
              >
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </IconButton>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete route expense?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want delete route expense with ID{" "}
              {expense?.id || ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleRouteExpense(expense);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewExpenseAction = ({ expense }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(trip);
  const addedTime = moment(expense?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(expense?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        title="Expense Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Expense Description :{" "}
            {expense.description ? <span>{expense.description}</span> : null}
          </p>
          <br />
          <br />
          {user.role_name === "admin" || user.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {expense.added_by ? (
                  <span>
                    {expense.added_by.first_name} {expense.added_by.middle_name}{" "}
                    {expense.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({expense.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {expense.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {expense.updated_by ? (
                  <span>
                    {expense.updated_by.first_name}{" "}
                    {expense.updated_by.middle_name}{" "}
                    {expense.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({expense.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {expense.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const RouteExpenses = () => {
  const dispatch = useDispatch();
  const [userData] = useAtom(authenticationAtom);
  const { routeID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const getRoute = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getRoute();
  }, [dispatch]);

  const route = useSelector(selectRouteDetails);

  const { userInfo: user } = userData;

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderEdit = (expense) => {
    return (
      <>
        <EditExpense
          expense={expense}
          onSuccess={() => {
            getRoute();
          }}
        />
      </>
    );
  };

  const renderDelete = (expense) => {
    if (user) {
      if (user.role_name === "Clerk" || user.role_name === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteRouteExpenseAction
              expense={expense}
              onSuccess={() => {
                getRoute();
              }}
            />
          </>
        );
      }
    }
  };

  const renderView = (expense) => {
    return (
      <>
        <ViewExpenseAction expense={expense} />
      </>
    );
  };

  const renderExpenses = () => {
    if (!isEmpty(route)) {
      if (route.route_expenses) {
        return (
          <div>
            {route.route_expenses.map((expense, index) => {
              const position = index + 1;
              return (
                <div
                  key={index}
                  className={`bg-white h-10 ${tables.routeExpenseTable} rounded-md text-sm my-2 mx-2`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 pl-2 capitalize">
                    {expense.expense_type ? (
                      <span>{expense.expense_type.type_name}</span>
                    ) : null}
                  </p>
                  <p className="py-2 pl-2">{expense.item_number}</p>
                  <p className="py-2 pl-4">
                    {formatter.format(expense.item_price)}
                  </p>
                  <p className="py-2 pl-2">
                    {formatter.format(expense.total_amount)}
                  </p>
                  <p className="py-2 text-center">
                    {expense.supplier ? (
                      <span>{expense.supplier.supplier_name}</span>
                    ) : null}
                  </p>
                  <p className="py-2 text-center">
                    {expense.expense_date ? (
                      <span>{expense.expense_date}</span>
                    ) : null}
                  </p>
                  <p className="py-2 pl-4">
                    {expense.status === "unpaid" ? (
                      <span>Not paid</span>
                    ) : (
                      <span className="capitalize">{expense.status}</span>
                    )}
                  </p>
                  <p className="flex flex-row justify-center pl-4">
                    {renderEdit(expense)}
                    {renderDelete(expense)}
                  </p>
                  <p className="flex flex-row justify-center pl-4">
                    {renderView(expense)}
                  </p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div>
            <p className="text-center">Route has no expenses</p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p className="text-center">Route has no expenses</p>
        </div>
      );
    }
  };

  const renderRouteExpenses = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.routeExpenseTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Expense Type</p>
          <p className="py-2">Items</p>
          <p className="py-2">Item Price</p>
          <p className="py-2">Total Amount</p>
          <p className="py-2">Expense Supplier</p>
          <p className="py-2">Date</p>
          <p className="py-2">Status</p>
          <p className="py-2">Actions</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderExpenses()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <>
      <Grid container sx={{ py: 2 }}>
        <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AddExpense />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <div className="flex flex-row justify-between pb-1">
              <h5>Route Expenses</h5>
              <div>
                <RouteExpensesPDFCard
                  expenses={route?.route_expenses}
                  route={route}
                />
              </div>
            </div>
            <div className="w-[100%]">{renderRouteExpenses()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RouteExpenses;
