import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import tables from "../../styles/tables.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdLibraryAdd, MdRemoveCircle } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { addExpenseTypes, selectExpenseTypes } from "../../features/settingSlice";
import {
  addRegisteredNames,
  addSuppliers,
  selectRegisteredNames,
  selectSuppliers,
} from "../../features/userSlice";
import {
  getAllRegisteredNames,
  getAllSuppliers,
} from "../../api/users/userAPI";
import { getAllExpenseTypes } from "../../api/settings/settingAPI";
import useAuth from "../../hooks/useAuth";
import {
  createStockOrder,
  getAllStockOrders,
  getTrucksSummary,
} from "../../api/stock/stockAPI";
import {
  addOrderItems,
  addStockOrders,
  addTrucksSummary,
  selectOrderItems,
  selectTruckSummary,
} from "../../features/stockSlice";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const AddItem = ({ sortedItemTypes }) => {
  const [openChild, setOpenChild] = useState(false);
  const handleOpenChild = () => {
    setOpenChild(true);
  };
  const handleCloseChild = () => {
    setOpenChild(false);
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [item_type, setType] = useState("");
  const [quantity, setNumber] = useState("");
  const [item_price, setPrice] = useState("");
  const [amount, setOrderAmount] = useState("");
  const [item_amount, setItemAmount] = useState("");

  const typeOnChange = (e, value) => {
    setType(value);
  };

  let formatter = new Intl.NumberFormat("en-uS");

  const getTotal = () => {
    let total = quantity * item_price;
    setItemAmount(total);
    if (isNumber(total)) {
      setOrderAmount(formatter.format(total));
    }
  };

  useEffect(() => {
    getTotal();
  }, [quantity, item_price]);

  let orderItems = useSelector(selectOrderItems);

  const storeItems = async (e) => {
    e.preventDefault();

    if (!item_type) {
      notifyToastError({
        message: "Please select item type",
        icon: "❗",
      });
    } else if (!quantity) {
      notifyToastError({
        message: "Please enter items quantity",
        icon: "❗",
      });
    } else if (!item_price) {
      notifyToastError({
        message: "Please enter item price",
        icon: "❗",
      });
    } else if (!item_amount) {
      notifyToastError({
        message: "Please enter item total amount",
        icon: "❗",
      });
    } else {
      //store details
      setLoading(true);
      const data = {
        id: item_type.id,
        item_type,
        quantity,
        price: item_price,
        total_price: item_amount,
      };
      orderItems = [...orderItems, data];
      if (orderItems.length > 0) {
        setType("");
        setNumber("");
        setPrice("");
        setOrderAmount("");
        setItemAmount("");

        dispatch(addOrderItems(orderItems));
        setLoading(false);

        notifyToastSuccess({
          message: "Item is stored successfully",
          icon: "✅",
        });
      }
    }
  };

  const renderItemButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[90%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[90%]"
          onClick={(e) => storeItems(e)}
        >
          ADD ORDER ITEM TO LIST
        </Button>
      );
    }
  };

  return (
    <div>
      <Button
        onClick={handleOpenChild}
        size="small"
        variant="contained"
        className="w-[100%]"
      >
        Add Item
      </Button>
      <Modal
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 700 }}>
          <div>
            <h3 className="text-center text-xl py-4">Add Order Items</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedItemTypes}
                  size="small"
                  className="w-[45%]"
                  value={item_type}
                  onChange={typeOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Item" />
                  )}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item price"
                  variant="outlined"
                  className="w-[45%]"
                  type={"number"}
                  value={item_price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item quantity"
                  variant="outlined"
                  className="w-[45%]"
                  type={"number"}
                  value={quantity}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Total amount"
                  variant="outlined"
                  className="w-[45%]"
                  value={amount}
                  onChange={(e) => setOrderAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderItemButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const AddOrder = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [supplier, setSupplier] = useState("");
  const [truck, setTruck] = useState("");
  const [trip, setTrip] = useState("");
  const [route, setRoute] = useState("");
  const [order_name, setOrderName] = useState("");
  const [order_type, setOrderType] = useState("");
  const [order_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const [truckTrips, setTruckTrips] = useState([]);
  const [truckRoutes, setTruckRoutes] = useState([]);

  const dispatch = useDispatch();

  const auth = useAuth();
  const token = auth?.token || "";

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  const nameOnChange = (e, value) => {
    setOrderName(value);
  };

  const getOrders = async () => {
    const response = await getAllStockOrders({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addStockOrders(response.data.data));
    }
  };

  const getTypes = async () => {
    const response = await getAllExpenseTypes({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addExpenseTypes(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  const getNames = async () => {
    const response = await getAllRegisteredNames({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRegisteredNames(response.data.data));
    }
  };

  const getTrucks = async () => {
    const response = await getTrucksSummary({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTrucksSummary(response.data.data));
    }
  };

  useEffect(() => {
    getSuppliers();
    getTypes();
    getNames();
    getTrucks();
  }, [dispatch]);

  const suppliers = useSelector(selectSuppliers);
  const allItemTypes = useSelector(selectExpenseTypes);
  const orderItems = useSelector(selectOrderItems);
  const names = useSelector(selectRegisteredNames);
  const trucks = useSelector(selectTruckSummary);

  //console.log(orderItems);

  const truckOnChange = (e, value) => {
    //get truck trips and routes
    const selectedTruck = trucks.filter((truck) => {
      return truck.id === value?.id;
    });

    //console.log(selectedTruck);

    if (selectedTruck.length > 0) {
      const truck = selectedTruck[0];
      //console.log(truck);
      setTruck(value);

      if (truck?.trips) {
        setTruckTrips(truck.trips);
      }
    }
  };

  const tripOnChange = (e, value) => {
    //get truck trip routes
    const selectedTrip = truckTrips.filter((trip) => {
      return trip.id === value?.id;
    });

    if (selectedTrip.length > 0) {
      const trip = selectedTrip[0];

      if (trip?.trip_routes) {
        setTrip(value);
        setTruckRoutes(trip.trip_routes);
      } else {
        setTrip(value);
        setTruckRoutes([]);
      }
    }  
  };

  const routeOnChange = (e, value) => {
    setRoute(value);
  };

  const handleOrderType = (value) => {
    setOrderType(value);
    setRoute([]);
    setTrip([]);
    setTruckRoutes([]);
    setTruckTrips([]);
  }

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let orderTotalAmount = 0;

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));

  const sortedItemTypes = allItemTypes.map((item) => ({
    id: item.id,
    label: item.type_name,
  }));

  const sortedNames = names.map((name) => ({
    id: name.id,
    label: name.name,
  }));

  const sortedTrucks = trucks.map((truck) => ({
    id: truck.id,
    label: truck.registration_number,
  }));

  const sortedTruckTrips = truckTrips.map((trip) => ({
    id: trip?.id,
    label: trip?.trip_number,
  }));

  const sortedTripRoutes = truckRoutes.map((route) => ({
    id: route?.id,
    label: `${route?.route_number} : ${route.initial_location} - ${route.final_location}`,
  }));

  if (orderItems.length !== 0) {
    if (orderItems.length === 1) {
      orderTotalAmount = orderItems[0].total_price;
    } else {
      orderTotalAmount = orderItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total_price,
        0
      );
    }
  }

  const saveOrder = async (e) => {
    e.preventDefault();

    if (!supplier) {
      notifyToastError({
        message: "Please select supplier",
        icon: "❗",
      });
    } else if (!order_type) {
      notifyToastError({
        message: "Please select order type",
        icon: "❗",
      });
    }  else if (!order_name) {
      notifyToastError({
        message: "Please enter order name",
        icon: "❗",
      });
    } else if (orderItems.length < 1) {
      notifyToastError({
        message: "Please add order items",
        icon: "❗",
      });
    } else if (!order_date) {
      notifyToastError({
        message: "Please select order date",
        icon: "❗",
      });
    } else if (order_type && order_type !== "stock" && !truck) {
      notifyToastError({
        message: "Please select truck",
        icon: "❗",
      });
    } else if (order_type && order_type !== "stock" && truck && !trip) {
      notifyToastError({
        message: "Please select trip",
        icon: "❗",
      });
    } else if (order_type && order_type !== "stock" && truck && trip && !route) {
      notifyToastError({
        message: "Please select route",
        icon: "❗",
      });
    } else {

      //change date format
      const date = moment(order_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createStockOrder({
        supplier_id: supplier.id,
        order_person: order_name?.id,
        total_order_price: orderTotalAmount,
        order_type,
        truck_id: truck?.id || "",
        trip_id: trip?.id || "",
        route_id: route?.id || "",
        order_date: date,
        ordered_items: orderItems,
        description,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setSupplier("");
        setTruck("");
        setOrderType("");
        setRoute("");
        setTrip("");
        setTruckTrips([]);
        setTruckRoutes([]);
        setDate(null);
        setOrderName("");
        setDescription("");
        dispatch(addOrderItems([]));
        getOrders();

        notifyToastSuccess({
          message: "Order is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[98%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[98%]"
          onClick={(e) => saveOrder(e)}
        >
          SAVE ORDER
        </Button>
      );
    }
  };

  const renderRemove = (order) => {
    if (!isEmpty(order)) {
      const handleRemoveItem = () => {
        const itemsList = orderItems.filter(
          (orderItem) => orderItem?.id !== order.id
        );

        dispatch(addOrderItems(itemsList));
      };

      return (
        <div onClick={handleRemoveItem} className="cursor-pointer">
          <MdRemoveCircle className="text-[#F24E1E] text-xl ml-0" />
        </div>
      );
    }
  };

  const renderOrderItems = () => {
    if (orderItems.length !== 0) {
      return (
        <div>
          {orderItems.map((order, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.itemsList} rounded-md text-sm my-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">{order?.item_type?.label}</p>
                <p className="py-2 text-center">
                  {formatter.format(order?.price || 0)}
                </p>
                <p className="py-2 text-center">{order?.quantity}</p>
                <p className="py-2 text-center">
                  {formatter.format(order?.total_price || 0)}
                </p>
                <p className="py-2 flex justify-center cursor-pointer">
                  {renderRemove(order)}
                </p>
              </div>
            );
          })}
          <div className="flex justify-center py-2">
            <AddItem sortedItemTypes={sortedItemTypes} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center py-2">
          <AddItem sortedItemTypes={sortedItemTypes} />
        </div>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[85px] w-[160px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="flex flex-row justify-center items-center pt-4">
          <MdLibraryAdd className="text-xl" />
        </div>
        <p className="text-lg font-semibold text-center">Add Order</p>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Add Order Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedNames}
                  size="small"
                  className="w-[50%]"
                  value={order_name}
                  onChange={nameOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Order Name" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[50%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="large"
                  id="outlined-select-currency"
                  select
                  label="Order Type"
                  className={
                    order_type === "direct" || order_type === "indirect"
                      ? "w-[34%]"
                      : "w-[50%]"
                  }
                  value={order_type}
                  onChange={(e) => handleOrderType(e.target.value)}
                >
                  <MenuItem value={"stock"}>Stock</MenuItem>
                  <MenuItem value={"direct"}>Direct Expense</MenuItem>
                  <MenuItem value={"indirect"}>Indirect Expense</MenuItem>
                </TextField>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select order date"
                    value={order_date}
                    onChange={(newValue) => setDate(newValue)}
                    className={
                      order_type === "direct" || order_type === "indirect"
                        ? "w-[34%]"
                        : "w-[50%]"
                    }
                  />
                </LocalizationProvider>
                {order_type === "direct" || order_type === "indirect" ? (
                  <Autocomplete
                    id="combo-box-demo"
                    options={sortedTrucks}
                    size="large"
                    className="w-[33%]"
                    value={truck}
                    onChange={truckOnChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Truck" />
                    )}
                  />
                ) : null}
              </div>
              {order_type === "direct" && truck ? (
                <div className="w-full py-2 flex flex-row gap-2 justify-center">
                  <Autocomplete
                    id="combo-box-demo"
                    options={sortedTruckTrips}
                    size="small"
                    className={trip? "w-[50%]" : "w-[100%]"}
                    value={trip}
                    onChange={tripOnChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Truck Trip" />
                    )}
                  />
                  {trip ? (
                    <Autocomplete
                      id="combo-box-demo"
                      options={sortedTripRoutes}
                      size="small"
                      className="w-[50%]"
                      value={route}
                      onChange={routeOnChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Trip Route" />
                      )}
                    />
                  ) : null}
                </div>
              ) : null}
              <div className="pb-2">
                <div
                  className={`bg-[#EEDEDE] h-10 text-center ${tables.itemsList} rounded-t-md font-semibold`}
                >
                  <p className="py-2">No</p>
                  <p className="py-2">Item Name</p>
                  <p className="py-2">Item Price</p>
                  <p className="py-2">Quantity</p>
                  <p className="py-2">Total</p>
                  <p className="py-2">Actions</p>
                </div>
                <div className="bg-[#F0F0F0] h-[100px] overflow-y-scroll">
                  {renderOrderItems()}
                </div>
                <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md flex justify-center">
                  <p className="font-semibold py-2">
                    {formatter.format(orderTotalAmount)}
                  </p>
                </div>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={2}
                  variant="outlined"
                  className="w-[98%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddOrder;
