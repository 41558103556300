import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trucks: [],
  truckDetails: "",
  trailers: [],
};

const truckSlice = createSlice({
  name: "truck",
  initialState,
  reducers: {
    addTrucks(state, action) {
      state.trucks = action.payload;
    },

    addTruckDetails(state, action) {
      state.truckDetails = action.payload;
    },

    addTrailers(state, action) {
      state.trailers = action.payload;
    },

  },
});

export const { addTrucks, addTruckDetails, addTrailers } = truckSlice.actions;

export const selectTrucks = (state) => state.truck.trucks;
export const selectTruckDetails = (state) => state.truck.truckDetails;
export const selectTrailers = (state) => state.truck.trailers;

export default truckSlice.reducer;