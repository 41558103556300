import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Modal } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleRouteExpense } from "../../api/trips/tripsAPI";
import { colors } from "../../assets/utils/colors";
import { authenticationAtom } from "../../atoms/auth";
import {
  addExpenseDetails,
  addMonthlyExpenseDetails,
  selectExpenseDetails,
  selectMonthlyExpenseDetails,
} from "../../features/expensesSlice";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import EditExpensePayment from "./EditExpensePayment";
import { MdDelete } from "react-icons/md";
import { deleteMonthlyExpensePayment } from "../../api/expenses/expensesAPI";
import { notifyToastError, notifyToastSuccess } from "../toast";
import AddMonthlyExpensePayment from "./AddMonthlyExpensePayment";

const ViewPaymentAction = ({ payment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(transaction);
  const addedTime = moment(payment?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(payment?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Expense Payment Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {payment.description ? <span>{payment.description}</span> : null}
          </p>
          <br />
          <br />
          {user?.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {payment.added_by ? (
                  <span>
                    {payment.added_by.first_name} {payment.added_by.middle_name}{" "}
                    {payment.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({payment.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {payment.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {payment.updated_by ? (
                  <span>
                    {payment.updated_by.first_name}{" "}
                    {payment.updated_by.middle_name}{" "}
                    {payment.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({payment.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {payment.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const DeleteExpensePaymentAction = ({ payment, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const auth = useAuth();
  const { expenseID } = useParams();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const getPayments = async () => {
  //   const response = await getSingleMonthlyExpense({
  //     expense_id: expenseID,
  //     token: auth?.token || "",
  //   });

  //   if (response.ok && !isEmpty(response.data)) {
  //     dispatch(addMonthlyExpenseDetails(response.data.data));
  //   }
  // };

  const handleDeletePayment = async (payment) => {
    if (!isEmpty(payment)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteMonthlyExpensePayment({
        payment_id: payment?.id || "",
        token: auth?.token || "",
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete indirect expense payment, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Expense payment is successfully deleted",
          icon: "✅",
        });
        // getPayments();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete expense payment ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete{" "}
            {payment.amount_paid ? <span>{payment?.amount_paid}</span> : null} expense
            payment ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeletePayment(payment);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MonthlyExpensesPayments = () => {
  const dispatch = useDispatch();

  const { expenseID } = useParams();
  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const token = auth?.token || "";
  const { userInfo: user } = userData;

  const expense = useSelector(selectMonthlyExpenseDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderEdit = (payment) => {
    return (
      <>
        <EditExpensePayment payment={payment} />
      </>
    );
  };

  const renderView = (payment) => {
    return (
      <>
        <ViewPaymentAction payment={payment} />
      </>
    );
  };

  const renderDelete = (payment) => {
    if (user?.role_name.toLowerCase() === "clerk") {
      return (
        <>
          <p className="cursor-not-allowed py-2 flex justify-center">
            <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
          </p>
        </>
      );
    } else {
      return (
        <>
          <DeleteExpensePaymentAction payment={payment} />
        </>
      );
    }
  };

  const renderTransactions = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.transactionTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Expense Name</p>
          <p className="py-2">Paid Amount</p>
          <p className="py-2">Payment Type</p>
          <p className="py-2">Date</p>
          <p className="py-2">Actions</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderTransaction()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderTransaction = () => {
    if (!isEmpty(expense)) {
      if (expense.expense_payments) {
        return (
          <div>
            {expense.expense_payments.map((payment, index) => {
              const position = index + 1;
              return (
                <div
                  key={index}
                  className={`bg-white h-10 ${tables.transactionTable} rounded-md text-sm my-2 mx-1`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 text-center capitalize">
                    {expense?.expense_name}
                  </p>
                  <p className="py-2 pl-10">
                    {formatter.format(payment?.amount_paid || 0)}
                  </p>
                  <p className="py-2 pl-10">Supplier Balance</p>
                  <p className="py-2 text-center">{payment.payment_date}</p>
                  <p className="flex flex-row justify-center space-x-2 pl-4">
                    {renderEdit(payment)}
                    {renderView(payment)}
                    {renderDelete(payment)}
                  </p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div>
            <p className="text-center">Expense has no payments</p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p className="text-center">Expense has no payments</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 2, py: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <AddMonthlyExpensePayment/>
      </Grid>
      <Grid item sm={12}>
        <Box>
          <h5>Expense Payments</h5>
          <div className="w-[100%] pb-6">{renderTransactions()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MonthlyExpensesPayments;
