import {
  CREATE_USER,
  GET_ALL_USERS,
  GET_SINGLE_USER,
  GET_ALL_EMPLOYEES,
  GET_SINGLE_EMPLOYEE,
  CREATE_EMPLOYEE,
  ADD_EMPLOYEE_GUARANTOR,
  GET_EMPLOYEE_GUARANTOR,
  UPDATE_GUARANTOR,
  DELETE_GUARANTOR,
  GET_ALL_CUSTOMERS,
  GET_SINGLE_CUSTOMER,
  CREATE_CUSTOMER,
  GET_ALL_SUPPLIERS,
  CREATE_SUPPLIER,
  GET_SINGLE_SUPPLIER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  TOGGLE_CUSTOMER_STATUS,
  ACTIVATE_USER,
  DEACTIVATE_USER,
  UPDATE_USER_DETAILS,
  LOGOUT_URL,
  GET_ALL_DESIGNATIONS,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER,
  TOGGLE_SUPPLIER_STATUS,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  TOGGLE_EMPLOYEE_STATUS,
  GET_SUPPLIER_EXPENSES,
  GET_CUSTOMER_INVOICES,
  DELETE_SUPPLIER_BALANCE,
  UPDATE_SUPPLIER_BALANCE,
  CREATE_SUPPLIER_BALANCE,
  GET_ALL_SUPPLIER_BALANCES,
  GET_ALL_REGISTRATION_NAMES,
  GET_SINGLE_REGISTRATION_NAME,
  CREATE_REGISTRATION_NAME,
  UPDATE_REGISTRATION_NAME,
  DELETE_REGISTRATION_NAME,
} from "../apiEndPoints";
import client from "../client";

export const signOut = async ({ token }) => {
  if (!token) {
    alert("Failed to logout");
    return;
  }
  const response = await client.post(
    LOGOUT_URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );

  return response;
};
export const createUser = async ({
  role_id,
  first_name,
  middle_name,
  last_name,
  phone_number,
  gender,
  email,
  token,
}) => {
  const response = await client.post(
    CREATE_USER,
    {
      role_id,
      first_name,
      middle_name,
      last_name,
      phone_number,
      gender,
      email,
      password: "msa@123",
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllUsers = async ({ token }) => {
  const response = await client.get(
    GET_ALL_USERS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getUser = async ({ user_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_USER}/${user_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const toggleUserStatus = async ({ userId, status, token }) => {
  const slug = status === "active" ? DEACTIVATE_USER : ACTIVATE_USER;
  const response = await client.put(
    `${slug}/${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateUser = async ({
  role_id,
  first_name,
  middle_name,
  last_name,
  phone_number,
  gender,
  email,
  token,
  user_id,
}) => {
  const response = await client.put(
    `${UPDATE_USER_DETAILS}/${user_id}`,
    {
      role_id,
      first_name,
      middle_name,
      last_name,
      phone_number,
      gender,
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createEmployee = async ({
  first_name,
  middle_name,
  last_name,
  phone_number,
  gender,
  passport_number,
  salary_amount,
  license_number,
  designation_id,
  token,
}) => {
  const response = await client.post(
    CREATE_EMPLOYEE,
    {
      first_name,
      middle_name,
      last_name,
      phone_number,
      gender,
      passport_number,
      salary_amount,
      license_number,
      designation_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateEmployee = async ({
  first_name,
  middle_name,
  last_name,
  phone_number,
  gender,
  passport_number,
  salary_amount,
  license_number,
  designation_id,
  token,
}) => {
  const response = await client.put(
    UPDATE_EMPLOYEE,
    {
      first_name,
      middle_name,
      last_name,
      phone_number,
      gender,
      passport_number,
      salary_amount,
      license_number,
      designation_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const toggleEmployeeStatus = async ({ employeeId, token }) => {
  const response = await client.put(
    `${TOGGLE_EMPLOYEE_STATUS}/${employeeId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllEmployees = async ({ token }) => {
  const response = await client.get(
    GET_ALL_EMPLOYEES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getEmployee = async ({ employee_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_EMPLOYEE}/${employee_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteEmployee = async ({ employeeId, token }) => {
  const response = await client.delete(
    `${DELETE_EMPLOYEE}/${employeeId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createEmployeeGuarantor = async ({
  first_name,
  middle_name,
  last_name,
  phone_number,
  location,
  employee_id,
  token,
}) => {
  const response = await client.post(
    `${ADD_EMPLOYEE_GUARANTOR}/${employee_id}`,
    {
      first_name,
      middle_name,
      last_name,
      phone_number,
      location,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateEmployeeGuarantor = async ({
  first_name,
  middle_name,
  last_name,
  phone_number,
  location,
  employee_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_GUARANTOR}/${employee_id}`,
    {
      first_name,
      middle_name,
      last_name,
      phone_number,
      location,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteEmployeeGuarantor = async ({ employeeId, token }) => {
  const response = await client.delete(
    `${DELETE_GUARANTOR}/${employeeId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getEmployeeGuarantor = async ({ employee_id, token }) => {
  const response = await client.get(
    `${GET_EMPLOYEE_GUARANTOR}/${employee_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllCustomers = async ({ token }) => {
  const response = await client.get(
    `${GET_ALL_CUSTOMERS}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getCustomer = async ({ customerId, token }) => {
  const response = await client.get(
    `${GET_SINGLE_CUSTOMER}/${customerId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createCustomer = async ({
  customer_name,
  phone_number,
  email,
  tin,
  vrn,
  location,
  customer_type,
  contact_person_phone,
  contact_person_name,
  token,
}) => {
  const response = await client.post(
    `${CREATE_CUSTOMER}`,
    {
      customer_name,
      phone_number,
      email,
      tin,
      vrn,
      location,
      customer_type,
      contact_person_name,
      contact_person_phone,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateCustomer = async ({
  customerId,
  customer_name,
  phone_number,
  email,
  tin,
  vrn,
  location,
  customer_type,
  contact_person_phone,
  contact_person_name,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_CUSTOMER}/${customerId}`,
    {
      customer_name,
      phone_number,
      email,
      tin,
      vrn,
      location,
      customer_type,
      contact_person_name,
      contact_person_phone,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteCustomer = async ({ customerId, token }) => {
  const response = await client.delete(
    `${DELETE_CUSTOMER}/${customerId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const toggleCustomerStatus = async ({ customerId, token }) => {
  const response = await client.put(
    `${TOGGLE_CUSTOMER_STATUS}/${customerId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getCustomerInvoices = async ({ customer_id, token }) => {
  const response = await client.get(
    `${GET_CUSTOMER_INVOICES}/${customer_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllSuppliers = async ({ token }) => {
  const response = await client.get(
    `${GET_ALL_SUPPLIERS}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSupplier = async ({ supplierId, token }) => {
  const response = await client.get(
    `${GET_SINGLE_SUPPLIER}/${supplierId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteSupplier = async ({ supplierId, token }) => {
  const response = await client.delete(
    `${DELETE_SUPPLIER}/${supplierId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createSupplier = async ({
  supplier_name,
  phone_number,
  email,
  location,
  supplier_type,
  contact_person_name,
  contact_person_phone,
  token,
}) => {
  const response = await client.post(
    `${CREATE_SUPPLIER}`,
    {
      supplier_name,
      phone_number,
      email,
      location,
      supplier_type,
      contact_person_name,
      contact_person_phone,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateSupplier = async ({
  supplier_name,
  phone_number,
  email,
  location,
  supplier_type,
  contact_person_name,
  contact_person_phone,
  supplier_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_SUPPLIER}/${supplier_id}`,
    {
      supplier_name,
      phone_number,
      email,
      location,
      supplier_type,
      contact_person_name,
      contact_person_phone,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const toggleSupplierStatus = async ({ supplierId, token }) => {
  const response = await client.put(
    `${TOGGLE_SUPPLIER_STATUS}/${supplierId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSupplierExpenses = async ({ supplier_id, token }) => {
  const response = await client.get(
    `${GET_SUPPLIER_EXPENSES}/${supplier_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllDesignations = async ({ token }) => {
  const response = await client.get(
    `${GET_ALL_DESIGNATIONS}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSupplierAllBalances = async ({ token, supplier_id }) => {
  const response = await client.get(
    `${GET_ALL_SUPPLIER_BALANCES}/${supplier_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createSupplierBalance = async ({
  amount,
  date,
  description,
  supplier_id,
  token,
}) => {
  const response = await client.post(
    `${CREATE_SUPPLIER_BALANCE}/${supplier_id}`,
    {
      amount,
      date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateSupplierBalance = async ({
  balance_id,
  amount,
  date,
  description,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_SUPPLIER_BALANCE}/${balance_id}`,
    {
      amount,
      date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteSupplierBalance = async ({ balance_id, token }) => {
  const response = await client.delete(
    `${DELETE_SUPPLIER_BALANCE}/${balance_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllRegisteredNames = async ({ token }) => {
  const response = await client.get(
    `${GET_ALL_REGISTRATION_NAMES}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getRegisteredName = async ({ customerId, token }) => {
  const response = await client.get(
    `${GET_SINGLE_REGISTRATION_NAME}/${customerId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createRegisteredName = async ({
  name,
  company,
  address,
  phone_number,
  tin,
  vrn,
  description,
  name_id,
  token,
}) => {
  const response = await client.post(
    `${CREATE_REGISTRATION_NAME}`,
    {
      name,
      company,
      address,
      phone_number,
      tin,
      vrn,
      description,
      name_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateRegisteredName = async ({
  name,
  company,
  address,
  phone_number,
  tin,
  vrn,
  description,
  name_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_REGISTRATION_NAME}/${name_id}`,
    {
      name,
      company,
      address,
      phone_number,
      tin,
      vrn,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteRegisteredName = async ({ name_id, token }) => {
  const response = await client.delete(
    `${DELETE_REGISTRATION_NAME}/${name_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};
