import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { authenticationAtom } from "../../atoms/auth";
import { useAtom } from "jotai";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSupplierDetails,
  addSupplierExpenses,
  selectSupplierDetails,
} from "../../features/userSlice";
import { getSupplier, getSupplierExpenses } from "../../api/users/userAPI";
import { useParams } from "react-router-dom";
import { colors } from "../../assets/utils/colors";
import SupplierExpenses from "./SupplierExpenses";
import moment from "moment";
import { RemoveRedEye } from "@mui/icons-material";
import { Modal } from "antd";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import SupplierBalance from "./SupplierBalance";
import SupplierBalanceTransfer from "./SupplierBalanceTransfer";
import SupplierOrders from "./SupplierOrders";
import SupplierIndirectExpenses from "./SupplierIndirectExpenses";
import SupplierReceivedTransactions from "./SupplierReceivedTransactions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const ViewSupplierAction = (supplier) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const addedTime = moment(supplier?.supplier.created_at).format("DD-MM-YYYY");
  const updateTime = moment(supplier?.supplier.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Supplier Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {supplier.supplier.description ? (
              <span>{supplier.supplier.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {supplier.supplier.added_by ? (
                  <span>
                    {supplier.supplier.added_by.first_name}{" "}
                    {supplier.supplier.added_by.middle_name}{" "}
                    {supplier.supplier.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({supplier.supplier.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {supplier.supplier.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {supplier.supplier.updated_by ? (
                  <span>
                    {supplier.supplier.updated_by.first_name}{" "}
                    {supplier.supplier.updated_by.middle_name}{" "}
                    {supplier.supplier.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({supplier.supplier.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {supplier.supplier.updated_at ? (
                  <span>{updateTime}</span>
                ) : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const SupplierDetails = () => {
  const [value, setValue] = useState(0);

  const { supplierID } = useParams();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    const fetchSupplier = async () => {
      const response = await getSupplier({ supplierId: supplierID, token });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addSupplierDetails(response.data.data));
      }
    };

    const getExpenses = async () => {
      const response = await getSupplierExpenses({
        supplier_id: supplierID,
        token,
      });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addSupplierExpenses(response.data.data));
      } else {
        dispatch(addSupplierExpenses([]));
      }
    };

    fetchSupplier();
    getExpenses();
  }, [dispatch]);

  const supplier = useSelector(selectSupplierDetails);

  const renderSupplierDetails = () => {
    if (!isEmpty(supplier)) {
      return (
        <Card
          sx={{
            width: 650,
            height: 480,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">SUPPLIER DETAILS</h3>
            <div className="pt-4">
              <div className="text-lg px-6">
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Supplier Name :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {supplier.supplier_name ? (
                        <span>{supplier.supplier_name}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Phone :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {supplier.phone_number ? (
                        <span>{supplier.phone_number}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Email :</p>
                    {""}
                    <span className="text-[#F24E1E] w-[70%]">
                      {supplier.email ? <span>{supplier.email}</span> : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Status :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%] capitalize">
                      {supplier.status ? <span>{supplier.status}</span> : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Location :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {supplier.location ? (
                        <span>{supplier.location}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Supplier Type :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%] capitalize">
                      {supplier.supplier_type ? (
                        <span>{supplier.supplier_type}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                {supplier.supplier_type === "company" ? (
                  <>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        {supplier.supplier_type === "company" ? (
                          <>
                            <p className="w-[45%]">Contact Person Name :</p>{" "}
                            <span className="text-[#F24E1E] w-[55%]">
                              {supplier.contact_person_name ? (
                                <span>{supplier.contact_person_name}</span>
                              ) : (
                                <span></span>
                              )}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        {supplier.supplier_type === "company" ? (
                          <>
                            <p className="w-[45%]">Contact Person Number :</p>{" "}
                            <span className="text-[#F24E1E] w-[55%]">
                              {supplier.contact_person_phone ? (
                                <span>{supplier.contact_person_phone}</span>
                              ) : (
                                <span></span>
                              )}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        <p className="w-[45%]">View Details :</p>{" "}
                        <div className="w-[55%]">
                          <ViewSupplierAction supplier={supplier} />
                        </div>
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        <p className="w-[30%]">View Details :</p>{" "}
                        <div className="w-[70%]">
                          <ViewSupplierAction supplier={supplier} />
                        </div>
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p className="text-center">Supplier has no details</p>;
    }
  };

  const renderUserTabs = () => {
    return (
      <>
        <Grid container>
          <Grid item sm={12}>
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
              }}
              //   className="flex flex-row justify-between"
            >
              <Box sx={{ maxWidth: 1000 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  textColor={`${primary}`}
                  indicatorColor="primary"
                  sx={{ color: "#F24E1E" }}
                >
                  <Tab label="Supplier Details" {...a11yProps(0)} />
                  <Tab label="Supplier Direct Expenses" {...a11yProps(1)} />
                  <Tab label="Supplier Indirect Expenses" {...a11yProps(2)} />
                  <Tab label="Supplier Orders" {...a11yProps(3)} />
                  <Tab label="Supplier Balance" {...a11yProps(4)} />
                  <Tab label="Received Invoices Transactions" {...a11yProps(5)} />
                  <Tab label="Supplier Balance Transfers" {...a11yProps(6)} />
                </Tabs>
              </Box>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="flex flex-row justify-center pb-8">
                {renderSupplierDetails()}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SupplierExpenses />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SupplierIndirectExpenses />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <SupplierOrders />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <SupplierBalance />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <SupplierReceivedTransactions />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <SupplierBalanceTransfer />
            </TabPanel>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container sx={{ px: 2 }}>
        <Grid item sm={12}>
          {renderUserTabs()}
        </Grid>
      </Grid>
    </>
  );
};

export default SupplierDetails;
