import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrands } from "../../api/settings/settingAPI";
import { isEmpty, map } from "lodash";
import { addTruckBrands, selectTruckBrands } from "../../features/settingSlice";
import { getAllTrucks, updateTruck } from "../../api/trucks/trucksAPI";
import { getAllUsers } from "../../api/users/userAPI";
import { addAllUsers, selectAllUsers } from "../../features/userSlice";
import { addTrucks } from "../../features/truckSlice";
import { Toaster } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditTruck = ({ truck }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [truck_brand, setBrand] = useState(truck.brand_id);
  const [truck_owner, setOwner] = useState(truck.owner_id);
  const [registration_name, setRegName] = useState(truck.registration_name);
  const [registration_number, setRegNumber] = useState(
    truck.registration_number
  );
  const [truck_description, setDescription] = useState(truck.truck_description);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all trucks
  const getTrucks = async () => {
    const response = await getAllTrucks({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrucks(response.data.data));
      }
    }
  };

  useEffect(() => {
    //fetch all brands
    const getBrands = async () => {
      const response = await getAllBrands({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addTruckBrands(response.data.data));
        }
      }
    };

    //fetch all owners
    const getOwners = async () => {
      const response = await getAllUsers({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addAllUsers(response.data.data));
        }
      }
    };

    getBrands();
    getOwners();
  }, [dispatch]);

  const brands = useSelector(selectTruckBrands);
  const truckBrands = map(brands, (brand) => brand);

  const allUsers = useSelector(selectAllUsers);
  const users = map(allUsers, (user) => {
    if ("role_name" in user) {
      if (user.role_name === "Truck owner") {
        return user;
      }
    }
  });

  const filteredUsers = users.filter(function (x) {
    return x !== undefined;
  });

  const truckRegistration = async (e) => {
    e.preventDefault();

    if (!truck_brand) {
      notifyToastError({
        message: "Please select truck brand",
        icon: "❗",
      });
    } else if (!truck_owner) {
      notifyToastError({
        message: "Please select truck owner",
        icon: "❗",
      });
    } else if (!registration_name) {
      notifyToastError({
        message: "Please enter truck registration name",
        icon: "❗",
      });
    } else if (!registration_number) {
      notifyToastError({
        message: "Please enter truck registration number",
        icon: "❗",
      });
    } else {
      //start registration
      setLoading(true);

      const response = await updateTruck({
        brand_id: truck_brand,
        owner_id: truck_owner,
        registration_name,
        registration_number,
        truck_description,
        truck_id: truck.id,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getTrucks();
        setBrand("");
        setOwner("");
        setRegName("");
        setRegNumber("");
        setDescription("");

        notifyToastSuccess({
          message: "Truck is updated successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%] cursor-not-allowed"
            disabled
          >
            <svg
              class="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
            Loading...
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%]"
            onClick={(e) => truckRegistration(e)}
          >
            UPDATE TRUCK
          </Button>
        </>
      );
    }
  };

  return (
    <div>
      <div onClick={handleOpen}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
          <span>Edit</span>
        </ListItemIcon>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Edit Truck Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Truck Brand"
                  className="w-[40%]"
                  value={truck_brand}
                  onChange={(e) => setBrand(e.target.value)}
                >
                  {truckBrands.length !== 0
                    ? truckBrands.map((brand, index) => (
                        <MenuItem key={index} value={brand.id}>
                          {brand.brand_name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Truck Owner"
                  className="w-[40%]"
                  value={truck_owner}
                  onChange={(e) => setOwner(e.target.value)}
                >
                  {filteredUsers.length !== 0
                    ? filteredUsers.map((user, index) => (
                        <MenuItem key={index} value={user.id}>
                          {user.first_name} {user.middle_name} {user.last_name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Registration Name"
                  variant="outlined"
                  className="w-[40%]"
                  value={registration_name}
                  onChange={(e) => setRegName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Registration Number"
                  variant="outlined"
                  className="w-[40%]"
                  value={registration_number}
                  onChange={(e) => setRegNumber(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Truck Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={truck_description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditTruck;
