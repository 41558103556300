import { RemoveRedEye } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import { Modal } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getInvoiceTransactions } from "../../api/revenue/revenueAPI";
import { colors } from "../../assets/utils/colors";
import { authenticationAtom } from "../../atoms/auth";
import {
  addInvoiceTransactions,
  selectInvoiceTransactions,
} from "../../features/revenueSlice";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import AddTransaction from "./AddTransaction";
import EditTransaction from "./EditTransaction";

const ViewTransactionAction = (transaction) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(transaction);
  const addedTime = moment(transaction?.transaction.created_at).format(
    "DD-MM-YYYY"
  );
  const updateTime = moment(transaction?.transaction.updated_at).format(
    "DD-MM-YYYY"
  );

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Transaction Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {transaction.transaction.description ? (
              <span>{transaction.transaction.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user?.role_name === "admin" || user?.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {transaction.transaction.added_by ? (
                  <span>
                    {transaction.transaction.added_by.first_name}{" "}
                    {transaction.transaction.added_by.middle_name}{" "}
                    {transaction.transaction.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({transaction.transaction.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {transaction.transaction.created_at ? (
                  <span>{addedTime}</span>
                ) : null}
              </p>
              <p>
                Updated by :{" "}
                {transaction.transaction.updated_by ? (
                  <span>
                    {transaction.transaction.updated_by.first_name}{" "}
                    {transaction.transaction.updated_by.middle_name}{" "}
                    {transaction.transaction.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({transaction.transaction.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {transaction.transaction.updated_at ? (
                  <span>{updateTime}</span>
                ) : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const Transactions = () => {
  const dispatch = useDispatch();

  const { invoiceID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all
  const getTransactions = async () => {
    const response = await getInvoiceTransactions({
      invoice_id: invoiceID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addInvoiceTransactions(response.data.data));
    } else {
      dispatch(addInvoiceTransactions([]));
    }
  };

  useEffect(() => {
    getTransactions();
  }, [dispatch]);

  const transactions = useSelector(selectInvoiceTransactions);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let formatterUS = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });


  const renderEdit = (transaction) => {
    return (
      <>
        <EditTransaction transaction={transaction} />
      </>
    );
  };

  const renderView = (transaction) => {
    return (
      <>
        <ViewTransactionAction transaction={transaction} />
      </>
    );
  };

  const renderTransactions = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.transactionTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Invoice No</p>
          <p className="py-2">Paid Amount TZS</p>
          <p className="py-2">Paid Amount USD</p>
          <p className="py-2">Payment Type</p>
          <p className="py-2">Date</p>
          <p className="py-2">Actions</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderTransaction()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderTransaction = () => {
    if (transactions.length !== 0) {
      return (
        <div>
          {transactions.map((transaction, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.transactionTable} rounded-md text-sm my-2 mx-1`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">{transaction.invoice_number}</p>
                <p className="py-2 pl-7">
                  {formatter.format(transaction.amount_paid)}
                </p>
                <p className="py-2 pl-7">
                  {formatterUS.format(transaction?.amount_paid_usd || 0)}
                </p>
                <p className="py-2 pl-8">
                  {transaction?.payment_type.payment_name}
                </p>
                <p className="py-2 text-center">{transaction.payment_date}</p>
                <p className="flex flex-row justify-center space-x-2 pl-4">
                  {renderEdit(transaction)}
                  {renderView(transaction)}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">Invoice has no transactions</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 2, py: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <AddTransaction />
      </Grid>
      <Grid item sm={12}>
        <Box>
          <h5>Invoice Transactions</h5>
          <div className="w-[100%] pb-6">{renderTransactions()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Transactions;
