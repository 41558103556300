import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FaUserPlus } from "react-icons/fa";
import { Button, Autocomplete, Modal, Box, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  createMonthlyExpense,
  getAllMonthlyExpenses,
} from "../../api/expenses/expensesAPI";
import { getAllSuppliers } from "../../api/users/userAPI";
import { addSuppliers, selectSuppliers } from "../../features/userSlice";
import { addMonthlyExpenses } from "../../features/expensesSlice";
import useAuth from "../../hooks/useAuth";
import { addTrucks, selectTrucks } from "../../features/truckSlice";
import { getAllTrucks } from "../../api/trucks/trucksAPI";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddMonthlyExpense = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [expense_name, setExpense] = useState("");
  const [amount, setAmount] = useState("");
  const [truck, setTruck] = useState("");
  const [supplier, setSupplier] = useState("");
  const [expense_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const auth = useAuth();

  const truckOnChange = (e, value) => {
    setTruck(value);
  };

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  //fetch all monthly expenses
  const getExpenses = async () => {
    const response = await getAllMonthlyExpenses({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addMonthlyExpenses(response.data.data));
    }
  };

  //fetch all trucks and suppliers
  const getTrucks = async () => {
    const response = await getAllTrucks({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTrucks(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  useEffect(() => {
    getTrucks();
    getSuppliers();
  }, [dispatch]);

  const trucks = useSelector(selectTrucks);
  const suppliers = useSelector(selectSuppliers);

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));

  const sortedTrucks = trucks.map((truck) => ({
    id: truck.id,
    label: truck.registration_number,
  }));

  const saveExpense = async (e) => {
    e.preventDefault();

    if (!amount) {
      notifyToastError({
        message: "Please enter paid amount",
        icon: "❗",
      });
    } else if (!expense_name) {
      notifyToastError({
        message: "Please enter expense name",
        icon: "❗",
      });
    } else if (!truck) {
      notifyToastError({
        message: "Please select truck",
        icon: "❗",
      });
    } else if (!supplier) {
      notifyToastError({
        message: "Please select supplier",
        icon: "❗",
      });
    } else if (!expense_date) {
      notifyToastError({
        message: "Please select expense date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(expense_date).format("YYYY-MM-DD");
      const month = moment(expense_date).format("M");
      //start registration
      setLoading(true);
      const response = await createMonthlyExpense({
        truck_id: truck.id,
        supplier_id: supplier.id,
        amount,
        expense_name,
        expense_date: date,
        month,
        description,
        token: auth?.token || "",
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setAmount("");
        setExpense("");
        setTruck("");
        setSupplier("");
        setDate(null);
        setDescription("");
        getExpenses();

        notifyToastSuccess({
          message: "Truck indirect expense is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => saveExpense(e)}
        >
          SAVE TRUCK INDIRECT EXPENSE
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <p className="flex justify-center items-center">
            <FaUserPlus className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">
            Add Truck Indirect Expense
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new expense to an existing indirect expenses
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">
              Add Truck Indirect Expense Details
            </h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Expense Name"
                  variant="outlined"
                  type={"text"}
                  className="w-[40%]"
                  value={expense_name}
                  onChange={(e) => setExpense(e.target.value)}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedTrucks}
                  size="small"
                  className="w-[40%]"
                  value={truck}
                  onChange={truckOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Truck" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex  flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Paid Amount"
                  variant="outlined"
                  type={"number"}
                  className="w-[40%]"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[40%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select payment date"
                    value={expense_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[82%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddMonthlyExpense;
