import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSingleTruck,
  getAllTrucks,
  updateTruckImage,
} from "../../api/trucks/trucksAPI";
import { addTruckDetails, selectTruckDetails } from "../../features/truckSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { colors } from "../../assets/utils/colors";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button, Grid, IconButton } from "@mui/material";
import { addTrucks } from "../../features/truckSlice";
import truckImg from "../../assets/media/images/truck_3.jpg";
import { notifyToastError, notifyToastSuccess } from "../toast";
import clsx from "clsx";
import { useAtom } from "jotai";
import { Image, Modal } from "antd";
import { authenticationAtom } from "../../atoms/auth";
import useAuth from "../../hooks/useAuth";
import { RemoveRedEye } from "@mui/icons-material";
import moment from "moment";
import { isMobileOnly } from "react-device-detect";

const ViewTruckAction = (truck) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(truck);
  const addedTime = moment(truck?.truck.created_at).format("DD-MM-YYYY");
  const updateTime = moment(truck?.truck.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Truck Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {truck.truck.truck_description ? (
              <span>{truck.truck.truck_description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user?.role_name === "admin" || user?.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {truck.truck.added_by ? (
                  <span>
                    {truck.truck.added_by.first_name}{" "}
                    {truck.truck.added_by.middle_name}{" "}
                    {truck.truck.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({truck.truck.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {truck.truck.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {truck.truck.updated_by ? (
                  <span>
                    {truck.truck.updated_by.first_name}{" "}
                    {truck.truck.updated_by.middle_name}{" "}
                    {truck.truck.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({truck.truck.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {truck.truck.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const AdminTruck = () => {
  const dispatch = useDispatch();
  const { truckID } = useParams();

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const auth = useAuth();
  const token = auth?.token || "";

  const [uploadLoading, setUploadLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const [editImage, setEditImage] = useState(false);

  const inputFileRef = React.useRef();

  const getTrucks = async () => {
    const response = await getAllTrucks({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrucks(response.data.data));
      }
    }
  };

  const getTruck = async () => {
    const response = await getSingleTruck({ truck_id: truckID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTruckDetails(response.data.data));
      }
    }
  };

  // uploading the image
  const onFileChangeCapture = async (e, id) => {
    /*Selected files data can be collected here.*/
    e?.preventDefault();
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const uploadFile = async (id) => {
    if (image) {
      if (window.confirm("Are you sure you want to upload the file")) {
        let formData = new FormData();
        formData.append("truck_image", image);
        setUploadLoading(true);
        const result = await updateTruckImage({
          truck_id: id,
          formData,
          token,
        });
        checkResponse(result);
      } else {
        alert("Action is not permitted");
      }
    } else {
      alert("No image to be uploaded");
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setUploadLoading(false);
        setEditImage(false);
        getTruck();
        getTrucks();
        notifyToastSuccess({
          message: "Image is successfully uploaded",
          icon: "✅",
        });
      } else {
        setUploadLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setUploadLoading(false);
    } else {
      if (response.status > 210) {
        setUploadLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setUploadLoading(false);
    }
  };

  useEffect(() => {
    getTruck();
  }, [dispatch]);

  const truck = useSelector(selectTruckDetails);

  const handleEditImage = () => {
    setEditImage(true);
  };

  const renderImageAndEdit = () => {
    if (!editImage) {
      return (
        <div className="h-[400px] w-[462px] rounded-md shadow-xl">
          <Image
            width={460}
            height={330}
            className="rounded-md"
            src={truck?.truck_image || truckImg}
          />
          <div className="flex flex-row justify-center pt-2">
            <Button
              size="large"
              variant="contained"
              className="w-[96%]"
              onClick={() => handleEditImage()}
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {image && previewImage ? (
            <div>
              <div className="p-4 flex flex-col justify-center items-center mx-2 border-2 h-[350px] w-[300px] border-gray-300 border-dashed rounded-md shadow-xl cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <p>Image preview</p>
                {previewImage && (
                  <img
                    src={previewImage}
                    className="h-64 w-48 object-contain"
                  />
                )}
                <div className="flex items-center space-x-4 mt-4">
                  <button
                    onClick={() => {
                      setImage(null);
                      setPreviewImage(null);
                    }}
                    disabled={uploadLoading}
                    type="button"
                    className={clsx(
                      "py-2.5 px-5 flex items-center justify-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700",
                      uploadLoading && "cursor-not-allowed opacity-50"
                    )}
                  >
                    Edit
                  </button>

                  <button
                    onClick={() => {
                      uploadFile(truck?.id);
                    }}
                    disabled={uploadLoading}
                    type="button"
                    className={clsx(
                      "py-2.5 px-5 flex items-center justify-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700",
                      uploadLoading && "cursor-not-allowed opacity-50"
                    )}
                  >
                    {uploadLoading ? (
                      <>
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="">Loading...</span>
                      </>
                    ) : (
                      "Upload Image"
                    )}
                  </button>
                </div>
              </div>
              <div className="flex flex-row justify-center pt-2">
                <Button
                  size="large"
                  variant="contained"
                  className="w-[96%]"
                  onClick={() => {
                    setImage(null);
                    setPreviewImage(null);
                    setEditImage(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center h-[350px] w-[300px] border-2 border-gray-300 border-dashed rounded-md shadow-xl cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center ">
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10 mb-3 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span>
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG, GIF
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  ref={inputFileRef}
                  onChangeCapture={(e) => {
                    onFileChangeCapture(e, truck?.id);
                  }}
                  type="file"
                  className="hidden"
                />
              </label>
              <div className="flex flex-row justify-center pt-2">
                <Button
                  size="large"
                  variant="contained"
                  className="w-[96%]"
                  onClick={() => {
                    setImage(null);
                    setPreviewImage(null);
                    setEditImage(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  const renderTruckCurrentLocation = (truck) => {
    if (!isEmpty(truck)) {
      //check active trip
      if (truck.trips) {
        //check active trips
        const activeTripsArray = truck.trips.filter((trip) => {
          //filtering array value
          return trip.trip_status === "open";
        });

        if (activeTripsArray.length !== 0) {
          //get active trip
          const activeTrip = activeTripsArray[0];
          //check trip routes
          if (activeTrip.trip_routes) {
            const routes = activeTrip.trip_routes;

            const activeRoutesArray = routes.filter((route) => {
              //filtering array value
              return route.status === "open";
            });

            if (activeRoutesArray.length !== 0) {
              const activeRoute = activeRoutesArray[0];

              return <span>{activeRoute.current_position}</span>;
            } else {
              //not on trip
              //return initial location
              return <span>Dar Es Salaam</span>;
            }
          } else {
            //not on trip
            //return initial location
            return <span>Dar Es Salaam</span>;
          }
        } else {
          //not on trip
          //return initial location
          return <span>Dar Es Salaam</span>;
        }
      } else {
        //not on trip
        //return initial location
        return <span>Dar Es Salaam</span>;
      }
    } else {
      return null;
    }
  };

  const renderMobileTruckDetails = () => {
    if (!isEmpty(truck)) {
      return (
        <Card
          sx={{
            width: 360,
            height: 300,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">TRUCK DETAILS</h3>
            <div className="flex justify-center pt-4">
              <div className="text-base">
                <p className="py-2">
                  Registration Number :{" "}
                  <span>
                    {truck.registration_number ? (
                      <span>{truck.registration_number}</span>
                    ) : null}
                  </span>
                </p>
                <p className="py-2">
                  Registration Name :{" "}
                  <span>
                    {truck.registration_name ? (
                      <span>{truck.registration_name}</span>
                    ) : null}
                  </span>
                </p>
                <p className="py-2">
                  Truck Brand :{" "}
                  <span>
                    {truck.brand_name ? (
                      <span className="capitalize">{truck.brand_name}</span>
                    ) : null}
                  </span>
                </p>
                <p className="py-2">
                  Truck Owner :{" "}
                  <span className="text-[#F24E1E] hover:underline cursor-pointer">
                    {truck.owner ? (
                      <span>
                        {truck.owner.first_name} {truck.owner.middle_name}{" "}
                        {truck.owner.last_name}
                      </span>
                    ) : null}
                  </span>
                </p>
                <p className="py-2">
                  Trailer :{" "}
                  <span className="text-[#F24E1E] hover:underline cursor-pointer">
                    {truck.trailer ? (
                      <span>{truck.trailer.registration_number}</span>
                    ) : null}
                  </span>
                </p>
                <p className="py-2">
                    Truck Position : <span>{truck.isOnTrip > 0 ? <span>{renderTruckCurrentLocation(truck)}</span> : <span>Dar Es Salaam</span>}</span>
                  </p>
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      return <p className="text-center">Truck has no details</p>;
    }
  };

  const renderTruckDetails = () => {
    if (!isEmpty(truck)) {
      return (
        <div className="flex flex-row gap-8">
          <Card
            sx={{
              width: 600,
              height: 400,
              background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
            }}
          >
            <CardContent>
              <h3 className="text-center text-xl py-2">TRUCK DETAILS</h3>
              <div className="flex justify-center pt-4">
                <div className="text-lg">
                  <p className="py-2">
                    Registration Number :{" "}
                    <span>
                      {truck.registration_number ? (
                        <span>{truck.registration_number}</span>
                      ) : null}
                    </span>
                  </p>
                  <p className="py-2">
                    Registration Name :{" "}
                    <span>
                      {truck.registration_name ? (
                        <span>{truck.registration_name}</span>
                      ) : null}
                    </span>
                  </p>
                  <p className="py-2">
                    Truck Brand :{" "}
                    <span>
                      {truck.brand_name ? (
                        <span className="capitalize">{truck.brand_name}</span>
                      ) : null}
                    </span>
                  </p>
                  {user?.role_name === "Clerk" ||
                  user?.role_name === "clerk" ? null : (
                    <p className="py-2">
                      Truck Owner :{" "}
                      <span className="text-[#F24E1E] hover:underline cursor-pointer">
                        {truck.owner ? (
                          <span>
                            {truck.owner.first_name} {truck.owner.middle_name}{" "}
                            {truck.owner.last_name}
                          </span>
                        ) : null}
                      </span>
                    </p>
                  )}
                  <p className="py-2">
                    Trailer :{" "}
                    <span className="text-[#F24E1E] hover:underline cursor-pointer">
                      {truck.trailer ? (
                        <span>{truck.trailer.registration_number}</span>
                      ) : null}
                    </span>
                  </p>
                  <p className="py-2">
                    Truck Position : <span>{truck.isOnTrip > 0 ? <span>{renderTruckCurrentLocation(truck)}</span> : <span>Dar Es Salaam</span>}</span>
                  </p>
                  <div className="py-2">
                    View Details : <ViewTruckAction truck={truck} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {user?.role_name === "Truck owner" ? (
            <span></span>
          ) : (
            <div>
              {truck.truck_image === null ? (
                <div>
                  {image && previewImage ? (
                    <div className="p-4 flex flex-col justify-center items-center mx-4 border-2 h-[380px] w-[300px] border-gray-300 border-dashed rounded-md shadow-xl cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                      <p>Image preview</p>
                      {previewImage && (
                        <img
                          src={previewImage}
                          className="h-64 w-48 object-contain"
                        />
                      )}
                      <div className="flex items-center space-x-4 mt-4">
                        <button
                          onClick={() => {
                            setImage(null);
                            setPreviewImage(null);
                          }}
                          disabled={uploadLoading}
                          type="button"
                          className={clsx(
                            "py-2.5 px-5 flex items-center justify-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700",
                            uploadLoading && "cursor-not-allowed opacity-50"
                          )}
                        >
                          Edit
                        </button>

                        <button
                          onClick={() => {
                            uploadFile(truck?.id);
                          }}
                          disabled={uploadLoading}
                          type="button"
                          className={clsx(
                            "py-2.5 px-5 flex items-center justify-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700",
                            uploadLoading && "cursor-not-allowed opacity-50"
                          )}
                        >
                          {uploadLoading ? (
                            <>
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="">Loading...</span>
                            </>
                          ) : (
                            "Upload Image"
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center h-[380px] w-[300px] border-2 border-gray-300 border-dashed rounded-md shadow-xl cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center ">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          SVG, PNG, JPG, GIF
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        ref={inputFileRef}
                        onChangeCapture={(e) => {
                          onFileChangeCapture(e, truck?.id);
                        }}
                        type="file"
                        className="hidden"
                      />
                    </label>
                  )}
                </div>
              ) : (
                <>{renderImageAndEdit()}</>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return <p className="text-center">Truck has no details</p>;
    }
  };

  return (
    <>
      {isMobileOnly ? (
        <Grid container>
          <Grid item sm={12} xs={12}>
            {renderMobileTruckDetails()}
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={{ py: 2 }}>
          <Grid
            item
            sm={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center", }}
          >
            {renderTruckDetails()}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AdminTruck;
