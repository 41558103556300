import React, { useEffect, useState } from "react";
import flag from "../../assets/media/images/flag_2.png";
import truckImg from "../../assets/media/images/truck_1.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import EditTruck from "../trucks/EditTruck";
import { getAllTrucks, deleteTruck } from "../../api/trucks/trucksAPI";
import { addTruckDetails, addTrucks } from "../../features/truckSlice";
import AssignTrailerToTruck from "../trucks/AssignTrailerToTruck";
import { Toaster } from "react-hot-toast";
import UnassignTrailerToTruck from "../trucks/UnassignTrailerToTruck";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { getAllTrips } from "../../api/trips/tripsAPI";
import { addTrips, selectTrips } from "../../features/tripsSlice";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";

const DeleteTruckAction = ({ truck, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all trucks
  const getTrucks = async () => {
    const response = await getAllTrucks({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrucks(response.data.data));
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteTruck = async (truck, e) => {
    e.preventDefault();

    setIsLoading(true);

    const response = await deleteTruck({ truck_id: truck?.id || "", token });

    if (response) {
      //handle response
      checkResponse(response);
      // onSuccess();
    } else {
      notifyToastError({
        message: "Failed to delete truck , Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setIsLoading(false);
        handleClose();
        notifyToastSuccess({
          message: "Truck is deleted successfully",
          icon: "✅",
        });
        getTrucks();
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      setIsLoading(false);
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <div onClick={handleClickOpen} disabled={isLoading}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
          <span>Delete</span>
        </ListItemIcon>
      </div>
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {isLoading ? (
            <>
              <DialogTitle id="alert-dialog-title">
                {"Deleting Truck..."}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <CircularProgress size={28} />
                </DialogContentText>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle id="alert-dialog-title">
                {"Delete truck ?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Area you sure you want delete truck{" "}
                  {truck?.registration_number || ""} ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={(e) => {
                    handleDeleteTruck(truck, e);
                  }}
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    </>
  );
};

const TruckSummaryCard = ({ truck }) => {
  
  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const auth = useAuth();
  const token = auth?.token || "";

  const [open, setOpen] = useState(false);

  // const hide = () => {
  //   setOpen(false);
  // };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectedTruck = async () => {
    //get truck details the navigate to truck page
    dispatch(addTruckDetails(truck));
    navigate(`/trucks/${truck?.id}`);
  };

  const getTrips = async () => {
    const response = await getAllTrips({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrips(response.data.data));
      }
    }
  };

  useEffect(() => {
    getTrips();
  }, [dispatch]);

  const trips = useSelector(selectTrips);

  const truckTrips = trips.filter((trip) => {
    //filtering array value
    return trip.truck_id === truck.id;
  });

  const renderMoreOptions = () => {
    return (
      <>
        <Popover
          placement="bottomRight"
          content={
            <div className="flex flex-col">
              <div className="cursor-pointer hover:bg-gray-100 h-8 w-full rounded-md">
                <div className="pt-1.5">
                  <EditTruck truck={truck} />
                </div>
              </div>
              <div className="cursor-pointer hover:bg-gray-100 h-8 w-full rounded-md">
                {truck.trailer ? (
                  <div className="pt-1.5">
                    <UnassignTrailerToTruck truck={truck} />
                  </div>
                ) : (
                  <div className="pt-1.5">
                    <AssignTrailerToTruck truck={truck} />
                  </div>
                )}
              </div>
              <div className="cursor-pointer hover:bg-gray-100 h-8 w-full rounded-md">
                <div className="pt-1.5">
                  <DeleteTruckAction truck={truck} />
                </div>
              </div>
            </div>
          }
          title=""
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <MoreVertIcon />
        </Popover>
      </>
    );
  };

  const renderClerkMoreOptions = () => {
    return (
      <>
        <Popover
          placement="bottomRight"
          content={
            <div className="flex flex-col">
              <div className="cursor-pointer hover:bg-gray-100 h-8 w-full rounded-md">
                {truck.trailer ? (
                  <div className="pt-1.5">
                    <UnassignTrailerToTruck truck={truck} />
                  </div>
                ) : (
                  <div className="pt-1.5">
                    <AssignTrailerToTruck truck={truck} />
                  </div>
                )}
              </div>
            </div>
          }
          title=""
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <MoreVertIcon />
        </Popover>
      </>
    );
  };

  return (
    <div className="relative bg-gradient-to-r from-[#505050] to-[#B98D8D] w-auto md:w-auto rounded-md border-2 cursor-pointer">
      <Toaster />
      <div className="px-2 py-3">
        <div className="flex flex-row relative">
          <div onClick={handleSelectedTruck} className="w-[40%] relative group">
            <img
              src={truck?.truck_image || truckImg}
              alt="Truck Image"
              className="h-[120px] w-[120px] rounded-md object-contain object-center"
            />
          </div>
          <div className="w-[60%]">
            <div className="flex flex-row justify-between">
              <div
                onClick={handleSelectedTruck}
                className="text-white text-sm font semibold"
              >
                {user?.role_name === "Clerk" ||
                user?.role_name === "clerk" ? null : (
                  <p>
                    {truck?.owner ? (
                      <span className="truncate">
                        {truck.owner.first_name}{" "}
                        {truck.owner.middle_name.charAt(0)}{" "}
                        {truck.owner.last_name}
                      </span>
                    ) : null}
                  </p>
                )}
                <p>
                  {truckTrips ? (
                    <span>{truckTrips.length} Trips</span>
                  ) : (
                    <span>0 Trips</span>
                  )}
                </p>

                <p className="capitalize">
                  {truck?.brand_name ? (
                    <span className="uppercase">{truck.brand_name}</span>
                  ) : null}
                </p>
              </div>
              <div className="">
                {user?.role_name === "Truck owner" ||
                user?.role_name.toLowerCase() === "truck owner" ? (
                  <span></span>
                ) : user?.role_name === "Clerk" ||
                  user?.role_name.toLowerCase() === "clerk" ? (
                  <span>{renderClerkMoreOptions()}</span>
                ) : (
                  <span>{renderMoreOptions()}</span>
                )}
              </div>
            </div>
            <div onClick={handleSelectedTruck} className="py-1">
              <div className="flex flex-row gap-2">
                <div className="bg-[#FFBA0A] h-[54px] w-[110px]">
                  <div className="flex flex-row gap-2 justify-between">
                    <div className="w-[30%] py-1 px-1">
                      <img src={flag} alt="flag" />
                    </div>
                    <div className="flex flex-col px-1 text-xl font-semibold">
                      <p>
                        {truck?.registration_number ? (
                          <span className="uppercase">
                            {truck.registration_number.slice(0, 5)}
                          </span>
                        ) : null}
                      </p>
                      <p className="items-end">
                        {truck?.registration_number ? (
                          <span className="uppercase">
                            {truck.registration_number.slice(-3)}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  </div>
                </div>
                {truck?.trailer_registration_number ? (
                  <div className="bg-[#FFBA0A] h-[54px] w-[110px]">
                    <div className="flex flex-row gap-2 justify-between">
                      <div className="w-[30%] py-1 px-1">
                        <img src={flag} alt="flag" />
                      </div>
                      <div className="flex flex-col px-1 text-xl font-semibold">
                        <p>
                          {truck?.trailer_registration_number ? (
                            <span className="uppercase">
                              {truck.trailer_registration_number.slice(0, 5)}
                            </span>
                          ) : null}
                        </p>
                        <p className="items-end">
                          {truck?.trailer_registration_number ? (
                            <span className="uppercase">
                              {truck.trailer_registration_number.slice(-3)}
                            </span>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TruckSummaryCard;
