import React, { useEffect, useState } from "react";
import tables from "../../styles/tables.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { isEmpty } from "lodash";
import { Box, Grid, Divider, Chip, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { getSupplierInvoicesTransactions } from "../../api/revenue/revenueAPI";
import {
  addSupplierInvoicesTransactions,
  selectSupplierInvoicesTransaction,
} from "../../features/revenueSlice";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { Modal } from "antd";
import moment from "moment";
import { selectSupplierDetails } from "../../features/userSlice";

const ViewTransactionAction = ({ transaction }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(transaction);
  const addedTime = moment(transaction?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(transaction?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Transaction Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {transaction?.description ? (
              <span>{transaction.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user?.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {transaction.added_by ? (
                  <span>
                    {transaction.added_by.first_name}{" "}
                    {transaction.added_by.middle_name}{" "}
                    {transaction.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({transaction.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {transaction.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {transaction.updated_by ? (
                  <span>
                    {transaction.updated_by.first_name}{" "}
                    {transaction.updated_by.middle_name}{" "}
                    {transaction.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({transaction.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {transaction.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const SupplierReceivedTransactions = () => {
  const dispatch = useDispatch();
  const { supplierID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all balances
  const getTransactions = async () => {
    const response = await getSupplierInvoicesTransactions({
      supplier_id: supplierID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSupplierInvoicesTransactions(response.data.data));
    } else {
      dispatch(addSupplierInvoicesTransactions([]));
    }
  };

  useEffect(() => {
    getTransactions();
  }, [dispatch]);

  const supplier = useSelector(selectSupplierDetails);
  const transactions = useSelector(selectSupplierInvoicesTransaction);
  const sortedTransactions = transactions
    .slice()
    .sort((a, b) => new Date(b.payment_date) - new Date(a.payment_date));

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let formatterUS = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let transactionAmountTZS = 0;
  let transactionAmountUSD = 0;

  if (sortedTransactions.length !== 0) {
    for (const value of sortedTransactions) {
      transactionAmountTZS += value.amount_paid;
      transactionAmountUSD += value.amount_paid_usd;
    }
  }

  const renderTransactions = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.supplierInvoicesTransactionsTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Invoice No</p>
          <p className="py-2">Truck</p>
          <p className="py-2">Paid Amount TZS</p>
          <p className="py-2">Paid Amount USD</p>
          <p className="py-2">Payment Type</p>
          <p className="py-2">Date</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderTransaction()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderTransaction = () => {
    if (sortedTransactions.length !== 0) {
      return (
        <div>
          {sortedTransactions.map((transaction, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.supplierInvoicesTransactionsTable} rounded-md text-sm my-2 mx-1`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {transaction?.invoice?.invoice_number}
                </p>
                <p className="py-2 text-center">
                  {transaction?.invoice?.truck_reg_number}
                </p>
                <p className="py-2 pl-5">
                  {formatter.format(transaction.amount_paid)}
                </p>
                <p className="py-2 pl-7">
                  {formatterUS.format(transaction?.amount_paid_usd || 0)}
                </p>
                <p className="py-2 pl-8">
                  {transaction?.payment_type.payment_name}
                </p>
                <p className="py-2 text-center">{transaction.payment_date}</p>
                <p className="flex flex-row justify-center space-x-2 pl-4">
                  <ViewTransactionAction transaction={transaction} />
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">Supplier invoices has no transactions</p>
        </div>
      );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="flex flex-row justify-between pb-2">
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_names || ""}
            </h5>
          </Box>
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_name || ""}
            </h5>
          </Box>
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_names || ""}
            </h5>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Divider>
            <Chip
              className="font-semibold"
              label={`TRANSACTIONS TOTAL AMOUNT IN TZS: ${formatter.format(
                transactionAmountTZS
              )}`}
            />{" "}
            <Chip
              className="font-semibold"
              label={`TRANSACTIONS TOTAL AMOUNT IN USD: ${formatterUS.format(
                transactionAmountUSD
              )}`}
            />
          </Divider>
          <Grid item sm={12}>
            <Box>
              <h5>Invoice Transactions</h5>
              <div className="w-[100%] pb-6">{renderTransactions()}</div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SupplierReceivedTransactions;
