import React, { useState, useRef } from "react";
import { RemoveRedEye, Edit } from "@mui/icons-material";
import AddEmployee from "./AddEmployee";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getAllEmployees, toggleEmployeeStatus } from "../../api/users/userAPI";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import EditEmployee from "./EditEmployee";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { isEmpty } from "lodash";
import { deleteEmployee } from "../../api/users/userAPI";
import { MdDelete } from "react-icons/md";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployees,
  addSearchedEmployees,
  selectSearchedEmployees,
} from "../../features/userSlice";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import useAuth from "../../hooks/useAuth";
import { Input, Popover, Space } from "antd";
import { AiOutlineSearch } from "react-icons/ai";

const ChangeEmployeeStatusActions = ({
  employeeId,
  status,
  handleClose = () => {},
}) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const token = auth?.token || "";

  const { mutate, isLoading, isError } = useMutation(toggleEmployeeStatus, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["employees"] });
        queryClient.invalidateQueries({ queryKey: ["employees", employeeId] });

        notifyToastSuccess({
          message: "Successfully changed employee status",
          icon: "✅ ",
        });
        handleClose();
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong changing employee status");
      console.log(error);
    },
  });

  const toggleStatus = () => {
    if (
      window.confirm(
        "Are you sure you want to  " +
          (status === "active"
            ? "deactivate employee status?"
            : "activate employee status?")
      )
    ) {
      mutate({ employeeId, token });
    } else {
      alert("Action not permitted");
    }
  };

  return (
    <div className="">
      <div className="mt-2">
        <button
          onClick={() => {
            console.log("Delete item ", employeeId);
            toggleStatus();
          }}
          disabled={isLoading}
          className={clsx(
            "px-2 py-1 font-semibold rounded-md bg-violet-400 text-gray-900",
            isLoading && "opacity-60"
          )}
        >
          {isLoading ? (
            <span className="flex items-center space-x-2">
              <p>Please Wait</p>
              <CircularProgress size={24} sx={{ color: "white" }} />
            </span>
          ) : (
            <>{status === "active" ? "Deactivate" : "Activate"}</>
          )}
        </button>
      </div>
    </div>
  );
};

const DeleteEmployeeAction = ({ employee, onSuccess = () => {} }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all users
  const getEmployees = async () => {
    const response = await getAllEmployees({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployees(response.data.data));
      }
    }
  };

  const handleDeleteE = async (employee) => {
    if (!isEmpty(employee)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteEmployee({
        employeeId: employee?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
        queryClient.invalidateQueries({ queryKey: ["employees"] });
      } else {
        notifyToastError({
          message: "Failed to delete employee, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Employee is successfully deleted",
          icon: "✅",
        });
        getEmployees();
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={26} />
            </>
          ) : (
            <>
              <IconButton onClick={handleClickOpen} disabled={isLoading}>
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </IconButton>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Employee</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete employee{" "}
              {employee?.first_name || ""} {employee?.middle_name || ""}{" "}
              {employee?.last_name || ""}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleDeleteE(employee);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditEmployeeAction = ({ employee }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={() => {
          handleOpen();
        }}
      >
        <Edit />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="relative bg-white max-w-2xl p-4 space-y-2">
            <div className="absolute top-4 right-4">
              <button
                type="button"
                className="text-white bg-red-700 hover:bg-red-800  font-medium rounded-sm text-sm px-5 py-2 mb-2 "
                onClick={handleClose}
              >
                <XMarkIcon className="h-6 w-6 text-white" />
              </button>
            </div>
            <div>
              <EditEmployee employee={employee} />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const Employees = () => {
  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const auth = useAuth();
  const token = auth?.token || "";

  const [searchText, setSearchText] = useState("");
  const [searchEmployee, setSearchEmployee] = useState(false);
  const searchInput = useRef(null);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const { isLoading, error, data } = useQuery(["employees"], () =>
    getAllEmployees({ token })
  );

  const employees = data?.data?.data || [];
  const searchedEmployees = useSelector(selectSearchedEmployees);

  const handleSearch = () => {
    if (employees.length !== 0) {
      if (searchText) {
        const results = employees.filter((employee) => {
          //get user name
          const name = employee.first_name
            .concat(" ", employee.middle_name)
            .concat(" ", employee.last_name);
          // console.log(name);
          //filtering array value
          return name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

        dispatch(addSearchedEmployees(results));
        setSearchEmployee(true);
      }
    }
  };

  const handleReset = () => {
    setSearchEmployee(false);
    dispatch(addSearchedEmployees([]));
    setSearchText("");
  };

  const employeeData = () => {
    return (
      <div
        style={{
          padding: 4,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search employee`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() => handleSearch()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={hide}>
            close
          </Button>
        </Space>
      </div>
    );
  };

  const renderEmployees = () => {
    if (isLoading) return "Loading...";

    if (error) return "An error has occurred: " + error.message;

    const renderPhoneNumber = (number) => {
      const phone = JSON.parse(number);

      if (typeof phone === "object") {
        return (
          <span>
            {phone?.map((phoneNumber, index) => (
              <>
                {phoneNumber}
                <br />
              </>
            ))}
          </span>
        );
      }
      if (typeof phone === "string" || typeof phone === "number") {
        return <span>{phone}</span>;
      }
      return null;
    };

    return (
      <div className="">
        <div className="text-slate-900">
          <div className="overflow-x-auto">
            <table className="min-w-full  bg-[#F0F0F0]">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead className="bg-[#EEDEDE]">
                <tr className="text-left">
                  <th className="p-3">#</th>
                  <th className="p-3 flex -flex-row justify-between">
                    <span>Employee</span>{" "}
                    <Popover
                      placement="leftTop"
                      content={employeeData()}
                      title="Search Employee"
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <p className="px-4 py-2 cursor-pointer">
                        <AiOutlineSearch />
                      </p>
                    </Popover>
                  </th>
                  <th className="p-3">Contacts</th>
                  <th className="p-3">Gender</th>
                  <th className="p-3">Status</th>
                  <th className="p-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {employees && employees?.length === 0 && (
                  <td className="p-3">
                    <td
                      colSpan={5}
                      className="flex items-center justify-center py-4"
                    >
                      No employees saved
                    </td>
                  </td>
                )}
                {searchEmployee ? (
                  <>
                    {searchedEmployees &&
                      searchedEmployees?.length > 0 &&
                      searchedEmployees.map((employee, index) => {
                        return (
                          <tr
                            key={employee?.id || ""}
                            className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                          >
                            <td className="p-3">
                              <p>{index + 1}</p>
                            </td>
                            <td className="p-3">
                              <p>
                                {employee?.first_name} {employee?.middle_name}{" "}
                                {employee?.last_name}
                              </p>

                              <p className="">{employee?.email}</p>
                            </td>
                            <td className="p-3">
                              <p>{renderPhoneNumber(employee?.phone_number)}</p>
                            </td>
                            <td className="p-3">{employee?.gender}</td>
                            <td className="p-3 space-y-2">
                              <p className="">
                                {employee?.status !== "active" ? (
                                  <span>Deactivated</span>
                                ) : (
                                  <span>Active</span>
                                )}
                              </p>
                              <p>
                                <ChangeEmployeeStatusActions
                                  employeeId={employee?.id}
                                  status={employee?.status}
                                />
                              </p>
                            </td>
                            <td className="p-3 flex items-center space-x-2">
                              <Link to={`/users/employees/${employee?.id}`}>
                                <RemoveRedEye />
                              </Link>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <span>
                                    <EditEmployeeAction employee={employee} />
                                  </span>
                                ) : (
                                  <span>
                                    <Edit className="cursor-not-allowed opacity-25" />
                                  </span>
                                )}
                              </div>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <DeleteEmployeeAction employee={employee} />
                                ) : (
                                  <MdDelete className="text-[#F24E1E] text-xl ml-0 cursor-not-allowed opacity-25" />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {employees &&
                      employees?.length > 0 &&
                      employees.map((employee, index) => {
                        return (
                          <tr
                            key={employee?.id || ""}
                            className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                          >
                            <td className="p-3">
                              <p>{index + 1}</p>
                            </td>
                            <td className="p-3">
                              <p>
                                {employee?.first_name} {employee?.middle_name}{" "}
                                {employee?.last_name}
                              </p>

                              <p className="">{employee?.email}</p>
                            </td>
                            <td className="p-3">
                              <p>{renderPhoneNumber(employee?.phone_number)}</p>
                            </td>
                            <td className="p-3">{employee?.gender}</td>
                            <td className="p-3 space-y-2">
                              <p className="">
                                {employee?.status !== "active" ? (
                                  <span>Deactivated</span>
                                ) : (
                                  <span>Active</span>
                                )}
                              </p>
                              <p>
                                <ChangeEmployeeStatusActions
                                  employeeId={employee?.id}
                                  status={employee?.status}
                                />
                              </p>
                            </td>
                            <td className="p-3 flex items-center space-x-2">
                              <Link to={`/users/employees/${employee?.id}`}>
                                <RemoveRedEye />
                              </Link>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <span>
                                    <EditEmployeeAction employee={employee} />
                                  </span>
                                ) : (
                                  <span>
                                    <Edit className="cursor-not-allowed opacity-25" />
                                  </span>
                                )}
                              </div>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <DeleteEmployeeAction employee={employee} />
                                ) : (
                                  <MdDelete className="text-[#F24E1E] text-xl ml-0 cursor-not-allowed opacity-25" />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row justify-center items-center w-[100%]">
        <AddEmployee />
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>All Employees</h5>
          <div className="w-[100%]">{renderEmployees()}</div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
