import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/Dashboard";
import OwnerOverview from "../pages/OwnerOverview";
import AccountantOverview from "../pages/AccountantOverview";
import TrackerOverview from "../pages/TrackerOverview";
import Revenue from "../pages/invoices/Revenue";
import AppLayout from "../pages/layouts/AppLayout";
import Settings from "../pages/settings/Settings";
import Trip from "../pages/trips/Trip";
import Customer from "../pages/users/Customer";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";
import Trucks from "../pages/trucks/Trucks";
import Truck from "../pages/trucks/Truck";
import Trips from "../pages/trips/Trips";
import TripRoutes from "../pages/trips/TripRoutes";
import UserProfile from "../pages/users/UserProfile";
import TrackerRoutePositions from "../pages/trips/TrackerRoutePositions";
import Expenses from "../pages/expenses/Expenses";
import SupplierDetails from "../pages/users/SupplierDetails";
import CustomerDetails from "../pages/users/CustomerDetails";
import OwnerExpenses from "../pages/expenses/OwnerExpenses";
import InvoiceDetails from "../pages/invoices/InvoiceDetails";
import UserDetails from "../pages/users/UserDetails";
import EmployeeDetails from "../pages/users/EmployeeDetails";
import ExpenseDetails from "../pages/expenses/ExpenseDetails";
import TrackerRouteReports from "../pages/trips/TrackerRouteReports";
import DeviceInfo from "../pages/auth/DeviceInfo";
import TruckRoutes from "../pages/trucks/TruckRoutes";
import Inventory from "../pages/stocks/Inventory";
import OrderDetails from "../pages/stocks/OrderDetails";
import StockDetails from "../pages/stocks/StockDetails";
import OwnerRevenue from "../pages/invoices/OwnerRevenue";
import MonthlyExpenseDetails from "../pages/expenses/MonthlyExpenseDetails";

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => <Login />;
const DeviceElement = () => <DeviceInfo />;

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
  <AppLayout>
    <Dashboard />
  </AppLayout>
);

const OwnerDashboardElement = () => (
  <AppLayout>
    <OwnerOverview />
  </AppLayout>
);

const AccountantDashboardElement = () => (
  <AppLayout>
    <AccountantOverview />
  </AppLayout>
);

const TrackerDashboardElement = () => (
  <AppLayout>
    <TrackerOverview />
  </AppLayout>
);

const TrucksElement = () => (
  <AppLayout>
    <Trucks />
  </AppLayout>
);

const TripsElement = () => (
  <AppLayout>
    <Trips />
  </AppLayout>
);

const ExpensesElement = () => (
  <AppLayout>
    <Expenses />
  </AppLayout>
);

const CustomersElement = () => (
  <AppLayout>
    <Customer />
  </AppLayout>
);

const ViewEmployeeElement = () => (
  <AppLayout>
    <EmployeeDetails />
  </AppLayout>
);

const SettingsElement = () => (
  <AppLayout>
    <Settings />
  </AppLayout>
);

const TripElement = () => (
  <AppLayout>
    <Trip />
  </AppLayout>
);

const RevenueElement = () => (
  <AppLayout>
    <Revenue />
  </AppLayout>
);

const TransactionElement = () => (
  <AppLayout>
    <InvoiceDetails />
  </AppLayout>
);

const TruckElement = () => (
  <AppLayout>
    <Truck />
  </AppLayout>
);

const RouteElement = () => (
  <AppLayout>
    <TripRoutes />
  </AppLayout>
);

const ProfileElement = () => (
  <AppLayout>
    <UserProfile />
  </AppLayout>
);

const TrackerPositionsElement = () => (
  <AppLayout>
    <TrackerRoutePositions />
  </AppLayout>
);

const OwnerExpensesElement = () => (
  <AppLayout>
    <OwnerExpenses />
  </AppLayout>
);

const OwnerRevenuesElement = () => (
  <AppLayout>
    <OwnerRevenue />
  </AppLayout>
);

const CustomerInvoicesElement = () => (
  <AppLayout>
    <CustomerDetails />
  </AppLayout>
);

const SupplierExpensesElement = () => (
  <AppLayout>
    <SupplierDetails />
  </AppLayout>
);

const CustomUserExpensesElement = () => (
  <AppLayout>
    <UserDetails />
  </AppLayout>
);

const ExpensePaymentElement = () => (
  <AppLayout>
    <ExpenseDetails />
  </AppLayout>
);

const IndirectExpensePaymentElement = () => (
  <AppLayout>
    <MonthlyExpenseDetails />
  </AppLayout>
);

const PositionsReportElement = () => (
  <AppLayout>
    <TrackerRouteReports />
  </AppLayout>
);

const TruckTripElement = () => (
  <AppLayout>
    <TruckRoutes />
  </AppLayout>
);

const InventoryElement = () => (
  <AppLayout>
    <Inventory/>
  </AppLayout>
);

const OrderElement = () => (
  <AppLayout>
    <OrderDetails/>
  </AppLayout>
);

const StockElement = () => (
  <AppLayout>
    <StockDetails/>
  </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
  return (
    <React.Fragment>
      <Routes>
        {/* ################## GUEST ROUTES ################## */}
        <Route element={<GuestRoutes />}>
          <Route path="/" element={<LoginElement />} />
          <Route path="/device" element={<DeviceElement />} />
        </Route>

        {/* ################## PROTECTED ROUTES ################## */}
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashboardElement />} />
          <Route path="/device-info" element={<DeviceElement />} />
          <Route path="/trucks" element={<TrucksElement />} />
          <Route path="/trips" element={<TripsElement />} />
          <Route path="/expenses" element={<ExpensesElement />} />
          <Route path="/users" element={<CustomersElement />} />

          <Route
            path="/users/employees/:employeeId"
            element={<ViewEmployeeElement />}
          />
          <Route
            path="/users/customers/:customerID"
            element={<CustomerInvoicesElement />}
          />
          <Route
            path="/users/suppliers/:supplierID"
            element={<SupplierExpensesElement />}
          />

          <Route
            path="/users/user/:userID"
            element={<CustomUserExpensesElement />}
          />

          <Route path="/settings" element={<SettingsElement />} />
          <Route path="/revenue" element={<RevenueElement />} />
          <Route path="/owner" element={<OwnerDashboardElement />} />
          <Route path="/accountant" element={<AccountantDashboardElement />} />
          <Route path="/tracker" element={<TrackerDashboardElement />} />
          <Route path="/profile" element={<ProfileElement />} />

          <Route path="/trucks/:truckID" element={<TruckElement />} />
          <Route path="/trucks/:truckID/trips/:tripID" element={<TruckTripElement />} />
          <Route path="/trips/:tripID" element={<TripElement />} />
          <Route
            path="/trips/:tripID/routes/:routeID"
            element={<RouteElement />}
          />
          <Route
            path="/revenue/invoices/:invoiceID"
            element={<TransactionElement />}
          />
          <Route
            path="/expenses/:expenseID"
            element={<ExpensePaymentElement />}
          />
          <Route
            path="/expenses/indrect-expenses/:expenseID"
            element={<IndirectExpensePaymentElement />}
          />
          <Route
            path="/dashboard/:routeID"
            element={<TrackerPositionsElement />}
          />
          <Route path="/personal-expenses" element={<OwnerExpensesElement />} />
          <Route path="/personal-revenues" element={<OwnerRevenuesElement />} />
          <Route
            path="/positions-report"
            element={<PositionsReportElement />}
          />
          <Route path="/inventory" element={<InventoryElement />} />
          <Route path="/inventory/orders/:orderID" element={<OrderElement/>} />
          <Route path="/inventory/stocks/:stockID" element={<StockElement/>} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default App;
