import React, { useState } from "react";
import flag from "../../assets/media/images/flag_2.png";
import trailerImage from "../../assets/media/images/trailer_1.jpg";
import { Popover } from "antd";
import ListItemIcon from "@mui/material/ListItemIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { isEmpty } from "lodash";
import EditTrailer from "../trucks/EditTrailer";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { useDispatch } from "react-redux";
import { deleteTrailer, getAllTrailers } from "../../api/trucks/trucksAPI";
import { addTrailers } from "../../features/truckSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";

const DeleteTrailerAction = ({ trailer, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all trailers
  const getTrailers = async () => {
    const response = await getAllTrailers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrailers(response.data.data));
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteTrailer = async (trailer) => {
    if (!isEmpty(trailer)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteTrailer({
        trailer_id: trailer?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
      } else {
        notifyToastError({
          message: "Failed to delete trailer , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setIsLoading(false);
        notifyToastSuccess({
          message: "Trailer is deleted successfully",
          icon: "✅",
        });
        getTrailers();
        onSuccess();
      } else {
        setIsLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <>
        <div onClick={handleClickOpen} disabled={isLoading}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
            <span>Delete</span>
          </ListItemIcon>
        </div>
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoading ? (
          <>
            <DialogTitle id="alert-dialog-title">
              {"Deleting Trailer..."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <CircularProgress size={28} />
              </DialogContentText>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              {"Delete Trailer ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Area you sure you want delete trailer{" "}
                {trailer?.registration_number || ""} ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleDeleteTrailer(trailer);
                }}
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const TrailerSummaryCard = ({ trailer }) => {
  const [userData] = useAtom(authenticationAtom);

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const renderMoreOptions = () => {
    const { userInfo: user } = userData;
    if (!isEmpty(user)) {
      if (user.role_name === "Truck owner" || user.role_name === "Clerk") {
        return null;
      } else {
        return (
          <>
            <Popover
              placement="bottomRight"
              content={
                <div className="flex flex-col">
                  <div className="cursor-pointer hover:bg-gray-100 h-8 w-full rounded-md">
                    <div className="pt-1.5">
                      <EditTrailer trailer={trailer} />
                    </div>
                  </div>

                  <div className="cursor-pointer hover:bg-gray-100 h-8 w-full rounded-md">
                    <div className="pt-1.5">
                      <DeleteTrailerAction trailer={trailer} />
                    </div>
                  </div>
                </div>
              }
              title=""
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <MoreVertIcon />
            </Popover>
          </>
        );
      }
    }
  };

  return (
    <div className="bg-gradient-to-r from-[#505050] to-[#B98D8D] h-[150px] w-[400px] rounded-md border-2 cursor-pointer">
      <div className="px-2 py-3">
        <div className="flex flex-row gap-2">
          <div className="w-[35%]">
            <img
              src={trailerImage}
              alt="truck"
              className="h-[120px] rounded-md"
            />
          </div>
          <div className="w-[60%]">
            <div className="flex flex-row justify-between">
              <div className="text-white text-sm font semibold">
                <p>
                  {trailer?.registration_name ? (
                    <span className="truncate">
                      {trailer.registration_name}
                    </span>
                  ) : null}
                </p>
                <p>
                  {trailer?.brand_name ? (
                    <span className="uppercase">{trailer.brand_name}</span>
                  ) : null}
                </p>
              </div>
              <div className="">{renderMoreOptions()}</div>
            </div>
            <div className="py-1">
              <div className="bg-[#FFBA0A] h-[54px] w-[110px]">
                <div className="flex flex-row gap-2 justify-between">
                  <div className="w-[30%] py-1 px-1">
                    <img src={flag} alt="flag" />
                  </div>
                  <div className="flex flex-col px-1 text-xl font-semibold">
                    <p>
                      {trailer?.registration_number ? (
                        <span className="uppercase">
                          {trailer.registration_number.slice(0, 5)}
                        </span>
                      ) : null}
                    </p>
                    <p className="items-end">
                      {trailer?.registration_number ? (
                        <span className="uppercase">
                          {trailer.registration_number.slice(-3)}
                        </span>
                      ) : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrailerSummaryCard;
