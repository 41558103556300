import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdEdit } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Button, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";
import {
  getAllRegisteredNames,
  updateRegisteredName,
} from "../../api/users/userAPI";
import { addRegisteredNames } from "../../features/userSlice";
import { Edit } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditRegistrationName = ({ name }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [regName, setName] = useState(name?.name);
  const [company, setCompany] = useState(name?.company);
  const [address, setAddress] = useState(name?.address);
  const phoneNumbers = JSON.parse(name?.phone_number || []);
  const [phone_number, setPhoneNumber] = useState([
    phoneNumbers[0] || "",
    phoneNumbers[1] || "",
  ]);
  const [tin, setTIN] = useState(name?.tin);
  const [vrn, setVRN] = useState(name?.vrn);
  const [description, setDescription] = useState(name?.description);

  const dispatch = useDispatch();

  const auth = useAuth();
  const token = auth?.token || "";

  const getNames = async () => {
    const response = await getAllRegisteredNames({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRegisteredNames(response.data.data));
    }
  };

  const saveName = async (e) => {
    e.preventDefault();

    if (!regName) {
      notifyToastError({
        message: "Please enter name",
        icon: "❗",
      });
    } else if (!address) {
      notifyToastError({
        message: "Please enter address",
        icon: "❗",
      });
    } else if (!phone_number) {
      notifyToastError({
        message: "Please enter phone number",
        icon: "❗",
      });
    } else if (!tin) {
      notifyToastError({
        message: "Please enter tin",
        icon: "❗",
      });
    } else {
      //start registration
      setLoading(true);
      const response = await updateRegisteredName({
        name: regName,
        company,
        address,
        phone_number,
        tin,
        vrn,
        description,
        name_id: name?.id,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getNames();

        notifyToastSuccess({
          message: "Registration name is updated successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => saveName(e)}
        >
          UPDATE REGISTRATION NAME
        </Button>
      );
    }
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleOpen}>
        <Edit />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Edit Registration Name</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  className="w-[82%]"
                  value={regName}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Company Name"
                  variant="outlined"
                  className="w-[40%]"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  className="w-[40%]"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={phone_number[0]}
                  onChange={(e) => {
                    let p = [...phone_number];
                    p[0] = e.target.value;
                    setPhoneNumber(p);
                  }}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone Number 2"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={phone_number[1]}
                  onChange={(e) => {
                    let p = [...phone_number];
                    p[1] = e.target.value;
                    setPhoneNumber(p);
                  }}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="TIN"
                  variant="outlined"
                  className="w-[40%]"
                  value={tin}
                  onChange={(e) => setTIN(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="VRN"
                  variant="outlined"
                  className="w-[40%]"
                  value={vrn}
                  onChange={(e) => setVRN(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditRegistrationName;
