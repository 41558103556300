import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Text,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
import { Box, Modal } from "@mui/material";
import { MdDownloadForOffline } from "react-icons/md";

// Create styles for PDF document
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 30,
  },
  title: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 15,
  },
  owner: {
    fontSize: 13,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
  customer: {
    fontSize: 12,
    marginBottom: 5,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    borderRightWidth: 1,
    padding: 5,
  },
  tableColHeader1: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader2: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader3: {
    width: "7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader4: {
    width: "13%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader5: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader6: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    borderRightWidth: 1,
    padding: 5,
  },
  tableCol1: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol2: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol3: {
    width: "7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol4: {
    width: "13%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol5: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol6: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RouteExpensesPDFCard = ({ expenses, route }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  let formatterNum = new Intl.NumberFormat("en-uS");

  return (
    <>
      <button
        type="button"
        onClick={handleOpen}
        className="px-6 py-2 border rounded-md border-[#F24E1E] cursor-pointer hover:bg-[#F24E1E] hover:text-white flex flex-row gap-2 justify-center"
      >
        <p>Export</p>
        <p className="pt-1 text-lg">
          <MdDownloadForOffline />
        </p>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                GENERATE ROUTE EXPENSE REPORT
              </h1>
            </div>
            <PDFViewer width="1000" height="600">
              <Document>
                <Page size="A4" style={styles.page}>
                  <View>
                    <Text style={styles.title}>
                      {route?.route_trip?.truck_registration_number}{" "}
                      {route?.initial_location}({route?.start_date}) -{" "}
                      {route?.final_location}({route?.end_date})
                    </Text>
                    <Text style={styles.title}>EXPENSES REPORT</Text>
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableColHeader1}>#</Text>
                        <Text style={styles.tableColHeader2}>Expense Type</Text>
                        <Text style={styles.tableColHeader3}>Items</Text>
                        <Text style={styles.tableColHeader4}>Price TZS</Text>
                        <Text style={styles.tableColHeader4}>Total TZS</Text>
                        <Text style={styles.tableColHeader5}>Supplier</Text>
                        <Text style={styles.tableColHeader6}>Date</Text>
                        <Text style={styles.tableColHeader}>Debt</Text>
                      </View>
                      {expenses?.map((expense, index) => (
                        <View style={styles.tableRow} key={index}>
                          <Text style={styles.tableCol1}>{index + 1}</Text>
                          <Text style={styles.tableCol2}>
                            {expense?.expense_type?.type_name}
                          </Text>
                          <Text style={styles.tableCol3}>
                            {expense?.item_number}
                          </Text>
                          <Text style={styles.tableCol4}>
                            {formatterNum.format(expense?.item_price)}
                          </Text>
                          <Text style={styles.tableCol4}>
                            {formatterNum.format(expense?.total_amount)}
                          </Text>
                          <Text style={styles.tableCol5}>
                            {expense?.supplier?.supplier_name}
                          </Text>
                          <Text style={styles.tableCol6}>
                            {expense?.expense_date}
                          </Text>
                          {expense?.unpaid_amount === 0 ? (
                            <Text style={styles.tableCol}>Paid</Text>
                          ) : (
                            <Text style={styles.tableCol}>
                              {formatterNum.format(expense?.unpaid_amount || 0)}
                            </Text>
                          )}
                        </View>
                      ))}
                    </View>
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RouteExpensesPDFCard;
