import React, { useEffect } from "react";
import { Download } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../assets/utils/colors";
import tables from "../styles/tables.module.css";
import TodayTripsCard from "./cards/TodayTripsCard";
import TodayRoutesCard from "./cards/TodayRoutesCard";
import TodayLocationsCard from "./cards/TodayLocationsCard";
import TodayExpensesCard from "./cards/TodayExpensesCard";
import TodayTrucksCard from "./cards/TodayTrucksCard";
import TodayIncidentsCard from "./cards/TodayIncidentsCard";
import { useAtom } from "jotai";
import { Box, Grid, IconButton } from "@mui/material";
import { authenticationAtom } from "../atoms/auth";
import { isEmpty } from "lodash";
import {
  getAllRoutes,
  getAllTrips,
  getRouteExpenses,
  getRouteInvoices,
  getRoutePositions,
} from "../api/trips/tripsAPI";
import {
  addRouteExpenses,
  addRouteInvoices,
  addRoutePositions,
  addTripRoutes,
  addTrips,
  selectRouteExpenses,
  selectRouteInvoices,
  selectRoutePositions,
  selectTripRoutes,
  selectTrips,
} from "../features/tripsSlice";
import { getAllTransactions } from "../api/revenue/revenueAPI";
import { addTransactions, selectTransactions } from "../features/revenueSlice";
import moment from "moment/moment";
import useAuth from "../hooks/useAuth";
import Modal from "@mui/material/Modal";

import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    padd: 4,
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
  },
  weeklySmallColumn: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  weeklyMegaColumn: {
    width: "17.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const SumamryReport = ({ data, title = "" }) => {
  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Type
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Value
                    </Text>
                  </View>
                </View>

                {data?.map((item, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View style={styles.tableCol2}>
                        <Text style={styles.tableCell}>{item?.label}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{item?.value}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const WeeklyReportAction = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className="h-12 w-full bg-gradient-to-r from-[#EEDEDE] to-[#F0F0F0] flex flex-row gap-2 justify-center rounded-b-md">
        <button
          onClick={handleOpen}
          className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
        >
          Generate Weekly Report
        </button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                Weekly Report
              </h1>
            </div>
            <SumamryReport title="MSA Transport Weekly Report" data={data} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const MonthlyReportAction = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className="h-12 w-full bg-gradient-to-r from-[#EEDEDE] to-[#F0F0F0] flex flex-row gap-2 justify-center rounded-b-md">
        <button
          onClick={handleOpen}
          className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
        >
          Generate Monthly Report
        </button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                Montly Report
              </h1>
            </div>
            <SumamryReport title="MSA Transport Monthly Report" data={data} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const DetailedTripsReport = ({ data, title = "" }) => {
  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Truck
                    </Text>
                  </View>
                  <View style={styles.weeklyMegaColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Driver
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Start Date
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      End Date
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Routes
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Status
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Invoices
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Expenses
                    </Text>
                  </View>
                </View>

                {data?.map((item, index) => {
                  const nameArray = item.driver.split(" ");
                  const middleName = nameArray[1].charAt(0);
                  return (
                    <View style={styles.tableRow}>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>
                          {item?.truck_registration_number}
                        </Text>
                      </View>
                      <View style={styles.weeklyMegaColumn}>
                        <Text style={styles.tableCell}>
                          {nameArray[0]} {middleName} {nameArray[2]}
                        </Text>
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>{item?.start_date}</Text>
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>{item?.end_date}</Text>
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        {item.trip_routes ? (
                          <Text style={styles.tableCell}>
                            {item.trip_routes.length}
                          </Text>
                        ) : (
                          <Text style={styles.tableCell}>0</Text>
                        )}
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        {item.trip_status === "not_started" ? (
                          <Text style={styles.tableCell}>Not started</Text>
                        ) : item.trip_status === "not_complete" ? (
                          <Text style={styles.tableCell}>Failed</Text>
                        ) : (
                          <Text style={styles.tableCell}>
                            {item.trip_status}
                          </Text>
                        )}
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>
                          {item?.total_trip_invoice}
                        </Text>
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>
                          {item?.total_trip_expenses}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const DetailedRoutesReport = ({ data, title = "" }) => {
  // console.log("routes data passed", data);
  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Trip No
                    </Text>
                  </View>
                  <View style={styles.weeklyMegaColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Driver
                    </Text>
                  </View>
                  <View style={styles.weeklyMegaColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Truck
                    </Text>
                  </View>
                  <View style={styles.weeklyMegaColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Start Date
                    </Text>
                  </View>
                  <View style={styles.weeklyMegaColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      End Date
                    </Text>
                  </View>
                  <View style={styles.weeklySmallColumn}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Status
                    </Text>
                  </View>
                </View>

                {data?.map((item, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>
                          {item?.route_trip?.trip_number}
                        </Text>
                      </View>
                      <View style={styles.weeklyMegaColumn}>
                        <Text style={styles.tableCell}>
                          {item?.route_trip?.driver}
                        </Text>
                      </View>
                      <View style={styles.weeklyMegaColumn}>
                        <Text style={styles.tableCell}>
                          {item?.route_trip?.truck_registration_number}
                        </Text>
                      </View>
                      <View style={styles.weeklyMegaColumn}>
                        <Text style={styles.tableCell}>{item?.start_date}</Text>
                      </View>
                      <View style={styles.weeklyMegaColumn}>
                        <Text style={styles.tableCell}>{item?.end_date}</Text>
                      </View>
                      <View style={styles.weeklySmallColumn}>
                        <Text style={styles.tableCell}>{item?.status}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const DetailedLocationsReport = ({ data, title = "" }) => {
  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={[styles.weeklySmallColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "30%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Location
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Truck
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "40%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Route
                    </Text>
                  </View>
                </View>

                {data?.map((item, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View
                        style={[styles.weeklySmallColumn, { width: "15%" }]}
                      >
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View style={[styles.weeklyMegaColumn, { width: "30%" }]}>
                        <Text style={styles.tableCell}>{item?.position}</Text>
                      </View>
                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.truck_reg_number}
                        </Text>
                      </View>
                      <View style={[styles.weeklyMegaColumn, { width: "40%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.route?.initial_location} -{" "}
                          {item?.route?.final_location}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const DetailedExpensesReport = ({ data, title = "" }) => {
  // console.log("expense data passed");
  // console.log(data);

  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={[styles.weeklySmallColumn, { width: "8%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "10.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Type
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "11.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Supplier
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "9.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Truck
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "12.50%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Route
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "9.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Date
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "8%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Items
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "11.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Price
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "11.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Total Amount
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "9%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Debt
                    </Text>
                  </View>
                </View>

                {data?.map((item, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View style={[styles.weeklySmallColumn, { width: "8%" }]}>
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View
                        style={[styles.weeklyMegaColumn, { width: "10.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.expense_type?.type_name}
                        </Text>
                      </View>

                      <View
                        style={[styles.weeklyMegaColumn, { width: "11.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.supplier?.supplier_name}
                        </Text>
                      </View>

                      <View
                        style={[styles.weeklyMegaColumn, { width: "9.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.truck?.registration_number}
                        </Text>
                      </View>

                      <View
                        style={[styles.weeklyMegaColumn, { width: "12.50%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.route?.initial_location} -{" "}
                          {item?.route?.final_location}
                        </Text>
                      </View>

                      <View
                        style={[styles.weeklyMegaColumn, { width: "9.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.expense_date}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "8%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.item_number}
                        </Text>
                      </View>

                      <View
                        style={[styles.weeklyMegaColumn, { width: "11.25%" }]}
                      >
                        <Text style={styles.tableCell}>{item?.item_price}</Text>
                      </View>

                      <View
                        style={[styles.weeklyMegaColumn, { width: "11.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.total_amount}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "9%" }]}>
                        {item?.unpaid_amount > 0 ? (
                          <Text style={styles.tableCell}>
                            {item?.unpaid_amount}
                          </Text>
                        ) : (
                          <Text style={styles.tableCell}>Paid</Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const DetailedIssuedInvoicesReport = ({ data, title = "" }) => {
  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={[styles.weeklySmallColumn, { width: "8%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Invoice #
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Total Amount
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "12%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Truck #
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "20%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Route
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Debt
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Payment Date
                    </Text>
                  </View>
                </View>

                {data?.map((item, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View style={[styles.weeklySmallColumn, { width: "8%" }]}>
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.invoice_number}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.total_amount}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "12%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.truck_reg_number}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "20%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.route?.initial_location} -{" "}
                          {item?.route?.final_location}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        {item?.unpaid_amount > 0 ? (
                          <Text style={styles.tableCell}>
                            {item?.unpaid_amount}
                          </Text>
                        ) : (
                          <Text style={styles.tableCell}>Paid</Text>
                        )}
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.payment_date}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const DetailedPaidInvoicesReport = ({ data, title = "" }) => {
  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={[styles.weeklySmallColumn, { width: "10%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Invoice #
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Truck #
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Total Amount
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Paid Amount
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Debt
                    </Text>
                  </View>

                  <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Payment Date
                    </Text>
                  </View>  
                </View>

                {data?.map((item, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View
                        style={[styles.weeklySmallColumn, { width: "10%" }]}
                      >
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.invoice_number}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.truck_reg_number}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.total_amount}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.paid_amount}
                        </Text>
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        {item?.unpaid_amount > 0 ? (
                          <Text style={styles.tableCell}>
                            {item?.unpaid_amount}
                          </Text>
                        ) : (
                          <Text style={styles.tableCell}>Paid</Text>
                        )}
                      </View>

                      <View style={[styles.weeklyMegaColumn, { width: "15%" }]}>
                        <Text style={styles.tableCell}>
                          {item?.payment_date}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const DetailedTransactionsReceivedReport = ({ data, title = "" }) => {
  return (
    <PDFViewer style={{ width: 700, height: 450 }}>
      <Document style={{ width: 700, height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>
            <View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={[styles.weeklySmallColumn, { width: "15%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      No
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "21.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Invoice Number
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "21.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Paid Amount
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "21.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Payment Date
                    </Text>
                  </View>
                  <View style={[styles.weeklyMegaColumn, { width: "21.25%" }]}>
                    <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                      Payment Type
                    </Text>
                  </View>
                </View>

                {data?.map((item, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View
                        style={[styles.weeklySmallColumn, { width: "15%" }]}
                      >
                        <Text style={styles.tableCell}>{index + 1}</Text>
                      </View>
                      <View
                        style={[styles.weeklyMegaColumn, { width: "21.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.invoice_number}
                        </Text>
                      </View>
                      <View
                        style={[styles.weeklyMegaColumn, { width: "21.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.amount_paid}
                        </Text>
                      </View>
                      <View
                        style={[styles.weeklyMegaColumn, { width: "21.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.payment_date}
                        </Text>
                      </View>
                      <View
                        style={[styles.weeklyMegaColumn, { width: "21.25%" }]}
                      >
                        <Text style={styles.tableCell}>
                          {item?.payment_name}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const TripsReportAction = ({ data, title = "", documentTitle = "" }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                {title}
              </h1>
            </div>
            <DetailedTripsReport title={documentTitle} data={data} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const RoutesReportAction = ({ data, title = "", documentTitle = "" }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                {title}
              </h1>
            </div>
            <DetailedRoutesReport title={documentTitle} data={data} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const LocationReportAction = ({ data, title = "", documentTitle = "" }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //console.log(data);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                {title}
              </h1>
            </div>
            <DetailedLocationsReport title={documentTitle} data={data} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const ExpenseReportAction = ({ data, title = "", documentTitle = "" }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                {title}
              </h1>
            </div>
            <DetailedExpensesReport title={documentTitle} data={data} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const IssuedInvoicesReportAction = ({
  data,
  title = "",
  documentTitle = "",
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                {title}
              </h1>
            </div>
            <DetailedIssuedInvoicesReport title={documentTitle} data={data} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const PaidInvoicesReportAction = ({ data, title = "", documentTitle = "" }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                {title}
              </h1>
            </div>
            <DetailedPaidInvoicesReport title={documentTitle} data={data} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const TransactionReceivedReportAction = ({
  data,
  title = "",
  documentTitle = "",
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                {title}
              </h1>
            </div>
            <DetailedTransactionsReceivedReport
              title={documentTitle}
              data={data}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const AccountantOverview = () => {
  const dispatch = useDispatch();
  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all expense
  const getExpenses = async () => {
    const response = await getRouteExpenses({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteExpenses(response.data.data));
      }
    }
  };

  const getInvoices = async () => {
    const response = await getRouteInvoices({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteInvoices(response.data.data));
      }
    }
  };

  const getPositions = async () => {
    const response = await getRoutePositions({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRoutePositions(response.data.data));
      }
    }
  };

  const getTrips = async () => {
    const response = await getAllTrips({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrips(response.data.data));
      }
    }
  };

  const getRoutes = async () => {
    const response = await getAllRoutes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTripRoutes(response.data.data));
      }
    }
  };

  const getTransactions = async () => {
    const response = await getAllTransactions({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTransactions(response.data.data));
      }
    }
  };

  useEffect(() => {
    getExpenses();
    getInvoices();
    getPositions();
    getRoutes();
    getTrips();
    getTransactions();
  }, [dispatch]);

  const expenses = useSelector(selectRouteExpenses);
  const invoices = useSelector(selectRouteInvoices);
  const positions = useSelector(selectRoutePositions);
  const trips = useSelector(selectTrips);
  const routes = useSelector(selectTripRoutes);
  const transactions = useSelector(selectTransactions);

  const now = moment();

  let tripsArray = [];
  let weekTripsArray = [];
  let monthTripsArray = [];
  if (Array.isArray(trips)) {
    if (trips.length !== 0) {
      tripsArray = trips.filter((trip) => {
        //filtering array value
        return moment(trip.start_date).isSame(now, "day");
      });

      weekTripsArray = trips.filter((trip) => {
        //filtering array value
        return moment(trip.start_date).isSame(now, "week");
      });

      monthTripsArray = trips.filter((trip) => {
        //filtering array value
        return moment(trip.start_date).isSame(now, "month");
      });
    }
  }

  let expensesArray = [];
  let weekExpensesArray = [];
  let monthExpensesArray = [];
  if (Array.isArray(expenses)) {
    if (expenses.length !== 0) {
      expensesArray = expenses.filter((expense) => {
        //filtering array value
        return moment(expense.expense_date).isSame(now, "day");
      });

      weekExpensesArray = expenses.filter((expense) => {
        //filtering array value
        return moment(expense.expense_date).isSame(now, "week");
      });

      monthExpensesArray = expenses.filter((expense) => {
        //filtering array value
        return moment(expense.expense_date).isSame(now, "month");
      });
    }
  }

  let invoicesArray = [];
  let weekInvoicesArray = [];
  let monthInvoicesArray = [];
  let weekPaidInvoicesArray = [];
  let monthPaidInvoicesArray = [];
  if (Array.isArray(invoices)) {
    if (invoices.length !== 0) {
      invoicesArray = invoices.filter((invoice) => {
        //filtering array value
        return moment(invoice.created_at).isSame(now, "day");
      });

      weekInvoicesArray = invoices.filter((invoice) => {
        //filtering array value
        return moment(invoice.created_at).isSame(now, "week");
      });

      monthInvoicesArray = invoices.filter((invoice) => {
        //filtering array value
        return moment(invoice.created_at).isSame(now, "month");
      });

      weekPaidInvoicesArray = invoices.filter((invoice) => {
        //filtering array value
        return moment(invoice.payment_date).isSame(now, "week");
      });

      monthPaidInvoicesArray = invoices.filter((invoice) => {
        //filtering array value
        return moment(invoice.payment_date).isSame(now, "month");
      });
    }
  }

  let positionsArray = [];
  let weekPositionsArray = [];
  let monthPositionsArray = [];
  if (Array.isArray(positions)) {
    if (positions.length !== 0) {
      positionsArray = positions.filter((position) => {
        //filtering array value
        return moment(position.created_at).isSame(now, "day");
      });

      weekPositionsArray = positions.filter((position) => {
        //filtering array value
        return moment(position.created_at).isSame(now, "week");
      });

      monthPositionsArray = positions.filter((position) => {
        //filtering array value
        return moment(position.created_at).isSame(now, "month");
      });
    }
  }

  let routesArray = [];
  let weekRoutesArray = [];
  let monthRoutesArray = [];
  if (Array.isArray(routes)) {
    if (routes.length !== 0) {
      routesArray = routes.filter((route) => {
        //filtering array value
        return moment(route.start_date).isSame(now, "day");
      });

      weekRoutesArray = routes.filter((route) => {
        //filtering array value
        return moment(route.start_date).isSame(now, "week");
      });

      monthRoutesArray = routes.filter((route) => {
        //filtering array value
        return moment(route.start_date).isSame(now, "month");
      });
    }
  }

  let transactionsArray = [];
  let weekTransactionsArray = [];
  let monthTransactionsArray = [];
  if (Array.isArray(transactions)) {
    if (transactions.length !== 0) {
      transactionsArray = transactions.filter((transaction) => {
        //filtering array value
        return moment(transaction.payment_date).isSame(now, "day");
      });

      weekTransactionsArray = transactions.filter((transaction) => {
        //filtering array value
        return moment(transaction.payment_date).isSame(now, "week");
      });

      monthTransactionsArray = transactions.filter((transaction) => {
        //filtering array value
        return moment(transaction.payment_date).isSame(now, "month");
      });
    }
  }

  const weekReportData = [
    { label: "Trips added", value: weekTripsArray.length || 0 },
    { label: "Routes added", value: weekRoutesArray.length || 0 },
    { label: "Location posted", value: weekPositionsArray.length || 0 },
    { label: "Expenses made", value: weekExpensesArray.length || 0 },
    { label: "Issued invoices", value: weekInvoicesArray.length || 0 },
    { label: "Invoices paid", value: weekPaidInvoicesArray.length || 0 },
    {
      label: "Transactions received",
      value: weekTransactionsArray.length || 0,
    },
  ];

  const renderWeeklyReport = () => {
    return (
      <div className="py-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.reportTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Type</p>
          <p className="py-2">Value</p>
        </div>
        <div className="bg-[#F0F0F0] h-80 overflow-y-scroll">
          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">1</p>
            <p className="py-2">Trips added</p>
            <p className="py-2">{weekTripsArray.length}</p>
            <p className="cursor-pointer py-2">
              <TripsReportAction
                title="Trips Weekly Report"
                documentTitle="MSA Transport Trips Weekly Report"
                data={weekTripsArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">2</p>
            <p className="py-2">Routes added</p>
            <p className="py-2">{weekRoutesArray.length}</p>
            <p className="cursor-pointer py-2">
              <RoutesReportAction
                title="Routes Weekly Report"
                documentTitle="MSA Transport Routes Weekly Report"
                data={weekRoutesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">3</p>
            <p className="py-2">Location posted</p>
            <p className="py-2">{weekPositionsArray.length}</p>
            <p className="cursor-pointer py-2">
              <LocationReportAction
                title="Location Weekly Report"
                documentTitle="MSA Transport Location Weekly Report"
                data={weekPositionsArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">4</p>
            <p className="py-2">Expenses made</p>
            <p className="py-2">{weekExpensesArray.length}</p>
            <p className="cursor-pointer py-2">
              <ExpenseReportAction
                title="Expenses Weekly Report"
                documentTitle="MSA Transport Expenses Weekly Report"
                data={weekExpensesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">5</p>
            <p className="py-2">Issued invoices</p>
            <p className="py-2">{weekInvoicesArray.length}</p>
            <p className="cursor-pointer py-2">
              <IssuedInvoicesReportAction
                title="Issued Invoices Weekly Report"
                documentTitle="MSA Transport Issued Invoices Weekly Report"
                data={weekInvoicesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">6</p>
            <p className="py-2">Invoices paid</p>
            <p className="py-2">{weekPaidInvoicesArray.length}</p>
            <p className="cursor-pointer py-2">
              <PaidInvoicesReportAction
                title="Paid Invoices Weekly Report"
                documentTitle="MSA Transport Paid Invoices Weekly Report"
                data={weekPaidInvoicesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">7</p>
            <p className="py-2">Transactions received</p>
            <p className="py-2">{weekTransactionsArray.length}</p>
            <p className="cursor-pointer py-2">
              <TransactionReceivedReportAction
                title="Transactions Weekly Report"
                documentTitle="MSA Transport Transactions Weekly Report"
                data={weekTransactionsArray}
              />
            </p>
          </div>
        </div>
        <>
          <WeeklyReportAction data={weekReportData} />
        </>
      </div>
    );
  };

  const monthReportData = [
    { label: "Trips added", value: monthTripsArray.length || 0 },
    { label: "Routes added", value: monthRoutesArray.length || 0 },
    { label: "Location posted", value: monthPositionsArray.length || 0 },
    { label: "Expenses made", value: monthExpensesArray.length || 0 },
    { label: "Issued invoices", value: monthInvoicesArray.length || 0 },
    { label: "Invoices paid", value: monthPaidInvoicesArray.length || 0 },
    {
      label: "Transactions received",
      value: monthTransactionsArray.length || 0,
    },
  ];

  const renderMonthlyReport = () => {
    return (
      <div className="py-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.reportTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Type</p>
          <p className="py-2">Value</p>
        </div>
        <div className="bg-[#F0F0F0] h-80 overflow-y-scroll">
          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">1</p>
            <p className="py-2">Trips added</p>
            <p className="py-2">{monthTripsArray.length}</p>
            <p className="cursor-pointer py-2">
              <TripsReportAction
                title="Trips Monthly Report"
                documentTitle="MSA Transport Trips Monthly Report"
                data={monthTripsArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">2</p>
            <p className="py-2">Routes added</p>
            <p className="py-2">{monthRoutesArray.length}</p>
            <p className="cursor-pointer py-2">
              <RoutesReportAction
                title="Routes Monthly Report"
                documentTitle="MSA Transport Routes Monthly Report"
                data={monthRoutesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">3</p>
            <p className="py-2">Location posted</p>
            <p className="py-2">{monthPositionsArray.length}</p>
            <p className="cursor-pointer py-2">
              <LocationReportAction
                title="Location Monthly Report"
                documentTitle="MSA Transport Location Monthly Report"
                data={monthPositionsArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">4</p>
            <p className="py-2">Expenses made</p>
            <p className="py-2">{monthExpensesArray.length}</p>
            <p className="cursor-pointer py-2">
              <ExpenseReportAction
                title="Expenses Monthly Report"
                documentTitle="MSA Transport Expenses Monthly Report"
                data={monthExpensesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">5</p>
            <p className="py-2">Issued invoices</p>
            <p className="py-2">{monthInvoicesArray.length}</p>
            <p className="cursor-pointer py-2">
              <IssuedInvoicesReportAction
                title="Issued Invoices Monthly Report"
                documentTitle="MSA Transport Issued Invoices Monthly Report"
                data={monthInvoicesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">6</p>
            <p className="py-2">Invoices paid</p>
            <p className="py-2">{monthPaidInvoicesArray.length}</p>
            <p className="cursor-pointer py-2">
              <PaidInvoicesReportAction
                title="Paid Invoices Monthly Report"
                documentTitle="MSA Transport Paid Invoices Monthly Report"
                data={monthPaidInvoicesArray}
              />
            </p>
          </div>

          <div
            className={`bg-white h-10 text-center ${tables.reportTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2">7</p>
            <p className="py-2">Transactions received</p>
            <p className="py-2">{monthTransactionsArray.length}</p>
            <p className="cursor-pointer py-2">
              <TransactionReceivedReportAction
                title="Transactions Monthly Report"
                documentTitle="MSA Transport Transactions Monthly Report"
                data={monthTransactionsArray}
              />
            </p>
          </div>
        </div>
        <>
          <MonthlyReportAction data={monthReportData} />
        </>
      </div>
    );
  };

  return (
    <>
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TodayTripsCard value={tripsArray.length} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TodayRoutesCard value={routesArray.length} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TodayExpensesCard value={expensesArray.length} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TodayLocationsCard value={positionsArray.length} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TodayTrucksCard value={transactionsArray.length} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TodayIncidentsCard value={invoicesArray.length} />
        </Grid>
        <Grid item sm={6}>
          <Box>
            <h5>Weekly Activities Summary</h5>
            <div className="w-[100%]">{renderWeeklyReport()}</div>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box>
            <h5>Monthly Activities Summary</h5>
            <div className="w-[100%]">{renderMonthlyReport()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountantOverview;
