import React, { useEffect } from "react";
import moment from "moment/moment";
import tables from "../../styles/tables.module.css";
import { colors } from "../../assets/utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { getAllRoutes } from "../../api/trips/tripsAPI";
import { isEmpty } from "lodash";
import { addTripRoutes, selectTripRoutes } from "../../features/tripsSlice";
import AddTrackerPosition from "./AddTrackerPosition";
import { Box, Grid, IconButton } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { Modal } from "antd";
import { useState } from "react";

const ViewPositionDetails = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const addedTime = moment(position?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(position?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Trip Route Position Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {position?.description ? <span>{position.description}</span> : null}
          </p>
          <br />
          <br />
          {user.role_name.toLowerCase() === "admin" ? (
            <>
              {" "}
              <p>
                Added by :{" "}
                {position?.added_by ? (
                  <span>
                    {position?.added_by.first_name}{" "}
                    {position?.added_by.middle_name}{" "}
                    {position?.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({position?.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {position?.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {position?.updated_by ? (
                  <span>
                    {position?.updated_by.first_name}{" "}
                    {position?.updated_by.middle_name}{" "}
                    {position?.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({position?.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {position?.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const TrackerPositions = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getRoutes = async () => {
    const response = await getAllRoutes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTripRoutes(response.data.data));
      }
    }
  };

  useEffect(() => {
    getRoutes();
  }, [dispatch]);

  const routes = useSelector(selectTripRoutes);

  let activeRoutes = [];
  if (Array.isArray(routes)) {
    if (routes.length !== 0) {
      activeRoutes = routes.filter((route) => {
        return route.status === "open";
      });

      if (activeRoutes.length !== 0) {
        activeRoutes.reverse();
      }
    }
  }

  const renderCurrentTrips = () => {
    return (
      <div className="py-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.trackerRouteTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Initial Location</p>
          <p className="py-2">Final Location</p>
          <p className="py-2">Truck</p>
          <p className="py-2">Customer Name</p>
          <p className="py-2">Current Position</p>
          <p className="py-2">Date</p>
          <p className="py-2">Actions</p>
        </div>
        <div className="bg-[#F0F0F0] h-80 overflow-y-scroll">
          {renderRoutes()}
        </div>
        <div className="h-12 w-[100%] bg-gradient-to-r from-[#EEDEDE] to-[#F0F0F0] rounded-b-md"></div>
      </div>
    );
  };

  const renderRoutes = () => {
    if (activeRoutes.length !== 0) {
      return (
        <div>
          {activeRoutes.map((route, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.trackerRouteTable} rounded-md text-sm my-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 pl-8">{route.initial_location}</p>
                <p className="py-2 pl-8">{route.final_location}</p>
                <p className="py-2 text-center">
                  {route.route_trip ? (
                    <span>{route.route_trip.truck_registration_number}</span>
                  ) : (
                    <span></span>
                  )}
                </p>
                <p className="py-2 pl-8">
                  {route.route_customer?.customer_name}
                </p>
                <p className="py-2 pl-8">{route.current_position}</p>
                <p className="py-2 text-center">{renderDate(route)}</p>
                <p className="flex flex-row gap-1 justify-center pl-4">
                  <AddTrackerPosition route={route} icon={true} />
                  {renderViewRoute(route)}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p className="text-center">There are no active routes</p>;
    }
  };

  const renderViewRoute = (route) => {
    if (!isEmpty(route)) {
      if (route.route_positions) {
        const positions = route.route_positions;

        const positionArray = positions.filter((position) => {
          return position.position === route.current_position;
        });

        const position = positionArray[0];

        if (!isEmpty(position)) {
          return <ViewPositionDetails position={position} />;
        } else {
          return <ViewPositionDetails position={""} />;
        }
      } else {
        return <ViewPositionDetails position={""} />;
      }
    } else {
      return <ViewPositionDetails position={""} />;
    }
  };

  const renderDate = (route) => {
    if (!isEmpty(route)) {
      if (route.route_positions) {
        const positions = route.route_positions;

        const positionArray = positions.filter((position) => {
          return position.position === route.current_position;
        });

        const position = positionArray[0];

        if (!isEmpty(position)) {
          // const date = moment(position).format("DD-MM-YYYY");
          return <span>{position.position_date}</span>;
        }
      } else {
        const date = moment().format("YYYY-MM-DD");
        return <span>{date}</span>;
      }
    } else {
      return <span></span>;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Box>
            <h5>Active Routes</h5>
            <div className="">{renderCurrentTrips()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TrackerPositions;
