import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { authenticationAtom } from "../../atoms/auth";
import { useAtom } from "jotai";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserCustomDetails,
  selectUserCustomDetails,
} from "../../features/userSlice";
import { getUser } from "../../api/users/userAPI";
import { colors } from "../../assets/utils/colors";
import moment from "moment";
import { RemoveRedEye } from "@mui/icons-material";
import { Modal } from "antd";
import useAuth from "../../hooks/useAuth";
import { getOwnerPersonalExpense } from "../../api/expenses/expensesAPI";
import { addOwnerExpenses } from "../../features/expensesSlice";
import UserExpenses from "./UserExpenses";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const ViewCustomUserAction = (userDetails) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // console.log(userDetails);
  const addedTime = moment(userDetails?.customUser.created_at).format(
    "DD-MM-YYYY"
  );
  const updateTime = moment(userDetails?.customUser.updated_at).format(
    "DD-MM-YYYY"
  );

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="User Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Added by :{" "}
            {userDetails.customUser.added_by ? (
              <span>
                {userDetails.customUser.added_by.first_name}{" "}
                {userDetails.customUser.added_by.middle_name}{" "}
                {userDetails.customUser.added_by.last_name}{" "}
                <span className="text-xs">
                  ({userDetails.customUser.added_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Added at :{" "}
            {userDetails.customUser.created_at ? (
              <span>{addedTime}</span>
            ) : null}
          </p>
          <p>
            Updated by :{" "}
            {userDetails.customUser.updated_by ? (
              <span>
                {userDetails.customUser.updated_by.first_name}{" "}
                {userDetails.customUser.updated_by.middle_name}{" "}
                {userDetails.customUser.updated_by.last_name}{" "}
                <span className="text-xs">
                  ({userDetails.customUser.updated_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Updated at :{" "}
            {userDetails.customUser.updated_at ? (
              <span>{updateTime}</span>
            ) : null}
          </p>
        </div>
      </Modal>
    </>
  );
};

const UserDetails = () => {
  const [value, setValue] = React.useState(0);

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const { userID } = useParams();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const fetchUser = async () => {
    const response = await getUser({ user_id: userID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addUserCustomDetails(response.data.data));
      }
    }
  };

  const getExpenses = async () => {
    const response = await getOwnerPersonalExpense({
      owner_id: userID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addOwnerExpenses(response.data.data));
      } else {
        dispatch(addOwnerExpenses([]));
      }
    } else {
      dispatch(addOwnerExpenses([]));
    }
  };

  useEffect(() => {
    fetchUser();
    getExpenses();
  }, [dispatch]);

  const userDetails = useSelector(selectUserCustomDetails);
  // console.log({customer: customer});

  const renderUserDetails = () => {
    if (!isEmpty(userDetails)) {
      return (
        <Card
          sx={{
            width: 650,
            height: 400,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">USER DETAILS</h3>
            <div className="pt-4">
              <div className="text-lg px-6">
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">User Name :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {userDetails.first_name ? (
                        <span>
                          {userDetails.first_name} {userDetails.middle_name}{" "}
                          {userDetails.last_name}
                        </span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Phone :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {userDetails.phone_number ? (
                        <span>{userDetails.phone_number}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Email :</p>
                    {""}
                    <span className="text-[#F24E1E] w-[70%]">
                      {userDetails.email ? (
                        <span>{userDetails.email}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Gender :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {userDetails.gender ? (
                        <span>{userDetails.gender}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Status :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%] capitalize">
                      {userDetails.status ? (
                        <span>{userDetails.status}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">User Role :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%] capitalize">
                      {userDetails.role_name ? (
                        <span>{userDetails.role_name}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    {user.role_name === "Admin" ||
                    user.role_name === "admin" ? (
                      <>
                        <p className="w-[30%]">View Details :</p>{" "}
                        <p className="text-[#F24E1E] w-[70%]">
                          <ViewCustomUserAction customUser={userDetails} />
                        </p>
                      </>
                    ) : null}
                  </div>
                  <div className="w-[5%]"></div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p className="text-center">User has no details</p>;
    }
  };

  const renderUserTabs = () => {
    return (
      <>
        <Grid container>
          <Grid item sm={12}>
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor={`${primary}`}
                indicatorColor="primary"
                sx={{ color: "#F24E1E" }}
              >
                <Tab label="USER DETAILS" {...a11yProps(0)} />
                {userDetails?.role_name.toLowerCase() === "truck owner" ? (
                  <Tab label="USER FINANCIAL DETAILS" {...a11yProps(1)} />
                ) : null}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="flex flex-row justify-center pb-8">
                {renderUserDetails()}
              </div>
            </TabPanel>
            {userDetails?.role_name.toLowerCase() === "truck owner" ? (
              <TabPanel value={value} index={1}>
                <UserExpenses />
              </TabPanel>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          {renderUserTabs()}
        </Grid>
      </Grid>
    </>
  );
};

export default UserDetails;
