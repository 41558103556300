import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { authenticationAtom } from "../../atoms/auth";
import { useAtom, } from "jotai";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomerDetails,
  addCustomerInvoices,
  selectCustomerDetails,
} from "../../features/userSlice";
import { getCustomer, getCustomerInvoices } from "../../api/users/userAPI";
import { getCustomerRoutesPosition } from "../../api/trips/tripsAPI";
import { colors } from "../../assets/utils/colors";
import CustomerInvoices from "./CustomerInvoices";
import moment from "moment";
import { RemoveRedEye } from "@mui/icons-material";
import { Modal } from "antd";
import useAuth from "../../hooks/useAuth";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { useQuery } from "react-query";
import CustomerPositionsGeneratedReport from "./CustomerPositionsGeneratedReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const ViewCustomerAction = (customer) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addedTime = moment(customer?.customer.created_at).format("DD-MM-YYYY");
  const updateTime = moment(customer?.customer.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Customer Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Added by :{" "}
            {customer.customer.added_by ? (
              <span>
                {customer.customer.added_by.first_name}{" "}
                {customer.customer.added_by.middle_name}{" "}
                {customer.customer.added_by.last_name}{" "}
                <span className="text-xs">
                  ({customer.customer.added_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Added at :{" "}
            {customer.customer.created_at ? <span>{addedTime}</span> : null}
          </p>
          <p>
            Updated by :{" "}
            {customer.customer.updated_by ? (
              <span>
                {customer.customer.updated_by.first_name}{" "}
                {customer.customer.updated_by.middle_name}{" "}
                {customer.customer.updated_by.last_name}{" "}
                <span className="text-xs">
                  ({customer.customer.updated_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Updated at :{" "}
            {customer.customer.updated_at ? <span>{updateTime}</span> : null}
          </p>
        </div>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    padd: 4,
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColOneThird: {
    width: "33.333%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  text: {
    fontSize: 12,
  },
  textBold: {
    fontWeight: "bold",
  },
  titleData: {},
  tableCol2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
  },
  weeklySmallColumn: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  weeklyMegaColumn: {
    width: "17.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const CustomerPositionsReport = ({ data, title = "" }) => {
  // console.log("expense data passed");
  // console.log(data);

  return (
    <PDFViewer style={{ width: "100%", height: 450 }}>
      <Document style={{ width: "100%", height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>

            <View style={{ marginTop: 18 }}>
              {data?.map((route, index) => {
                const num = index + 1;
                return (
                  <View style={{ marginTop: 12 }}>
                    <View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text style={[styles.text, { width: 120 }]}>
                          Route{" "}
                        </Text>
                        <Text style={[styles.text, {}]}>: {num}</Text>
                      </View>
                      <View style={{ marginRight: 4 }}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={[styles.text, { width: 120 }]}>
                            Truck{" "}
                          </Text>
                          <Text style={[styles.text, {}]}>
                            : {route?.truck.registration_number}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={[styles.text, { width: 120 }]}>
                            Trailer{" "}
                          </Text>
                          <Text style={[styles.text, {}]}>
                            : {route?.truck.trailer_registration_number}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={[styles.text, { width: 120 }]}>
                            Initial Location{" "}
                          </Text>
                          <Text style={[styles.text, {}]}>
                            : {route?.initial_location}
                          </Text>
                        </View>

                        <View
                          style={{
                            marginTop: 2,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={[styles.text, { width: 120 }]}>
                            Final Location{" "}
                          </Text>
                          <Text style={[styles.text, {}]}>
                            : {route?.final_location}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={{ marginTop: 6 }}>
                      <Text style={[styles.text, styles.bold]}>
                        Route Positions
                      </Text>
                      <View style={{ marginTop: 4 }}>
                        <View style={styles.table}>
                          <View style={styles.tableRow}>
                            <View style={[styles.tableColOneThird]}>
                              <Text
                                style={[
                                  styles.tableCell,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                No
                              </Text>
                            </View>
                            <View style={[styles.tableColOneThird]}>
                              <Text
                                style={[
                                  styles.tableCell,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                Position
                              </Text>
                            </View>

                            <View style={[styles.tableColOneThird]}>
                              <Text
                                style={[
                                  styles.tableCell,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                Date
                              </Text>
                            </View>
                          </View>
                          {route?.route_positions?.map((position, index) => {
                            return (
                              <View style={styles.tableRow}>
                                <View style={[styles.tableColOneThird]}>
                                  <Text style={styles.tableCell}>
                                    {index + 1}
                                  </Text>
                                </View>
                                <View style={[styles.tableColOneThird]}>
                                  <Text style={styles.tableCell}>
                                    {position?.position}
                                  </Text>
                                </View>

                                <View style={[styles.tableColOneThird]}>
                                  <Text style={styles.tableCell}>
                                    {moment(position?.created_at).format(
                                      "Do MMMM YYYY"
                                    )}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
            <View></View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const CustomerRouteReport = ({ customerId = "", name }) => {
  const auth = useAuth();
  const token = auth?.token || "";

  const { isLoading, error, data } = useQuery(
    ["customer", "position", "report"],
    () => getCustomerRoutesPosition({ customer_id: customerId, token })
  );

  const renderReport = () => {
    if (error) {
      return <p>Something went wrong fetching data</p>;
    }
    if (isLoading) return "Loading report";
    if (data) {
      const positions = data.data;
      if (positions.success) {
        const routes = positions?.data || [];
        return (
          <>
            <CustomerPositionsReport
              title={`${name}'s Active Routes Positions Report`}
              data={routes}
            />
          </>
        );
      } else {
        <>
          <p className="text-red-500">{positions.message}</p>
        </>;
      }
    }
  };

  return <div className="mb-24">{renderReport()}</div>;
};

const CustomerDetails = () => {
  const [value, setValue] = React.useState(0);

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const { customerID } = useParams();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const fetchCustomer = async () => {
    const response = await getCustomer({ customerId: customerID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        // console.log({data: response.data.data});
        dispatch(addCustomerDetails(response.data.data));
      }
    }
  };

  const getInvoices = async () => {
    const response = await getCustomerInvoices({
      customer_id: customerID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addCustomerInvoices(response.data.data));
      }
    }
  };

  useEffect(() => {
    fetchCustomer();
    getInvoices();
  }, [dispatch]);

  const customer = useSelector(selectCustomerDetails);
  // console.log({customer: customer});

  const renderCustomerDetails = () => {
    if (!isEmpty(customer)) {
      return (
        <Card
          sx={{
            width: 650,
            height: 480,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">CUSTOMER DETAILS</h3>
            <div className="pt-4">
              <div className="text-lg px-6">
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Customer Name :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {customer.customer_name ? (
                        <span>{customer.customer_name}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Phone :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {customer.phone_number ? (
                        <span>{customer.phone_number}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Email :</p>
                    {""}
                    <span className="text-[#F24E1E] w-[70%]">
                      {customer.email ? <span>{customer.email}</span> : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Status :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%] capitalize">
                      {customer.status ? <span>{customer.status}</span> : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Location :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {customer.location ? (
                        <span>{customer.location}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Customer Type :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%] capitalize">
                      {customer.customer_type ? (
                        <span>{customer.customer_type}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                {customer.customer_type === "company" ? (
                  <>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        {customer.customer_type === "company" ? (
                          <>
                            <p className="w-[45%]">Contact Person Name :</p>{" "}
                            <span className="text-[#F24E1E] w-[55%]">
                              {customer.contact_person_name ? (
                                <span>{customer.contact_person_name}</span>
                              ) : (
                                <span></span>
                              )}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        {customer.customer_type === "company" ? (
                          <>
                            <p className="w-[45%]">Contact Person Number :</p>{" "}
                            <span className="text-[#F24E1E] w-[55%]">
                              {customer.contact_person_phone ? (
                                <span>{customer.contact_person_phone}</span>
                              ) : (
                                <span></span>
                              )}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        {user.role_name === "Admin" ||
                        user.role_name === "admin" ? (
                          <>
                            <p className="w-[45%]">View Details :</p>{" "}
                            <p className="text-[#F24E1E] w-[55%]">
                              <ViewCustomerAction customer={customer} />
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-row">
                      <div className="w-[95%] py-2 flex flex-row gap-2">
                        {user.role_name === "Admin" ||
                        user.role_name === "admin" ? (
                          <>
                            <p className="w-[30%]">View Details :</p>{" "}
                            <p className="text-[#F24E1E] w-[70%]">
                              <ViewCustomerAction customer={customer} />
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="w-[5%]"></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p className="text-center">Customer has no details</p>;
    }
  };

  const renderUserTabs = () => {
    return (
      <>
        <Grid container>
          <Grid item sm={12}>
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
              }}
              //   className="flex flex-row justify-between"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor={`${primary}`}
                indicatorColor="primary"
                sx={{ color: "#F24E1E" }}
              >
                <Tab label="Customer Details" {...a11yProps(0)} />
                <Tab label="Customer Invoices" {...a11yProps(1)} />
                <Tab
                  label="Customer' Active Routes Positions"
                  {...a11yProps(2)}
                />
                <Tab
                  label="Generate Route Positions Report"
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="flex flex-row justify-center pb-8">
                {renderCustomerDetails()}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CustomerInvoices />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CustomerRouteReport customerId={customerID} name={customer?.customer_name} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CustomerPositionsGeneratedReport />
            </TabPanel>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container sx={{ px: 2 }}>
        <Grid item sm={12}>
          {renderUserTabs()}
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerDetails;
