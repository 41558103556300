import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import tables from "../../styles/tables.module.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { colors } from "../../assets/utils/colors";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { Chip, Divider, Grid, IconButton } from "@mui/material";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { RemoveRedEye } from "@mui/icons-material";
import { getSingleStock } from "../../api/stock/stockAPI";
import { addStockDetails, selectStockDetails } from "../../features/stockSlice";
import StockTransfers from "./StockTransfers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const ViewStockAction = ({ stock }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const addedTime = moment(stock?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(stock?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton
        variant="outlined"
        onClick={showModal}
        size="small"
        className="pb-3"
      >
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
          className="pb-1"
        />
      </IconButton>
      <Modal
        title="Stock Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {stock.description ? <span>{stock.description}</span> : null}
          </p>
          <br />
          <br />
          {user?.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {stock.added_by ? (
                  <span>
                    {stock.added_by.first_name} {stock.added_by.middle_name}{" "}
                    {stock.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({stock.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at : {stock.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {stock.updated_by ? (
                  <span>
                    {stock.updated_by.first_name} {stock.updated_by.middle_name}{" "}
                    {stock.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({stock.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {stock.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const StockDetails = () => {
  const [value, setValue] = useState(0);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { stockID } = useParams();
  const dispatch = useDispatch();

  const getStock = async () => {
    const response = await getSingleStock({
      stock_id: stockID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addStockDetails(response.data.data));
    }
  };

  useEffect(() => {
    getStock();
  }, [dispatch]);

  const stock = useSelector(selectStockDetails);
  //console.log(stock);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderStockDetails = () => {
    if (!isEmpty(stock)) {
      return (
        <Box>
          <div className="flex flex-row justify-center">
            <Card
              sx={{
                width: 850,
                height: 250,
                background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
              }}
            >
              <CardContent>
                <h3 className="text-center text-xl py-2">STOCK DETAILS</h3>
                <div className="px-8">
                  <div className="flex flex-row gap-2 py-2">
                    <div className="flex flex-row gap-2 w-[50%]">
                      <h4>ITEM NAME :</h4>
                      <p className="capitalize">{stock?.item?.type_name}</p>
                    </div>
                    <div className="flex flex-row gap-2 w-[50%]">
                      <h4>ITEM QUANTITY :</h4>
                      <p>{stock?.quantity}</p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 py-2">
                    <div className="flex flex-row gap-2 w-[50%]">
                      <h4>USED QUANTITY :</h4>
                      <p>{stock?.total_used}</p>
                    </div>
                    <div className="flex flex-row gap-2 w-[50%]">
                      <h4>AVAILABLE QUANTITY :</h4>
                      <p>{stock?.in_stock}</p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 py-2">
                    <div className="flex flex-row gap-2 w-[50%]">
                      <h4>LAST UPDATE :</h4>
                      <p>{moment(stock?.updated_at).format("DD-MM-YYYY")}</p>
                    </div>
                    <div className="flex flex-row gap-2 w-[50%]">
                      <h4>AVAILABLE STOCK COSTS :</h4>
                      <p>{formatter.format(stock?.total_cost)}</p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 py-2">
                    <div className="flex flex-row gap-2 w-[50%]">
                      <h4>VIEW :</h4>
                      <ViewStockAction stock={stock} />
                    </div>
                    <div className="flex flex-row gap-2 w-[50%]"></div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="w-[100%] py-3">
            {/* <Divider>
              <Chip
                className="font-semibold"
                label={`ORDER ITEMS (${order?.ordered_items?.length})`}
              />{" "}
              <Chip
                className="font-semibold"
                label={`TOTAL ORDER ITEMS PRICE: ${formatter.format(
                  order?.total_order_price || 0
                )}`}
              />
            </Divider> */}
          </div>
          {/* <div className="pb-8">
            <div className="pb-2">
              <div
                className={`bg-[#EEDEDE] h-10 text-center ${tables.orderItemsList} rounded-t-md font-semibold`}
              >
                <p className="py-2">No</p>
                <p className="py-2">Item Name</p>
                <p className="py-2">Item Price</p>
                <p className="py-2">Quantity</p>
                <p className="py-2">Total</p>
              </div>
              <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
                {order?.ordered_items?.length > 0 ? (
                  <div>
                    {order?.ordered_items.map((item, index) => {
                      const position = index + 1;
                      return (
                        <div
                          key={index}
                          className={`bg-white h-10 ${tables.orderItemsList} rounded-md text-sm my-2`}
                        >
                          <p className="py-2 text-center">{position}</p>
                          <p className="py-2 text-center">{item?.item_name}</p>
                          <p className="py-2 text-center">
                            {formatter.format(item?.price || 0)}
                          </p>
                          <p className="py-2 text-center">{item?.quantity}</p>
                          <p className="py-2 text-center">
                            {formatter.format(item?.total_price || 0)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="text-center">There are no order items</p>
                )}
              </div>
              <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
            </div>
          </div> */}
        </Box>
      );
    } else {
      <p className="text-center">Order has no details</p>;
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Box
            sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}
            // className="flex flex-row justify-between"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor={`${primary}`}
              indicatorColor="primary"
              sx={{ color: "#F24E1E" }}
            >
              <Tab label="STOCK DETAILS" {...a11yProps(0)} />
              <Tab label="STOCK TRANSFERS" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="pb-8">{renderStockDetails()}</div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <StockTransfers />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default StockDetails;
