import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddPaymentType from "./AddPaymentType";
import tables from "../../styles/tables.module.css";
import { MdDelete } from "react-icons/md";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  deletePaymentType,
  getAllPaymentTypes,
} from "../../api/settings/settingAPI";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addPaymentTypes,
  selectPaymentTypes,
} from "../../features/settingSlice";
import EditPaymentType from "./EditPaymentType";
import { authenticationAtom } from "../../atoms/auth";
import { useAtom } from "jotai";
import { CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const DeletePaymentTypeAction = ({ type, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePaymentType = async (type) => {
    if (!isEmpty(type)) {
      setIsLoading(true);
      handleClose();
      const response = await deletePaymentType({
        paymentType_id: type.id,
        token,
      });
      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
      } else {
        notifyToastError({
          message: "Failed to delete payment type, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Payment Type is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={26} />
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={handleClickOpen}
                disabled={isLoading}
              >
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </Button>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete payment type"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete {type?.payment_name || ""}{" "}
              payment type
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handlePaymentType(type);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const SettingPaymentType = () => {
  
  const [userData] = useAtom(authenticationAtom);

  const dispatch = useDispatch();

  const { userInfo: user } = userData;
  const auth = useAuth();
  const token = auth?.token || "";

  const getPaymentTypes = async () => {
    const response = await getAllPaymentTypes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addPaymentTypes(response.data.data));
      }
    }
  };

  useEffect(() => {
    getPaymentTypes();
  }, [dispatch]);

  const paymentTypes = useSelector(selectPaymentTypes);

  const renderEdit = (paymentType) => {
    if (user) {
      return (
        <>
          <EditPaymentType paymentType={paymentType} />
        </>
      );
    }
  };

  const renderDelete = (paymentType) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeletePaymentTypeAction
              type={paymentType}
              onSuccess={() => {
                getPaymentTypes();
              }}
            />
          </>
        );
      }
    }
  };

  const renderPayments = () => {
    if (!isEmpty(paymentTypes)) {
      return (
        <div>
          {paymentTypes.map((paymentType, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.brandTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2">
                  <span className="pl-12 capitalize">
                    {paymentType.payment_name}
                  </span>
                </p>
                <p className="py-2">{paymentType.type_description}</p>
                {renderEdit(paymentType)}
                {renderDelete(paymentType)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">No payment types available</p>
        </div>
      );
    }
  };

  const renderPaymentTypes = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.brandTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Name</p>
          <p className="py-2">Payment Type Description</p>
          <p className="py-2">Edit</p>
          <p className="py-2">Delete</p>
        </div>
        <div className="bg-[#F0F0F0] h-[150px] overflow-y-scroll">
          {renderPayments()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row gap-4 justify-center items-center w-[100%]">
        <AddPaymentType />
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>Payment Types</h5>
          <div className="w-[100%]">{renderPaymentTypes()}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingPaymentType;
