import React, { useEffect } from "react";
import AddRole from "./AddRole";
import tables from "../../styles/tables.module.css";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles, deleteRole } from "../../api/settings/settingAPI";
import { isEmpty } from "lodash";
import { addUserRoles, selectUserRoles } from "../../features/settingSlice";
import EditRole from "./EditRole";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { notifyToastError, notifyToastSuccess } from "../toast";
import { CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const DeleteUserRole = ({ role, onSuccess = () => {} }) => {

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleRole = async () => {
    if (!isEmpty(role)) {
      setIsLoading(true)
      handleClose();
      const response = await deleteRole({ role_id: role.id, token });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false)
      } else {
        notifyToastError({
          message: "Failed to delete role, Please try again",
          icon: "❗",
        });
        setIsLoading(false)
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false)
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Role is successfully deleted",
          icon: "✅",
        });
        onSuccess()
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {
            isLoading ?
              <>
                <CircularProgress size={28} />
              </>
              :
              <>
                <Button variant="outlined" onClick={handleClickOpen}
                  disabled={isLoading}
                >
                  <MdDelete className="text-[#F24E1E] text-xl ml-0" />
                </Button>
              </>
          }
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete user role"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete {role?.role_name || ""} role?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleRole} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

      </p>
    </div>
  )
}

const SettingRoles = () => {

  const dispatch = useDispatch();
  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const token = auth?.token || "";


  const getRoles = async () => {
    const response = await getAllRoles({token});

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addUserRoles(response.data.data));
      }
    }
  };

  useEffect(() => {
    getRoles();
  }, [dispatch]);

  const roles = useSelector(selectUserRoles);

  const { userInfo: user } = userData

  const renderEdit = (role) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdEdit className="text-[#F24E1E] text-xl ml-5 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <EditRole role={role} />
          </>
        );
      }
    }
  };

  const renderDelete = (role) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteUserRole role={role} 
              onSuccess={()=>{
                getRoles()
              }}
            />
          </>
        );
      }
    }
  };

  const renderRoles = () => {
    if (!isEmpty(roles)) {
      return (
        <div>
          {roles.map((role, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.brandTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2">
                  <span className="pl-20">{role.role_name}</span>
                </p>
                <p>{role.role_description}</p>
                {renderEdit(role)}
                {renderDelete(role)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">No user roles available</p>
        </div>
      );
    }
  };

  const renderUserRoles = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.brandTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Name</p>
          <p className="py-2">User Role Description</p>
          <p className="py-2">Edit</p>
          <p className="py-2">Delete</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderRoles()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row gap-4 justify-center items-center w-[100%]">
        <AddRole />
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>User Roles</h5>
          <div className="w-[100%]">{renderUserRoles()}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingRoles;
