import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Select, Checkbox } from "antd";
import { getCustomerRoutesPosition } from "../../api/trips/tripsAPI";
import useAuth from "../../hooks/useAuth";
import { getAllCustomers } from "../../api/users/userAPI";
import { isEmpty } from "lodash";
import { addCustomers, selectCustomers } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Modal } from "@mui/material";
import { notifyToastError } from "../toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    padd: 4,
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColOneThird: {
    width: "33.333%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  text: {
    fontSize: 12,
  },
  textBold: {
    fontWeight: "bold",
  },
  titleData: {},
  tableCol2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
  },
  weeklySmallColumn: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  weeklyMegaColumn: {
    width: "17.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const CustomerPositionsReport = ({ data, title = "", today }) => {
  // console.log("expense data passed");
  // console.log(data);

  const now = moment().format("YYYY-MM-DD");

  return (
    <PDFViewer style={{ width: "100%", height: 450 }}>
      <Document style={{ width: "100%", height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>

            <View style={{ marginTop: 18 }}>
              {data?.map((route, index) => {
                const num = index + 1;
                return (
                  <View style={{ marginTop: 12 }}>
                    <View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text style={[styles.text, { width: 120 }]}>
                          Route{" "}
                        </Text>
                        <Text style={[styles.text, {}]}>: {num}</Text>
                      </View>
                      <View style={{ marginRight: 4 }}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={[styles.text, { width: 120 }]}>
                            Truck{" "}
                          </Text>
                          <Text style={[styles.text, {}]}>
                            : {route?.truck.registration_number}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={[styles.text, { width: 120 }]}>
                            Initial Location{" "}
                          </Text>
                          <Text style={[styles.text, {}]}>
                            : {route?.initial_location}
                          </Text>
                        </View>

                        <View
                          style={{
                            marginTop: 2,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={[styles.text, { width: 120 }]}>
                            Final Location{" "}
                          </Text>
                          <Text style={[styles.text, {}]}>
                            : {route?.final_location}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={{ marginTop: 6 }}>
                      <Text style={[styles.text, styles.bold]}>
                        Route Positions
                      </Text>
                      <View style={{ marginTop: 4 }}>
                        <View style={styles.table}>
                          <View style={styles.tableRow}>
                            <View style={[styles.tableColOneThird]}>
                              <Text
                                style={[
                                  styles.tableCell,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                No
                              </Text>
                            </View>
                            <View style={[styles.tableColOneThird]}>
                              <Text
                                style={[
                                  styles.tableCell,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                Position
                              </Text>
                            </View>

                            <View style={[styles.tableColOneThird]}>
                              <Text
                                style={[
                                  styles.tableCell,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                Date
                              </Text>
                            </View>
                          </View>
                          {today ? (
                            <>
                              {route?.route_positions?.map(
                                (position, index) => {
                                  if (now === position.position_date) {
                                    return (
                                      <View style={styles.tableRow}>
                                        <View style={[styles.tableColOneThird]}>
                                          <Text style={styles.tableCell}>
                                            {index + 1}
                                          </Text>
                                        </View>
                                        <View style={[styles.tableColOneThird]}>
                                          <Text style={styles.tableCell}>
                                            {position?.position}
                                          </Text>
                                        </View>

                                        <View style={[styles.tableColOneThird]}>
                                          <Text style={styles.tableCell}>
                                            {moment(
                                              position?.position_date
                                            ).format("Do MMMM YYYY")}
                                          </Text>
                                        </View>
                                      </View>
                                    );
                                  }
                                }
                              )}
                            </>
                          ) : (
                            <>
                              {route?.route_positions?.map(
                                (position, index) => {
                                  return (
                                    <View style={styles.tableRow}>
                                      <View style={[styles.tableColOneThird]}>
                                        <Text style={styles.tableCell}>
                                          {index + 1}
                                        </Text>
                                      </View>
                                      <View style={[styles.tableColOneThird]}>
                                        <Text style={styles.tableCell}>
                                          {position?.position}
                                        </Text>
                                      </View>

                                      <View style={[styles.tableColOneThird]}>
                                        <Text style={styles.tableCell}>
                                          {moment(
                                            position?.position_date
                                          ).format("Do MMMM YYYY")}
                                        </Text>
                                      </View>
                                    </View>
                                  );
                                }
                              )}
                            </>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
            <View></View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const CustomerRouteReport = ({ customer_id, name, today }) => {
  const auth = useAuth();
  const token = auth?.token || "";

  const { isLoading, error, data } = useQuery(
    ["customer", "position", "report"],
    () => getCustomerRoutesPosition({ customer_id, token })
  );

  const renderReport = () => {
    if (error) {
      return <p>Something went wrong fetching data</p>;
    }
    if (isLoading) return "Loading report";
    if (data) {
      const positions = data.data;
      if (positions.success) {
        const routes = positions?.data || [];

        return (
          <>
            <CustomerPositionsReport
              title={`${name}'s Positions Report`}
              data={routes}
              today={today}
            />
          </>
        );
      } else {
        <>
          <p className="text-red-500">{positions.message}</p>
        </>;
      }
    }
  };

  return <div className="mb-24">{renderReport()}</div>;
};

const TrackerRouteReports = () => {
  const dispatch = useDispatch();

  const auth = useAuth();
  const token = auth?.token || "";

  const [customer, setCustomer] = useState("");
  const [today, setToday] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getCustomers = async () => {
    const response = await getAllCustomers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addCustomers(response.data.data));
      }
    }
  };

  useEffect(() => {
    getCustomers();
  }, [dispatch]);

  const customers = useSelector(selectCustomers);

  let sortedCustomer = [];

  if (customers.length !== 0) {
    customers.map((customer) => {
      sortedCustomer.push({ id: customer.id, value: customer.customer_name });
    });
  }

  const generateReport = async (e) => {
    e.preventDefault();

    if (customer) {
      //return
      setOpen(true);
    } else {
      notifyToastError({
        message: "Please select customer",
        icon: "❗",
      });
    }
  };

  const handleOnChange = (value, id) => {
    setCustomer(id);
  };

  const customerSearch = () => {
    return (
      <div>
        <h4 className="text-center text-xl py-2">
          GENERATE CUSTOMER' ACTIVE ROUTES POSITIONS PDF
        </h4>
        <div className="pt-8 flex justify-center">
          <Select
            showSearch
            style={{
              width: 446,
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            size="middle"
            filterOption={(input, option) =>
              (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.value ?? "")
                .toLowerCase()
                .localeCompare((optionB?.value ?? "").toLowerCase())
            }
            onChange={handleOnChange}
            options={sortedCustomer}
          />
        </div>

        <div className="py-3 pl-20">
          <Checkbox
            onChange={(e) => setToday(e.target.checked)}
            checked={today}
          >
            Today Posted Positions Only
          </Checkbox>
        </div>

        <div className="py-2 flex justify-center">
          <Button
            size="large"
            variant="contained"
            className="w-[75%]"
            onClick={(e) => generateReport(e)}
          >
            SUBMIT
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Grid container sx={{ px: 2 }}>
      <Grid
        item
        sm={12}
        sx={{ display: "flex", justifyContent: "center", py: 2 }}
      >
        <Box sx={{ width: 600, height: 250 }} className="rounded-md shadow-xl">
          {customerSearch()}
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style}>
            <div className="mx-auto bg-white p-2 pt-4">
              <div className="flex items-center justify-center">
                <h1 className="text-center text-xl font-semibold mb-2">
                  {/* {customer?.id || ""} */}
                </h1>
              </div>
              <CustomerRouteReport
                customer_id={customer?.id}
                name={customer?.value}
                today={today}
              />
            </div>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default TrackerRouteReports;
