import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  employees: [],
  customers: [],
  suppliers: [],
  registeredNames: [],
  designations: [],
  customerInvoices: [],
  supplierExpenses: [],
  guarantors: "",
  customerDetails: "",
  supplierDetails: "",
  employeeDetails: "",
  userCustomDetails: "",
  searchedUsers: [],
  balances: [],
  searchedCustomers: [],
  searchedSuppliers: [],
  searchedEmployees: [],
  searchedNames: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addAllUsers(state, action) {
      state.users = action.payload;
    },

    addEmployees(state, action) {
      state.employees = action.payload;
    },

    addCustomers(state, action) {
      state.customers = action.payload;
    },

    addCustomerInvoices(state, action) {
      state.customerInvoices = action.payload;
    },

    addSuppliers(state, action) {
      state.suppliers = action.payload;
    },
    
    addRegisteredNames(state, action) {
      state.registeredNames = action.payload;
    },

    addSupplierExpenses(state, action) {
      state.supplierExpenses = action.payload;
    },

    addDesignations(state, action) {
      state.designations = action.payload;
    },

    addCustomerDetails(state, action) {
      state.customerDetails = action.payload;
    },

    addSupplierDetails(state, action) {
      state.supplierDetails = action.payload;
    },

    addEmployeeDetails(state, action) {
      state.employeeDetails = action.payload;
    },

    addEmployeeGuarantors(state, action) {
      state.guarantors = action.payload;
    },

    addUserCustomDetails(state, action) {
      state.userCustomDetails = action.payload;
    },

    addSearchedUsers(state, action) {
      state.searchedUsers = action.payload;
    },

    addSupplierBalances(state, action) {
      state.balances = action.payload;
    },

    addSearchedCustomers(state, action) {
      state.searchedCustomers = action.payload;
    },

    addSearchedSuppliers(state, action) {
      state.searchedSuppliers = action.payload;
    },

    addSearchedEmployees(state, action) {
      state.searchedEmployees = action.payload;
    },

    addSearchedNames(state, action) {
      state.searchedNames = action.payload;
    },
  },
});

export const {
  addAllUsers,
  addEmployees,
  addCustomers,
  addCustomerInvoices,
  addSuppliers,
  addSupplierExpenses,
  addDesignations,
  addCustomerDetails,
  addSupplierDetails,
  addEmployeeDetails,
  addEmployeeGuarantors,
  addUserCustomDetails,
  addSearchedUsers,
  addSupplierBalances,
  addSearchedCustomers,
  addSearchedSuppliers,
  addSearchedEmployees,
  addRegisteredNames,
  addSearchedNames,
} = userSlice.actions;

export const selectAllUsers = (state) => state.user.users;
export const selectEmployees = (state) => state.user.employees;
export const selectCustomers = (state) => state.user.customers;
export const selectCustomerDetails = (state) => state.user.customerDetails;
export const selectCustomerInvoices = (state) => state.user.customerInvoices;
export const selectSuppliers = (state) => state.user.suppliers;
export const selectRegisteredNames = (state) => state.user.registeredNames;
export const selectSupplierDetails = (state) => state.user.supplierDetails;
export const selectSupplierExpenses = (state) => state.user.supplierExpenses;
export const selectDesignations = (state) => state.user.designations;
export const selectEmployeeDetails = (state) => state.user.employeeDetails;
export const selectGuarantors = (state) => state.user.guarantors;
export const selectUserCustomDetails = (state) => state.user.userCustomDetails;
export const selectSearchedUsers = (state) => state.user.searchedUsers;
export const selectBalances = (state) => state.user.balances;
export const selectSearchedCustomers = (state) => state.user.searchedCustomers;
export const selectSearchedSuppliers = (state) => state.user.searchedSuppliers;
export const selectSearchedEmployees = (state) => state.user.searchedEmployees;
export const selectSearchedNames = (state) => state.user.searchedNames;

export default userSlice.reducer;
