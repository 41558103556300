import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FaUserPlus } from "react-icons/fa";
import { Button, Autocomplete, Modal, Box, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  createPersonalExpense,
  getAllPersonalExpenses,
} from "../../api/expenses/expensesAPI";
import { getAllSuppliers, getAllUsers } from "../../api/users/userAPI";
import {
  addAllUsers,
  addSuppliers,
  selectAllUsers,
  selectSuppliers,
} from "../../features/userSlice";
import { setPersonalExpenses } from "../../features/expensesSlice";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddPersonalExpense = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [amount, setAmount] = useState("");
  const [owner, setOwner] = useState("");
  const [supplier, setSupplier] = useState("");
  const [expense_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const auth = useAuth();

  const ownerOnChange = (e, value) => {
    setOwner(value);
  };

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  //fetch all personal expenses
  const getExpenses = async () => {
    const response = await getAllPersonalExpenses({ token: auth?.token || "" });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(setPersonalExpenses(response.data.data));
      }
    }
  };

  //fetch all owners
  const getOwners = async () => {
    const response = await getAllUsers({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addAllUsers(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  useEffect(() => {
    getOwners();
    getSuppliers();
  }, [dispatch]);

  const allUsers = useSelector(selectAllUsers);
  const suppliers = useSelector(selectSuppliers);

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));

  const sortedOwners = allUsers
    .filter((user) => user.role_name.toLowerCase() === "truck owner")
    .map((user) => ({
      id: user.id,
      label: `${user.first_name} ${user.middle_name} ${user.last_name}`,
    }));

  const transactionRegistration = async (e) => {
    e.preventDefault();

    if (!amount) {
      notifyToastError({
        message: "Please enter paid amount",
        icon: "❗",
      });
    } else if (!owner) {
      notifyToastError({
        message: "Please select payment receiver",
        icon: "❗",
      });
    } else if (!supplier) {
      notifyToastError({
        message: "Please select payment supplier",
        icon: "❗",
      });
    } else if (!expense_date) {
      notifyToastError({
        message: "Please select payment date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(expense_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createPersonalExpense({
        amount,
        expense_date: date,
        owner_id: owner.id,
        supplier_id: supplier?.id,
        description,
        token: auth?.token || "",
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setAmount("");
        setOwner("");
        setSupplier("");
        setDate(null);
        setDescription("");
        getExpenses();

        notifyToastSuccess({
          message: "Personal Expense is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => transactionRegistration(e)}
        >
          SAVE PERSONAL EXPENSE
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <p className="flex justify-center items-center">
            <FaUserPlus className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">
            Add Personal Expense
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new expense to an existing personal expense
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">
              Add Personal Expense Details
            </h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Paid Amount"
                  variant="outlined"
                  type={"number"}
                  className="w-[40%]"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[40%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedOwners}
                  size="large"
                  className="w-[40%]"
                  value={owner}
                  onChange={ownerOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Payment Receiver" />
                  )}
                />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select payment date"
                    value={expense_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[40%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddPersonalExpense;
