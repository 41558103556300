export const colors = {
    primary: '#323232',
    secondary: '#43A515',
    bgColor1: '#EBD1D1',
    bgColor2: '#ECEBEB',
    bgColor3: '#FEFEFE',
    bgColor4: '#f2fdee',
    bgColor5: '#d9f8cb',
    bgColor6: '#a7ef85',
    link: '#F24E1E'
}