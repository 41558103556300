import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map } from "lodash";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  assignTrailer,
  getAllTrailers,
  getAllTrucks,
} from "../../api/trucks/trucksAPI";
import {
  addTrailers,
  addTrucks,
  selectTrailers,
} from "../../features/truckSlice";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AssignTrailerToTruck = ({ truck }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [trailer, setTrailer] = useState("");

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const trailerOnChange = (e, value) => {
    setTrailer(value);
  };

  const getTrailers = async () => {
    const response = await getAllTrailers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTrailers(response.data.data));
    }
  };

  //fetch all trucks
  const getTrucks = async () => {
    const response = await getAllTrucks({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTrucks(response.data.data));
    }
  };

  useEffect(() => {
    //fetch all trailers
    getTrailers();
  }, [dispatch]);

  const trailers = useSelector(selectTrailers);

  const sortedTrailers = trailers
  .filter((trailer) => "isAssigned" in trailer && trailer.isAssigned === 0)
  .map(({ id, registration_number }) => ({
    id,
    label: registration_number,
  }));

  const assignTrailerTruck = async (e) => {
    e.preventDefault();

    if (!trailer) {
      notifyToastError({
        message: "Please select trailer",
        icon: "❗",
      });
    } else {
      //start registration
      setLoading(true);

      const response = await assignTrailer({
        truck_id: truck.id,
        trailer_id: trailer?.id || "",
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getTrailers();
        getTrucks();
        setTrailer("");

        notifyToastSuccess({
          message: "Trailer is assigned to truck successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%] cursor-not-allowed"
            disabled
          >
            <svg
              class="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
            Loading...
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%]"
            onClick={(e) => assignTrailerTruck(e)}
          >
            ASSIGN TRUCK TRAILER
          </Button>
        </>
      );
    }
  };

  return (
    <div>
      <div onClick={handleOpen}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
          <span>Trailer</span>
        </ListItemIcon>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Assign Truck Trailer</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedTrailers}
                  size="small"
                  className="w-[82%]"
                  value={trailer}
                  onChange={trailerOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Trailer" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AssignTrailerToTruck;
