import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { MdDelete, MdEdit } from "react-icons/md";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import AddRoute from "./AddRoute";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTrip, deleteRoute } from "../../api/trips/tripsAPI";
import { isEmpty } from "lodash";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import {
  addRouteDetails,
  addTripDetails,
  selectTripDetails,
} from "../../features/tripsSlice";
import EditRoute from "./EditRoute";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAuth from "../../hooks/useAuth";

const DeleteTripRouteAction = ({ route, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTripRoute = async (route) => {
    if (!isEmpty(route)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteRoute({ route_id: route?.id || "", token });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete trip route , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "trip route is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={20} className="py-1" />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete trip route?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete trip route with ID {route?.id || ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              handleTripRoute(route);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TripRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tripID } = useParams();
  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const token = auth?.token || "";

  const getTrip = async () => {
    const response = await getSingleTrip({ trip_id: tripID, token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTripDetails(response.data.data));
    }
  };

  useEffect(() => {
    getTrip();
  }, [dispatch]);

  const trip = useSelector(selectTripDetails);

  const handleSelectedRoute = (route) => {
    //get trip details the navigate to route page
    dispatch(addRouteDetails(route));
    navigate(`/trips/${tripID}/routes/${route?.id}`);
  };

  const { userInfo: user } = userData;

  const renderRoutes = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.tripRouteTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Destination</p>
          <p className="py-2">Customer</p>
          <p className="py-2">Start Date</p>
          <p className="py-2">End Date</p>
          <p className="py-2">Status</p>
          <p className="py-2">Current Position</p>
          <p className="py-2">Actions</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderRoute()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderRoute = () => {
    if (!isEmpty(trip)) {
      if (trip.trip_routes) {
        // Sorting the routes based on the 'date' property
        const sortedRoutes = trip.trip_routes
          .slice()
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        return (
          <div>
            {sortedRoutes.map((route, index) => {
              const position = index + 1;
              return (
                <div
                  className={`h-10 bg-white ${tables.tripRouteTable} rounded-md text-sm my-2 mx-1`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 pl-6">{route.final_location}</p>
                  <p className="py-2 text-center">
                    {route.route_customer.customer_name}
                  </p>
                  <p className="py-2 text-center">{route.start_date}</p>
                  <p className="py-2 text-center">{route.end_date}</p>
                  <p className="py-2 pl-6">
                    {" "}
                    <span className="">
                      {route.status === "not_started" ? (
                        <span>Not started</span>
                      ) : route.status === "not_complete" ? (
                        <span>Failed</span>
                      ) : (
                        <span className="capitalize">{route.status}</span>
                      )}
                    </span>
                  </p>
                  <p className="py-2 text-center">{route.current_position}</p>
                  <div className="flex flex-row gap-1 justify-center pl-4">
                    {renderEdit(route)}
                    {renderDelete(route)}
                  </div>
                  <div className="text-center pl-4">{renderView(route)}</div>
                </div>
              );
            })}
          </div>
        );
      } else {
        return <p className="text-center">Trip has no routes</p>;
      }
    } else {
      return <p className="text-center">Trip has no routes</p>;
    }
  };

  const renderView = (route) => {
    return (
      <>
        <IconButton
          variant="outlined"
          onClick={() => handleSelectedRoute(route)}
        >
          <RemoveRedEye
            sx={{
              color: colors.link,
            }}
          />
        </IconButton>
      </>
    );
  };

  const renderEdit = (route) => {
    if (user.role_name?.toLowerCase() === "clerk") {
      return (
        <>
          <p className="cursor-not-allowed py-2 flex justify-center">
            <MdEdit className="text-[#F24E1E] text-xl ml-5 opacity-25" />
          </p>
        </>
      );
    } else {
      return (
        <>
          <EditRoute
            route={route}
            onSuccess={() => {
              getTrip();
            }}
          />
        </>
      );
    }
  };

  const renderDelete = (route) => {
    if (user.role_name?.toLowerCase() === "clerk") {
      return (
        <>
          <p className="cursor-not-allowed py-2 flex justify-center">
            <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
          </p>
        </>
      );
    } else {
      return (
        <>
          <DeleteTripRouteAction
            route={route}
            onSuccess={() => {
              getTrip();
            }}
          />
        </>
      );
    }
  };

  return (
    <>
      <Grid container sx={{ py: 2 }}>
        <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AddRoute
            trip={tripID}
            onSuccess={() => {
              getTrip();
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Trip Routes</h5>
            <div className="w-[100%] pb-8">{renderRoutes()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default TripRoute;
