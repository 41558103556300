import React from "react";
import { isEmpty, map } from "lodash";
import { useSelector } from "react-redux";
import { selectTrucks } from "../../features/truckSlice";
import TruckSummaryCard from "../cards/TruckSummaryCard";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";

const TrucksOwner = () => {
  const [userData] = useAtom(authenticationAtom);
  const trucks = useSelector(selectTrucks);

  let userTrucksArray = [];

  //search user trucks

  const { userInfo: user } = userData;
  if (!isEmpty(user) && !isEmpty(trucks)) {
    const user_id = user.id;

    userTrucksArray = trucks.filter((truck) => {
      //filtering array value
      return truck.owner_id === user_id;
    });
  }

  const renderTrucks = () => {
    if (userTrucksArray.length !== 0) {
      const userTrucks = map(userTrucksArray, (truck) => truck);

      return (
        <div className="mt-2 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-4">
          {userTrucks.map((truck, index) => {
            return (
              <div className="md:col-span-1">
                <TruckSummaryCard key={index} truck={truck} />
              </div>
            )
          })}
        </div>
      );
    } else {
      return (
        <p className="text-center">User has no registered trucks</p>
      );
    }
  };

  return (
    <div className="py-2 pl-4 pr-4 w-screen md:w-[calc(100vw-84px)] mx-auto">
      <h4 className="py-1">Your Trucks</h4>
      <div>{renderTrucks()}</div>
    </div>
  );
};

export default TrucksOwner;
