import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import ExpensesCard from "../cards/ExpensesCard";
import IncomeCard from "../cards/IncomeCard";
import ProfitCard from "../cards/ProfitCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import tables from "../../styles/tables.module.css";
import { colors } from "../../assets/utils/colors";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTruck } from "../../api/trucks/trucksAPI";
import { isEmpty } from "lodash";
import { addTruckDetails, selectTruckDetails } from "../../features/truckSlice";
import { addTripDetails } from "../../features/tripsSlice";
import { Grid, IconButton } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { RemoveRedEye } from "@mui/icons-material";
import { Modal } from "antd";
import { isMobileOnly } from "react-device-detect";
import { getTruckMonthlyExpense } from "../../api/expenses/expensesAPI";
import {
  addTruckMonthlyExpenses,
  selectTruckMonthlyExpenses,
} from "../../features/expensesSlice";

const ViewDescriptionAction = (expense) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Expense Description"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {expense.expense.description ? (
              <span>{expense.expense.description}</span>
            ) : null}
          </p>
          <br />
        </div>
      </Modal>
    </>
  );
};

const TripMobileCard = ({
  trip: {
    truck_registration_number = "",
    driver = "",
    start_date = "",
    end_date = "",
    trip_routes = "",
    trip_status = "",
    total_trip_invoice = "",
    total_trip_expenses = "",
  },
  selectedTrip,
  position,
}) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { truckID } = useParams();

  const handleSelectedTrip = (selectedTrip) => {
    dispatch(addTripDetails(selectedTrip));
    navigate(`/trucks/${truckID}/trips/${selectedTrip?.id}`);
  };

  const nameArray = driver.split(" ");
  const middleName = nameArray[1].charAt(0);

  return (
    <div
      className="shadow-md mt-4 border border-slate-800 px-2 py-2"
      onClick={() => {
        handleSelectedTrip(selectedTrip);
      }}
    >
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">No</p>
        <p className="text-right">{position}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Truck</p>
        <p className="text-right">{truck_registration_number}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Driver</p>
        <p className="text-right capitalize">
          {nameArray[0]} {middleName} {nameArray[2]}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Start Date</p>
        <p className="text-right">{start_date}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">End Date</p>
        <p className="text-right">{end_date}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Routes</p>
        <p className="text-right">
          {trip_routes ? <span>{trip_routes.length}</span> : 0}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Status</p>
        <p className="text-right capitalize">{trip_status}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Invoices Amount</p>
        <p className="text-right">{formatter.format(total_trip_invoice)}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Expenses Amount</p>
        <p className="text-right">{formatter.format(total_trip_expenses)}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Profit Amount</p>
        <p className="text-right">
          {formatter.format(total_trip_invoice - total_trip_expenses)}
        </p>
      </div>
    </div>
  );
};

const FinanceTruck = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dispatch = useDispatch();
  const { truckID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all
  const getExpenses = async () => {
    const response = await getTruckMonthlyExpense({
      truck_id: truckID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTruckMonthlyExpenses(response.data.data));
    }
  };

  const getTruck = async () => {
    const response = await getSingleTruck({ truck_id: truckID, token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTruckDetails(response.data.data));
    }
  };

  useEffect(() => {
    getTruck();
    getExpenses();
  }, [dispatch]);

  const truck = useSelector(selectTruckDetails);
  const expenses = useSelector(selectTruckMonthlyExpenses);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let indirectAmount = 0;
  let unpaidInvoiceAmount = 0;
  let profitAmount = 0;
  let availableBalance = 0;

  let invoicesArray = [];

  if (Array.isArray(expenses)) {
    if (expenses.length !== 0) {
      for (const value of expenses) {
        indirectAmount += value.amount;
      }
    }
  }

  if(truck?.trips){
    truck.trips.map(trip => {
      if(trip?.trip_routes){
        trip.trip_routes.map(route => {
          if(route?.route_invoice && route?.route_invoice?.status === "unpaid"){
            invoicesArray.push(route.route_invoice);
          }
        })
      }
    })
  }

  if(invoicesArray.length > 0){
    for(const value of invoicesArray){
      unpaidInvoiceAmount += value.unpaid_amount;
    }
  }



  //view route expenses
  const ViewExpensesAction = (route) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const renderExpenses = () => {
      if (!isEmpty(route)) {
        if (route.route.route_expenses) {
          return (
            <div>
              {route.route.route_expenses.map((expense, index) => {
                const position = index + 1;
                return (
                  <div
                    key={index}
                    className={`bg-white h-10 ${tables.financeTruckExpenseTable} rounded-md text-sm my-2 mx-2`}
                  >
                    <p className="py-2 text-center">{position}</p>
                    <p className="py-2 pl-4 capitalize">
                      {expense.expense_type ? (
                        <span>{expense.expense_type.type_name}</span>
                      ) : null}
                    </p>
                    <p className="py-2 pl-4">{expense.item_number}</p>
                    <p className="py-2 pl-4">
                      {formatter.format(expense.item_price)}
                    </p>
                    <p className="py-2 pl-4">
                      {formatter.format(expense.total_amount)}
                    </p>
                    <p className="py-2 text-center">
                      {expense.supplier ? (
                        <span>{expense.supplier.supplier_name}</span>
                      ) : null}
                    </p>
                    <p className="py-2 text-center">
                      {expense.expense_date ? (
                        <span>{expense.expense_date}</span>
                      ) : null}
                    </p>
                    <p className="flex flex-row justify-center pl-4">
                      <ViewDescriptionAction expense={expense} />
                    </p>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div>
              <p className="text-center">Route has no expenses</p>
            </div>
          );
        }
      } else {
        return (
          <div>
            <p className="text-center">Route has no expenses</p>
          </div>
        );
      }
    };

    const renderRouteExpenses = () => {
      return (
        <div className="pb-6">
          <div
            className={`bg-[#EEDEDE] h-10 text-center ${tables.financeTruckExpenseTable} rounded-t-md font-semibold`}
          >
            <p className="py-2">No</p>
            <p className="py-2">Expense Type</p>
            <p className="py-2">Items</p>
            <p className="py-2">Item Price</p>
            <p className="py-2">Total Amount</p>
            <p className="py-2">Expense Supplier</p>
            <p className="py-2">Date</p>
            <p className="py-2">Description</p>
          </div>
          <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
            {renderExpenses()}
          </div>
          <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
        </div>
      );
    };

    return (
      <>
        <div onClick={showModal}>
          <span className="text-[#F24E1E] text-sm cursor-pointer hover:underline">
            {formatter.format(route?.route.total_route_expense || 0)}
          </span>
        </div>
        <Modal
          title="Route Expenses"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          zIndex={800}
          width={1200}
          okType="default"
          cancelButtonProps={{
            className: "hidden",
          }}
        >
          <div className="">{renderRouteExpenses()}</div>
        </Modal>
      </>
    );
  };

  const renderTruckDetails = () => {
    //display truck trips table with drop down at each trip that shows trip's routes, expense and revenues
    return (
      <div className="pb-8">
        <h3 className="text-xl text-center py-4 pb-4">Truck Trips</h3>
        {isMobileOnly ? <>{renderMobileTrip()}</> : <>{renderTrip()}</>}
      </div>
    );
  };

  const renderTrip = () => {
    if (!isEmpty(truck)) {
      if (truck.trips) {
        if (truck.trips.length !== 0) {
          return (
            <div>
              {truck.trips.map((trip, index) => {
                const num = index + 1;
                let value = "".concat("panel", num);
                let idValue = "".concat(value, "bh-header");
                let ariaValue = "".concat(value, "bh-content");

                return (
                  <Accordion
                    key={index}
                    expanded={expanded === value}
                    onChange={handleChange(value)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={ariaValue}
                      id={idValue}
                      sx={{
                        background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
                      }}
                    >
                      <Typography sx={{ width: "3%", flexShrink: 0 }}>
                        {num}
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", width: "15%" }}
                      >
                        <p>Start date:</p>
                        <p>
                          <span className="text-sm">{trip.start_date}</span>
                        </p>
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", width: "15%" }}
                      >
                        <p>End date:</p>
                        <p>
                          <span className="text-sm">{trip.end_date}</span>
                        </p>
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", width: "13%" }}
                      >
                        <p>Status:</p>
                        <p>
                          {trip.trip_status === "not_started" ? (
                            <span className="text-sm">Not started</span>
                          ) : trip.trip_status === "not_complete" ? (
                            <span className="text-sm">Failed</span>
                          ) : (
                            <span className="capitalize text-sm">
                              {trip.trip_status}
                            </span>
                          )}
                        </p>
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", width: "14%" }}
                      >
                        <p>Profit:</p>
                        <p>
                          <span className="text-sm">
                            {formatter.format(
                              trip.total_trip_invoice - trip.total_trip_expenses
                            )}
                          </span>
                        </p>
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", width: "20%" }}
                      >
                        <p>Invoices Amount:</p>
                        <p>
                          <span className="text-sm">
                            {formatter.format(trip.total_trip_invoice)}
                          </span>
                        </p>
                      </Typography>
                      <Typography
                        sx={{ color: "text.secondary", width: "20%" }}
                      >
                        <p>Expenses Amount:</p>
                        <p>
                          <span className="text-sm">
                            {formatter.format(trip.total_trip_expenses)}
                          </span>
                        </p>
                      </Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                      <h3 className="py-2">Trip Routes</h3>
                      <Typography>
                        {trip.trip_routes ? (
                          <>
                            <div
                              className={`bg-[#EEDEDE] h-10 text-center ${tables.truckRouteTable} rounded-t-md font-semibold`}
                            >
                              <p className="py-2">No</p>
                              <p className="py-2">Initial Location</p>
                              <p className="py-2">Final Location</p>
                              <p className="py-2">Start Date</p>
                              <p className="py-2">End Date</p>
                              <p className="py-2">Status</p>
                              <p className="py-2">Invoice Amount</p>
                              <p className="py-2">Total Expenses</p>
                            </div>
                            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
                              {renderTruckRoutes(trip.trip_routes)}
                            </div>
                          </>
                        ) : (
                          <Typography>Trip has no routes</Typography>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          );
        } else {
          return <p className="text-center">Truck has no trips</p>;
        }
      } else {
        return <p className="text-center">Truck has no trips</p>;
      }
    } else {
      return <p className="text-center">Truck has no trips</p>;
    }
  };

  const renderMobileTrip = () => {
    if (!isEmpty(truck)) {
      if (truck.trips) {
        if (truck.trips.length !== 0) {
          return (
            <div className="pb-8">
              {truck.trips.map((trip, index) => {
                const position = index + 1;
                return (
                  <TripMobileCard
                    trip={trip}
                    selectedTrip={trip}
                    key={index}
                    position={position}
                  />
                );
              })}
            </div>
          );
        } else {
          return <p className="text-center">Truck has no trips</p>;
        }
      } else {
        return <p className="text-center">Truck has no trips</p>;
      }
    } else {
      return <p className="text-center">Truck has no trips</p>;
    }
  };

  const renderTruckRoutes = (routes) => {
    if (routes.length !== 0) {
      return routes.map((route, index) => {
        const position = index + 1;
        return (
          <div
            key={index}
            className={`bg-white h-10 ${tables.truckRouteTable} rounded-md text-sm my-2 mx-2`}
          >
            <p className="py-2 text-center">{position}</p>
            <p className="py-2 pl-4">{route.initial_location}</p>
            <p className="py-2 pl-4">{route.final_location}</p>
            <p className="py-2 text-center">{route.start_date}</p>
            <p className="py-2 text-center">{route.end_date}</p>
            <p className="py-2 pl-6">
              {route.status === "not_started" ? (
                <span>Not started</span>
              ) : route.status === "not_complete" ? (
                <span>Failed</span>
              ) : (
                <span className="capitalize">{route.status}</span>
              )}
            </p>
            <p className="py-2 pl-6">
              {formatter.format(route?.route_invoice.total_amount || 0)}
            </p>
            <p className="py-2 pl-6">
              <ViewExpensesAction route={route} />
            </p>
          </div>
        );
      });
    } else {
      return <p className="text-center">Trip has no routes</p>;
    }
  };

  profitAmount = truck?.total_truck_invoice - truck?.total_truck_expenses;
  availableBalance = profitAmount - unpaidInvoiceAmount;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
        <TripsCompletedCard
          trip={true}
          car={true}
          title={"TRIPS"}
          value={truck?.trips?.length}
        />
        </Grid>
        <Grid item sm={4} xs={12}>
          <IncomeCard
            car={false}
            owner={true}
            value={formatter.format(truck?.total_truck_invoice || 0)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ExpensesCard
            car={false}
            owner={true}
            value={formatter.format(truck?.total_truck_expenses || 0)}
            indirect={formatter.format(indirectAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ProfitCard
            title={"PROFITS"}
            car={false}
            owner={true}
            value={formatter.format(profitAmount - indirectAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
          trip={true}
          car={true}
          value={formatter.format(unpaidInvoiceAmount)}
          title={"UNPAID INVOICES AMOUNT"}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            trip={true}
            car={true}
            title={"BALANCE"}
            balance={availableBalance - indirectAmount}
            value={formatter.format(profitAmount - indirectAmount)}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          {renderTruckDetails()}
        </Grid>
      </Grid>
    </>
  );
};

export default FinanceTruck;
