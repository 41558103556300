import useAuth from "../hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";

// !####### MAIN FUNC ##########
const GuestRoutes = () => {
  // -> Variables
  const auth = useAuth();

  // -> CHECK AUTH
  if (!auth.authenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default GuestRoutes;
