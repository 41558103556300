import React, { useEffect } from "react";
import tables from "../../styles/tables.module.css";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import AddPosition from "./AddPosition";
import { getSingleRoute } from "../../api/trips/tripsAPI";
import { addRouteDetails, selectRouteDetails } from "../../features/tripsSlice";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const TrackerRoutePositions = () => {
  const dispatch = useDispatch();
  const { routeID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const getPositions = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getPositions();
  }, [dispatch]);

  const route = useSelector(selectRouteDetails);

  const renderPositions = () => {
    if (!isEmpty(route)) {
      if (route.route_positions) {
        if (route.route_positions.length !== 0) {
          const positions = route.route_positions;
          return (
            <div>
              {positions.map((position, index) => {
                const num = index + 1;
                return (
                  <div
                    key={index}
                    className={`bg-white h-10 ${tables.positionTable} rounded-md text-sm my-2 mx-2`}
                  >
                    <p className="py-2 text-center">{num}</p>
                    <p className="py-2 pl-14">{position.position}</p>
                    <p className="py-2 text-center">
                      {position?.position_date}
                    </p>
                    <p className="py-2 pl-10">{position.description}</p>
                    <p className="flex flex-row justify-center space-x-2 pl-4"></p>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <p>Route has no positions</p>
            </div>
          );
        }
      } else {
        return (
          <div className="text-center">
            <p>Route has no positions</p>
          </div>
        );
      }
    } else {
      return (
        <div className="text-center">
          <p>Route has no positions</p>
        </div>
      );
    }
  };

  const renderRoutePositions = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.positionTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Truck Position Name</p>
          <p className="py-2">Date</p>
          <p className="py-2">Truck Position Description</p>
          <p className="py-2">Update</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderPositions()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <>
      <Grid container sx={{ px: 2, py: 2 }}>
        <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AddPosition
            onSuccess={() => {
              getPositions();
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Route Positions</h5>
            <div className="w-[100%]">{renderRoutePositions()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TrackerRoutePositions;
