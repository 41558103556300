import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, Grid, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { selectSupplierDetails } from "../../features/userSlice";
import useAuth from "../../hooks/useAuth";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import {
  addMonthlyExpenseDetails,
  addSupplierTruckMonthlyExpenses,
  selectSupplierTruckMonthlyExpenses,
} from "../../features/expensesSlice";
import SuppliersPayment from "./SuppliersPayment";
import { getSupplierTruckMonthlyExpense } from "../../api/expenses/expensesAPI";
import SupplierIndirectExpensePayment from "./SupplierIndirecExpensePayments";

const SupplierIndirectExpenses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { supplierID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    //fetch all expenses
    const getExpenses = async () => {
      const response = await getSupplierTruckMonthlyExpense({
        supplier_id: supplierID,
        token,
      });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addSupplierTruckMonthlyExpenses(response.data.data));
        // setExpenses(response.data.data);
      }
    };

    getExpenses();
  }, [dispatch]);

  const expenses = useSelector(selectSupplierTruckMonthlyExpenses);
  const supplier = useSelector(selectSupplierDetails);
  const sortedExpenses = expenses
    .slice()
    .sort((a, b) => new Date(b.expense_date) - new Date(a.expense_date));

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let expenseTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;

  let expenseArray = [];

  if (Array.isArray(expenses)) {
    expenseArray = sortedExpenses;

    if (expenses.length !== 0) {
      for (const value of expenses) {
        expenseTotalAmount += value.amount;
        paidTotalAmount += value.paid_amount;
      }

      unpaidTotalAmount = expenseTotalAmount - paidTotalAmount;
    }
  }

  const handleExpense = (expense) => {
    //add expense data to store
    dispatch(addMonthlyExpenseDetails(expense));
    navigate(`/expenses/indrect-expenses/${expense?.id}`);
  };

  const renderExpenses = () => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.monthlyExpensesTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Truck</p>
              <p className="py-2">Expense Name</p>
              <p className="py-2">Expense Amount</p>
              <p className="py-2">Debt Status</p>
              <p className="py-2">Expense Date</p>
              <p className="py-2">Actions</p>
            </div>
            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
              {renderExpense()}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderExpense = () => {
    if (expenseArray.length !== 0) {
      return (
        <div>
          {expenseArray.map((expense, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.monthlyExpensesTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {expense.truck ? (
                    <span>{expense.truck?.registration_number}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-2">{expense.expense_name}</p>
                <p className="py-2 text-center">
                  {formatter.format(expense.amount)}
                </p>
                <p className="py-2 text-center">
                  {expense.unpaid_amount ? (
                    <span>{formatter.format(expense.unpaid_amount)}</span>
                  ) : (
                    "Cleared"
                  )}
                </p>
                <p className="py-2 text-center">{expense.expense_date}</p>
                <p className="flex flex-row justify-center space-x-2 pl-4">
                  <IconButton
                    variant="outlined"
                    onClick={() => handleExpense(expense)}
                  >
                    <RemoveRedEye
                      sx={{
                        color: colors.link,
                      }}
                    />
                  </IconButton>
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no expenses</p>
        </div>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex flex-row justify-between">
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_names || ""}
            </h5>
          </Box>
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_name || ""}
            </h5>
          </Box>
          <Box>
            <SupplierIndirectExpensePayment balance={supplier?.available_amount || 0} />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            trip={true}
            title={"Total Expenses Amount"}
            value={formatter.format(expenseTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            trip={true}
            title={"Total Paid Amount"}
            value={formatter.format(paidTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            trip={true}
            title={"Total Debt Amount"}
            value={formatter.format(unpaidTotalAmount)}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Indirect Expenses</h5>
            <div className="pb-8 w-[100%]">{renderExpenses()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SupplierIndirectExpenses;
