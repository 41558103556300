import React, { useEffect, useState } from "react";
import { Switch, Space, Modal } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { colors } from "../../assets/utils/colors";
import { isEmpty } from "lodash";
import RoutePositions from "./RoutePositions";
import RouteInvoices from "./RouteInvoices";
import RouteExpenses from "./RouteExpenses";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { endRoute, getSingleRoute, starRoute } from "../../api/trips/tripsAPI";
import { addRouteDetails, selectRouteDetails } from "../../features/tripsSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StartRouteAction = ({ route, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const auth = useAuth();
  const token = auth?.token || "";

  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState(
    route?.status === "open" ? true : false
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const activateRoute = async (route) => {
    if (!isEmpty(route)) {
      setIsLoading(true);
      handleClose();
      const response = await starRoute({ route_id: route.id, token });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to start route , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const deactivateRoute = async (route) => {
    if (!isEmpty(route)) {
      setIsLoading(true);
      handleClose();
      const response = await endRoute({ route_id: route.id, token });

      if (response) {
        //handle response
        checkEndResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to end route , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Trip route is successfully started",
          icon: "✅",
        });
        setChecked(true);
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  const checkEndResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Trip route is successfully closed",
          icon: "✅",
        });
        setChecked(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={24} />
          </>
        ) : (
          <>
            <Space direction="vertical">
              <Switch
                checkedChildren="OFF"
                unCheckedChildren="ON"
                checked={checked}
                autoFocus
                onClick={handleClickOpen}
                loading={loading}
                className={checked ? null : `bg-[#F24E1E] rounded-full`}
              />
            </Space>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {route.status !== "open" ? (
            <span>Start trip route?</span>
          ) : (
            <span>End trip route</span>
          )}
        </DialogTitle>
        <DialogContent>
          {route.status !== "open" ? (
            <DialogContentText id="alert-dialog-description">
              Area you sure you want start this route
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Area you sure you want end this route
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          {route.status !== "open" ? (
            <Button
              onClick={() => {
                activateRoute(route);
              }}
              autoFocus
            >
              Agree
            </Button>
          ) : (
            <Button
              onClick={() => {
                deactivateRoute(route);
              }}
              autoFocus
            >
              Agree
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const ViewRouteAction = (route) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(route);
  const addedTime = moment(route?.route.created_at).format("DD-MM-YYYY");
  const updateTime = moment(route?.route.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Route Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {route.route.route_description ? (
              <span>{route.route.route_description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user.role_name === "admin" || user.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {route.route.added_by ? (
                  <span>
                    {route.route.added_by.first_name}{" "}
                    {route.route.added_by.middle_name}{" "}
                    {route.route.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({route.route.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {route.route.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {route.route.updated_by ? (
                  <span>
                    {route.route.updated_by.first_name}{" "}
                    {route.route.updated_by.middle_name}{" "}
                    {route.route.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({route.route.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {route.route.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const primary = "#F24E1E";

const TripRoutes = () => {
  const [value, setValue] = useState(0);

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const dispatch = useDispatch();
  const { routeID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const getRoute = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getRoute();
  }, [dispatch]);

  const route = useSelector(selectRouteDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderRouteDetails = () => {
    if (!isEmpty(route)) {
      // console.log(route);
      return (
        <Card
          sx={{
            width: 650,
            height: 580,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">ROUTE DETAILS</h3>
            <div className="pt-6">
              <div className="text-lg px-6">
                {route.status === "not_started" || route.status === "open" ? (
                  <div className="py-2 flex flex-row gap-2">
                    <p className="w-[30%]">
                      {" "}
                      {route.status === "not_started" ? (
                        <span>Start Route :</span>
                      ) : (
                        <span>End Route :</span>
                      )}
                    </p>{" "}
                    <div className="w-[70%]">
                      <StartRouteAction
                        route={route}
                        onSuccess={() => {
                          getRoute();
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="py-2 flex flex-row gap-2">
                  <p className="w-[30%]">Route Number :</p>{" "}
                  <span className="w-[70%] text-[#F24E1E]">
                    {route.route_number ? (
                      <span>{route.route_number}</span>
                    ) : null}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-2">
                  <p className="w-[30%]">Initial Location :</p>{" "}
                  <span className="w-[70%] text-[#F24E1E]">
                    {route.initial_location ? (
                      <span>{route.initial_location}</span>
                    ) : null}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-2">
                  <p className="w-[30%]">Final Location :</p>{" "}
                  <span className="w-[70%] text-[#F24E1E]">
                    {route.final_location ? (
                      <span>{route.final_location}</span>
                    ) : null}
                  </span>
                </div>
                <div className="flex flex-row gap-2">
                  <div className="w-[60%] py-2 flex flex-row gap-2">
                    <p className="w-[50%]">Start Date :</p>{" "}
                    <span className="w-[43%] text-[#F24E1E]">
                      {route.start_date ? (
                        <span>{route.start_date}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[40%] py-2 flex flex-row gap-2">
                    <p className="w-[44%]">End Date :</p>{" "}
                    <span className="w-[55%] text-[#F24E1E]">
                      {route.end_date ? <span>{route.end_date}</span> : null}
                    </span>
                  </div>
                </div>
                <div className="py-2 flex flex-row gap-2">
                  <p className="w-[30%]">Customer :</p>{" "}
                  <span className="text-[#F24E1E] w-[70%]">
                    {route.route_customer ? (
                      <span>{route.route_customer.customer_name}</span>
                    ) : null}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-2">
                  <p className="w-[30%]">Status :</p>{" "}
                  <span className="text-[#F24E1E] w-[70%]">
                    {route.status === "not_started" ? (
                      <span>Not started</span>
                    ) : route.statuss === "not_complete" ? (
                      <span>Failed</span>
                    ) : (
                      <span className="capitalize">{route.status}</span>
                    )}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-2">
                  <p className="w-[50%]">Total Expenses Amount :</p>{" "}
                  <span className="text-[#F24E1E] w-[50%]">
                    {route.total_route_expense ? (
                      <span>{formatter.format(route.total_route_expense)}</span>
                    ) : (
                      <span>{formatter.format(0)}</span>
                    )}
                  </span>
                </div>
                <div className="py-1 flex flex-row gap-2">
                  <p className="w-[50%]">Total Invoices Amount :</p>{" "}
                  <span className="text-[#F24E1E] w-[50%]">
                    {route.route_invoice ? (
                      <span>
                        {formatter.format(route.route_invoice.total_amount)}
                      </span>
                    ) : (
                      <span>{formatter.format(0)}</span>
                    )}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-4">
                  <p className="w-[50%]">Total Profit Amount :</p>{" "}
                  <span className="text-[#F24E1E] w-[50%]">
                    {route.total_route_expense &&
                    route.route_invoice.total_amount ? (
                      <span>
                        {formatter.format(
                          route.route_invoice.total_amount -
                            route.total_route_expense
                        )}
                      </span>
                    ) : (
                      <span>{formatter.format(0)}</span>
                    )}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-2">
                  <div className="w-[50%]">View Details :</div>{" "}
                  <div className="text-[#F24E1E] w-[50%]">
                    <ViewRouteAction route={route} />
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p className="text-center">Route has no details</p>;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container sx={{ px: 2 }}>
        <Grid item sm={12}>
          <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor={`${primary}`}
              indicatorColor="primary"
              sx={{ color: "#F24E1E" }}
            >
              <Tab label="Route Details" {...a11yProps(0)} />
              <Tab label="Route Invoices" {...a11yProps(1)} />
              <Tab label="Route Expenses" {...a11yProps(2)} />
              <Tab label="Route Positions" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="flex flex-row justify-center pb-8">
              {renderRouteDetails()}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RouteInvoices />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RouteExpenses />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <RoutePositions />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default TripRoutes;
