import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getOwnerPersonalExpense } from '../../api/expenses/expensesAPI';
import { authenticationAtom } from '../../atoms/auth';
import {
    addOwnerExpenses,
    selectOwnerExpenses,
} from '../../features/expensesSlice';
import useAuth from '../../hooks/useAuth';
import tables from '../../styles/tables.module.css';
import { RemoveRedEye } from '@mui/icons-material';
import { colors } from '../../assets/utils/colors';
import { Modal } from 'antd';
import { IconButton } from '@mui/material';

const ViewExpenseAction = (description) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <>
            <IconButton variant="outlined" onClick={showModal}>
                <RemoveRedEye
                    sx={{
                        color: colors.link,
                    }}
                />
            </IconButton>
            <Modal
                title="Personal Expense Details"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                zIndex={800}
                okType="default"
                cancelButtonProps={{
                    className: 'hidden',
                }}
            >
                <div className="flex flex-col">
                    <p>
                        Description :{' '}
                        {description.description ? <span>{description.description}</span> : null}
                    </p>
                </div>
            </Modal>
        </>
    );
};

const ExpenseMobileCard = ({
    expense: { owner = '', amount = '', expense_date = '', description },
    position,
}) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'TZS',
    });

    const middleName = owner.middle_name.charAt(0);

    return (
        <div className="shadow-md mt-4 border border-slate-800 px-2 py-2">
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">No</p>
                <p className="text-right">{position}</p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Name</p>
                <p className="text-right capitalize">
                    {owner.first_name} {middleName} {owner.last_name}
                </p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Expense Amount</p>
                <p className="text-right">{formatter.format(amount)}</p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Expense Date</p>
                <p className="text-right">{expense_date}</p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Description</p>
                <p className="text-right">
                    <ViewExpenseAction description={description} />
                </p>
            </div>
        </div>
    );
};

const OwnerExpenses = () => {
    const dispatch = useDispatch();

    const [userData] = useAtom(authenticationAtom);
    const auth = useAuth();
    const { userInfo: user } = userData;

    //fetch all
    const getExpenses = async () => {
        const response = await getOwnerPersonalExpense({
            owner_id: user?.id || '',
            token: auth?.token || '',
        });

        if (response.ok && !isEmpty(response.data)) {
            dispatch(addOwnerExpenses(response.data.data));
        }
    };

    useEffect(() => {
        getExpenses();
    }, [dispatch]);

    const expenses = useSelector(selectOwnerExpenses);
    // Sorting the expenses based on the 'date' property
    const sortedExpenses = expenses.slice().sort((a, b) => new Date(b.expense_date) - new Date(a.expense_date));

    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'TZS',
    });

    let personalExpensesTotalAmount = 0;
    if (expenses.length !== 0) {
        for (const value of expenses) {
            personalExpensesTotalAmount += value.amount;
        }
    }

    const renderExpenses = () => {
        return (
            <div>
                <div className="block md:hidden">
                    {sortedExpenses.length !== 0 ? (
                        <div>
                            {sortedExpenses.map((expense, index) => {
                                const position = index + 1;
                                return (
                                    <ExpenseMobileCard
                                        expense={expense}
                                        key={index}
                                        position={position}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <p className="text-center">
                            There are no personal expenses
                        </p>
                    )}
                </div>
                <div className="hidden md:block">
                    <div
                        className={`bg-[#EEDEDE] h-10 text-center ${tables.ownerPersonalExpenseTable} rounded-t-md font-semibold`}
                    >
                        <p className="py-2">No</p>
                        <p className="py-2">Owner Name</p>
                        <p className="py-2">Paid Amount</p>
                        <p className="py-2">Date</p>
                        <p className="py-2">Description</p>
                    </div>
                    <div className="bg-[#F0F0F0] h-[350px] overflow-y-scroll">
                        {renderExpense()}
                    </div>
                    <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
                </div>
            </div>
        );
    };

    const renderExpense = () => {
        if (sortedExpenses.length !== 0) {
            return (
                <div>
                    {sortedExpenses.map((expense, index) => {
                        const position = index + 1;
                        return (
                            <div
                                key={index}
                                className={`bg-white h-10 ${tables.ownerPersonalExpenseTable} rounded-md text-sm my-2 mx-2`}
                            >
                                <p className="py-2 text-center">{position}</p>
                                <p className="py-2 text-center">
                                    {expense.owner ? (
                                        <span>
                                            {expense.owner.first_name}{' '}
                                            {expense.owner.middle_name}{' '}
                                            {expense.owner.last_name}
                                        </span>
                                    ) : null}
                                </p>
                                <p className="py-2 text-center">
                                    {formatter.format(expense.amount)}
                                </p>
                                <p className="py-2 text-center">
                                    {expense.expense_date}
                                </p>
                                <p className="py-2 text-center text-sm">
                                    {expense.description ? (
                                        <span>{expense.description}</span>
                                    ) : null}
                                </p>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div>
                    <p className="text-center">
                        There are no personal expenses
                    </p>
                </div>
            );
        }
    };

    return (
        <div className="py-2 pl-4 pr-4 w-screen md:w-[calc(100vw-84px)] mx-auto">
            <div>
                <h4 className="text-xl text-center">PERSONAL EXPENSES</h4>
                <h4 className="text-lg text-center">
                    {formatter.format(personalExpensesTotalAmount)}
                </h4>
            </div>
            <div className="py-2">
                <div className="w-[100%] pb-6 mt-0">{renderExpenses()}</div>
            </div>
        </div>
    );
};

export default OwnerExpenses;
