import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdLibraryAdd } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { addSuppliers, selectSuppliers } from "../../features/userSlice";
import { getAllSuppliers } from "../../api/users/userAPI";
import useAuth from "../../hooks/useAuth";
import { createStockOrder, dispatchStockItem, getSingleStock, getTrucksSummary } from "../../api/stock/stockAPI";
import {
  addOrderItems,
  addStockDetails,
  addTrucksSummary,
  selectStockDetails,
  selectTruckSummary,
} from "../../features/stockSlice";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const AddStockTransfer = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [supplier, setSupplier] = useState("");
  const [truck, setTruck] = useState("");
  const [trip, setTrip] = useState("");
  const [route, setRoute] = useState("");
  const [order_type, setOrderType] = useState("");
  const [order_date, setDate] = useState(null);
  const [item_number, setNumber] = useState("");
  const [item_price, setPrice] = useState("");
  const [total_amount, setAmount] = useState(0);
  const [amount, setExpenseAmount] = useState("");
  const [description, setDescription] = useState("");

  const [truckTrips, setTruckTrips] = useState([]);
  const [truckRoutes, setTruckRoutes] = useState([]);

  const dispatch = useDispatch();

  const auth = useAuth();
  const token = auth?.token || "";

  const { stockID } = useParams();

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  const getTrucks = async () => {
    const response = await getTrucksSummary({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTrucksSummary(response.data.data));
    }
  };

  const getStock = async () => {
    const response = await getSingleStock({
      stock_id: stockID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addStockDetails(response.data.data));
    }
  };

  useEffect(() => {
    getSuppliers();
    getTrucks();
  }, [dispatch]);

  const suppliers = useSelector(selectSuppliers);
  const trucks = useSelector(selectTruckSummary);
  const stock = useSelector(selectStockDetails);

  //console.log(orderItems);

  const truckOnChange = (e, value) => {
    //get truck trips and routes
    const selectedTruck = trucks.filter((truck) => {
      return truck.id === value?.id;
    });

    //console.log(selectedTruck);

    if (selectedTruck.length > 0) {
      const truck = selectedTruck[0];
      //console.log(truck);
      setTruck(value);

      if (truck?.trips) {
        setTruckTrips(truck.trips);
      }
    }
  };

  const tripOnChange = (e, value) => {
    //get truck trip routes
    const selectedTrip = truckTrips.filter((trip) => {
      return trip.id === value?.id;
    });

    if (selectedTrip.length > 0) {
      const trip = selectedTrip[0];

      if (trip?.trip_routes) {
        setTrip(value);
        setTruckRoutes(trip.trip_routes);
      } else {
        setTrip(value);
        setTruckRoutes([]);
      }
    }
  };

  const routeOnChange = (e, value) => {
    setRoute(value);
  };

  const handleOrderType = (value) => {
    setOrderType(value);
    setRoute([]);
    setTrip([]);
    setTruckRoutes([]);
    setTruckTrips([]);
  };

  const getTotal = () => {
    let total = item_number * item_price;
    setAmount(total);
    if (isNumber(total)) {
      setExpenseAmount(formatter.format(total));
    }
  };

  useEffect(() => {
    getTotal();
  }, [item_number, item_price]);

  let formatter = new Intl.NumberFormat("en-US");

  const sortedSuppliers = suppliers
    .filter((supplier) => supplier?.supplier_name.toLowerCase() === "msa store")
    .map((supplier) => ({
      id: supplier.id,
      label: supplier.supplier_name,
    }));

  const sortedTrucks = trucks.map((truck) => ({
    id: truck.id,
    label: truck.registration_number,
  }));

  const sortedTruckTrips = truckTrips.map((trip) => ({
    id: trip?.id,
    label: trip?.trip_number,
  }));

  const sortedTripRoutes = truckRoutes.map((route) => ({
    id: route?.id,
    label: `${route?.route_number} : ${route.initial_location} - ${route.final_location}`,
  }));

  const transferItem = async (e) => {
    e.preventDefault();

    if (!supplier) {
      notifyToastError({
        message: "Please select supplier",
        icon: "❗",
      });
    } else if (!order_type) {
      notifyToastError({
        message: "Please select order type",
        icon: "❗",
      });
    } else if (!item_number) {
      notifyToastError({
        message: "Please enter item quantity",
        icon: "❗",
      });
    } else if (!item_price) {
      notifyToastError({
        message: "Please enter item price",
        icon: "❗",
      });
    } else if (!order_date) {
      notifyToastError({
        message: "Please select expense date",
        icon: "❗",
      });
    } else if (order_type && !truck) {
      notifyToastError({
        message: "Please select truck",
        icon: "❗",
      });
    } else if (order_type && order_type !== "indirect" && truck && !trip) {
      notifyToastError({
        message: "Please select trip",
        icon: "❗",
      });
    } else if (
      order_type &&
      order_type !== "indirect" &&
      truck &&
      trip &&
      !route
    ) {
      notifyToastError({
        message: "Please select route",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(order_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await dispatchStockItem({
        supplier_id: supplier.id,
        dispatch: order_type,
        truck_id: truck?.id || "",
        trip_id: trip?.id || null,
        route_id: route?.id || null,
        quantity: item_number,
        price: item_price,
        total_price: total_amount,
        date,
        description,
        item_id: stock?.item_id || "",
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setSupplier("");
        setTruck("");
        setOrderType("");
        setRoute("");
        setTrip("");
        setAmount("");
        setNumber("");
        setPrice("");
        setTruckTrips([]);
        setTruckRoutes([]);
        setDate(null);
        setDescription("");
        dispatch(addOrderItems([]));
        getStock();

        notifyToastSuccess({
          message: "Transfer is dispatched successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[98%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[98%]"
          onClick={(e) => transferItem(e)}
        >
          TRANSFER ITEM
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[85px] w-[160px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="flex flex-row justify-center items-center pt-4">
          <MdLibraryAdd className="text-xl" />
        </div>
        <p className="text-lg font-semibold text-center">Transfer Item</p>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Transfer Item Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="large"
                  className="w-[50%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select date"
                    value={order_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[50%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Transfer Type"
                  className="w-[50%]"
                  value={order_type}
                  onChange={(e) => handleOrderType(e.target.value)}
                >
                  <MenuItem value={"direct"}>Direct Expense</MenuItem>
                  <MenuItem value={"indirect"}>Indirect Expense</MenuItem>
                </TextField>
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedTrucks}
                  size="small"
                  className="w-[50%]"
                  value={truck}
                  onChange={truckOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Truck" />
                  )}
                />
              </div>
              {order_type === "direct" && truck ? (
                <div className="w-full py-2 flex flex-row gap-2 justify-center">
                  <Autocomplete
                    id="combo-box-demo"
                    options={sortedTruckTrips}
                    size="small"
                    className={trip ? "w-[50%]" : "w-[100%]"}
                    value={trip}
                    onChange={tripOnChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Truck Trip" />
                    )}
                  />
                  {trip ? (
                    <Autocomplete
                      id="combo-box-demo"
                      options={sortedTripRoutes}
                      size="small"
                      className="w-[50%]"
                      value={route}
                      onChange={routeOnChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Trip Route" />
                      )}
                    />
                  ) : null}
                </div>
              ) : null}
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  className="w-[50%]"
                  type={"number"}
                  value={item_number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item price"
                  variant="outlined"
                  className="w-[50%]"
                  type={"number"}
                  value={item_price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Total amount"
                  variant="outlined"
                  className="w-[100%]"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={2}
                  variant="outlined"
                  className="w-[100%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddStockTransfer;
