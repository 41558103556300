import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSupplierBalance,
  getSupplier,
  getSupplierAllBalances,
} from "../../api/users/userAPI";
import {
  addSupplierBalances,
  addSupplierDetails,
  selectBalances,
  selectSupplierDetails,
} from "../../features/userSlice";
import { Modal } from "antd";
import useAuth from "../../hooks/useAuth";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import AddBalance from "./AddBalance";
import { MdDelete } from "react-icons/md";
import EditBalance from "./EditBalance";
import { notifyToastError, notifyToastSuccess } from "../toast";

const DeleteBalanceAction = ({ balance, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteBalance = async (balance) => {
    if (!isEmpty(balance)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteSupplierBalance({
        balance_id: balance?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete balance , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Balance is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={26} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Balance ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete supplier balance{" "}
            {balance?.amount || ""} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteBalance(balance);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ViewBalanceAction = (balance) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const addedTime = moment(balance?.balance.created_at).format("DD-MM-YYYY");
  const updateTime = moment(balance?.balance.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Supplier Balance Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {balance.balance.description ? (
              <span>{balance.balance.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user?.role_name === "admin" || user?.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {balance.balance.added_by ? (
                  <span>
                    {balance.balance.added_by.first_name}{" "}
                    {balance.balance.added_by.middle_name}{" "}
                    {balance.balance.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({balance.balance.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {balance.balance.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {balance.balance.updated_by ? (
                  <span>
                    {balance.balance.updated_by.first_name}{" "}
                    {balance.balance.updated_by.middle_name}{" "}
                    {balance.balance.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({balance.balance.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {balance.balance.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const SupplierBalance = () => {
  const dispatch = useDispatch();
  const { supplierID } = useParams();

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all balances
  const getBalances = async () => {
    const response = await getSupplierAllBalances({
      supplier_id: supplierID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addSupplierBalances(response.data.data));
      } else {
        dispatch(addSupplierBalances([]));
      }
    } else {
      dispatch(addSupplierBalances([]));
    }
  };

  const fetchSupplier = async () => {
    const response = await getSupplier({ supplierId: supplierID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addSupplierDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getBalances();
    fetchSupplier();
  }, [dispatch]);

  const balances = useSelector(selectBalances);
  const supplier = useSelector(selectSupplierDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderView = (balance) => {
    return (
      <>
        <ViewBalanceAction balance={balance} />
      </>
    );
  };

  const renderEdit = (balance) => {
    return (
      <>
        <EditBalance balance={balance} />
      </>
    );
  };

  const renderDelete = (balance) => {
    if (user) {
      if (user.role_name === "Clerk" || user.role_name === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteBalanceAction
              balance={balance}
              onSuccess={() => {
                getBalances();
              }}
            />
          </>
        );
      }
    }
  };

  const renderBalances = () => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.balanceTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Total Amount</p>
              <p className="py-2">Transaction Date</p>
              <p className="py-2">Actions</p>
            </div>
            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
              {renderBalance()}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderBalance = () => {
    if (!isEmpty(balances)) {
      if (balances?.balance_history.length !== 0) {
        const sortedBalances = balances.balance_history
          .slice()
          .sort((a, b) => new Date(b.date) - new Date(a.date));
        return (
          <div>
            {sortedBalances.map((balance, index) => {
              const position = index + 1;
              return (
                <div
                  key={index}
                  className={`bg-white h-10 ${tables.balanceTable} rounded-md text-sm my-2`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 text-center">
                    {formatter.format(balance?.amount)}
                  </p>
                  <p className="py-2 text-center">{balance?.date}</p>
                  <p className="flex flex-row justify-center pl-4">
                    {renderEdit(balance)}
                    {renderView(balance)}
                    {renderDelete(balance)}
                  </p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div>
            <p className="text-center">There are no balances</p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p className="text-center">There are no balances</p>
        </div>
      );
    }
  };

  let totalAmount = 0;
  let usedAmount = 0;
  let availableAmount = 0;

  if (!isEmpty(balances)) {
    totalAmount = balances.total_amount;
    usedAmount = balances.used_amount;
    availableAmount = balances.available_amount;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex flex-row justify-between">
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_names || ""}
            </h5>
          </Box>
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_name || ""}
            </h5>
          </Box>
          <Box>
            <AddBalance />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            trip={true}
            title={"Total Added Balance"}
            value={formatter.format(totalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            trip={true}
            title={"Total Used Balance"}
            value={formatter.format(usedAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            trip={true}
            title={"Total Available Balance"}
            value={formatter.format(availableAmount)}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Balances</h5>
            <div className="pb-8 w-[100%]">{renderBalances()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SupplierBalance;
