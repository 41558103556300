import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { loginUser } from "../../api/auth/authAPI";
import { isEmpty } from "lodash";
import TextField from "@mui/material/TextField";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { Toaster } from "react-hot-toast";
import { isMobileOnly } from "react-device-detect";
import { useAtom } from "jotai";
import {
  authenticationAtom,
} from "../../atoms/auth";
import bgImg from "../../assets/media/images/3.jpg";
import { LocalShipping, LoginOutlined } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
// import { signOut } from "../../api/users/userAPI";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useAtom(authenticationAtom);

  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // SIGN IN FUNCTIONALITY
  const signinUser = async (e) => {
    e.preventDefault();

    if (!phone_number) {
      notifyToastError({
        message: "Please enter your phone number",
        icon: "❗",
      });
    } else if (!password) {
      notifyToastError({
        message: "Please enter your password",
        icon: "❗",
      });
    } else {
      // Go on signing in the user
      setLoading(true);
      const response = await loginUser({
        phone_number,
        password,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        if (!isEmpty(response.data.data)) {
          const user_data = response.data.data.user;

          //check if user has mobile version
          if (isMobileOnly) {
            setLoading(false);
            setUserData((prev) => ({
              ...prev,
              userInfo: user_data,
              userTokenInfo: response.data.data.token,
              isAuthenticated: true,
              userDetails: user_data,
              userToken: response.data.data.token,
            }));

            notifyToastSuccess({
              message: "You're logged in",
              icon: "✅",
            });
            //check user role
            // if (user_data.role_name.toLowerCase() === "truck owner") {
            //   setLoading(false);
            //   setUserData((prev) => ({
            //     ...prev,
            //     userInfo: user_data,
            //     userTokenInfo: response.data.data.token,
            //     isAuthenticated: true,
            //     userDetails: user_data,
            //     userToken: response.data.data.token,
            //   }));

            //   notifyToastSuccess({
            //     message: "You're logged in",
            //     icon: "✅",
            //   });
            // } else {
            //   //sign out user and redirect to device info
            //   signOut({ token: response.data.data.token })
            //     .then((res) => {
            //       return res.data;
            //     })
            //     .then((res) => {
            //       if (res.success) {
            //         setLoading(false);
            //         setUserData((prev) => ({
            //           ...initialAuthenticationState,
            //         }));

            //         navigate(`/device`);
            //       } else {
            //         setLoading(false);
            //         notifyToastError({
            //           message:
            //             "Please try again later",
            //           icon: "❗",
            //         });
            //       }
            //     });
            // }
          } else {
            setLoading(false);
            setUserData((prev) => ({
              ...prev,
              userInfo: user_data,
              userTokenInfo: response.data.data.token,
              isAuthenticated: true,
              userDetails: user_data,
              userToken: response.data.data.token,
            }));

            notifyToastSuccess({
              message: "You're logged in",
              icon: "✅",
            });
          }
        } else {
          notifyToastError({
            message: "Something went wrong, Please try again!",
            icon: "❗",
          });
        }
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      // setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      } else if (response.status === 400) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      } else if (response.status === 404) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
    // setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[100%] md:w-[100%] cursor-not-allowed"
            disabled
            fullWidth
          >
            <CircularProgress size={26} />
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[100%] md:w-[100%]"
            onClick={(e) => signinUser(e)}
            fullWidth
            startIcon={<LoginOutlined />}
            sx={{
              boxShadow: `none !important`,
              borderRadius: 2,
              border: `2px solid ${colors.link}`,
            }}
          >
            LOGIN
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <Toaster />
      <Box
        sx={{
          height: "100vh",
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            background: `rgba(242, 84, 38, .2)`,
          }}
        >
          <Grid
            container
            alignItems={"center"}
            className="w-full h-screen px-4 py-2 justify-center overflow-scroll md:px-10 md:py-2 md:justify-end"
          >
            <Grid item xs={12} sm={5} className="min-w-full md:min-w-0">
              <Box
                sx={{
                  width: "100%",
                  borderRadius: 5,
                  px: 5,
                  opacity: 0.9,
                  display: "flex",
                  flexDirection: "column",
                  border: `15px solid ${colors.bgColor1}`,
                  background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                }}
                className="w-full"
              >
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LocalShipping
                    sx={{
                      fontSize: 55,
                      color: colors.link,
                      opacity: 0.8,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 25,
                      fontWeight: "bold",
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: colors.primary,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 45,
                        fontStyle: "italic",
                        color: colors.link,
                      }}
                    >
                      M
                    </span>
                    SA{" "}
                    <span
                      style={{
                        fontSize: 45,
                        fontStyle: "italic",
                      }}
                    >
                      T
                    </span>
                    ranspor
                    <span
                      style={{
                        fontSize: 45,
                        fontStyle: "italic",
                      }}
                    >
                      T
                    </span>
                    ation
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 22,
                      fontWeight: "bold",
                    }}
                  >
                    Welcome back
                  </Typography>
                  <Typography sx={{ fontSize: 15 }}>
                    Sign in to your account
                  </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={3} justifyContent={"center"}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Phone number"
                        variant="outlined"
                        fullWidth
                        className="w-[90%] md:w-[62%]"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        className="w-[90%] md:w-[62%]"
                        type="password"
                        fullWidth
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {renderButton()}
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderRight: `none`,
                    "& .head": {
                      fontSize: 20,
                      fontWeight: "bold",
                      mb: 1,
                    },
                    "& .item": {
                      display: "flex",
                      alignItems: "center",
                      fontSize: 13,
                      mb: 0.5,
                      "& .icon": {
                        mr: 1,
                        color: colors.secondary,
                      },
                    },
                  }}
                >
                  <Box>
                    <Typography className="head">
                      MSA Management dashboard
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="item">
                      <LocalShipping className="icon" />
                      Manage your trucks and trailers
                    </Typography>
                    <Typography className="item">
                      <LocalShipping className="icon" />
                      Manage your trips
                    </Typography>
                    <Typography className="item">
                      <LocalShipping className="icon" />
                      Manage your trips routes
                    </Typography>
                    <Typography className="item">
                      <LocalShipping className="icon" />
                      Manage trips invoices, expences and transactions
                    </Typography>
                    <Typography className="item">
                      <LocalShipping className="icon" />
                      Manage your users (Truck owners, Drivers, Customers)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Login;
