import React from "react";
import { DirectionsBus } from "@mui/icons-material";

const PaidAmountCard = ({title, value}) => {
  return (
    <div className="bg-gradient-to-r from-[#323232] to-[#8C5747] h-[140px] w-auto rounded-md border-2">
      <div className="px-4 py-4">
        <div>
          <h4 className="text-white text-lg font-semibold">{title? <span>{title}</span>: <span></span>}</h4>
        </div>
        <div className="py-3">
          <p className="text-3xl md:text-xl sm:text-xl text-white font-bold">{value? <span>{value}</span>: <span>0</span>}</p>
        </div>
        <div className="flex flex-row gap-6 justify-between">
          <div className="flex flex-row"></div>
          <div>
            <p>
              <DirectionsBus
                sx={{
                  color: "#CCCCCC",
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaidAmountCard;
