import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TbBrandMercedes } from "react-icons/tb";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrands } from "../../api/settings/settingAPI";
import { isEmpty } from "lodash";
import { addTruckBrands, selectTruckBrands } from "../../features/settingSlice";
import { createTruck, getAllTrucks } from "../../api/trucks/trucksAPI";
import { getAllUsers } from "../../api/users/userAPI";
import { addAllUsers, selectAllUsers } from "../../features/userSlice";
import { addTrucks } from "../../features/truckSlice";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddTruck = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [truck_brand, setBrand] = useState("");
  const [truck_owner, setOwner] = useState("");
  const [registration_name, setRegName] = useState("");
  const [registration_number, setRegNumber] = useState("");
  const [truck_description, setDescription] = useState("");

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all trucks
  const getTrucks = async () => {
    const response = await getAllTrucks({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrucks(response.data.data));
      }
    }
  };

  //fetch all brands
  const getBrands = async () => {
    const response = await getAllBrands({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTruckBrands(response.data.data));
      }
    }
  };

  //fetch all owners
  const getOwners = async () => {
    const response = await getAllUsers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addAllUsers(response.data.data));
      }
    }
  };

  useEffect(() => {
    getBrands();
    getOwners();
  }, [dispatch]);

  const brands = useSelector(selectTruckBrands);
  const allUsers = useSelector(selectAllUsers);

  const sortedOwners = allUsers
  .filter(user => user.role_name.toLowerCase() === "truck owner")
  .map(({ id, first_name, middle_name, last_name }) => ({
    id,
    label: `${first_name} ${middle_name} ${last_name}`,
  }));

const sortedBrands = brands
  .filter(brand => brand.brand_type.toLowerCase() === "truck")
  .map(({ id, brand_name }) => ({
    id,
    label: brand_name,
  }));


  const brandOnChange = (e, value) => {
    setBrand(value);
  };

  const ownerOnChange = (e, value) => {
    setOwner(value);
  };

  const truckRegistration = async (e) => {
    e.preventDefault();

    if (!truck_brand) {
      notifyToastError({
        message: "Please select truck brand",
        icon: "❗",
      });
    } else if (!truck_owner) {
      notifyToastError({
        message: "Please select truck owner",
        icon: "❗",
      });
    } else if (!registration_name) {
      notifyToastError({
        message: "Please enter truck registration name",
        icon: "❗",
      });
    } else if (!registration_number) {
      notifyToastError({
        message: "Please enter truck registration number",
        icon: "❗",
      });
    } else {
      //start registration
      setLoading(true);

      const response = await createTruck({
        brand_id: truck_brand.id,
        owner_id: truck_owner.id,
        registration_name,
        registration_number,
        truck_description,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setBrand("");
        setOwner("");
        setRegName("");
        setRegNumber("");
        setDescription("");
        getTrucks();

        notifyToastSuccess({
          message: "Truck is registered successfully",
          icon: "✅",
        });

        handleClose();
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
        setLoading(false);
      }
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%] cursor-not-allowed"
            disabled
          >
            <svg
              class="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
            Loading...
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%]"
            onClick={(e) => truckRegistration(e)}
          >
            SAVE TRUCK
          </Button>
        </>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[85px] w-[160px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="flex flex-row justify-center items-center pt-4">
          <TbBrandMercedes className="text-xl" />
        </div>
        <p className="text-lg font-semibold text-center">Add Truck</p>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Add Truck Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedBrands}
                  size="small"
                  className="w-[40%]"
                  value={truck_brand}
                  onChange={brandOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Truck Brand" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedOwners}
                  size="small"
                  className="w-[40%]"
                  value={truck_owner}
                  onChange={ownerOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Truck Owner" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Registration Name"
                  variant="outlined"
                  className="w-[40%]"
                  onChange={(e) => setRegName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Registration Number"
                  variant="outlined"
                  className="w-[40%]"
                  onChange={(e) => setRegNumber(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Truck Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddTruck;
