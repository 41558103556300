import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdLibraryAdd } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { createOrderPayment, getSingleStockOrder } from "../../api/stock/stockAPI";
import { addOrderDetails } from "../../features/stockSlice";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddOrderPayment = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [amount_paid, setAmount] = useState("");
  const [payment_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { orderID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all payments
    const getOrder = async () => {
      const response = await getSingleStockOrder({ order_id: orderID, token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addOrderDetails(response.data.data));
        }
      }
    };


  const orderPayment = async (e) => {
    e.preventDefault();

    if (!amount_paid) {
      notifyToastError({
        message: "Please enter paid amount",
        icon: "❗",
      });
    } else if (!payment_date) {
      notifyToastError({
        message: "Please select date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(payment_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createOrderPayment({
        amount_paid,
        payment_date: date,
        order_id: orderID,
        description,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setAmount("");
        setDate(null);
        setDescription("");
        getOrder();

        notifyToastSuccess({
          message: "Order payment is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => orderPayment(e)}
        >
          SAVE PAYMENT
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <div className="flex flex-row justify-center items-center">
            <MdLibraryAdd className="text-4xl" />
          </div>
          <p className="text-lg font-semibold text-center pb-2">
            Add Order Payment
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new payment to an existing order
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">
              Add Order Payment Details
            </h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Paid Amount"
                  variant="outlined"
                  type={"number"}
                  className="w-[82%]"
                  value={amount_paid}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select payment date"
                    value={payment_date}
                    size="small"
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[82%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddOrderPayment;
