import React, { useEffect, useState } from "react";
import { colors } from "../../assets/utils/colors";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getSingleTrip } from "../../api/trips/tripsAPI";
import { addTripDetails, selectTripDetails } from "../../features/tripsSlice";
import { Grid, IconButton } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { RemoveRedEye } from "@mui/icons-material";
import { Modal } from "antd";
import { IoArrowBackCircle } from "react-icons/io5";


const TruckRoutes = () => {

  const dispatch = useDispatch();
  const { tripID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  const getTrip = async () => {
    const response = await getSingleTrip({ trip_id: tripID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTripDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getTrip();
  }, [dispatch]);

  const trip = useSelector(selectTripDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  
  const navigate = useNavigate();
  const { truckID } = useParams();

  const handleBackButton = () => {
    navigate(`/trucks/${truckID}`);
  };

  //view expense description
  const ViewDescriptionAction = (expense) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    console.log(expense);
  
    return (
      <>
        <IconButton variant="outlined" onClick={showModal}>
          <RemoveRedEye
            sx={{
              color: colors.link,
            }}
          />
        </IconButton>
        <Modal
          title="Expense Description"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          zIndex={800}
          okType="default"
          cancelButtonProps={{
            className: "hidden",
          }}
        >
          <div className="flex flex-col">
            <p>
              Description :{" "}
              {expense.expense.description ? (
                <span>{expense.expense.description}</span>
              ) : null}
            </p>
            <br />
          </div>
        </Modal>
      </>
    );
  };


  //view route expenses
  const ViewExpensesAction = (route) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const renderExpenses = () => {
      if (!isEmpty(route)) {
        if (route.route.route_expenses) {
          return (
            <div>
              {route.route.route_expenses.map((expense, index) => {
                const position = index + 1;
                return (
                  <div className="shadow-md mt-4 border border-slate-800 px-2 py-2">
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">No</p>
                      <p className="text-right">{position}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">Type</p>
                      <p className="text-right capitalize">
                        {expense.expense_type ? (
                          <span>{expense.expense_type.type_name}</span>
                        ) : null}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">Items</p>
                      <p className="text-right">{expense?.item_number}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">Price</p>
                      <p className="text-right">
                        {formatter.format(expense?.item_price || 0)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">Total Amount</p>
                      <p className="text-right">
                        {formatter.format(expense?.total_amount || 0)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">Supplier</p>
                      <p className="text-right">
                        {expense.supplier ? (
                          <span>{expense.supplier.supplier_name}</span>
                        ) : null}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">Expense Date</p>
                      <p className="text-right">{expense?.expense_date}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-left font-semibold">Description</p>
                      <p className="text-right"> <ViewDescriptionAction expense={expense} /></p>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div>
              <p className="text-center">Route has no expenses</p>
            </div>
          );
        }
      } else {
        return (
          <div>
            <p className="text-center">Route has no expenses</p>
          </div>
        );
      }
    };

    const renderRouteExpenses = () => {
      return (
        <div className="pb-6">
          <div className="bg-[#F0F0F0]">{renderExpenses()}</div>
          <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
        </div>
      );
    };

    return (
      <>
        <div onClick={showModal}>
          <span className="text-[#F24E1E] text-sm cursor-pointer hover:underline">
            {formatter.format(route?.route.total_route_expense || 0)}
          </span>
        </div>
        <Modal
          title="Route Expenses"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          zIndex={800}
          width={400}
          okType="default"
          cancelButtonProps={{
            className: "hidden",
          }}
        >
          <div className="">{renderRouteExpenses()}</div>
        </Modal>
      </>
    );
  };

  const renderRouteDetails = () => {
    return (
      <div>
        <div>
          <IoArrowBackCircle
            onClick={() => handleBackButton()}
            className="text-3xl cursor-pointer text-[#F24E1E]"
          />
        </div>
        <h3 className="text-xl text-center py-4">TRIP ROUTES</h3>
        {renderRoutes()}
      </div>
    );
  };

  const renderRoutes = () => {
    let profit = 0;

    if (!isEmpty(trip)) {
      return (
        <>
          <div className="block md:hidden pb-4">
            {trip.trip_routes ? (
              <div>
                {trip.trip_routes.map((route, index) => {
                  const position = index + 1;
                  profit = route?.total_route_invoice - route?.total_route_expense;

                  return (
                    <div className="shadow-md mt-4 border border-slate-800 px-2 py-2">
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">No</p>
                        <p className="text-right">{position}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">From</p>
                        <p className="text-right capitalize">
                          {route?.initial_location}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">To</p>
                        <p className="text-right">{route?.final_location}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">Start Date</p>
                        <p className="text-right">{route?.start_date}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">End Date</p>
                        <p className="text-right">{route?.end_date}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">Route Status</p>
                        <p className="text-right">{route?.status}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">Route Profit</p>
                        <p className="text-right">
                          {formatter.format( profit)}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">
                          Invoice Amount
                        </p>
                        <p className="text-right">
                          {formatter.format(
                            route?.total_route_invoice || 0
                          )}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-left font-semibold">
                          Total Expenses
                        </p>
                        <p className="text-right">
                          <ViewExpensesAction route={route} />
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-center">Trip has no routes</p>
            )}
          </div>
        </>
      );
    } else {
      return <p className="text-center">Trip has no routes</p>;
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid item sm={12} xs={12} sx={{ py: 4 }} >
          {renderRouteDetails()}
        </Grid>
      </Grid>
    </>
  );
};

export default TruckRoutes;
