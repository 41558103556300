import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  getAllDesignations,
  getAllEmployees,
  updateEmployee,
} from "../../api/users/userAPI";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  addDesignations,
  addEmployees,
  selectDesignations,
} from "../../features/userSlice";
import { Toaster } from "react-hot-toast";
import _ from "lodash";
import useAuth from "../../hooks/useAuth";

const EditEmployee = ({ employee, handleClose = () => {} }) => {
  const dispatch = useDispatch();
  // TODO  : to write the following states using react-hook-form
  const [first_name, setFirstName] = useState(employee?.first_name || "");
  const [middle_name, setMiddleName] = useState(employee?.middle_name || "");
  const [last_name, setLastName] = useState(employee?.last_name || "");

  const phoneNumbers = JSON.parse(employee?.phone_number || []);
  const [phone_number, setPhoneNumber] = useState([
    phoneNumbers[0] || "",
    phoneNumbers[1] || "",
    phoneNumbers[2] || "",
    phoneNumbers[3] || "",
  ]);

  const [gender, setGender] = useState(employee?.gender || "");
  const [passport_number, setPassport] = useState(
    employee?.passport_number || ""
  );
  const [salary_amount, setSalary] = useState(employee?.salary_amount || "");
  const [license_number, setLicense] = useState(employee?.license_number || "");
  const [designation_id, setDesignation] = useState(
    employee?.designation_id || ""
  );

  const queryClient = useQueryClient();
  const auth = useAuth();
  const token = auth?.token || "";

  const getDesignations = async () => {
    const response = await getAllDesignations({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addDesignations(response.data.data));
      }
    }
  };

  const getEmployees = async () => {
    const response = await getAllEmployees({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployees(response.data.data));
      }
    }
  };

  useEffect(() => {
    getDesignations();
  }, [dispatch]);

  const designations = useSelector(selectDesignations);

  const { mutate, isLoading, isError } = useMutation(updateEmployee, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["employees"] });

        notifyToastSuccess({
          message: "Employee is updated successfully",
          icon: "✅",
        });
        getEmployees();
        handleClose();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
    },
  });

  const employeeUpdate = async (e) => {
    e.preventDefault();

    if (!first_name) {
      notifyToastError({
        message: "Please enter employee first name",
        icon: "❗",
      });
    } else if (!middle_name) {
      notifyToastError({
        message: "Please enter employee middle name",
        icon: "❗",
      });
    } else if (!last_name) {
      notifyToastError({
        message: "Please enter employee last name",
        icon: "❗",
      });
    } else if (!phone_number) {
      notifyToastError({
        message: "Please enter employee phone numbers",
        icon: "❗",
      });
    } else if (!passport_number) {
      notifyToastError({
        message: "Please enter employee passport number",
        icon: "❗",
      });
    } else if (!gender) {
      notifyToastError({
        message: "Please select employee gender",
        icon: "❗",
      });
    } else if (!salary_amount) {
      notifyToastError({
        message: "Please enter employee salary amount",
        icon: "❗",
      });
    } else if (!license_number) {
      notifyToastError({
        message: "Please enter employee licence number",
        icon: "❗",
      });
    } else if (!designation_id) {
      notifyToastError({
        message: "Please enter employee designation",
        icon: "❗",
      });
    } else {
      //start registration
      mutate({
        designation_id,
        first_name,
        middle_name,
        last_name,
        phone_number: JSON.stringify(phone_number),
        gender,
        passport_number,
        salary_amount,
        license_number,
        token,
      });
    }
  };

  return (
    <div>
      <div>
        <Toaster />
        <h3 className="text-center text-xl py-4">Edit Employee Details</h3>

        <div className="w-full py-2 flex justify-center">
          <div className="mb-2 w-[82%]">
            <p className="font-semibold ">ID </p>
            <p>{employee?.id || ""}</p>
          </div>
        </div>

        <div>
          <div className="w-full py-2 flex flex-row gap-2 justify-center">
            <TextField
              size="small"
              id="outlined-basic"
              label="First name"
              variant="outlined"
              className="w-[27%]"
              defaultValue={first_name}
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="Middle name"
              variant="outlined"
              className="w-[25%]"
              defaultValue={middle_name}
              value={middle_name}
              onChange={(e) => setMiddleName(e.target.value)}
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="Last name"
              variant="outlined"
              className="w-[27%]"
              defaultValue={last_name}
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="w-full py-2 flex flex-row gap-2 justify-center">
            <TextField
              size="small"
              id="outlined-basic"
              label="Phone number"
              variant="outlined"
              className="w-[40%]"
              defaultValue={phone_number[0]}
              value={phone_number[0]}
              onChange={(e) => {
                let p = [...phone_number];
                p[0] = e.target.value;
                setPhoneNumber(p);
              }}
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="Phone number"
              variant="outlined"
              className="w-[40%]"
              defaultValue={phone_number[1]}
              value={phone_number[1]}
              onChange={(e) => {
                let p = [...phone_number];
                p[1] = e.target.value;
                setPhoneNumber(p);
              }}
            />
          </div>
          <div className="w-full py-2 flex flex-row gap-2 justify-center">
            <TextField
              size="small"
              id="outlined-basic"
              label="Phone number"
              variant="outlined"
              className="w-[40%]"
              defaultValue={phone_number[2]}
              value={phone_number[2]}
              onChange={(e) => {
                let p = [...phone_number];
                p[2] = e.target.value;
                setPhoneNumber(p);
              }}
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="Phone number"
              variant="outlined"
              className="w-[40%]"
              defaultValue={phone_number[3]}
              value={phone_number[3]}
              onChange={(e) => {
                let p = [...phone_number];
                p[3] = e.target.value;
                setPhoneNumber(p);
              }}
            />
          </div>
          <div className="w-full py-2 flex flex-row gap-2 justify-center">
            <TextField
              size="small"
              id="outlined-select-currency"
              select
              label="Gender"
              className="w-[40%]"
              // defaultValue={_.toLower(gender)}
              value={_.toLower(gender)}
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"male"}>Male</MenuItem>
            </TextField>
            <TextField
              size="small"
              id="outlined-select-currency"
              select
              label="Designation"
              className="w-[40%]"
              defaultValue={designation_id}
              value={designation_id}
              onChange={(e) => setDesignation(e.target.value)}
            >
              {designations.length !== 0
                ? designations.map((designation, index) => (
                    <MenuItem key={index} value={designation.id}>
                      {designation.designation_name}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </div>
          <div className="w-full py-2 flex justify-center">
            <TextField
              size="small"
              id="outlined-basic"
              label="Passport number"
              variant="outlined"
              className="w-[82%]"
              defaultValue={passport_number}
              value={passport_number}
              onChange={(e) => setPassport(e.target.value)}
            />
          </div>
          <div className="w-full py-2 flex flex-row gap-2 justify-center">
            <TextField
              size="small"
              id="outlined-basic"
              label="License number"
              variant="outlined"
              className="w-[40%]"
              defaultValue={license_number}
              value={license_number}
              onChange={(e) => setLicense(e.target.value)}
            />
            <TextField
              size="small"
              id="outlined-basic"
              label="Salary amount"
              variant="outlined"
              className="w-[40%]"
              defaultValue={salary_amount}
              value={salary_amount}
              onChange={(e) => setSalary(e.target.value)}
            />
          </div>
          <div className="w-full py-2 flex justify-center">
            <Button
              size="large"
              variant="contained"
              className="w-[82%]"
              onClick={(e) => employeeUpdate(e)}
              disabled={isLoading}
            >
              {isLoading ? "UPDATING EMPLOYEE ..." : "UPDATE EMPLOYEE"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmployee;
