import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaUserPlus } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addUserRoles, selectUserRoles } from "../../features/settingSlice";
import { getAllRoles } from "../../api/settings/settingAPI";
import { _, isEmpty, map } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { createUser, getAllUsers, } from "../../api/users/userAPI";
import useAuth from "../../hooks/useAuth";
import { addAllUsers } from "../../features/userSlice";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddUser = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [first_name, setFirstName] = useState();
  const [middle_name, setMiddleName] = useState();
  const [last_name, setLastName] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [gender, setGender] = useState();
  const [role, setRole] = useState();

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all roles
  const getRoles = async () => {
    const response = await getAllRoles({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addUserRoles(response.data.data));
      }
    }
  };

  const getUsers = async () => {
    const response = await getAllUsers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addAllUsers(response.data.data));
      }
    }
  };

  useEffect(() => {
    getRoles();
  }, [dispatch]);

  const roles = useSelector(selectUserRoles);
  const rolesData = map(roles, (role) => role);

  const userRegistration = async (e) => {
    e.preventDefault();

    if (!first_name) {
      notifyToastError({
        message: "Please enter user first name",
        icon: "❗",
      });
    } else if (!middle_name) {
      notifyToastError({
        message: "Please enter user middle name",
        icon: "❗",
      });
    } else if (!last_name) {
      notifyToastError({
        message: "Please enter user last name",
        icon: "❗",
      });
    } else if (!phone_number) {
      notifyToastError({
        message: "Please enter user phone number",
        icon: "❗",
      });
    } else if (!email) {
      notifyToastError({
        message: "Please enter user email",
        icon: "❗",
      });
    } else if (!gender) {
      notifyToastError({
        message: "Please select user gender",
        icon: "❗",
      });
    } else if (!role) {
      notifyToastError({
        message: "Please enter user role",
        icon: "❗",
      });
    } else {
      //start registration
      setLoading(true);
      const response = await createUser({
        first_name,
        middle_name,
        last_name,
        phone_number,
        email,
        gender,
        role_id: role,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setPhoneNumber("");
        setEmail("");
        setGender("");
        setRole("");
        getRoles();
        getUsers();

        notifyToastSuccess({
          message: "User is registered successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => userRegistration(e)}
        >
          SAVE USER
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <p className="flex justify-center items-center">
            <FaUserPlus className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">Add User</p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new user to existing users list
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            {/* <Toaster /> */}
            <h3 className="text-center text-xl py-4">Add User Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="First name"
                  variant="outlined"
                  className="w-[27%]"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Middle name"
                  variant="outlined"
                  className="w-[25%]"
                  value={middle_name}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Last name"
                  variant="outlined"
                  className="w-[27%]"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  className="w-[40%]"
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className="w-[40%]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Gender"
                  className="w-[40%]"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value={""}></MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                </TextField>
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Role"
                  className="w-[40%]"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  {rolesData
                    ? rolesData.map((role, index) => (
                        <MenuItem key={index} value={role.id}>
                          {role.role_name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUser;
