import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Modal } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteEmployeeGuarantor,
  getEmployeeGuarantor,
} from "../../api/users/userAPI";
import { colors } from "../../assets/utils/colors";
import { authenticationAtom } from "../../atoms/auth";
import {
  addEmployeeGuarantors,
  selectGuarantors,
} from "../../features/userSlice";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import { notifyToastError, notifyToastSuccess } from "../toast";
import AddGuarantor from "./AddGuarantor";
import EditGuarantor from "./EditGuarantor";

const DeleteGuarantorAction = ({ guarantor, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteGuarantor = async (guarantor) => {
    if (!isEmpty(guarantor)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteEmployeeGuarantor({
        employeeId: guarantor?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete guarantor , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Guarantor is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={26} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Guarantor ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete guarantor{" "}
            {guarantor?.first_name || ""} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteGuarantor(guarantor);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ViewGuarantorAction = (guarantor) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // console.log(guarantor);
  const addedTime = moment(guarantor?.guarantor.created_at).format(
    "DD-MM-YYYY"
  );
  const updateTime = moment(guarantor?.guarantor.updated_at).format(
    "DD-MM-YYYY"
  );

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Transaction Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Added by :{" "}
            {guarantor.guarantor.added_by ? (
              <span>
                {guarantor.guarantor.added_by.first_name}{" "}
                {guarantor.guarantor.added_by.middle_name}{" "}
                {guarantor.guarantor.added_by.last_name}{" "}
                <span className="text-xs">
                  ({guarantor.guarantor.added_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Added at :{" "}
            {guarantor.guarantor.created_at ? <span>{addedTime}</span> : null}
          </p>
          <p>
            Updated by :{" "}
            {guarantor.guarantor.updated_by ? (
              <span>
                {guarantor.guarantor.updated_by.first_name}{" "}
                {guarantor.guarantor.updated_by.middle_name}{" "}
                {guarantor.guarantor.updated_by.last_name}{" "}
                <span className="text-xs">
                  ({guarantor.guarantor.updated_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Updated at :{" "}
            {guarantor.guarantor.updated_at ? <span>{updateTime}</span> : null}
          </p>
        </div>
      </Modal>
    </>
  );
};

const EmployeeGuarantors = () => {
  const dispatch = useDispatch();

  const [userData] = useAtom(authenticationAtom);
  const { employeeId } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all
  const getGuarantors = async () => {
    const response = await getEmployeeGuarantor({
      employee_id: employeeId,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployeeGuarantors(response.data.data));
      }
    }
  };

  useEffect(() => {
    getGuarantors();
  }, [dispatch]);

  const guarantor = useSelector(selectGuarantors);

  const { userInfo: user } = userData;

  const renderEdit = (guarantor) => {
    if (user) {
      if (user.role_name === "Clerk" || user.role_name === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdEdit className="text-[#F24E1E] text-xl ml-5 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <EditGuarantor guarantor={guarantor} />
          </>
        );
      }
    }
  };

  const renderView = (guarantor) => {
    if (user) {
      if (user.role_name === "Admin" || user.role_name === "admin") {
        return (
          <>
            <ViewGuarantorAction guarantor={guarantor} />
          </>
        );
      } else {
        return (
          <>
            <IconButton variant="outlined" className="cursor-not-allowed">
              <RemoveRedEye
                sx={{
                  color: colors.link,
                }}
                className="opacity-25"
              />
            </IconButton>
          </>
        );
      }
    }
  };

  const renderDelete = (guarantor) => {
    if (user) {
      if (user.role_name === "Clerk" || user.role_name === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteGuarantorAction
              guarantor={guarantor}
              onSuccess={() => {
                getGuarantors();
              }}
            />
          </>
        );
      }
    }
  };

  const renderGuarantors = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.guarantorTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Guarantor Name</p>
          <p className="py-2">Phone Number</p>
          <p className="py-2">Location</p>
          <p className="py-2">Actions</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderGuarantor()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderGuarantor = () => {
    if (!isEmpty(guarantor)) {
      return (
        <div
          className={`bg-white h-10 ${tables.guarantorTable} rounded-md text-sm my-2 mx-1`}
        >
          <p className="py-2 text-center">1</p>
          <p className="py-2 text-center">
            {guarantor.first_name} {guarantor.middle_name} {guarantor.last_name}
          </p>
          <p className="py-2 text-center">{guarantor.phone_number}</p>
          <p className="py-2 text-center">{guarantor.location}</p>
          <p className="flex flex-row justify-center space-x-2 pl-4">
            {renderEdit(guarantor)}
            {renderDelete(guarantor)}
          </p>
          <p className="flex flex-row justify-center space-x-2 pl-4">
            {renderView(guarantor)}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">Employee has no guarantors</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 1 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <AddGuarantor />
      </Grid>
      <Grid item sm={12}>
        <Box>
          <h5>Employee Guarantors</h5>
          <div className="w-[100%] pb-6">{renderGuarantors()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmployeeGuarantors;
