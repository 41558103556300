import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import TripsProgressCard from "../cards/TripsProgressCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import MonthlyTripsCard from "../cards/MonthlyTripsCard";
import PaidAmountCard from "../cards/PaidAmountCard";
import PaidInvoicesCard from "../cards/PaidInvoicesCard";
import { useDispatch, useSelector } from "react-redux";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { getRouteInvoices } from "../../api/trips/tripsAPI";
import { isEmpty } from "lodash";
import {
  addRouteInvoices,
  selectRouteInvoices,
} from "../../features/tripsSlice";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { addInvoiceDetails } from "../../features/revenueSlice";
import { Box, Grid } from "@mui/material";


const AdminInvoices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    //fetch all invoices
    const getInvoices = async () => {
      const response = await getRouteInvoices({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addRouteInvoices(response.data.data));
        }
      }
    };

    getInvoices();
  }, [dispatch]);

  const invoices = useSelector(selectRouteInvoices);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let formatterUS = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const paidInvoicesArray = invoices.filter((invoice) => {
    //filtering array value
    return invoice.payment_status === "paid";
  });

  const unpaidInvoicesArray = invoices.filter((invoice) => {
    //filtering array value
    return invoice.payment_status !== "paid";
  });

  let invoicesTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;

  if (invoices.length !== 0) {
    for (const value of invoices) {
      invoicesTotalAmount += value.total_amount;
      paidTotalAmount += value.paid_amount;
    }
  }

  if (unpaidInvoicesArray.length !== 0) {
    for (const value of invoices) {
      unpaidTotalAmount += value.unpaid_amount;
    }
  }

  const handleTransaction = (invoice) => {
    //add invoice data to store
    dispatch(addInvoiceDetails(invoice));
    navigate(`/revenue/invoices/${invoice?.id}`);
  };

  const renderExpenses = () => {
    return (
      <div>

        <div className="">
          <div
            className={`bg-[#EEDEDE] h-10 text-center ${tables.customerInvoiceTable} rounded-t-md font-semibold`}
          >
            <p className="py-2">No</p>
            <p className="py-2">Invoice No</p>
            <p className="py-2">Amount TZS</p>
            <p className="py-2">Amount USD</p>
            <p className="py-2">Issued Date</p>
            <p className="py-2">Status</p>
            <p className="py-2">Paid Amount</p>
            <p className="py-2">Payment Date</p>
            <p className="py-2">View</p>
          </div>
          <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
            {renderInvoice()}
          </div>
          <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
        </div>
      </div>
    );
  };

  const renderInvoice = () => {
    if (invoices.length !== 0) {
      return (
        <div>
          {invoices.map((invoice, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`h-10 bg-white ${tables.customerInvoiceTable} rounded-md text-sm my-2 mx-1`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 pl-6">{invoice.invoice_number}</p>
                <p className="py-2 pl-4">
                  {formatter.format(invoice.total_amount)}
                </p>
                <p className="py-2 pl-6">
                  {formatterUS.format(invoice.total_amount_usd)}
                </p>
                <p className="py-2 text-center">
                  {invoice?.invoice_date ? (
                    <>{invoice.invoice_date}</>
                  ) : (
                    <>{moment(invoice.created_at).format("YYYY-MM-DD")}</>
                  )}
                </p>
                <p className="py-2 pl-8">
                  {invoice.status === "unpaid" ? (
                    <span>Not paid</span>
                  ) : (
                    <span className="capitalize">{invoice.status}</span>
                  )}
                </p>
                <p className="py-2 pl-4">
                  {formatter.format(invoice.paid_amount)}
                </p>
                <p className="py-2 pl-6">{invoice.payment_date}</p>
                <p
                  className="cursor-pointer py-2 text-center pl-4"
                  onClick={() => handleTransaction(invoice)}
                >
                  <RemoveRedEye
                    sx={{
                      color: colors.link,
                    }}
                  />
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p className="text-center">There are no issued invoices</p>;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            title={"Issued Invoices"}
            value={invoices.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            title={"Paid Invoices"}
            value={paidInvoicesArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <PaidInvoicesCard
            title={"Unpaid Invoices"}
            value={unpaidInvoicesArray.length}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <MonthlyTripsCard
            title={"Total Amount"}
            value={formatter.format(invoicesTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <PaidAmountCard
            title={"Paid Amount"}
            value={formatter.format(paidTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            title={"Unpaid Amount"}
            value={formatter.format(unpaidTotalAmount)}
            trip={true}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Invoices</h5>
            <div className="pb-8 mt-0">{renderExpenses()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminInvoices;
