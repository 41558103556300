import React, { useEffect, useState, useRef, Fragment } from "react";
import { RemoveRedEye, Edit } from "@mui/icons-material";
import {
  getAllUsers,
  toggleUserStatus,
  updateUser,
} from "../../api/users/userAPI";
import { isEmpty, map } from "lodash";
import {
  addAllUsers,
  addUserCustomDetails,
  selectAllUsers,
  addSearchedUsers,
  selectSearchedUsers,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { useQueryClient, useMutation } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import { Chip, CircularProgress, Divider, IconButton } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import clsx from "clsx";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { selectUserRoles, addUserRoles } from "../../features/settingSlice";
import { useForm, Controller } from "react-hook-form";
import { getAllRoles } from "../../api/settings/settingAPI";
import useAuth from "../../hooks/useAuth";
import { resetPassword } from "../../api/auth/authAPI";
import { useNavigate } from "react-router-dom";
import { Button, Input, Space, Popover } from "antd";

const ChangeUserStatusAction = ({ userId, status, handleClose = () => {} }) => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all users
  const getUsers = async () => {
    const response = await getAllUsers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addAllUsers(response.data.data));
      }
    }
  };

  const { mutate, isLoading } = useMutation(toggleUserStatus, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["users", userId] });

        notifyToastSuccess({
          message: "User status is changed successfully",
          icon: "✅",
        });
        getUsers();
        handleClose();
      } else {
        notifyToastError({
          message: data?.data.message,
          icon: "❗",
        });
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong in creating changeing customer status");
      console.log(error);
    },
  });

  const toggleStatus = () => {
    if (
      window.confirm(
        "Are you sure you want to " +
          (status === "active"
            ? "deactivate this user ?"
            : "activate this user ?")
      )
    ) {
      mutate({ userId, status: status, token });
    } else {
      alert("Action not permitted");
    }
  };

  return (
    <div className="flex justify-between items-center">
      <button
        onClick={() => {
          toggleStatus();
        }}
        disabled={isLoading}
        className={clsx(
          "px-2 py-1 font-semibold rounded-md bg-violet-400 text-gray-900",
          isLoading && "opacity-60 cursor-not-allowed"
        )}
      >
        {isLoading ? (
          <span className="flex items-center space-x-2">
            <p>Please Wait</p>

            <CircularProgress size={24} sx={{ color: "white" }} />
          </span>
        ) : (
          <>{status === "active" ? "Deactivate" : "Activate"}</>
        )}
      </button>
    </div>
  );
};

const ResetUserPassword = ({ phone_number, handleClose = () => {} }) => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all users
  const getUsers = async () => {
    const response = await getAllUsers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addAllUsers(response.data.data));
      }
    }
  };

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });

        notifyToastSuccess({
          message: "User password is reset successfully",
          icon: "✅",
        });
        getUsers();
        handleClose();
      } else {
        notifyToastError({
          message: data?.data.message,
          icon: "❗",
        });
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong when resetting user password");
      console.log(error);
    },
  });

  const toggleStatus = () => {
    if (window.confirm("Are you sure you want to reset user password?")) {
      mutate({ phone_number, token: auth?.token });
    } else {
      alert("Action not permitted");
    }
  };

  return (
    <div className="flex justify-between items-center">
      <button
        onClick={() => {
          toggleStatus();
        }}
        disabled={isLoading}
        className={clsx(
          "px-2 py-1 font-semibold rounded-md bg-[#F24E1E]",
          isLoading && "opacity-60 cursor-not-allowed"
        )}
      >
        {isLoading ? (
          <span className="flex items-center space-x-2">
            <p>Please Wait</p>

            <CircularProgress size={24} sx={{ color: "white" }} />
          </span>
        ) : (
          <>
            <p>Reset Password</p>
          </>
        )}
      </button>
    </div>
  );
};

const EditUser = ({
  user = {
    id: "",
    role_name: "",
    role_id: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    phone_number: "",
    email: "",
  },
  handleClose = () => {},
  onUpdateSuccess = () => {},
}) => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all users
  const getUsers = async () => {
    const response = await getAllUsers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addAllUsers(response.data.data));
      }
    }
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      role_id: user.role_id,
      first_name: user.first_name,
      last_name: user.last_name,
      middle_name: user.middle_name,
      gender: user.gender,
      phone_number: user.phone_number,
      email: user.email,
    },
  });

  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["users", user?.id] });

        notifyToastSuccess({
          message: "User is updated successfully",
          icon: "✅",
        });

        getUsers();
        onUpdateSuccess();
        handleClose();
      } else {
        notifyToastError({
          message: data?.data.message,
          icon: "❗",
        });
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
    },
  });

  const onSubmit = (data) => {
    // console.log(data)
    const final = {
      role_id: data?.role_id || "",
      first_name: data?.first_name || "",
      middle_name: data?.middle_name || "",
      last_name: data?.last_name || "",
      phone_number: data?.phone_number || "",
      gender: data?.gender || "",
      email: data?.email || "",
      token: auth?.token || "",
      user_id: user?.id || "",
    };

    mutate({
      ...final,
    });
  };

  const roles = useSelector(selectUserRoles);
  const rolesData = map(roles, (role) => role);

  return (
    <div>
      <div>
        <div className="w-full py-2 flex flex-row gap-2 ">
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="First name"
                variant="outlined"
                className="flex-1"
                {...field}
              />
            )}
          />

          <Controller
            name="middle_name"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Middle name"
                variant="outlined"
                className="flex-1"
                {...field}
              />
            )}
          />

          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                className="flex-1"
                {...field}
              />
            )}
          />
        </div>
        <div className="w-full py-2 flex flex-row gap-2 justify-center">
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Phone number"
                variant="outlined"
                className="flex-1"
                {...field}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                className="flex-1"
                {...field}
              />
            )}
          />
        </div>
        <div className="w-full py-2 flex flex-row gap-2 justify-center">
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label="Gender"
                className="flex-1"
                {...field}
              >
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
              </TextField>
            )}
          />

          <Controller
            name="role_id"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label="Role"
                className="flex-1"
                {...field}
              >
                {rolesData
                  ? rolesData.map((role, index) => (
                      <MenuItem key={index} value={role.id}>
                        {role.role_name}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            )}
          />
        </div>
        <div className="w-full py-2 flex ">
          <button
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            className={clsx(
              "bg-slate-900 text-white rounded-sm px-6 py-2 flex-1 flex items-center justify-center",
              isLoading && "cursor-not-allowed opacity-75"
            )}
          >
            {isLoading ? (
              <span className="flex items-center space-x-2">
                <p>Please Wait</p>

                <CircularProgress size={24} sx={{ color: "white" }} />
              </span>
            ) : (
              <span className="flex items-center space-x-2">Update User</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const TruckOwners = () => {
  const [activeUserId, setActivateUserId] = useState("");
  const [action, setAction] = useState("");
  const [activeUser, setActiveUser] = useState();

  const [searchText, setSearchText] = useState("");
  const [searchUser, setSearchUser] = useState(false);
  const searchInput = useRef(null);

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const token = auth?.token || "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    //fetch all roles
    const getRoles = async () => {
      const response = await getAllRoles({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addUserRoles(response.data.data));
        }
      }
    };

    getRoles();
  }, [dispatch]);

  //fetch all owners
  const getUsers = async () => {
    const response = await getAllUsers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addAllUsers(response.data.data));
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, [dispatch]);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const usersArray = useSelector(selectAllUsers);
  const searchedUsers = useSelector(selectSearchedUsers);

  const users = usersArray.filter((user) => {
    //filtering array value
    return user.role_name.toLowerCase() === "truck owner";
  });

  const handleSelectedUser = (user) => {
    //get user details the navigate to user page
    dispatch(addUserCustomDetails(user));
    navigate(`/users/user/${user?.id}`);
  };

  const handleSearch = () => {
    if (users.length !== 0) {
      if (searchText) {
        const results = users.filter((user) => {
          //get user name
          const name = user.first_name
            .concat(" ", user.middle_name)
            .concat(" ", user.last_name);
          // console.log(name);
          //filtering array value
          return name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

        dispatch(addSearchedUsers(results));
        setSearchUser(true);
      }
    }
  };

  const handleReset = () => {
    setSearchUser(false);
    dispatch(addSearchedUsers([]));
    setSearchText("");
  };

  const userData = () => {
    return (
      <div
        style={{
          padding: 4,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search user`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() => handleSearch()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={hide}>
            close
          </Button>
        </Space>
      </div>
    );
  };

  const renderUsers = () => {
    return (
      <div className="">
        <Transition appear show={isOpen} as={Fragment}>
          {/* TODO : modify the following line to close the modal when clicking outside of the modal  */}
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-xl transform  max-h-[80vh]  self-center justify-self-center rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg flex items-center justify-between font-medium leading-6 text-gray-900"
                    >
                      <span>View User Details</span>
                      <span>
                        <button
                          type="button"
                          className="text-white bg-red-700 hover:bg-red-800  font-medium rounded-sm text-sm px-5 py-2 mb-2 "
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </span>
                    </Dialog.Title>
                    <div className="mt-2">
                      {activeUserId && action === "edit" && (
                        <EditUser
                          userId={activeUserId}
                          user={activeUser}
                          handleClose={closeModal}
                          onUpdateSuccess={getUsers}
                        />
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        <div className="text-slate-900">
          <div className="overflow-x-auto">
            <table className="min-w-full  bg-[#F0F0F0]">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead className="bg-[#EEDEDE]">
                <tr className="text-left">
                  <th className="p-3">#</th>
                  <th className="p-3 flex -flex-row justify-between">
                    <span>User</span>{" "}
                    <Popover
                      placement="leftTop"
                      content={userData()}
                      title="Search User"
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <p className="px-4 py-2 cursor-pointer">
                        <AiOutlineSearch />
                      </p>
                    </Popover>
                  </th>
                  <th className="p-3">Gender</th>
                  <th className="p-3">User Role</th>
                  <th className="p-3">Status</th>
                  <th className="p-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users && users?.length === 0 && (
                  <td className="p-3">
                    <td
                      colSpan={6}
                      className="flex items-center justify-center py-4"
                    >
                      No users saved
                    </td>
                  </td>
                )}
                {searchUser ? (
                  <>
                    {searchedUsers.length !== 0 ? (
                      <>
                        {searchedUsers.map((user, index) => {
                          return (
                            <tr
                              key={user?.id || ""}
                              className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                            >
                              <td className="p-3">
                                <p>{index + 1}</p>
                              </td>
                              <td className="p-3">
                                <p>
                                  {user.first_name} {user.middle_name}{" "}
                                  {user.last_name}
                                </p>
                                <p className="">{user?.phone_number}</p>
                                <p className="">{user?.email}</p>
                              </td>
                              <td className="p-3">
                                <p>{user?.gender}</p>
                              </td>
                              <td className="p-3">
                                <p>{user?.role_name}</p>
                              </td>
                              <td className="p-3 space-y-2">
                                <p className="">
                                  {user?.status !== "active" ? (
                                    <span>Deactivated</span>
                                  ) : (
                                    <span>Active</span>
                                  )}
                                </p>
                                <p>
                                  <ChangeUserStatusAction
                                    status={user?.status}
                                    userId={user?.id}
                                  />
                                </p>
                              </td>

                              <td className="p-3 flex items-center space-x-2">
                                <IconButton
                                  onClick={() => {
                                    setActivateUserId(user?.id);
                                    handleSelectedUser(user);
                                  }}
                                >
                                  <RemoveRedEye />
                                </IconButton>

                                <IconButton
                                  onClick={() => {
                                    setActivateUserId(user?.id);
                                    setActiveUser(user);
                                    setAction("edit");
                                    openModal();
                                  }}
                                >
                                  <Edit />
                                </IconButton>

                                <div>
                                  <ResetUserPassword
                                    phone_number={user?.phone_number}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <td className="p-3">
                          <td
                            colSpan={6}
                            className="flex items-center justify-center py-4"
                          >
                            No user found
                          </td>
                        </td>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {users &&
                      users?.length > 0 &&
                      users.map((user, index) => {
                        return (
                          <tr
                            key={user?.id || ""}
                            className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                          >
                            <td className="p-3">
                              <p>{index + 1}</p>
                            </td>
                            <td className="p-3">
                              <p>
                                {user.first_name} {user.middle_name}{" "}
                                {user.last_name}
                              </p>
                              <p className="">{user?.phone_number}</p>
                              <p className="">{user?.email}</p>
                            </td>
                            <td className="p-3">
                              <p>{user?.gender}</p>
                            </td>
                            <td className="p-3">
                              <p>{user?.role_name}</p>
                            </td>
                            <td className="p-3 space-y-2">
                              <p className="">
                                {user?.status !== "active" ? (
                                  <span>Deactivated</span>
                                ) : (
                                  <span>Active</span>
                                )}
                              </p>
                              <p>
                                <ChangeUserStatusAction
                                  status={user?.status}
                                  userId={user?.id}
                                />
                              </p>
                            </td>

                            <td className="p-3 flex items-center space-x-2">
                              <IconButton
                                onClick={() => {
                                  setActivateUserId(user?.id);
                                  handleSelectedUser(user);
                                }}
                              >
                                <RemoveRedEye />
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  setActivateUserId(user?.id);
                                  setActiveUser(user);
                                  setAction("edit");
                                  openModal();
                                }}
                              >
                                <Edit />
                              </IconButton>

                              <div>
                                <ResetUserPassword
                                  phone_number={user?.phone_number}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="w-[100%] py-3">
        <Divider>
          <Chip
            className="font-semibold"
            label={`TOTAL PROFIT: ${formatter.format(0)}`}
          />{" "}
          <Chip
            className="font-semibold"
            label={`TOTAL PAID AMOUNT: ${formatter.format(0)}`}
          />{" "}
          <Chip
            className="font-semibold"
            label={`TOTAL DEBT AMOUNT: ${formatter.format(0)}`}
          />
        </Divider>
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>All Users</h5>
          <div className="w-[100%]">{renderUsers()}</div>
        </div>
      </div>
    </div>
  );
};

export default TruckOwners;
