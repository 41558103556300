import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalExpenses: [],
  ownerExpenses: [],
  expensesPayments: [],
  supplierPayments: [],
  expenseDetails: "",
  monthlyExpenses: [],
  truckExpenses: [],
  monthlyExpenseDetails: "",
  supplierMonthlyExpenses: [],
  supplierMonthlyExpensePayments: [],
};

const expensesSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {
    setPersonalExpenses(state, action) {
      state.personalExpenses = action.payload;
    },

    addOwnerExpenses(state, action) {
      state.ownerExpenses = action.payload;
    },

    addExpensesPayments(state, action) {
      state.expensesPayments = action.payload;
    },

    addSupplierPayments(state, action) {
      state.supplierPayments = action.payload;
    },

    addExpenseDetails(state, action) {
      state.expenseDetails = action.payload;
    },

    addMonthlyExpenseDetails(state, action) {
      state.monthlyExpenseDetails = action.payload;
    },

    addMonthlyExpenses(state, action) {
      state.monthlyExpenses = action.payload;
    },

    addTruckMonthlyExpenses(state, action) {
      state.truckExpenses = action.payload;
    },

    addSupplierTruckMonthlyExpenses(state, action) {
      state.supplierMonthlyExpenses = action.payload;
    },

    addSupplierTruckMonthlyExpensePayments(state, action) {
      state.supplierMonthlyExpensePayments = action.payload;
    },

  },
});

export const { setPersonalExpenses, addOwnerExpenses, addExpensesPayments, addSupplierPayments, addExpenseDetails, addMonthlyExpenses, addTruckMonthlyExpenses, addSupplierTruckMonthlyExpenses, addSupplierTruckMonthlyExpensePayments, addMonthlyExpenseDetails } = expensesSlice.actions;

export const selectPersonalExpenses = (state) => state.expenses.personalExpenses;
export const selectOwnerExpenses = (state) => state.expenses.ownerExpenses;
export const selectExpensesPayments = (state) => state.expenses.expensesPayments;
export const selectSupplierPayments = (state) => state.expenses.supplierPayments;
export const selectExpenseDetails = (state) => state.expenses.expenseDetails;
export const selectMonthlyExpenseDetails = (state) => state.expenses.monthlyExpenseDetails;
export const selectMonthlyExpenses = (state) => state.expenses.monthlyExpenses;
export const selectTruckMonthlyExpenses = (state) => state.expenses.truckExpenses;
export const selectSupplierTruckMonthlyExpenses = (state) => state.expenses.supplierMonthlyExpenses;
export const selectSupplierTruckMonthlyExpensePayments = (state) => state.expenses.supplierMonthlyExpensePayments;

export default expensesSlice.reducer;