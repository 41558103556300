import React from "react";
import { DirectionsBus } from "@mui/icons-material";

const ProfitCard = ({ value, title, owner, car }) => {
  if (!owner) {
    return (
      <div className="bg-gradient-to-r from-[#323232] to-[#B98D8D] h-[200px] w-[100%] rounded-md border-2">
        <div className="px-4 py-4">
          <div className="flex flex-row gap-6 justify-between">
            <div>
              <h4 className="text-white text-lg font-semibold">
                {title ? <span>{title}</span> : "PROFITS"}
              </h4>
            </div>
            <p className="text-white text-sm font-semibold cursor-pointer"></p>
          </div>
          <div className="py-3">
            <p className="text-3xl text-white font-bold">
              {value ? <span>{value}</span> : 0}
            </p>
          </div>
          <div className="flex flex-row gap-6 justify-between py-12">
            <div className="flex flex-row"></div>
            <div className="">
              <p>
                <DirectionsBus
                  sx={{
                    color: "#CCCCCC",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-gradient-to-r from-[#323232] to-[#B98D8D] h-[140px] w-[100%] rounded-md border-2">
        <div className="px-4 py-4">
          <div className="flex flex-row gap-6 justify-between">
            <div>
              <h4 className="text-white text-lg font-semibold">
                {title ? <span>{title}</span> : ""}
              </h4>
            </div>
            <p className="text-white text-sm font-semibold cursor-pointer"></p>
          </div>
          <div className="py-3">
            <p className="text-3xl md:text-xl sm:text-lg text-white font-bold">
              {value ? <span>{value}</span> : 0}
            </p>
          </div>
          <div className="flex flex-row gap-6 justify-between py-12">
            <div className="flex flex-row"></div>
            <div className="">
              <p>
                {car ? (
                  <DirectionsBus
                    sx={{
                      color: "#CCCCCC",
                    }}
                  />
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ProfitCard;
