import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import EditIcon from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import useAuth from "../../hooks/useAuth";
import { changeUserPassword } from "../../api/auth/authAPI";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    lg: 600,
    xl: 600,
    md: 320,
    sm: 320,
    xs: 320,
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const registerNewPassword = async (e) => {
    e.preventDefault();

    if (!old_password) {
      notifyToastError({
        message: "Please enter your current password",
        icon: "❗",
      });
    } else if (!new_password) {
      notifyToastError({
        message: "Please enter your new password",
        icon: "❗",
      });
    } else if (!new_password) {
      notifyToastError({
        message: "Please enter your new password",
        icon: "❗",
      });
    } else {
      if (new_password === confirm_password) {
        setLoading(true);
        const response = await changeUserPassword({
          old_password,
          password: new_password,
          token: auth?.token,
        });

        if (response) {
          //handle response
          checkResponse(response);
        } else {
          setLoading(false);
          notifyToastError({
            message: "Something went wrong",
            icon: "❗",
          });
        }
      } else {
        notifyToastError({
          message: "Sorry! new password and confirm password does not match",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);

        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");

        notifyToastSuccess({
          message: "Your password is successfully changed",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
        console.log(response?.data || {});
      }
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%] cursor-not-allowed"
            disabled
          >
            <svg
              class="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
            Loading...
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%]"
            onClick={(e) => registerNewPassword(e)}
          >
            CHANGE PASSWORD
          </Button>
        </>
      );
    }
  };

  return (
    <div>
      <Fab aria-label="edit" onClick={handleOpen}>
        <EditIcon />
      </Fab>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div>{/* <Toaster /> */}</div>
            <h3 className="text-center text-xl py-4">Change Password</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  type="password"
                  label="Current password"
                  variant="outlined"
                  className="w-[82%]"
                  value={old_password}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  type="password"
                  label="New password"
                  variant="outlined"
                  className="w-[82%]"
                  value={new_password}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  type="password"
                  label="Confirm password"
                  variant="outlined"
                  className="w-[82%]"
                  value={confirm_password}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ChangePassword;
