import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AdminTruck from "./AdminTruck";
import FinanceTruck from "./FinanceTruck";
import { Grid } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import TruckMonthlyExpenses from "./TruckMonthlyExpenses";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const Truck = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isMobileOnly ? (
        <div className="py-2 w-screen md:w-[calc(100vw-84px)] mx-auto">
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Box
                sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  textColor={`${primary}`}
                  indicatorColor="primary"
                  sx={{ color: "#F24E1E" }}
                >
                  <Tab label="DETAILS" {...a11yProps(0)} />
                  <Tab label="TRIPS & FINANCE" {...a11yProps(1)} />
                  <Tab label="INDIRECT EXPENSES" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <AdminTruck />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <FinanceTruck />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TruckMonthlyExpenses/>
              </TabPanel>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="w-screen md:w-[calc(100vw-84px)] mx-auto">
          <div className="grid grid-cols-1">
            <div>
              <Box
                sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  textColor={`${primary}`}
                  indicatorColor="primary"
                  sx={{ color: "#F24E1E" }}
                >
                  <Tab label="DETAILS" {...a11yProps(0)} />
                  <Tab label="TRIPS & FINANCE" {...a11yProps(1)} />
                  <Tab label="INDIRECT EXPENSES" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <AdminTruck />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <FinanceTruck />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TruckMonthlyExpenses/>
              </TabPanel>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Truck;
