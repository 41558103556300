import React from "react";
import { isEmpty } from "lodash";
import AccountantOverview from "./AccountantOverview";
import OwnerOverview from "./OwnerOverview";
import TrackerOverview from "./TrackerOverview";
import Login from "./auth/Login";
import { useAtom } from "jotai";
import { authenticationAtom } from "../atoms/auth";

const Dashboard = () => {
  const [userData] = useAtom(authenticationAtom);

  const renderUserDashboard = () => {
    const { userInfo: user } = userData;

    if (!isEmpty(user)) {
      switch (true) {
        case user.role_name.toLowerCase() === "accountant" ||
          user.role_name.toLowerCase() === "clerk" ||
          user.role_name.toLowerCase() === "admin":
          return <AccountantOverview />;
        case user.role_name.toLowerCase() === "truck owner":
          return <OwnerOverview />;
        case user.role_name.toLowerCase() === "tracking person":
          return <TrackerOverview />;

        default:
          return <div className="text-center">NOT AUTHORIZED</div>;
      }
    } else {
      return <Login />;
    }
  };

  return <div>{renderUserDashboard()}</div>;
};

export default Dashboard;
