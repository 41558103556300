import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import { Download, Reddit } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import signatureImage from "../../assets/media/images/signature2.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Create styles for PDF document
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 30,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 15,
  },
  owner: {
    fontSize: 13,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
  customer: {
    fontSize: 12,
    marginBottom: 5,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader1: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol1: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
});

const OrderPDF = ({ order }) => {
  // Sample order data
  const phone_numbers = JSON.parse(order?.order_person?.phone_number);
  const particulars = order?.ordered_items;

  let driverNumber = [];

  if (order.driver !== null) {
    driverNumber = JSON.parse(order?.driver?.phone_number);
  }
  const driverPhoneNumber = driverNumber[0];

  let formatterNum = new Intl.NumberFormat("en-uS");

  return (
    <PDFViewer width="1000" height="600">
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>ORDER</Text>
            <Text
              style={{
                textAlign: "left",
                marginBottom: 5,
                marginTop: 5,
                fontSize: 13,
              }}
            >
              FROM
            </Text>
            <Text style={{ fontSize: 20, textAlign: "center" }}>
              {order?.order_person?.name.toUpperCase()}
            </Text>
            <View style={styles.owner}>
              <Text>{order?.order_person?.company}</Text>
              <Text>{order?.order_person?.address}</Text>
            </View>
            <View style={{ textAlign: "right", marginBottom: 5, marginTop: 4, fontSize: 13 }}>
              <Text>ORDER NO : {order?.order_number}</Text>
              <Text>TIN: {order?.order_person?.tin}</Text>
              <Text>VRN: {order?.order_person?.vrn}</Text>
              <Text>Mobile : {phone_numbers[0]}</Text>
              {phone_numbers.length > 1 ? (
                <Text>Mobile : {phone_numbers[1]}</Text>
              ) : null}
            </View>
            <View style={styles.customer}>
              <Text style={{ fontSize: 13 }}>TO: </Text>
              <Text>{order?.supplier?.supplier_name}</Text>
              <Text>{order?.supplier?.location}</Text>
            </View>
            <View style={{ textAlign: "right", marginBottom: 10 }}>
              <Text>
                Date: {moment(order?.order_date).format("DD-MM-YYYY")}
              </Text>
            </View>
            <Text
              style={{ fontSize: 13, marginBottom: 5, textAlign: "center" }}
            >
              PLEASE SUPPLY THE FOLLOWING GOODS
            </Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColHeader1}>Qty</Text>
                <Text style={styles.tableColHeader2}>Particular</Text>
                <Text style={styles.tableColHeader1}>@</Text>
                <Text style={styles.tableColHeader}>Amount TZS</Text>
              </View>
              {particulars &&
                particulars.map((item, index) => (
                  <View style={styles.tableRow} key={index}>
                    <Text style={styles.tableCol1}>{item?.quantity}</Text>
                    <Text style={styles.tableCol2}>{item?.item_name}</Text>
                    <Text style={styles.tableCol1}>
                      {formatterNum.format(item?.price || 0)}
                    </Text>
                    <Text style={styles.tableCol}>
                      {formatterNum.format(item?.total_price || 0)}
                    </Text>
                  </View>
                ))}
            </View>
            <Text
              style={{
                fontSize: 13,
                marginBottom: 10,
                marginTop: 10,
                textAlign: "right",
              }}
            >
              TOTAL : TZS {formatterNum.format(order?.total_order_price || 0)}
            </Text>
            {order?.description ? (
              <Text
                style={{
                  fontSize: 13,
                  marginBottom: 10,
                  marginTop: 10,
                  textAlign: "left",
                }}
              >
                {order?.description}
              </Text>
            ) : null}
            {order?.truck ? (
              <>
                <Text
                  style={{
                    fontSize: 13,
                    marginBottom: 5,
                    marginTop: 10,
                    textAlign: "center",
                  }}
                >
                  {order?.truck?.registration_number} /{" "}
                  {order?.truck?.trailer_registration_number}
                </Text>
              </>
            ) : null}
            {order?.driver !== null ? (
              <>
                <Text
                  style={{
                    fontSize: 13,
                    marginBottom: 10,
                    marginTop: 10,
                    textAlign: "center",
                  }}
                >
                  {order?.driver?.fist_name } {order?.driver?.middle_name}{" "}
                  {order?.driver?.last_name} / {driverPhoneNumber}
                </Text>
              </>
            ) : null}
            <Text
              style={{
                fontSize: 13,
                marginBottom: 10,
                marginTop: 10,
                textAlign: "left",
              }}
            >
              Please supply and charge to our account.
            </Text>
            <View style={styles.owner}>
              <Text>Signature: <Image source={signatureImage} style={{width: 100, height: 40, position: "absolute", paddingTop: 40 }} /></Text>
              <Text style={{
                fontSize: 9,
                marginTop: 10,
                textAlign: "center",
              }}>(This order is electronically generated.)</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const OrderPDFCard = ({ data }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                GENERATE ORDER
              </h1>
            </div>
            <OrderPDF order={data} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default OrderPDFCard;
