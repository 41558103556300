import {
  CREATE_MULTIORDER_PAYMENT,
  CREATE_ORDER,
  CREATE_ORDER_PAYMENT,
  CREATE_STOCK,
  DELETE_ORDER,
  DELETE_ORDER_PAYMENT,
  DELETE_STOCK,
  DISPATCH_STOCK_ITEM,
  GET_ALL_ORDERS,
  GET_ALL_ORDER_PAYMENT,
  GET_ALL_STOCKS,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_PAYMENT,
  GET_SINGLE_STOCK,
  GET_SUPPLIER_ORDERS,
  GET_TRUCKS_SUMMARY,
  UPDATE_ORDER,
  UPDATE_ORDER_PAYMENT,
  UPDATE_STOCK,
} from "../apiEndPoints";
import client from "../client";

export const createStockOrder = async ({
  order_number,
  supplier_id,
  order_person,
  total_order_price,
  order_date,
  order_type,
  truck_id,
  trip_id,
  route_id,
  ordered_items,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_ORDER}`,
    {
      order_number,
      order_person,
      supplier_id,
      total_order_price,
      order_date,
      order_type,
      truck_id,
      trip_id,
      route_id,
      ordered_items,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllStockOrders = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ORDERS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleStockOrder = async ({ order_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ORDER}/${order_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSupplierOrders = async ({ supplier_id, token }) => {
  const response = await client.get(
    `${GET_SUPPLIER_ORDERS}/${supplier_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateStockOrder = async ({
  order_number,
  order_person,
  supplier_id,
  total_order_price,
  order_date,
  ordered_items,
  description,
  order_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ORDER}/${order_id}`,
    {
      order_number,
      order_person,
      supplier_id,
      total_order_price,
      order_date,
      ordered_items,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteStockOrder = async ({ order_id, token }) => {
  const response = await client.delete(
    `${DELETE_ORDER}/${order_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createOrderPayment = async ({
  amount_paid,
  payment_date,
  description,
  order_id,
  token,
}) => {
  const response = await client.post(
    `${CREATE_ORDER_PAYMENT}/${order_id}`,
    {
      amount_paid,
      payment_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createMultiOrdersPayment = async ({ order_ids, token }) => {
  const response = await client.post(
    `${CREATE_MULTIORDER_PAYMENT}`,
    {
      order_ids,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllOrderPayments = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ORDER_PAYMENT,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleOrderPayment = async ({ expense_payment_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ORDER_PAYMENT}/${expense_payment_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateOrderPayment = async ({
  amount_paid,
  payment_date,
  description,
  order_payment_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ORDER_PAYMENT}/${order_payment_id}`,
    {
      amount_paid,
      payment_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteOrderPayment = async ({ expense_payment_id, token }) => {
  const response = await client.delete(
    `${DELETE_ORDER_PAYMENT}/${expense_payment_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createStock = async ({
  item_id,
  supplier_id,
  order_number,
  order_person,
  quantity,
  price,
  total_price,
  order_date,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_STOCK}`,
    {
      item_id,
      supplier_id,
      order_number,
      order_person,
      quantity,
      price,
      total_price,
      order_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllStocks = async ({ token }) => {
  const response = await client.get(
    GET_ALL_STOCKS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleStock = async ({ stock_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_STOCK}/${stock_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateStock = async ({
  item_id,
  supplier_id,
  order_number,
  order_person,
  quantity,
  price,
  total_price,
  order_date,
  description,
  stock_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_STOCK}/${stock_id}`,
    {
      item_id,
      supplier_id,
      order_number,
      order_person,
      quantity,
      price,
      total_price,
      order_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteStock = async ({ stock_id, token }) => {
  const response = await client.delete(
    `${DELETE_STOCK}/${stock_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getTrucksSummary = async ({ token }) => {
  const response = await client.get(
    GET_TRUCKS_SUMMARY,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const dispatchStockItem = async ({
  item_id,
  supplier_id,
  dispatch,
  truck_id,
  trip_id,
  route_id,
  quantity,
  price,
  total_price,
  date,
  description,
  token,
}) => {
  const response = await client.post(
    `${DISPATCH_STOCK_ITEM}/${item_id}`,
    {
      supplier_id,
      dispatch,
      truck_id,
      trip_id,
      route_id,
      quantity,
      price,
      total_price,
      date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};
