import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { colors } from "../../assets/utils/colors";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { getSingleRouteExpense } from "../../api/trips/tripsAPI";
import { Grid, IconButton } from "@mui/material";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { RemoveRedEye } from "@mui/icons-material";
import {
  addExpenseDetails,
  selectExpenseDetails,
} from "../../features/expensesSlice";
import ExpensesPayments from "./ExpensesPayments";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const ViewExpenseAction = ({ expense }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(trip);
  const addedTime = moment(expense?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(expense?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal} size="small" className="pb-3">
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
          className="pb-1"
        />
      </IconButton>
      <Modal
        title="Expense Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {expense.description ? <span>{expense.description}</span> : null}
          </p>
          <br />
          <br />
          {user?.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {expense.added_by ? (
                  <span>
                    {expense.added_by.first_name} {expense.added_by.middle_name}{" "}
                    {expense.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({expense.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {expense.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {expense.updated_by ? (
                  <span>
                    {expense.updated_by.first_name}{" "}
                    {expense.updated_by.middle_name}{" "}
                    {expense.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({expense.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {expense.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const ExpenseDetails = () => {
  const [value, setValue] = useState(0);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { expenseID } = useParams();
  const dispatch = useDispatch();

  const getExpense = async () => {
    const response = await getSingleRouteExpense({
      expense_id: expenseID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addExpenseDetails(response.data.data));
      } else {
        dispatch(addExpenseDetails([]));
      }
    } else {
      dispatch(addExpenseDetails([]));
    }
  };

  useEffect(() => {
    getExpense();
  }, [dispatch]);

  const expense = useSelector(selectExpenseDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderExpenseDetails = () => {
    if (!isEmpty(expense)) {
      return (
        <Card
          sx={{
            width: 850,
            height: 300,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">EXOENSE DETAILS</h3>
            <div className="px-8">
              <div className="flex flex-row gap-2 py-2">
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Expense Type :</h4>
                  <p>
                    {expense.expense_type ? (
                      <span>{expense.expense_type.type_name}</span>
                    ) : null}
                  </p>
                </div>
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Supplier :</h4>
                  <p>
                    {expense.supplier ? (
                      <span>{expense.supplier.supplier_name}</span>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2 py-2">
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Total Items :</h4>
                  <p>
                    {expense.item_number ? (
                      <span>{expense.item_number}</span>
                    ) : null}
                  </p>
                </div>
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Item Price :</h4>
                  <p>
                    {expense.item_price ? (
                      <span>{expense.item_price}</span>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2 py-2">
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Total Amount :</h4>
                  <p>{formatter.format(expense?.total_amount || 0)}</p>
                </div>
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Expense Date :</h4>
                  <p>{expense?.expense_date}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2 py-2">
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Payment Status :</h4>
                  <p>
                    {expense?.status === "not_paid" ? (
                      <span>Not Paid</span>
                    ) : (
                      <span className="capitalize">{expense.status}</span>
                    )}
                  </p>
                </div>
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Paid Amount :</h4>
                  <p>{formatter.format(expense?.paid_amount || 0)}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2 py-2">
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>Debt Amount :</h4>
                  <p>{formatter.format(expense?.unpaid_amount || 0)}</p>
                </div>
                <div className="flex flex-row gap-2 w-[50%]">
                  <h4>View :</h4>
                  <ViewExpenseAction expense={expense} />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p className="text-center">Expense has no details</p>;
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Box
            sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}
            // className="flex flex-row justify-between"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor={`${primary}`}
              indicatorColor="primary"
              sx={{ color: "#F24E1E" }}
            >
              <Tab label="EXPENSE DETAILS" {...a11yProps(0)} />
              <Tab label="EXPENSE PAYMENTS" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="flex flex-row justify-center pb-8">
              {renderExpenseDetails()}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ExpensesPayments />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default ExpenseDetails;
