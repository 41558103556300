import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, Grid } from "@mui/material";
import {
  addCustomerInvoices,
  selectCustomerDetails,
  selectCustomerInvoices,
} from "../../features/userSlice";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { getCustomerInvoices } from "../../api/users/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import { addInvoiceDetails } from "../../features/revenueSlice";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import CustomerInvoicesPDFCard from "../cards/CustomerInvoicesPDFCard";

const CustomerInvoices = () => {
  const dispatch = useDispatch();

  const { customerID } = useParams();
  const navigate = useNavigate();

  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all trucks
  const getInvoices = async () => {
    const response = await getCustomerInvoices({
      customer_id: customerID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addCustomerInvoices(response.data.data));
    }
  };

  useEffect(() => {
    getInvoices();
  }, [dispatch]);

  const invoices = useSelector(selectCustomerInvoices);
  const customer = useSelector(selectCustomerDetails);

  const sortedInvoices = invoices
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let formatterUS = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const unpaidInvoicesArray = invoices.filter((invoice) => {
    //filtering array value
    return invoice.payment_status !== "paid";
  });

  let invoicesTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;
  let invoicesTotalAmountUSD = 0;
  let paidTotalAmountUSD = 0;
  let unpaidTotalAmountUSD = 0;

  if (invoices.length !== 0) {
    for (const value of invoices) {
      invoicesTotalAmount += value.total_amount;
      paidTotalAmount += value.paid_amount;
      invoicesTotalAmountUSD += value.total_amount_usd;
      paidTotalAmountUSD += value.paid_amount_usd;
    }
  }

  if (unpaidInvoicesArray.length !== 0) {
    for (const value of invoices) {
      unpaidTotalAmount += value.unpaid_amount;
      unpaidTotalAmountUSD += value.unpaid_amount_usd;
    }
  }

  const renderDebt = (invoice) => {
    const debt = invoice?.total_amount - invoice?.paid_amount || 0;
    if (debt > 0) {
      return <p>{formatter.format(debt)}</p>;
    } else {
      return <p>Cleared</p>;
    }
  };

  const renderDebtUS = (invoice) => {
    const debt = invoice?.total_amount_usd - invoice?.paid_amount_usd || 0;
    if (debt > 0) {
      return <p>{formatterUS.format(debt)}</p>;
    } else {
      return <p>Cleared</p>;
    }
  };

  const handleInvoice = (invoice) => {
    //add invoice data to store
    dispatch(addInvoiceDetails(invoice));
    navigate(`/revenue/invoices/${invoice?.id}`);
  };

  const renderInvoices = () => {
    return (
      <div>
        <div className="">
          <div
            className={`bg-[#EEDEDE] h-10 text-center ${tables.customInvoiceTable} rounded-t-md font-semibold`}
          >
            <p className="py-2">No</p>
            <p className="py-2">Invoice No</p>
            <p className="py-2">Truck Number</p>
            <p className="py-2">Amount TZS</p>
            <p className="py-2">Amount USD</p>
            <p className="py-2">Date</p>
            <p className="py-2">Paid USD</p>
            <p className="py-2">Debt TZS</p>
            <p className="py-2">Debt USD</p>
            <p className="py-2">View</p>
          </div>
          <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
            {renderInvoice()}
          </div>
          <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
        </div>
      </div>
    );
  };

  const renderInvoice = () => {
    if (sortedInvoices.length !== 0) {
      return (
        <div>
          {sortedInvoices.map((invoice, index) => {
            // console.log(invoice);
            const position = index + 1;
            return (
              <div
                key={index}
                className={`h-10 bg-white ${tables.customInvoiceTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2">{invoice.invoice_number}</p>
                <p className="py-3">{invoice?.truck_reg_number}</p>
                <p className="py-2">{formatter.format(invoice.total_amount)}</p>
                <p className="py-2 pl-4">
                  {formatterUS.format(invoice.total_amount_usd)}
                </p>
                <p className="py-2">
                  {invoice?.invoice_date ? (
                    <>{invoice.invoice_date}</>
                  ) : (
                    <>{moment(invoice.created_at).format("YYYY-MM-DD")}</>
                  )}
                </p>
                <p className="py-2 pl-6">
                  {formatterUS.format(invoice?.paid_amount_usd || 0)}
                </p>
                <p className="py-2 pl-4">{renderDebt(invoice)}</p>
                <p className="py-2 pl-6">{renderDebtUS(invoice)}</p>
                <p
                  className="cursor-pointer py-2 text-center pl-4"
                  onClick={() => handleInvoice(invoice)}
                >
                  <RemoveRedEye
                    sx={{
                      color: colors.link,
                    }}
                  />
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p className="text-center">There are no issued invoices</p>;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex flex-row justify-center">
          <Box>
            <h5 className="text-xl text-center">
              {customer?.customer_name || ""}
            </h5>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            trip={false}
            title={"Total Invoices Amount"}
            value={invoicesTotalAmount}
            valueUSD={invoicesTotalAmountUSD}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            trip={false}
            title={"Total Paid Amount"}
            value={paidTotalAmount}
            valueUSD={paidTotalAmountUSD}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            trip={false}
            title={"Total Debt Amount"}
            value={unpaidTotalAmount}
            valueUSD={unpaidTotalAmountUSD}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
          <div className="flex flex-row justify-between pb-1">
              <h5>Expenses</h5>
              <div>
                <CustomerInvoicesPDFCard
                  invoices={sortedInvoices}
                  customer={customer}
                />
              </div>
            </div>
            <div className="pb-8 w-[100%]">{renderInvoices()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerInvoices;
