import toast from "react-hot-toast";

export const notifyToastError = ({ message, icon }) => {
  return toast.error(`${message}`, {
    icon: icon,
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

export const notifyToastSuccess = ({ message, icon }) => {
  return toast.success(`${message}`, {
    icon: icon,
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

export const notifyToastLoading = ({ message, icon }) => {
  return toast.loading(`${message}`, {
    icon: icon,
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

export const notifyToastPromise = ({
  promising,
  loadMessage,
  successText,
  errorText,
}) => {
  return toast.promise(promising, {
    loading: `${loadMessage}`,
    success: `${successText}`,
    error: `${errorText}`,
  });
};