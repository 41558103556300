import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BsCashCoin } from "react-icons/bs";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";
import {
  createSupplierBalance,
  getSupplier,
  getSupplierAllBalances,
} from "../../api/users/userAPI";
import {
  addSupplierBalances,
  addSupplierDetails,
} from "../../features/userSlice";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddBalance = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { supplierID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const getBalances = async () => {
    const response = await getSupplierAllBalances({
      token,
      supplier_id: supplierID,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addSupplierBalances(response.data.data));
      }
    }
  };

  const fetchSupplier = async () => {
    const response = await getSupplier({ supplierId: supplierID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addSupplierDetails(response.data.data));
      }
    }
  };

  const saveBalance = async (e) => {
    e.preventDefault();

    if (!amount) {
      notifyToastError({
        message: "Please enter amount",
        icon: "❗",
      });
    } else if (!date) {
      notifyToastError({
        message: "Please select date",
        icon: "❗",
      });
    } else {
      //change date format
      const balance_date = moment(date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createSupplierBalance({
        amount,
        date: balance_date,
        description,
        supplier_id: supplierID,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setAmount("");
        setDate("");
        setDescription("");
        getBalances();
        fetchSupplier();

        notifyToastSuccess({
          message: "Supplier balance is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => saveBalance(e)}
        >
          SAVE BALANCE
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[85px] w-[160px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="flex flex-row justify-center items-center pt-4">
          <BsCashCoin className="text-xl" />
        </div>
        <p className="text-lg font-semibold text-center">Add Balance</p>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            {/* <Toaster/> */}
            <h3 className="text-center text-xl py-4">Add Balance Details</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Total Amount"
                  variant="outlined"
                  className="w-[82%]"
                  type={"number"}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select date"
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[82%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddBalance;
