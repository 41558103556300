import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdLibraryAdd } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { addExpenseTypes, addItemTypes, selectExpenseTypes, selectItemTypes } from "../../features/settingSlice";
import {
  addRegisteredNames,
  addSuppliers,
  selectRegisteredNames,
  selectSuppliers,
} from "../../features/userSlice";
import {
  getAllRegisteredNames,
  getAllSuppliers,
} from "../../api/users/userAPI";
import { getAllExpenseTypes, getAllItemsTypes } from "../../api/settings/settingAPI";
import useAuth from "../../hooks/useAuth";
import { createStock, getAllStocks } from "../../api/stock/stockAPI";
import { addStocks } from "../../features/stockSlice";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const AddStock = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [item_type, setType] = useState("");
  const [order_person, setOrderName] = useState("");
  const [supplier, setSupplier] = useState("");
  const [quantity, setNumber] = useState("");
  const [item_price, setPrice] = useState("");
  const [total_amount, setAmount] = useState(0);
  const [amount, setExpenseAmount] = useState("");
  const [order_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const auth = useAuth();
  const token = auth?.token || "";

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  const typeOnChange = (e, value) => {
    setType(value);
  };

  const nameOnChange = (e, value) => {
    setOrderName(value);
  };

  const getStocks = async () => {
    const response = await getAllStocks({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addStocks(response.data.data));
    }
  };

  const getTypes = async () => {
    const response = await getAllExpenseTypes({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addExpenseTypes(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  const getNames = async () => {
    const response = await getAllRegisteredNames({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRegisteredNames(response.data.data));
    }
  };

  let formatter = new Intl.NumberFormat("en-uS");

  const getTotal = () => {
    let total = quantity * item_price;
    setAmount(total);
    if (isNumber(total)) {
      setExpenseAmount(formatter.format(total));
    }
  };

  useEffect(() => {
    getSuppliers();
    getTypes();
    getNames();
  }, [dispatch]);

  useEffect(() => {
    getTotal();
  }, [quantity, item_price]);

  const suppliers = useSelector(selectSuppliers);
  const allItemTypes = useSelector(selectExpenseTypes);
  const names = useSelector(selectRegisteredNames);

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));

  const sortedItemTypes = allItemTypes.map((item) => ({
    id: item.id,
    label: item.type_name,
  }));

  const sortedNames = names.map((name) => ({
    id: name.id,
    label: name.name,
  }));

  const saveOrder = async (e) => {
    e.preventDefault();

    if (!item_type) {
      notifyToastError({
        message: "Please select item type",
        icon: "❗",
      });
    } else if (!supplier) {
      notifyToastError({
        message: "Please select supplier",
        icon: "❗",
      });
    } else if (!quantity) {
      notifyToastError({
        message: "Please enter items quantity",
        icon: "❗",
      });
    } else if (!item_price) {
      notifyToastError({
        message: "Please enter item price",
        icon: "❗",
      });
    } else if (!total_amount) {
      notifyToastError({
        message: "Please enter total amount",
        icon: "❗",
      });
    } else if (!order_person) {
      notifyToastError({
        message: "Please enter order name",
        icon: "❗",
      });
    } else if (!order_date) {
      notifyToastError({
        message: "Please select stock date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(order_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createStock({
        item_id: item_type.id,
        supplier_id: supplier.id,
        quantity,
        price: item_price,
        total_price: total_amount,
        description,
        order_person: order_person?.id,
        order_date: date,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setType("");
        setSupplier("");
        setNumber("");
        setOrderName("");
        setPrice("");
        setAmount("");
        setDate(null);
        setDescription("");
        getStocks();

        notifyToastSuccess({
          message: "Stock is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => saveOrder(e)}
        >
          SAVE STOCK
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[85px] w-[160px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="flex flex-row justify-center items-center pt-4">
          <MdLibraryAdd className="text-xl" />
        </div>
        <p className="text-lg font-semibold text-center">Add Stock</p>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Add Stock Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedItemTypes}
                  size="small"
                  className="w-[40%]"
                  value={item_type}
                  onChange={typeOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Item" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[40%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedNames}
                  size="small"
                  className="w-[82%]"
                  value={order_person}
                  onChange={nameOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Order Name" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item quantity"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={quantity}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item price"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={item_price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="large"
                  id="outlined-basic"
                  label="Total amount"
                  variant="outlined"
                  className="w-[40%]"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select order date"
                    value={order_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[40%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddStock;
