import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { colors } from "../../assets/utils/colors";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { getSingleRouteInvoice } from "../../api/trips/tripsAPI";
import { Grid, IconButton } from "@mui/material";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { RemoveRedEye } from "@mui/icons-material";
import Transactions from "./Transactions";
import {
  addInvoiceDetails,
  addInvoiceTransactions,
  selectInvoiceDetails,
} from "../../features/revenueSlice";
import { getInvoiceTransactions } from "../../api/revenue/revenueAPI";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const ViewInvoiceAction = (invoice) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(trip);
  const addedTime = moment(invoice?.invoice.created_at).format("DD-MM-YYYY");
  const updateTime = moment(invoice?.invoice.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Invoice Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {invoice.invoice.description ? (
              <span>{invoice.invoice.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user?.role_name === "admin" || user?.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {invoice.invoice.added_by ? (
                  <span>
                    {invoice.invoice.added_by.first_name}{" "}
                    {invoice.invoice.added_by.middle_name}{" "}
                    {invoice.invoice.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({invoice.invoice.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {invoice.invoice.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {invoice.invoice.updated_by ? (
                  <span>
                    {invoice.invoice.updated_by.first_name}{" "}
                    {invoice.invoice.updated_by.middle_name}{" "}
                    {invoice.invoice.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({invoice.invoice.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {invoice.invoice.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const InvoiceDetails = () => {
  const [value, setValue] = useState(0);
  const auth = useAuth();
  const token = auth?.token || "";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { invoiceID } = useParams();
  const dispatch = useDispatch();

  const getInvoice = async () => {
    const response = await getSingleRouteInvoice({
      invoice_id: invoiceID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addInvoiceDetails(response.data.data));
      }
    }
  };

  const getTransactions = async () => {
    const response = await getInvoiceTransactions({
      invoice_id: invoiceID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addInvoiceTransactions(response.data.data));
      } else {
        dispatch(addInvoiceTransactions([]));
      }
    } else {
      dispatch(addInvoiceTransactions([]));
    }
  };

  useEffect(() => {
    getInvoice();
    getTransactions();
  }, [dispatch]);

  const invoice = useSelector(selectInvoiceDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let formatterUS = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const renderInvoiceDetails = () => {
    if (!isEmpty(invoice)) {
      return (
        <Card
          sx={{
            width: 650,
            height: 500,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">INVOICE DETAILS</h3>
            <div className="pt-4">
              <div className="text-lg px-6">
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Invoice Number :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {invoice.invoice_number ? (
                        <span>{invoice.invoice_number}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Amount TZS :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {invoice.total_amount ? (
                        <span>{formatter.format(invoice.total_amount)}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Amount USD :</p>
                    {""}
                    <span className="text-[#F24E1E] w-[70%]">
                      {invoice.total_amount_usd ? (
                        <span>
                          {formatterUS.format(invoice.total_amount_usd)}
                        </span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Issued Date :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%] capitalize">
                      {invoice?.invoice_date ? (
                        <span>{invoice?.invoice_date}</span>
                      ) : (
                        <span>
                          {moment(invoice.created_at).format("YYYY-MM-DD")}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[30%]">Status :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {invoice.status ? (
                        <span>
                          {invoice.status === "unpaid" ? (
                            <span>Not paid</span>
                          ) : (
                            <span className="capitalize">{invoice.status}</span>
                          )}
                        </span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Paid Amount TZS:</p>{" "}
                    <span className="text-[#F24E1E] w-[68%] capitalize">
                      {invoice.paid_amount ? (
                        <span>{formatter.format(invoice.paid_amount)}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Paid Amount USD:</p>{" "}
                    <span className="text-[#F24E1E] w-[68%] capitalize">
                      {invoice.paid_amount_usd ? (
                        <span>
                          {formatterUS.format(invoice.paid_amount_usd)}
                        </span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">Payment Date :</p>{" "}
                    <span className="text-[#F24E1E] w-[68%] capitalize">
                      {invoice.payment_date ? (
                        <span>{invoice.payment_date}</span>
                      ) : null}
                    </span>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[95%] py-2 flex flex-row gap-2">
                    <p className="w-[32%]">View Details :</p>{" "}
                    <p className="text-[#F24E1E] w-[68%]">
                      <ViewInvoiceAction invoice={invoice} />
                    </p>
                  </div>
                  <div className="w-[5%]"></div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p>Invoice has no details</p>;
    }
  };

  return (
    <>
      <Grid container sx={{ px: 1 }}>
        <Grid item sm={12}>
          <Box
            sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}
            // className="flex flex-row justify-between"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor={`${primary}`}
              indicatorColor="primary"
              sx={{ color: "#F24E1E" }}
            >
              <Tab label="INVOICE DETAILS" {...a11yProps(0)} />
              <Tab label="INVOICE TRANSACTIONS" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="flex flex-row justify-center pb-8">
              {renderInvoiceDetails()}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Transactions />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceDetails;
