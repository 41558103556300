import React, { Fragment, useState, useRef } from "react";
import { RemoveRedEye, Edit } from "@mui/icons-material";
import AddCustomer from "./AddCustomer";
import { useNavigate } from "react-router-dom";
import {
  getAllCustomers,
  deleteCustomer,
  toggleCustomerStatus,
} from "../../api/users/userAPI";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import EditCustomer from "./EditCustomer";
import { activeCustomerIdAtom } from "../../atoms/user";
import { useAtom } from "jotai";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { notifyToastError, notifyToastSuccess } from "../toast";
import CircularProgress from "@mui/material/CircularProgress";
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Chip, Divider, IconButton } from "@mui/material";
import { isEmpty } from "lodash";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomerDetails,
  addCustomers,
  addSearchedCustomers,
  selectSearchedCustomers,
} from "../../features/userSlice";
import { authenticationAtom } from "../../atoms/auth";
import useAuth from "../../hooks/useAuth";
import { Popover, Space, Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { selectRouteInvoices } from "../../features/tripsSlice";

const DeleteCustomer = ({ customer, onSuccess = () => {} }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getCustomers = async () => {
    const response = await getAllCustomers({ token });

    if (response) {
      if (!isEmpty(response.data)) {
        dispatch(addCustomers(response.data.data));
      }
    }
  };

  const handleDeleteCustomer = async (customer) => {
    if (!isEmpty(customer)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteCustomer({
        customerId: customer?.id,
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
        queryClient.invalidateQueries({ queryKey: ["customers"] });
      } else {
        notifyToastError({
          message: "Failed to delete customer, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "customer is successfully deleted",
          icon: "✅",
        });
        getCustomers();
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={28} />
            </>
          ) : (
            <>
              <IconButton onClick={handleClickOpen} disabled={isLoading}>
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </IconButton>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete customer"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete customer{" "}
              {customer?.customer_name || ""} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleDeleteCustomer(customer);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const ChangeCustomerStatusActions = ({
  customerId,
  status,
  handleClose = () => {},
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getCustomers = async () => {
    const response = await getAllCustomers({ token });

    if (response) {
      if (!isEmpty(response.data)) {
        dispatch(addCustomers(response.data.data));
      }
    }
  };

  const { mutate, isLoading, isError } = useMutation(toggleCustomerStatus, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["customers"] });
        queryClient.invalidateQueries({ queryKey: ["customers", customerId] });

        notifyToastSuccess({
          message: "Customer status is changed successfully",
          icon: "✅",
        });
        getCustomers();
        handleClose();
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong in creating changeing customer status");
      console.log(error);
    },
  });

  const toggleStatus = () => {
    if (
      window.confirm(
        "Are you sure you want to " +
          (status === "active"
            ? "deactivate this customer ?"
            : "activate this customer ?")
      )
    ) {
      mutate({ customerId, token });
    } else {
      alert("Action not permitted");
    }
  };

  return (
    <div className="">
      <div className="mt-2">
        <button
          onClick={() => {
            toggleStatus();
          }}
          disabled={isLoading}
          className={clsx(
            "px-2 py-1 font-semibold rounded-md bg-violet-400 text-gray-900",
            isLoading && "opacity-60"
          )}
        >
          {isLoading ? (
            <span className="flex items-center space-x-2">
              <p>Please Wait</p>

              <CircularProgress size={24} sx={{ color: "white" }} />
            </span>
          ) : (
            <>{status === "active" ? "Deactivate" : "Activate"}</>
          )}
        </button>
      </div>
    </div>
  );
};

const Customers = () => {
  const auth = useAuth();
  const token = auth?.token || "";

  const { isLoading, error, data } = useQuery(["customers"], () =>
    getAllCustomers({ token })
  );
  const [activeUserId, setActiveUserId] = useAtom(activeCustomerIdAtom);
  const [activeCustomer, setActiveCustomer] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [modalForm, setModalForm] = useState("");

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchCustomer, setSearchCustomer] = useState(false);
  const searchInput = useRef(null);

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  function closeModal() {
    setModalForm("");
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleSelectedCustomer = (customer) => {
    //get customer details the navigate to customer page
    dispatch(addCustomerDetails(customer));
    navigate(`/users/customers/${customer?.id}`);
  };

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const customers = data?.data?.data || [];
  const searchedCustomers = useSelector(selectSearchedCustomers);
  const invoices = useSelector(selectRouteInvoices);

  const unpaidInvoicesArray = invoices.filter((invoice) => {
    //filtering array value
    return invoice.payment_status !== "paid";
  });

  let invoicesTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;

  if (invoices.length !== 0) {
    for (const value of invoices) {
      invoicesTotalAmount += value.total_amount;
      paidTotalAmount += value.paid_amount;
    }
  }

  if (unpaidInvoicesArray.length !== 0) {
    for (const value of invoices) {
      unpaidTotalAmount += value.unpaid_amount;
    }
  }

  const handleSearch = () => {
    if (customers.length !== 0) {
      if (searchText) {
        const results = customers.filter((customer) => {
          //filtering array value
          return customer.customer_name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

        dispatch(addSearchedCustomers(results));
        setSearchCustomer(true);
      }
    }
  };

  const handleReset = () => {
    setSearchCustomer(false);
    dispatch(addSearchedCustomers([]));
    setSearchText("");
  };

  const customerData = () => {
    return (
      <div
        style={{
          padding: 4,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search customer`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() => handleSearch()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={hide}>
            close
          </Button>
        </Space>
      </div>
    );
  };

  const renderCustomers = () => {
    if (isLoading) return "Loading...";

    if (error) {
      console.log(error);
    }

    // const customers = data?.data?.data || [];

    return (
      <div className="">
        <Transition appear show={isOpen} as={Fragment}>
          <HeadlessDialog
            as="div"
            className="relative z-10"
            onClose={closeModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <HeadlessDialog.Panel className="w-full max-w-2xl transform  rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <HeadlessDialog.Title
                      as="h3"
                      className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900"
                    >
                      <span>Details</span>
                      <button
                        type="button"
                        className="text-white bg-red-700 hover:bg-red-800  font-medium rounded-sm text-sm px-5 py-2 mb-2 "
                        onClick={closeModal}
                      >
                        <XMarkIcon className="h-6 w-6 text-white" />
                      </button>
                    </HeadlessDialog.Title>
                    <div className="mt-2">
                      {modalForm === "update" && activeCustomer && (
                        <EditCustomer
                          customer={{
                            id: activeCustomer?.id || "",
                            customer_name: activeCustomer?.customer_name || "",
                            phone_number: activeCustomer?.phone_number || "",
                            email: activeCustomer?.email || "",
                            location: activeCustomer?.location || "",
                            tin: activeCustomer?.tin || "",
                            vrn: activeCustomer?.vrn || "",
                            customer_type: activeCustomer?.customer_type || "",
                            contact_person_phone:
                              activeCustomer?.contact_person_phone || "",
                            contact_person_name:
                              activeCustomer?.contact_person_name || "",
                            status: activeCustomer?.status || "",
                          }}
                          handleClose={closeModal}
                          formType="update"
                        />
                      )}
                    </div>
                    <div className="mt-4"></div>
                  </HeadlessDialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </HeadlessDialog>
        </Transition>

        <div className="text-slate-900">
          <div className="overflow-x-auto">
            <table className="min-w-full  bg-[#F0F0F0]">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24" />
              </colgroup>
              <thead className="bg-[#EEDEDE]">
                <tr className="text-left">
                  <th className="p-3">#</th>
                  <th className="p-3 flex -flex-row justify-between">
                    <span>Customer</span>{" "}
                    <Popover
                      placement="leftTop"
                      content={customerData()}
                      title="Search Customer"
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <p className="px-4 py-2 cursor-pointer">
                        <AiOutlineSearch />
                      </p>
                    </Popover>
                  </th>
                  <th className="p-3">Type</th>
                  <th className="p-3">Status</th>
                  <th className="p-3">Contact Person</th>
                  <th className="p-3">Location</th>
                  <th className="p-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {customers && customers?.length === 0 && (
                  <td className="p-3">
                    <td className="flex items-center justify-center py-4">
                      No customers saved
                    </td>
                  </td>
                )}
                {searchCustomer ? (
                  <>
                    {searchedCustomers.length !== 0 ? (
                      <>
                        {searchedCustomers.map((customer, index) => {
                          return (
                            <tr
                              key={customer?.id || ""}
                              className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                            >
                              <td className="p-3">
                                <p>{index + 1}</p>
                              </td>
                              <td className="p-3">
                                <p>{customer?.customer_name}</p>
                                <p>{customer?.phone_number}</p>
                                <p className="">{customer?.email}</p>
                              </td>
                              <td className="p-3">{customer?.customer_type}</td>
                              <td className="p-3 space-y-2">
                                <p className="">
                                  {customer?.status !== "active" ? (
                                    <span>Deactivated</span>
                                  ) : (
                                    <span>Active</span>
                                  )}
                                </p>
                                <p>
                                  <ChangeCustomerStatusActions
                                    customerId={customer?.id}
                                    status={customer?.status}
                                  />
                                </p>
                              </td>
                              <td className="p-3">
                                <p>{customer?.contact_person_name}</p>
                                {customer?.contact_person_phone}
                              </td>

                              <td className="p-3">{customer?.location}</td>

                              <td className="p-3 flex items-center space-x-2">
                                <IconButton
                                  aria-label="view"
                                  component="label"
                                  onClick={(e) => {
                                    handleSelectedCustomer(customer);
                                  }}
                                >
                                  <RemoveRedEye />
                                </IconButton>

                                <div>
                                  {user?.role_name !== "Clerk" ? (
                                    <IconButton
                                      aria-label="view"
                                      component="label"
                                      onClick={(e) => {
                                        setActiveUserId(customer?.id);
                                        setActiveCustomer(customer);
                                        setModalForm("update");
                                        openModal();
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  ) : (
                                    <span>
                                      <Edit className="cursor-not-allowed opacity-25" />
                                    </span>
                                  )}
                                </div>

                                <div>
                                  {user?.role_name !== "Clerk" ? (
                                    <DeleteCustomer customer={customer} />
                                  ) : (
                                    <MdDelete className="text-[#F24E1E] text-xl ml-0 cursor-not-allowed opacity-25" />
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <td className="p-3">
                        <td className="flex items-center justify-center py-4">
                          No customer available
                        </td>
                      </td>
                    )}
                  </>
                ) : (
                  <>
                    {customers &&
                      customers?.length > 0 &&
                      customers.map((customer, index) => {
                        return (
                          <tr
                            key={customer?.id || ""}
                            className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                          >
                            <td className="p-3">
                              <p>{index + 1}</p>
                            </td>
                            <td className="p-3">
                              <p>{customer?.customer_name}</p>
                              <p>{customer?.phone_number}</p>
                              <p className="">{customer?.email}</p>
                            </td>
                            <td className="p-3">{customer?.customer_type}</td>
                            <td className="p-3 space-y-2">
                              <p className="">
                                {customer?.status !== "active" ? (
                                  <span>Deactivated</span>
                                ) : (
                                  <span>Active</span>
                                )}
                              </p>
                              <p>
                                <ChangeCustomerStatusActions
                                  customerId={customer?.id}
                                  status={customer?.status}
                                />
                              </p>
                            </td>
                            <td className="p-3">
                              <p>{customer?.contact_person_name}</p>
                              {customer?.contact_person_phone}
                            </td>

                            <td className="p-3">{customer?.location}</td>

                            <td className="p-3 flex items-center space-x-2">
                              <IconButton
                                aria-label="view"
                                component="label"
                                onClick={(e) => {
                                  handleSelectedCustomer(customer);
                                }}
                              >
                                <RemoveRedEye />
                              </IconButton>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <IconButton
                                    aria-label="view"
                                    component="label"
                                    onClick={(e) => {
                                      setActiveUserId(customer?.id);
                                      setActiveCustomer(customer);
                                      setModalForm("update");
                                      openModal();
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                ) : (
                                  <span>
                                    <Edit className="cursor-not-allowed opacity-25" />
                                  </span>
                                )}
                              </div>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <DeleteCustomer customer={customer} />
                                ) : (
                                  <MdDelete className="text-[#F24E1E] text-xl ml-0 cursor-not-allowed opacity-25" />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row justify-center items-center w-[100%]">
        <AddCustomer />
      </div>
      <div className="w-[100%] py-3">
        <Divider>
          <Chip
            className="font-semibold"
            label={`TOTAL INVOICES AMOUNT: ${formatter.format(
              invoicesTotalAmount
            )}`}
          />{" "}
          <Chip
            className="font-semibold"
            label={`TOTAL PAID AMOUNT: ${formatter.format(paidTotalAmount)}`}
          />{" "}
          <Chip
            className="font-semibold"
            label={`TOTAL DEBT AMOUNT: ${formatter.format(unpaidTotalAmount)}`}
          />
        </Divider>
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>All Customers</h5>
          <div className="w-[100%] mb-12">{renderCustomers()}</div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
