import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaUserPlus } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from 'react-query'
import { createSupplier, getAllSuppliers } from "../../api/users/userAPI";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { addSuppliers } from "../../features/userSlice";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddSuppliers = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getSuppliers = async() => {
    const response = await getAllSuppliers({token});

    if(response){
      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addSuppliers(response.data.data));
        }
      }
    }
  }

  const [supplier_type, setSupplierType] = useState("");

  const queryClient = useQueryClient()

  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      contactPersonName: "",
      contactPersonPhone: "",
      location: ""
    }
  });

  const { mutate, isLoading, isError } = useMutation(createSupplier, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["suppliers"] })

        notifyToastSuccess({
          message: "Successffully added a new supplier",
          icon: "✅",
        });
        getSuppliers();
        handleClose();
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong in creating a customer")
      console.log(error)
    }
  })

  React.useEffect(() => {
    if (supplier_type === "Individual") {
      setValue("contactPersonName", "")
      setValue("contactPersonPhone", "")
    }
  }, [supplier_type])

  const onSubmit = data => {

    mutate({
      supplier_name: data?.fullName || "",
      phone_number: data?.phoneNumber || "",
      email: data?.email || "",
      supplier_type: supplier_type || "",
      contact_person_name: data?.contactPersonName || "",
      contact_person_phone: data?.contactPersonPhone || "",
      location: data?.location || "",
      token
    })

  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <p className="flex justify-center items-center">
            <FaUserPlus className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">Add Supplier</p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new supplier to existing suppliers list
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Add Supplier Details</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => <TextField
                    size="small"
                    id="outlined-basic"
                    label="Full name"
                    variant="outlined"
                    className="w-[82%]"
                    {...field}
                  />}
                />

              </div>
              <div className="w-full py-2 flex flex-row gap-4 justify-center">
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => <TextField
                    size="small"
                    id="outlined-basic"
                    label="Phone number"
                    variant="outlined"
                    className="w-[40%]"
                    {...field}
                  />}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => <TextField
                    size="small"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    className="w-[40%]"
                    {...field}
                  />}
                />

              </div>
              {supplier_type === "Company" ? (
                <>
                  <div className="w-full py-2 flex flex-row gap-2 justify-center">
                    <TextField
                      size="small"
                      id="outlined-select-currency"
                      select
                      label="Supplier type"
                      className="w-[82%]"
                      value={supplier_type}
                      onChange={(e) => {
                        setSupplierType(e.target.value || "")
                      }}
                    >
                      <MenuItem value={"Company"}>Company</MenuItem>
                      <MenuItem value={"Individual"}>Individual</MenuItem>
                    </TextField>
                  </div>
                  <div className="w-full py-2 flex flex-row gap-4 justify-center">
                    <Controller
                      name="contactPersonName"
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Contact Person Name"
                          variant="outlined"
                          className="w-[40%]"
                          {...field}
                        />}
                    />

                    <Controller
                      name="contactPersonPhone"
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          label="Contact Person Phone Number"
                          variant="outlined"
                          className="w-[40%]"
                          {...field}
                        />}
                    />
                  </div>
                </>
              ) : (
                <div className="w-full py-2 flex justify-center">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="Supplier type"
                    className="w-[82%]"
                    value={supplier_type}
                    onChange={(e) => {
                      setSupplierType(e.target.value || "")
                    }}
                  >
                    <MenuItem value={"Company"}>Company</MenuItem>
                    <MenuItem value={"Individual"}>Individual</MenuItem>
                  </TextField>
                </div>
              )}
              <div className="w-full py-2 flex justify-center">
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Location"
                      variant="outlined"
                      className="w-[82%]"
                      {...field}
                    />
                  }
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}
                  size="large" variant="contained" className="w-[82%]">
                  {isLoading ? "SAVING SUPPLIER ... " : "SAVE SUPPLIER"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddSuppliers;
