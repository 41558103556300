

//====================================== AUTHENTICATION  =================================================
export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const REGISTER_USER_URL = "/register";
export const UPDATE_USER_URL = "/customers";
export const CHANGE_PASSWORD_URL = "/change-password";
export const RESET_PASSWORD = "/reset-password";

//====================================== USERS  =================================================
export const CREATE_USER = "/register";
export const GET_ALL_USERS = "/users";
export const GET_SINGLE_USER = "/users";
export const ACTIVATE_USER = "/users/activate";
export const DEACTIVATE_USER = "/users/deactivate";
export const SUSPEND_USER = "/users";
export const UPDATE_USER_DETAILS = "/users";
export const UPDATE_USER_IMAGE = "/users";

export const CREATE_CUSTOMER = "/customers";
export const GET_ALL_CUSTOMERS = "/customers";
export const GET_SINGLE_CUSTOMER = "/customers";
export const UPDATE_CUSTOMER = "/customers";
export const DELETE_CUSTOMER = "/customers";
export const TOGGLE_CUSTOMER_STATUS = "/customers/status"
export const GET_CUSTOMER_INVOICES = "/invoices/customers"

export const CREATE_EMPLOYEE = "/employees";
export const GET_ALL_EMPLOYEES = "/employees";
export const GET_SINGLE_EMPLOYEE = "/employees";
export const UPDATE_EMPLOYEE = "/employees";
export const DELETE_EMPLOYEE = "/employees";
export const TOGGLE_EMPLOYEE_STATUS="/employees/status";

export const CREATE_SUPPLIER = "/suppliers";
export const GET_ALL_SUPPLIERS = "/suppliers";
export const GET_SINGLE_SUPPLIER = "/suppliers";
export const DELETE_SUPPLIER = "/suppliers";
export const UPDATE_SUPPLIER = "/suppliers";
export const TOGGLE_SUPPLIER_STATUS = "/suppliers/status";
export const GET_SUPPLIER_EXPENSES = "/expenses/suppliers";

export const CREATE_SUPPLIER_BALANCE = "/supplier-balances";
export const GET_ALL_SUPPLIER_BALANCES = "/supplier-balances";
export const DELETE_SUPPLIER_BALANCE = "/supplier-balances";
export const UPDATE_SUPPLIER_BALANCE = "/supplier-balances";

export const ADD_EMPLOYEE_GUARANTOR = "/employees/guarantors";
export const UPDATE_GUARANTOR = "/employees/guarantors";
export const DELETE_GUARANTOR = "/employees/guarantors";
export const GET_EMPLOYEE_GUARANTOR = "/employees/guarantors";

export const GET_ALL_DESIGNATIONS = "/designations";

export const CREATE_REGISTRATION_NAME = "/registration-names";
export const GET_ALL_REGISTRATION_NAMES = "/registration-names";
export const GET_SINGLE_REGISTRATION_NAME = "/registration-names";
export const DELETE_REGISTRATION_NAME = "/registration-names";
export const UPDATE_REGISTRATION_NAME = "/registration-names";


//====================================== TRUCKS AND TRAILERS  =================================================
export const CREATE_TRUCK = "/trucks";
export const GET_ALL_TRUCKS = "/trucks";
export const GET_SINGLE_TRUCK = "/trucks";
export const UPDATE_TRUCK_IMAGE = "/trucks";
export const UPDATE_TRUCK_DETAILS = "/trucks";
export const DELETE_TRUCK = "/trucks";


export const CREATE_TRAILER = "/trailers";
export const GET_ALL_TRAILERS = "/trailers";
export const GET_SINGLE_TRAILER = "/trailers";
export const UPDATE_TRAILER_IMAGE = "/trailers";
export const UPDATE_TRAILER_DETAILS = "/trailers";
export const DELETE_TRAILER = "/trailers";

export const ASSIGN_TRAILER_TO_TRUCK = "/trucks/assign-trailer";
export const UNASSIGN_TRAILER_TO_TRUCK = "/trucks/unassign-trailer";


//====================================== TRIPS  =================================================
export const CREATE_TRIP = "/trips";
export const UPDATE_TRIP = "/trips";
export const GET_ALL_TRIPS = "/trips";
export const GET_SINGLE_TRIP = "/trips";
export const DELETE_TRIP = "/trips";
export const END_TRIP = "/trips/end";


export const CREATE_ROUTE = "/routes";
export const UPDATE_ROUTE = "/routes";
export const GET_ALL_ROUTES = "/routes";
export const GET_SINGLE_ROUTE = "/routes";
export const DELETE_ROUTE = "/routes";
export const START_ROUTE = "/routes/start";
export const END_ROUTE = "/routes/close";
export const FAIL_ROUTE = "/routes/fail";

export const CREATE_ROUTE_EXPENSE = "/expenses";
export const UPDATE_ROUTE_EXPENSE = "/expenses";
export const GET_ALL_ROUTE_EXPENSES = "/expenses";
export const GET_SINGLE_ROUTE_EXPENSE = "/expenses";
export const DELETE_ROUTE_EXPENSE = "/expenses";

export const CREATE_ROUTE_INVOICE = "/invoices";
export const UPDATE_ROUTE_INVOICE = "/invoices";
export const GET_ALL_ROUTE_INVOICES = "/invoices";
export const GET_SINGLE_ROUTE_INVOICE = "/invoices";
export const DELETE_ROUTE_INVOICE = "/invoices";
export const GET_INVOICE_REPORT = "/invoices/reports";

export const CREATE_ROUTE_POSITION = "/positions";
export const UPDATE_ROUTE_POSITION = "/positions";
export const GET_ALL_ROUTE_POSITIONS = "/positions";
export const GET_SINGLE_ROUTE_POSITION = "/positions";
export const DELETE_ROUTE_POSITION = "/positions";

export const UPDATE_TRIP_DRIVER = "/trips/change-driver";
export const UPDATE_TRIP_TRUCK = "/trips/change-truck";

export const GET_CUSTOMER_ROUTES_POSITIONS = "/positions/customers";

//====================================== EXPENSES  =================================================
export const CREATE_PERSONAL_EXPENSE = "/personal-expenses";
export const UPDATE_PERSONAL_EXPENSE = "/personal-expenses";
export const GET_OWNER_PERSONAL_EXPENSE = "/personal-expenses/person";
export const GET_ALL_PERSONAL_EXPENSES = "/personal-expenses";
export const GET_SINGLE_PERSONAL_EXPENSE = "/personal-expenses";
export const DELETE_PERSONAL_EXPENSE = "/personal-expenses";

export const CREATE_EXPENSE_PAYMENT = "/expense-payments";
export const UPDATE_EXPENSE_PAYMENT = "/expense-payments";
export const GET_ALL_EXPENSES_PAYMENTS = "/expense-payments";
export const GET_SINGLE_EXPENSE_PAYMENT = "/expense-payments";
export const DELETE_EXPENSE_PAYMENT = "/expense-payments";
export const CREATE_MULTISUPPLIER_PAYMENT = "/expense-payments";

export const CREATE_SUPPLIER_TRANSFER_PAYMENT = "/balance-transfer";
export const DELETE_SUPPLIER_TRANSFER_PAYMENT = "/balance-transfer";

export const CREATE_TRUCK_MONTHLY_EXPENSE = "/monthly-expenses";
export const UPDATE_TRUCK_MONTHLY_EXPENSE = "/monthly-expenses";
export const GET_TRUCK_MONTHLY_EXPENSE = "/monthly-expenses/trucks";
export const GET_SUPPLIER_TRUCK_MONTHLY_EXPENSE = "/monthly-expenses/suppliers";
export const GET_ALL_TRUCK_MONTHLY_EXPENSES = "/monthly-expenses";
export const GET_SINGLE_TRUCK_MONTHLY_EXPENSE = "/monthly-expenses";
export const DELETE_TRUCK_MONTHLY_EXPENSE = "/monthly-expenses";

export const CREATE_MONTHLY_EXPENSE_PAYMENT = "/monthly-expense-payments";
export const UPDATE_MONTHLY_EXPENSE_PAYMENT = "/monthly-expense-payments";
export const GET_ALL_MONTHLY_EXPENSES_PAYMENTS = "/monthly-expense-payments";
export const GET_SINGLE_MONTHLY_EXPENSE_PAYMENT = "/monthly-expense-payments";
export const DELETE_MONTHLY_EXPENSE_PAYMENT = "/monthly-expense-payments";
export const CREATE_MONTHLY_MULTISUPPLIER_PAYMENT = "/monthly-expense-payments";

//====================================== REVENUE  =================================================
export const CREATE_TRANSACTION = "/transactions";
export const UPDATE_TRANSACTION = "/transactions";
export const GET_ALL_TRANSACTIONS = "/transactions";
export const GET_SINGLE_TRANSACTION = "/transactions";
export const GET_INVOICE_TRANSACTION = "/transactions/invoices";

export const GET_SUPPLIER_INVOICES_TRANSACTION = "/transactions/suppliers";

export const CREATE_PERSONAL_REVENUE = "/personal-revenues";
export const UPDATE_PERSONAL_REVENUE = "/personal-revenues";
export const GET_OWNER_PERSONAL_REVENUE = "/personal-revenues/person";
export const GET_ALL_PERSONAL_REVENUES = "/personal-revenues";
export const GET_SINGLE_PERSONAL_REVENUE = "/personal-revenues";
export const DELETE_PERSONAL_REVENUE = "/personal-revenues";


//====================================== ORDER & STOCK  =================================================
export const CREATE_ORDER = "/stock-orders";
export const UPDATE_ORDER = "/stock-orders";
export const GET_ALL_ORDERS = "/stock-orders";
export const GET_SUPPLIER_ORDERS = "/stock-orders/suppliers";
export const GET_SINGLE_ORDER = "/stock-orders";
export const DELETE_ORDER = "/stock-orders";

export const CREATE_ORDER_PAYMENT = "/order-payments";
export const UPDATE_ORDER_PAYMENT = "/order-payments";
export const GET_ALL_ORDER_PAYMENT = "/order-payments";
export const GET_SINGLE_ORDER_PAYMENT = "/order-payments";
export const CREATE_MULTIORDER_PAYMENT = "/order-payments";
export const DELETE_ORDER_PAYMENT = "/order-payments";

export const CREATE_STOCK = "/stocks";
export const UPDATE_STOCK = "/stocks";
export const GET_ALL_STOCKS = "/stocks";
export const GET_SINGLE_STOCK = "/stocks";
export const DELETE_STOCK = "/stocks";
export const DISPATCH_STOCK_ITEM = "/stocks/dispatch";

export const GET_TRUCKS_SUMMARY = "/trucks/routes/summaries";


//====================================== SETTINGS  =================================================

//Roles
export const CREATE_ROLE = "/roles";
export const GET_ALL_ROLES = "/roles";
export const GET_SINGLE_ROLE = "/roles";
export const UPDATE_ROLE = "/roles";
export const DELETE_ROLE = "/roles";

//Brands
export const CREATE_BRAND = "/brands";
export const GET_ALL_BRANDS = "/brands";
export const GET_SINGLE_BRAND = "/brands";
export const UPDATE_BRAND = "/brands";
export const DELETE_BRAND = "/brands";

//Expense types
export const CREATE_EXPENSE_TYPE = "/expense-types";
export const GET_ALL_EXPENSE_TYPES = "/expense-types";
export const GET_SINGLE_EXPENSE_TYPE = "/expense-types";
export const UPDATE_EXPENSE_TYPE = "/expense-types";
export const DELETE_EXPENSE_TYPE = "/expense-types";

//Payment types
export const CREATE_PAYMENT_TYPE = "/payment-types";
export const GET_ALL_PAYMENT_TYPES = "/payment-types";
export const GET_SINGLE_PAYMENT_TYPE = "/payment-types";
export const UPDATE_PAYMENT_TYPE = "/payment-types";
export const DELETE_PAYMENT_TYPE = "/payment-types";

//Designation types
export const CREATE_DESIGNATION_TYPE = "/designations";
export const GET_ALL_DESIGNATION_TYPES = "/designations";
export const GET_SINGLE_DESIGNATION_TYPE = "/designations";
export const UPDATE_DESIGNATION_TYPE = "/designations";
export const DELETE_DESIGNATION_TYPE = "/designations";

//Items types
export const CREATE_ITEM_TYPE = "/stock-items";
export const GET_ALL_ITEM_TYPES = "/stock-items";
export const GET_SINGLE_ITEM_TYPE = "/stock-items";
export const UPDATE_ITEM_TYPE = "/stock-items";
export const DELETE_ITEM_TYPE = "/stock-items";