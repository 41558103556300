import {
  CREATE_ROLE,
  CREATE_BRAND,
  GET_SINGLE_ROLE,
  GET_SINGLE_BRAND,
  GET_ALL_ROLES,
  GET_ALL_BRANDS,
  UPDATE_BRAND,
  DELETE_BRAND,
  UPDATE_ROLE,
  CREATE_EXPENSE_TYPE,
  GET_ALL_EXPENSE_TYPES,
  GET_SINGLE_EXPENSE_TYPE,
  UPDATE_EXPENSE_TYPE,
  DELETE_EXPENSE_TYPE,
  CREATE_PAYMENT_TYPE,
  GET_ALL_PAYMENT_TYPES,
  GET_SINGLE_PAYMENT_TYPE,
  UPDATE_PAYMENT_TYPE,
  DELETE_PAYMENT_TYPE,
  DELETE_ROLE,
  CREATE_DESIGNATION_TYPE,
  DELETE_DESIGNATION_TYPE,
  UPDATE_DESIGNATION_TYPE,
  GET_SINGLE_DESIGNATION_TYPE,
  GET_ALL_DESIGNATION_TYPES,
  DELETE_ITEM_TYPE,
  UPDATE_ITEM_TYPE,
  GET_SINGLE_ITEM_TYPE,
  GET_ALL_ITEM_TYPES,
  CREATE_ITEM_TYPE,
} from "../apiEndPoints";
import client from "../client";

export const createRole = async ({ role, description, token }) => {
  const response = await client.post(
    CREATE_ROLE,
    {
      role_name: role,
      role_description: description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllRoles = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ROLES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleRole = async ({ role_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ROLE}/${role_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteRole = async ({ role_id, token }) => {
  const response = await client.delete(
    `${DELETE_ROLE}/${role_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateRole = async ({
  role_name,
  role_description,
  role_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ROLE}/${role_id}`,
    {
      role_name,
      role_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createBrand = async ({
  brand_name,
  brand_type,
  brand_description,
  token,
}) => {
  const response = await client.post(
    CREATE_BRAND,
    {
      brand_name,
      brand_type,
      brand_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllBrands = async ({ token }) => {
  const response = await client.get(
    GET_ALL_BRANDS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getBrand = async ({ brand_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_BRAND}/${brand_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateBrand = async ({
  brand_name,
  brand_type,
  brand_description,
  brand_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_BRAND}/${brand_id}`,
    {
      brand_name,
      brand_type,
      brand_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteBrand = async ({ brand_id, token }) => {
  const response = await client.delete(
    `${DELETE_BRAND}/${brand_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createExpenseType = async ({
  type_name,
  type_description,
  token,
}) => {
  const response = await client.post(
    CREATE_EXPENSE_TYPE,
    {
      type_name,
      type_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllExpenseTypes = async ({ token }) => {
  const response = await client.get(
    GET_ALL_EXPENSE_TYPES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getExpenseType = async ({ expenseType_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_EXPENSE_TYPE}/${expenseType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateExpenseType = async ({
  type_name,
  type_description,
  expenseType_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_EXPENSE_TYPE}/${expenseType_id}`,
    {
      type_name,
      type_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteExpenseType = async ({ expenseType_id, token }) => {
  const response = await client.delete(
    `${DELETE_EXPENSE_TYPE}/${expenseType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createPaymentType = async ({
  payment_name,
  description,
  token,
}) => {
  const response = await client.post(
    CREATE_PAYMENT_TYPE,
    {
      payment_name,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllPaymentTypes = async ({ token }) => {
  const response = await client.get(
    GET_ALL_PAYMENT_TYPES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getPaymentType = async ({ paymentType_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_PAYMENT_TYPE}/${paymentType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updatePaymentType = async ({
  payment_name,
  description,
  paymentType_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_PAYMENT_TYPE}/${paymentType_id}`,
    {
      payment_name,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deletePaymentType = async ({ paymentType_id, token }) => {
  const response = await client.delete(
    `${DELETE_PAYMENT_TYPE}/${paymentType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createDesignationType = async ({
  designation_name,
  description,
  token,
}) => {
  const response = await client.post(
    CREATE_DESIGNATION_TYPE,
    {
      designation_name,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllDesignationTypes = async ({ token }) => {
  const response = await client.get(
    GET_ALL_DESIGNATION_TYPES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getDesignationType = async ({ designationType_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_DESIGNATION_TYPE}/${designationType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateDesignationType = async ({
  designation_name,
  description,
  designationType_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_DESIGNATION_TYPE}/${designationType_id}`,
    {
      designation_name,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteDesignationType = async ({ designationType_id, token }) => {
  const response = await client.delete(
    `${DELETE_DESIGNATION_TYPE}/${designationType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};


export const createItemType = async ({
  item_name,
  description,
  token,
}) => {
  const response = await client.post(
    CREATE_ITEM_TYPE,
    {
      item_name,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllItemsTypes = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ITEM_TYPES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getItemType = async ({ itemType_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ITEM_TYPE}/${itemType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateItemType = async ({
  item_name,
  description,
  itemType_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ITEM_TYPE}/${itemType_id}`,
    {
      item_name,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteItemType = async ({ itemType_id, token }) => {
  const response = await client.delete(
    `${DELETE_ITEM_TYPE}/${itemType_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

