import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationAtom } from '../../atoms/auth';
import useAuth from '../../hooks/useAuth';
import tables from '../../styles/tables.module.css';
import { RemoveRedEye } from '@mui/icons-material';
import { colors } from '../../assets/utils/colors';
import { Modal } from 'antd';
import { IconButton } from '@mui/material';
import { getOwnerPersonalRevenue } from '../../api/revenue/revenueAPI';
import { addOwnerRevenues, selectOwnerRevenues } from '../../features/revenueSlice';

const ViewRevenueAction = (description) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <>
            <IconButton variant="outlined" onClick={showModal}>
                <RemoveRedEye
                    sx={{
                        color: colors.link,
                    }}
                />
            </IconButton>
            <Modal
                title="Personal Expense Details"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                zIndex={800}
                okType="default"
                cancelButtonProps={{
                    className: 'hidden',
                }}
            >
                <div className="flex flex-col">
                    <p>
                        Description :{' '}
                        {description.description ? <span>{description.description}</span> : null}
                    </p>
                </div>
            </Modal>
        </>
    );
};

const RevenueMobileCard = ({
    revenue: { owner = '', amount = '', revenue_date = '', description },
    position,
}) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'TZS',
    });

    const middleName = owner.middle_name.charAt(0);

    return (
        <div className="shadow-md mt-4 border border-slate-800 px-2 py-2">
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">No</p>
                <p className="text-right">{position}</p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Name</p>
                <p className="text-right capitalize">
                    {owner.first_name} {middleName} {owner.last_name}
                </p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Revenue Amount</p>
                <p className="text-right">{formatter.format(amount)}</p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Revenue Date</p>
                <p className="text-right">{revenue_date}</p>
            </div>
            <div className="flex items-center justify-between">
                <p className="text-left font-semibold">Description</p>
                <p className="text-right">
                    <ViewRevenueAction description={description} />
                </p>
            </div>
        </div>
    );
};

const OwnerRevenue = () => {
    const dispatch = useDispatch();

    const [userData] = useAtom(authenticationAtom);
    const auth = useAuth();
    const { userInfo: user } = userData;

    //fetch all
    const getRevenue = async () => {
        const response = await getOwnerPersonalRevenue({
            owner_id: user?.id || '',
            token: auth?.token || '',
        });

        if (response.ok) {
            if (!isEmpty(response.data)) {
                dispatch(addOwnerRevenues(response.data.data));
            }
        }
    };

    useEffect(() => {
        getRevenue();
    }, [dispatch]);

    const revenues = useSelector(selectOwnerRevenues);
    // Sorting the revenues based on the 'date' property
    const sortedRevenues = revenues.slice().sort((a, b) => new Date(b.revenue_date) - new Date(a.revenue_date));

    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'TZS',
    });

    let personalRevenueTotalAmount = 0;
    if (revenues.length !== 0) {
        for (const value of revenues) {
            personalRevenueTotalAmount += value.amount;
        }
    }

    const renderRevenues = () => {
        return (
            <div>
                <div className="block md:hidden">
                    {sortedRevenues.length !== 0 ? (
                        <div>
                            {sortedRevenues.map((revenue, index) => {
                                const position = index + 1;
                                return (
                                    <RevenueMobileCard
                                        revenue={revenue}
                                        key={index}
                                        position={position}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <p className="text-center">
                            There are no personal revenue
                        </p>
                    )}
                </div>
                <div className="hidden md:block">
                    <div
                        className={`bg-[#EEDEDE] h-10 text-center ${tables.ownerPersonalExpenseTable} rounded-t-md font-semibold`}
                    >
                        <p className="py-2">No</p>
                        <p className="py-2">Owner Name</p>
                        <p className="py-2">Paid Amount</p>
                        <p className="py-2">Date</p>
                        <p className="py-2">Description</p>
                    </div>
                    <div className="bg-[#F0F0F0] h-[350px] overflow-y-scroll">
                        {renderRevenue()}
                    </div>
                    <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
                </div>
            </div>
        );
    };

    const renderRevenue = () => {
        if (sortedRevenues.length !== 0) {
            return (
                <div>
                    {sortedRevenues.map((revenue, index) => {
                        const position = index + 1;
                        return (
                            <div
                                key={index}
                                className={`bg-white h-10 ${tables.ownerPersonalExpenseTable} rounded-md text-sm my-2 mx-2`}
                            >
                                <p className="py-2 text-center">{position}</p>
                                <p className="py-2 text-center">
                                    {revenue.owner ? (
                                        <span>
                                            {revenue.owner.first_name}{' '}
                                            {revenue.owner.middle_name}{' '}
                                            {revenue.owner.last_name}
                                        </span>
                                    ) : null}
                                </p>
                                <p className="py-2 text-center">
                                    {formatter.format(revenue?.amount)}
                                </p>
                                <p className="py-2 text-center">
                                    {revenue?.revenue_date}
                                </p>
                                <p className="py-2 text-center text-sm">
                                    {revenue.description ? (
                                        <span>{revenue.description}</span>
                                    ) : null}
                                </p>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div>
                    <p className="text-center">
                        There are no personal revenues
                    </p>
                </div>
            );
        }
    };

    return (
        <div className="py-2 pl-4 pr-4 w-screen md:w-[calc(100vw-84px)] mx-auto">
            <div>
                <h4 className="text-xl text-center">PERSONAL REVENUES</h4>
                <h4 className="text-lg text-center">
                    {formatter.format(personalRevenueTotalAmount)}
                </h4>
            </div>
            <div className="py-2">
                <div className="w-[100%] pb-6 mt-0">{renderRevenues()}</div>
            </div>
        </div>
    );
};

export default OwnerRevenue;
