import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrands } from "../../api/settings/settingAPI";
import { isEmpty, map } from "lodash";
import { addTruckBrands, selectTruckBrands } from "../../features/settingSlice";
import { getAllTrailers, updateTrailer } from "../../api/trucks/trucksAPI";
import { Toaster } from "react-hot-toast";
import { addTrailers } from "../../features/truckSlice";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditTrailer = ({ trailer }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [brand_id, setBrand] = useState(trailer.brand_id);
  const [registration_name, setRegName] = useState(trailer.registration_name);
  const [registration_number, setRegNumber] = useState(
    trailer.registration_number
  );
  const [trailer_description, setDescription] = useState(
    trailer.trailer_description
  );

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all brands
  const getBrands = async () => {
    const response = await getAllBrands({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTruckBrands(response.data.data));
      }
    }
  };

  //fetch all trailers
  const getTrailers = async () => {
    const response = await getAllTrailers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrailers(response.data.data));
      }
    }
  };

  useEffect(() => {
    getBrands();
  }, [dispatch]);

  const brands = useSelector(selectTruckBrands);

  const truckBrands = map(brands, (brand) => {
    if ("brand_name" in brand) {
      if (brand.brand_type === "trailer") {
        return brand;
      }
    }
  });

  const filteredBrands = truckBrands.filter(function (x) {
    return x !== undefined;
  });

  const trailerRegistration = async (e) => {
    e.preventDefault();

    if (!brand_id) {
      notifyToastError({
        message: "Please select trailer brand",
        icon: "❗",
      });
    } else if (!registration_name) {
      notifyToastError({
        message: "Please enter trailer registration name",
        icon: "❗",
      });
    } else if (!registration_number) {
      notifyToastError({
        message: "Please enter trailer registration number",
        icon: "❗",
      });
    } else {
      //start registration
      setLoading(true);
      const response = await updateTrailer({
        trailer_id: trailer.id,
        brand_id,
        registration_name,
        registration_number,
        trailer_description,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getTrailers();
        setBrand("");
        setRegName("");
        setRegNumber("");
        setDescription("");

        notifyToastSuccess({
          message: "Trailer is updated successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          // sx={{ bgcolor: "#F24E1E" }}
          className="w-[82%]"
          onClick={(e) => trailerRegistration(e)}
        >
          UPDATE TRAILER
        </Button>
      );
    }
  };

  return (
    <div>
      <div>
        <ListItemIcon onClick={handleOpen}>
          <EditIcon fontSize="small" />
          <span>Edit</span>
        </ListItemIcon>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Toaster />
            <h3 className="text-center text-xl py-4">Edit Trailer Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Trailer Brand"
                  className="w-[82%]"
                  value={brand_id}
                  onChange={(e) => setBrand(e.target.value)}
                >
                  {filteredBrands !== 0
                    ? filteredBrands.map((brand, index) => (
                        <MenuItem key={index} value={brand.id}>
                          {brand.brand_name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Registration Name"
                  variant="outlined"
                  className="w-[40%]"
                  value={registration_name}
                  onChange={(e) => setRegName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Registration Number"
                  variant="outlined"
                  className="w-[40%]"
                  value={registration_number}
                  onChange={(e) => setRegNumber(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Trailer Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={trailer_description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditTrailer;
