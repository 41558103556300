import React, { useEffect, useState } from "react";
import { Switch, Space, Modal } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { colors } from "../../assets/utils/colors";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import TripRoute from "./TripRoute";
import { endTrip, getSingleTrip } from "../../api/trips/tripsAPI";
import { addTripDetails, selectTripDetails } from "../../features/tripsSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from "@mui/material";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { RemoveRedEye } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const EndTripAction = ({ trip, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const auth = useAuth();
  const token = auth?.token || "";

  const [checked, setChecked] = useState(
    trip?.trip_status === "open" ? true : false
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deactivateTrip = async (trip) => {
    if (!isEmpty(trip)) {
      setIsLoading(true);
      handleClose();
      const response = await endTrip({ trip_id: trip?.trip_id || "", token });

      if (response) {
        //handle response
        checkEndResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to end trip , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkEndResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Trip is successfully closed",
          icon: "✅",
        });
        setChecked(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={24} />
          </>
        ) : (
          <>
            <Space direction="vertical">
              <Switch
                checkedChildren="OFF"
                unCheckedChildren="ON"
                checked={checked}
                autoFocus
                onClick={handleClickOpen}
                className={checked ? null : `bg-[#F24E1E] rounded-full`}
              />
            </Space>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">End Trip</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want end this trip ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              deactivateTrip(trip);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ViewTripAction = (trip) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(trip);
  const addedTime = moment(trip?.trip.created_at).format("DD-MM-YYYY");
  const updateTime = moment(trip?.trip.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Trip Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {trip.trip.description ? (
              <span>{trip.trip.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user.role_name === "admin" || user.role_name === "Admin" ? (
            <>
              {" "}
              <p>
                Added by :{" "}
                {trip.trip.added_by ? (
                  <span>
                    {trip.trip.added_by.first_name}{" "}
                    {trip.trip.added_by.middle_name}{" "}
                    {trip.trip.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({trip.trip.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {trip.trip.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {trip.trip.updated_by ? (
                  <span>
                    {trip.trip.updated_by.first_name}{" "}
                    {trip.trip.updated_by.middle_name}{" "}
                    {trip.trip.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({trip.trip.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {trip.trip.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const Trip = () => {
  const [value, setValue] = useState(0);
  
  const auth = useAuth();
  const token = auth?.token || "";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { tripID } = useParams();
  const dispatch = useDispatch();

  const getTrip = async () => {
    const response = await getSingleTrip({ trip_id: tripID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTripDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getTrip();
  }, [dispatch]);

  const trip = useSelector(selectTripDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderTripDetails = () => {
    if (!isEmpty(trip)) {
      return (
        <Card
          sx={{
            width: 650,
            height: 520,
            background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1}) !important`,
          }}
        >
          <CardContent>
            <h3 className="text-center text-xl py-2">TRIP DETAILS</h3>
            <div className="pt-4">
              <div className="text-lg px-6">
                {trip?.trip_status === "open" ? (
                  <div className="flex flex-row">
                    <div className="w-[50%] py-2 flex flex-row gap-2">
                      <div className="w-[55%]">
                        <span class="mr-3">End Trip :</span>
                      </div>
                      <div className="w-[44%]">
                        <EndTripAction
                          trip={trip}
                          onSuccess={() => {
                            getTrip();
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-[50%] flex flex-row"></div>
                  </div>
                ) : null}
                <div className="py-2 flex flex-row">
                  <p className="w-[30%]">Trip Number :</p>{" "}
                  <span className="text-[#F24E1E] w-[70%]">
                    {trip.trip_number ? <span>{trip.trip_number}</span> : null}
                  </span>
                </div>
                <div className="py-2 flex flex-row">
                  <p className="w-[30%]">Truck Number :</p>{" "}
                  <span className="text-[#F24E1E] w-[70%]">
                    {trip.truck_registration_number ? (
                      <span>{trip.truck_registration_number}</span>
                    ) : null}
                  </span>
                </div>
                <div className="py-2 flex flex-row">
                  <p className="w-[30%]">Truck Driver :</p>{" "}
                  <span className="text-[#F24E1E] w-[70%]">
                    {trip.driver ? <span>{trip.driver}</span> : null}
                  </span>
                </div>
                <div className="flex flex-row gap-2">
                  <div className="w-[50%] py-2 flex flex-row gap-4">
                    <p className="w-[50%]">Start Date :</p>{" "}
                    <span className="text-[#F24E1E] w-[50%]">
                      {trip.start_date ? <span>{trip.start_date}</span> : null}
                    </span>
                  </div>
                  <div className="w-[50%] py-2 flex flex-row gap-2">
                    <p className="w-[50%]">End Date :</p>
                    {""}
                    <span className="text-[#F24E1E] w-[50%]">
                      {trip.end_date ? <span>{trip.end_date}</span> : null}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className="w-[50%] py-2 flex flex-row gap-4">
                    <p className="w-[50%]">Status :</p>{" "}
                    <span className="text-[#F24E1E] w-[50%]">
                      {trip.trip_status === "not_started" ? (
                        <span>Not started</span>
                      ) : trip.trip_status === "not_complete" ? (
                        <span>Failed</span>
                      ) : (
                        <span className="capitalize">{trip.trip_status}</span>
                      )}
                    </span>
                  </div>
                  <div className="w-[50%] py-2 flex flex-row">
                    <p className="w-[30%]">Routes :</p>{" "}
                    <span className="text-[#F24E1E] w-[70%]">
                      {trip.trip_routes ? (
                        <span>{trip.trip_routes.length}</span>
                      ) : (
                        <span>0</span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="py-2 flex flex-row gap-4">
                  <p className="w-[50%]">Total Expenses Amount :</p>{" "}
                  <span className="text-[#F24E1E] w-[50%]">
                    {trip.total_trip_expenses ? (
                      <span>{formatter.format(trip.total_trip_expenses)}</span>
                    ) : (
                      <span>{formatter.format(0)}</span>
                    )}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-4">
                  <p className="w-[50%]">Total Invoices Amount :</p>{" "}
                  <span className="text-[#F24E1E] w-[50%]">
                    {trip.total_trip_invoice ? (
                      <span>{formatter.format(trip.total_trip_invoice)}</span>
                    ) : (
                      <span>{formatter.format(0)}</span>
                    )}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-4">
                  <p className="w-[50%]">Total Profit Amount :</p>{" "}
                  <span className="text-[#F24E1E] w-[50%]">
                    {trip.total_trip_expenses && trip.total_trip_invoice ? (
                      <span>
                        {formatter.format(
                          trip.total_trip_invoice - trip.total_trip_expenses
                        )}
                      </span>
                    ) : (
                      <span>{formatter.format(0)}</span>
                    )}
                  </span>
                </div>
                <div className="py-2 flex flex-row gap-4">
                  <div className="w-[50%]">View Details :</div>{" "}
                  <div className="text-[#F24E1E] w-[50%]">
                    <ViewTripAction trip={trip} />
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      );
    } else {
      <p>Trip has no details</p>;
    }
  };

  return (
    <>
      <Grid container sx={{ px: 1 }}>
        <Grid item sm={12}>
          <Box
            sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}
            // className="flex flex-row justify-between"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor={`${primary}`}
              indicatorColor="primary"
              sx={{ color: "#F24E1E" }}
            >
              <Tab label="TRIP DETAILS" {...a11yProps(0)} />
              <Tab label="TRIP ROUTES" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="flex flex-row justify-center pb-8">
              {renderTripDetails()}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TripRoute />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default Trip;
