import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Modal } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { authenticationAtom } from "../../atoms/auth";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import { colors } from "../../assets/utils/colors";
import { notifyToastError, notifyToastSuccess } from "../toast";
import moment from "moment";
import AddPersonalRevenue from "./AddPersonalRevenue";
import { deletePersonalRevenue, getAllPersonalRevenues } from "../../api/revenue/revenueAPI";
import { addPersonalRevenues, selectPersonalRevenues } from "../../features/revenueSlice";
import EditPersonalRevenue from "./EditPersonalRevenue";

const ViewRevenueAction = ({revenue}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const addedTime = moment(revenue?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(revenue?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Personal Expense Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {revenue.description ? (
              <span>{revenue.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user?.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {revenue.added_by ? (
                  <span>
                    {revenue.added_by.first_name}{" "}
                    {revenue.added_by.middle_name}{" "}
                    {revenue.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({revenue.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {revenue.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {revenue.updated_by ? (
                  <span>
                    {revenue.updated_by.first_name}{" "}
                    {revenue.updated_by.middle_name}{" "}
                    {revenue.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({revenue.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {revenue.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const DeleteRevenueAction = ({ revenue, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const auth = useAuth();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRevenues = async () => {
    const response = await getAllPersonalRevenues({ token: auth?.token || "" });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addPersonalRevenues(response.data.data));
      }
    }
  };

  const handleDeleteRevenue = async (revenue) => {
    if (!isEmpty(revenue)) {
      setIsLoading(true);
      handleClose();
      const response = await deletePersonalRevenue({
        revenue_id: revenue?.id || "",
        token: auth?.token || "",
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete personal revenue , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Personal revenue is successfully deleted",
          icon: "✅",
        });
        getRevenues();
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete personal revenue ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete{" "}
            {revenue.owner ? (
              <span>
                {revenue.owner.first_name} {revenue.owner.last_name}
              </span>
            ) : null}{" "}
            personal revenue ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteRevenue(revenue);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const PersonalRevenue = () => {
  const dispatch = useDispatch();

  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();

  //fetch all
  const getRevenues = async () => {
    const response = await getAllPersonalRevenues({ token: auth?.token || "" });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addPersonalRevenues(response.data.data));
      }
    }
  };

  useEffect(() => {
    getRevenues();
  }, [dispatch]);

  const revenues = useSelector(selectPersonalRevenues);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const { userInfo: user } = userData;

  const renderEdit = (revenue) => {
    return (
      <>
        <EditPersonalRevenue revenue={revenue} />
      </>
    );
  };

  const renderDelete = (revenue) => {
    if (user?.role_name.toLowerCase() === "clerk") {
      return (
        <>
          <p className="cursor-not-allowed py-2 flex justify-center">
            <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
          </p>
        </>
      );
    } else {
      return (
        <>
          <DeleteRevenueAction
            revenue={revenue}
          />
        </>
      );
    }
  };

  const renderView = (revenue) => {
    return (
      <>
        <ViewRevenueAction revenue={revenue} />
      </>
    );
  };

  const renderRevenues = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.personalExpenseTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Owner Name</p>
          <p className="py-2">Paid Amount</p>
          <p className="py-2">Revenue Supplier</p>
          <p className="py-2">Date</p>
          <p className="py-2">Description</p>
          <p className="py-2">Actions</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderRevenue()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderRevenue = () => {
    if (revenues.length !== 0) {
      return (
        <div>
          {revenues.map((revenue, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.personalExpenseTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {revenue.owner ? (
                    <span>
                      {revenue.owner.first_name} {revenue.owner.middle_name}{" "}
                      {revenue.owner.last_name}
                    </span>
                  ) : null}
                </p>
                <p className="py-2 text-center">
                  {formatter.format(revenue?.amount || 0)}
                </p>
                <p className="py-2 pl-6">{revenue?.supplier?.supplier_name}</p>
                <p className="py-2 text-center">{revenue?.revenue_date}</p>
                <p className="py-2 text-center">
                  {revenue.description ? <span>{revenue.description}</span> : null}
                </p>
                <p className="flex flex-row justify-center space-x-1 pl-4">
                  {renderEdit(revenue)}
                  {renderDelete(revenue)}
                  {renderView(revenue)}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no personal revenue</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 2, py: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <AddPersonalRevenue/>
      </Grid>
      <Grid item sm={12}>
        <Box>
          <h5>Personal Revenue</h5>
          <div className="w-[100%] pb-6">
            {renderRevenues()}
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PersonalRevenue;
