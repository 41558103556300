import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { Button, IconButton } from "@mui/material";
import {
  createRoutePosition,
  getAllRoutes,
  getRoutePositions,
} from "../../api/trips/tripsAPI";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useDispatch } from "react-redux";
import { addRoutePositions, addTripRoutes } from "../../features/tripsSlice";
import { isEmpty } from "lodash";
import { MdEdit, MdLibraryAdd } from "react-icons/md";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddTrackerPosition = ({ onSuccess = () => {}, route, icon }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [position, setPosition] = useState("");
  const [position_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getPositions = async () => {
    const response = await getRoutePositions({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRoutePositions(response.data.data));
      }
    }
  };

  const getRoutes = async () => {
    const response = await getAllRoutes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTripRoutes(response.data.data));
      }
    }
  };

  const positionRegistration = async (e) => {
    e.preventDefault();

    if (!position) {
      notifyToastError({
        message: "Please enter position",
        icon: "❗",
      });
    } else if (!position_date) {
      notifyToastError({
        message: "Please select date",
        icon: "❗",
      });
    } else {
      if (!isEmpty(route)) {
        //change date format
        const date = moment(position_date).format("YYYY-MM-DD");
        //start registration
        setLoading(true);
        const response = await createRoutePosition({
          position,
          position_date: date,
          description,
          route_id: route.id,
          token,
        });

        if (response) {
          checkResponse(response);
        } else {
          setLoading(false);
          notifyToastError({
            message: "No response returned",
            icon: "❗",
          });
        }
      } else {
        notifyToastError({
          message: "Something went wrong, Please refresh and try again!",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setPosition("");
        setDate(null);
        setDescription("");
        getPositions();
        getRoutes();

        notifyToastSuccess({
          message: "Route position is saved successfully",
          icon: "✅",
        });
        onSuccess();
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => positionRegistration(e)}
        >
          SAVE POSITION
        </Button>
      );
    }
  };

  return (
    <div>
      {icon ? (
        <IconButton variant="outlined" onClick={handleOpen}>
          <MdLibraryAdd className="text-[#F24E1E] text-xl" />
        </IconButton>
      ) : (
        <IconButton variant="outlined" onClick={handleOpen}>
          <MdEdit className="text-[#F24E1E] text-xl" />
        </IconButton>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Add Truck Position</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Position"
                  variant="outlined"
                  className="w-[82%]"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select date"
                    value={position_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[82%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddTrackerPosition;
