import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Avatar, Box, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../assets/utils/colors";
import { WbCloudy, WbSunny, WbTwilight } from "@mui/icons-material";
import moment from "moment";
import { signOut } from "../api/users/userAPI"
import { notifyToastError, notifyToastSuccess } from "../pages/toast";
import CircularProgress from '@mui/material/CircularProgress';

import { useAtom } from "jotai";
import { authenticationAtom, initialAuthenticationState } from "../atoms/auth";
import MenuIcon from '@mui/icons-material/Menu';
import _ from "lodash";
import useAuth from "../hooks/useAuth"
import { useNavigate } from "react-router-dom";

// ################# CONSTANT ###############
const drawerWidth = 65;
const date = moment().format("ddd, DD MMMM");

// ################# GREETINGS ################
const currentTime = new Date();
currentTime.getHours();
const currentHour = currentTime.getHours();
let greetings = "Hi";
switch (true) {
  case currentHour < 12:
    greetings = "Good morning";
    break;
  case currentHour > 11 && currentHour < 17:
    greetings = "Good afternoon";
    break;
  default:
    greetings = "Good evening";
    break;
}

const renderIcons = () => {
  if (greetings === "Good morning") {
    return (
      <>
        <WbCloudy
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            color: colors.link,
          }}
        />
        <WbTwilight
          sx={{
            position: "absolute",
            right: 0,
            top: -2,
            color: colors.link,
          }}
        />
      </>
    );
  } else if (greetings === "Good afternoon") {
    return (
      <>
        <WbCloudy
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            color: colors.link,
          }}
        />
        <WbSunny
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: colors.link,
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <WbCloudy
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            color: colors.link,
          }}
        />
      </>
    );
  }
};

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "none",
  background: `linear-gradient(270deg, ${colors.bgColor1}, ${colors.bgColor3}) !important`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: colors.primary,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################

const SignOutButton = ({ handleClose = () => { } }) => {

  const auth = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const [userData, setUserData] = useAtom(authenticationAtom);


  const logout = () => {
    setIsLoading(true)

    // TODO: to be removed once figured out why the store is not being cleared
    signOut({ token: auth?.token })
      .then(res => {
        return res.data
      })
      .then(res => {

        if (res.success) {
          notifyToastSuccess({
            message: "Successfully signed out",
            icon: "✅",
          });
          setUserData((prev) => ({
            ...initialAuthenticationState
          }))
          handleClose()
        } else {
          notifyToastError({
            message: "The user could not be signed out please try again later",
            icon: "❗",
          });

        }

      })
      .catch(e => {
        notifyToastError({
          message: "Something went wrong in signing out",
          icon: "❗",
        });
        console.log("Soomething went wrong in signout out")
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return (
    <div>
      <MenuItem onClick={e => {
        e.preventDefault()
        logout()
      }}
        disabled={isLoading}

      >
        {isLoading ?
          <>
            <div className="flex items-center">
              <span>Logging out </span>
              <CircularProgress />
            </div>
          </>
          :
          (
            <>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </>
          )}
      </MenuItem>
    </div>
  )
}

const TopBar = ({ mobileOpen, handleDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const auth = useAuth();


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate(`/profile`);
  }


  const { authorization: userInfo } = auth

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
        }}
        className="px-2 py-2 w-screen md:w-full"
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          {/* ################### LEFT ################# */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {mobileOpen ? (
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  display: { xs: "block", sm: "none" },
                  marginTop: 1.3,
                }}
              >
                <CloseIcon className="text-slate-900 h-12 w-12" />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  display: { xs: "block", sm: "none" },
                  marginTop: 1.3,
                }}
              >
                <MenuIcon className="text-slate-900 h-12 w-12" />
              </IconButton>

            )}
            <Typography
              noWrap
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 18,
                color: colors.primary,
                fontWeight: "bold",
                ml: 1
              }}
            >
              Dashboard
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                position: "relative",
                width: 30,
                height: 30,
                mr: 1,
              }}
            >
              {renderIcons()}
            </Box>
            <Typography
              sx={{
                color: colors.primary,
                fontSize: 14,
                fontWeight: "bold",
                display: { xs: "none", sm: "block" },
              }}
            >
              {greetings}, {_.upperFirst(userInfo?.first_name || " ")} {_.upperFirst(userInfo?.last_name || " ")}
            </Typography>
          </Box>

          {/* ################### RIGHT ################# */}
          <TopbarRight
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Typography
              sx={{
                fontSize: 13,
                color: colors.primary,
                fontWeight: "bold",
                display: { xs: "none", sm: "block" },
              }}
            >
              {date}
            </Typography>
            <Avatar
              sx={{ border: `1px solid ${colors.bgColor2}` }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              className="cursor-pointer"
              onClick={handleClick}
            />
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleProfile}>
                <Avatar /> Profile
              </MenuItem>
              <Divider />
              <SignOutButton
                handleClose={handleClose}
              />
            </Menu>
          </TopbarRight>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
