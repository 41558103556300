import React, { useEffect, useState } from "react";
import { BsCashCoin } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";
import { createMultiMonthlyExpensePayment, getSupplierTruckMonthlyExpense } from "../../api/expenses/expensesAPI";
import { Select, Space, Modal } from "antd";
import { addSupplierTruckMonthlyExpenses, selectSupplierTruckMonthlyExpenses } from "../../features/expensesSlice";

const SupplierIndirectExpensePayment = ({ balance }) => {
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [supplier_expenses, setExpenses] = useState([]);
  const [expenseTotal, setTotal] = useState(0);

  const dispatch = useDispatch();
  const { supplierID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const expenseOnChange = (value) => {
    setExpenses(value);
    if (value.length !== 0) {
      if (value.length === 1) {
        const total = value[0].amount;
        setTotal(total);
      } else {
        const total = value.reduce(
          (accumulator, currentValue) => accumulator + currentValue.amount,
          0
        );
        setTotal(total);
      }
    } else {
      setTotal(0);
    }
  };

  const availableBalance = balance - expenseTotal;

  //fetch all expenses
  const getExpenses = async () => {
    const response = await getSupplierTruckMonthlyExpense({
      supplier_id: supplierID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSupplierTruckMonthlyExpenses(response.data.data));
    }
  };

  useEffect(() => {
    getExpenses();
  }, [dispatch]);

  const expenses = useSelector(selectSupplierTruckMonthlyExpenses);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let sortedExpenses = [];

  expenses.filter((expense) => expense?.unpaid_amount > 0)
    .forEach((expense, index) => {
      const position = index + 1;
      const { expense_date, truck, expense_name, unpaid_amount, id } = expense;
      const value = `${position} : ${expense_date} ${truck.registration_number} ${expense_name.toUpperCase()} 
      ${formatter.format(unpaid_amount)}`;

      sortedExpenses.push({
        id: id,
        value: value,
        amount: unpaid_amount,
      });
    });

  // console.log(sortedExpenses);

  const selectProps = {
    mode: "multiple",
    style: { width: "100%" },
    size: "large",
    value: supplier_expenses,
    options: sortedExpenses,
    onChange: (newValue, value) => {
      expenseOnChange(value);
    },
    placeholder: "Select Supplier Expenses",
    maxTagCount: 10,
  };

  function selectFewerProps(value) {
    return value.id;
  }

  const payExpense = async (e) => {
    e.preventDefault();

    if (!supplier_expenses) {
      notifyToastError({
        message: "Please select supplier expense",
        icon: "❗",
      });
    } else {
      //sort expense ids
      const expensesArray = supplier_expenses.map(selectFewerProps);
      //start payment
      setLoading(true);
      const response = await createMultiMonthlyExpensePayment({
        expense_ids: expensesArray,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setExpenses([]);
        getExpenses();

        notifyToastSuccess({
          message: "Supplier expenses are paid successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => payExpense(e)}
        >
          PAY SUPPLIER EXPENSES
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={showModal}
        className="h-[85px] w-[165px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="flex flex-row justify-center items-center pt-4">
          <BsCashCoin className="text-xl" />
        </div>
        <p className="font-semibold text-center">Expenses Payment</p>
      </div>

      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={850}
        closable={false}
        cancelButtonProps={{
          className: "hidden",
        }}
        okButtonProps={{
          className: "hidden",
        }}
      >
        <div>
          <h3 className="text-center text-xl py-4">Expenses Payment Details</h3>
          <p className="text-lg text-center pb-2">
            Available balance {formatter.format(availableBalance)}
          </p>
          <div>
            <div className="w-full py-2 flex justify-center">
              <Space direction="vertical" className="w-[82%]">
                <Select {...selectProps} />
              </Space>
            </div>
            <div className="w-full py-2 flex justify-center">
              {renderButton()}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SupplierIndirectExpensePayment;
