import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import MonthlyTripsCard from "../cards/MonthlyTripsCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import PaidInvoicesCard from "../cards/PaidInvoicesCard";
import PaidAmountCard from "../cards/PaidAmountCard";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import {
  addOrderDetails,
  addStockOrders,
  selectOrders,
} from "../../features/stockSlice";
import { deleteStockOrder, getAllStockOrders } from "../../api/stock/stockAPI";
import { MdDelete, MdEdit } from "react-icons/md";
import { notifyToastError, notifyToastSuccess } from "../toast";
import EditOrder from "./EditOrder";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import OrderPDFCard from "../cards/OrderPDFCard";

const DeleteOrderAction = ({ order, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOrder = async (order) => {
    if (!isEmpty(order)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteStockOrder({
        order_id: order?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete order , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Order is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete trip ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete order number{" "}
            {order?.order_number || ""} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteOrder(order);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useAuth();
  const token = auth?.token || "";
  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  //fetch all orders
  const getOrders = async () => {
    const response = await getAllStockOrders({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addStockOrders(response.data.data));
    } else {
      dispatch(addStockOrders([]));
    }
  };

  useEffect(() => {
    getOrders();
  }, [dispatch]);

  const orders = useSelector(selectOrders);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let ordersTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;

  let ordersArray = [];
  let paidOrdersArray = [];
  let unpaidOrdersArray = [];

  if (Array.isArray(orders)) {
    ordersArray = orders;

    if (orders.length !== 0) {
      for (const value of orders) {
        ordersTotalAmount += value.total_order_price;
        paidTotalAmount += value.paid_amount;
      }

      unpaidTotalAmount = ordersTotalAmount - paidTotalAmount;
    }

    paidOrdersArray = orders.filter((order) => {
      //filtering array value
      return order.status === "paid";
    });

    unpaidOrdersArray = orders.filter((order) => {
      //filtering array value
      return order.status !== "paid";
    });
  }

  const sortedOrders = ordersArray
    .slice()
    .sort((a, b) => new Date(b.order_date) - new Date(a.order_date));

  const handleOrder = (order) => {
    //add order data to store
    dispatch(addOrderDetails(order));
    navigate(`/inventory/orders/${order?.id}`);
  };

  const renderDelete = (order) => {
    if (user) {
      if (user.role_name === "Clerk" || user.role_name === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteOrderAction
              order={order}
              onSuccess={() => {
                getOrders();
              }}
            />
          </>
        );
      }
    }
  };

  const handleEdit = () => {
    notifyToastError({
      message: "Sorry! You can't edit direct or indirect orders",
      icon: "❗",
    });
  };

  const renderEdit = (order) => {
    if (order?.order_type === "stock") {
      return (
        <>
          <EditOrder order={order} />
        </>
      );
    } else {
      return (
        <IconButton variant="outlined" onClick={handleEdit}>
          <MdEdit className="text-[#F24E1E] text-xl cursor-not-allowed" />
        </IconButton>
      );
    }
  };

  const renderView = (order) => {
    return (
      <IconButton
        variant="outlined"
        className="cursor-pointer"
        onClick={() => handleOrder(order)}
      >
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
    );
  };

  const renderOrders = () => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.orderTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Order No</p>
              <p className="py-2">Order Name</p>
              <p className="py-2">Total Amount</p>
              <p className="py-2">Order Supplier</p>
              <p className="py-2">Order Type</p>
              <p className="py-2">Date</p>
              <p className="py-2">Status</p>
              <p className="py-2">Actions</p>
            </div>
            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
              {renderOrder()}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = () => {
    if (sortedOrders.length !== 0) {
      return (
        <div>
          {sortedOrders.map((order, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.orderTable} rounded-md text-sm my-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {order.order_number ? (
                    <span>{order.order_number}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-2">
                  {order.order_person ? (
                    <span>{order.order_person?.name}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-5">
                  {formatter.format(order.total_order_price)}
                </p>
                <p className="py-2 pl-4">
                  {order.supplier ? (
                    <span>{order.supplier.supplier_name}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-4">
                  {order.order_type ? <span>{order.order_type}</span> : null}
                </p>
                <p className="py-2 text-center">
                  {order.order_date ? <span>{order.order_date}</span> : null}
                </p>
                <p className="py-2 pl-6">
                  {order.payment_status === "not_paid" ? (
                    <span>Not paid</span>
                  ) : (
                    <span className="capitalize">{order.payment_status}</span>
                  )}
                </p>
                <p className="flex justify-center pl-4 py-1">
                  {renderEdit(order)}
                  {renderDelete(order)}
                  {renderView(order)}
                  <OrderPDFCard data={order} />
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no orders</p>
        </div>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            title={"Total Orders"}
            value={ordersArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            title={"Total Paid Orders"}
            value={paidOrdersArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            title={"Total Unpaid Orders"}
            value={unpaidOrdersArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <MonthlyTripsCard
            title={"Total Orders Amount"}
            value={formatter.format(ordersTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <PaidAmountCard
            title={"Total Paid Amount"}
            value={formatter.format(paidTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <PaidInvoicesCard
            title={"Total Debt Amount"}
            value={formatter.format(unpaidTotalAmount)}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Orders</h5>
            <div className="pb-8 w-[100%]">{renderOrders()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Orders;
