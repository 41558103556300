import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DatePicker, Input, Space, Popover } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { RemoveRedEye } from "@mui/icons-material";
import { MdDelete } from "react-icons/md";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import MonthlyTripsCard from "../cards/MonthlyTripsCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTrips } from "../../api/trips/tripsAPI";
import {
  addFilteredTrips,
  addTripDetails,
  addTrips,
  selectFilteredTrips,
  selectTrips,
} from "../../features/tripsSlice";
import { isEmpty } from "lodash";
import AddTrip from "./AddTrip";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import EditTrip from "./EditTrip";
import useAuth from "../../hooks/useAuth";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { deleteTrip } from "../../api/trips/tripsAPI";
import { Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";

const DeleteTripAction = ({ trip, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteTrip = async (trip) => {
    if (!isEmpty(trip)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteTrip({ trip_id: trip?.id || "", token });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete trip , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "trip is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={28} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete trip ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete trip number {trip?.trip_number || ""}{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteTrip(trip);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TripsAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  //fetch all trips
  const getTrips = async () => {
    const response = await getAllTrips({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrips(response.data.data));
      }
    }
  };

  useEffect(() => {
    getTrips();
  }, [dispatch]);

  const allTrips = useSelector(selectTrips);
  const filteredTrips = useSelector(selectFilteredTrips);

  //filter trips
  const now = moment();
  const monthTripsArray = allTrips.filter((trip) => {
    //filtering array value
    return moment(trip.start_date).isSame(now, "month");
  });

  const [userData] = useAtom(authenticationAtom);

  const progressTripsArray = allTrips.filter((trip) => {
    //filtering array value
    return trip.trip_status !== "closed" || trip.trip_status !== "failed";
  });

  const failedTripsArray = allTrips.filter((trip) => {
    //filtering array value
    return trip.trip_status === "failed";
  });

  const completedTripsArray = allTrips.filter((trip) => {
    //filtering array value
    return trip.trip_status === "closed";
  });

  const onYearChange = (date, dateString) => {
    // console.log(date);
    // console.log(dateString);
    const trips = allTrips.filter((trip) => {
      //filtering array value
      return moment(trip.start_date).format("YYYY") === dateString;
    });

    // console.log(trips);
    if (date) {
      dispatch(addFilteredTrips(trips));
      setFilter(true);
    } else {
      setFilter(false);
      dispatch(addFilteredTrips([]));
    }
  };

  const handleClearFilter = () => {
    setFilter(false);
    dispatch(addFilteredTrips([]));
  };

  const handleSelectedTrip = (trip) => {
    //get trip details the navigate to trip page
    dispatch(addTripDetails(trip));
    navigate(`/trips/${trip?.trip_id}`);
  };

  const { userInfo: user } = userData;

  const handleSearch = () => {
    if (filter) {
      if (filteredTrips.length !== 0) {
        if (searchText) {
          const results = filteredTrips.filter((trip) => {
            //filtering array value
            return trip.truck_registration_number
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase());
          });

          dispatch(addFilteredTrips(results));
          setFilter(true);
        }
      }
    } else {
      if (allTrips.length !== 0) {
        if (searchText) {
          const results = allTrips.filter((trip) => {
            //filtering array value
            return trip.truck_registration_number
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase());
          });

          dispatch(addFilteredTrips(results));
          setFilter(true);
        }
      }
    }
  };

  const handleReset = () => {
    setFilter(false);
    dispatch(addFilteredTrips([]));
    setSearchText("");
  };

  const truckData = () => {
    return (
      <div
        style={{
          padding: 4,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search user`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() => handleSearch()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={hide}>
            close
          </Button>
        </Space>
      </div>
    );
  };

  const renderTrips = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.tripTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2 flex flex-row gap-6 justify-center">
            <span className="pl-4">Truck</span>{" "}
            <Popover
              placement="leftTop"
              content={truckData()}
              title="Search Truck"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <p className="px-2 py-1.5 cursor-pointer">
                <AiOutlineSearch />
              </p>
            </Popover>
          </p>
          <p className="py-2">Truck Driver</p>
          <p className="py-2">Trip No</p>
          <p className="py-2">Start Date</p>
          <p className="py-2">End Date</p>
          <p className="py-2">Status</p>
          <p className="py-2">Actions</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderTrip()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderTrip = () => {
    if (!filter) {
      if (allTrips.length !== 0) {
        return (
          <div>
            {allTrips.map((trip, index) => {
              const position = index + 1;
              const nameArray = trip.driver.split(" ");
              const middleName = nameArray[1].charAt(0);
              return (
                <div
                  className={`h-10 bg-white ${tables.tripTable} rounded-md text-sm my-2 mx-1`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 text-center">
                    {trip.truck_registration_number}
                  </p>
                  <p className="py-2 pl-6 capitalize">
                    {nameArray[0]} {middleName} {nameArray[2]}
                  </p>
                  <p className="py-2 pl-6">{trip.trip_number}</p>
                  <p className="py-2 text-center">{trip.start_date}</p>
                  <p className="py-2 text-center">{trip.end_date}</p>
                  <p className="py-2 pl-8">
                    {" "}
                    <span className="">
                      {trip.trip_status === "not_started" ? (
                        <span>Not started</span>
                      ) : trip.trip_status === "not_complete" ? (
                        <span>Failed</span>
                      ) : (
                        <span className="capitalize">{trip.trip_status}</span>
                      )}
                    </span>
                  </p>
                  <p className="flex flex-row gap-1 justify-center pl-4">
                    {renderEdit(trip)}
                    {renderDelete(trip)}
                  </p>
                  <p className="text-center">{renderView(trip)}</p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return <p className="text-center">No trips available</p>;
      }
    } else {
      if (filteredTrips.length !== 0) {
        return (
          <div>
            {filteredTrips.map((trip, index) => {
              const position = index + 1;
              const nameArray = trip.driver.split(" ");
              const middleName = nameArray[1].charAt(0);
              return (
                <div
                  className={`h-10 bg-white ${tables.tripTable} rounded-md text-sm my-2 mx-1`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 text-center">
                    {trip.truck_registration_number}
                  </p>
                  <p className="py-2 pl-6 capitalize">
                    {nameArray[0]} {middleName} {nameArray[2]}
                  </p>
                  <p className="py-2 pl-6">{trip.trip_number}</p>
                  <p className="py-2 text-center">{trip.start_date}</p>
                  <p className="py-2 text-center">{trip.end_date}</p>
                  <p className="py-2 pl-8">
                    {" "}
                    <span className="">
                      {trip.trip_status === "not_started" ? (
                        <span>Not started</span>
                      ) : trip.trip_status === "not_complete" ? (
                        <span>Failed</span>
                      ) : (
                        <span className="capitalize">{trip.trip_status}</span>
                      )}
                    </span>
                  </p>
                  <p className="flex flex-row gap-1 justify-center pl-4">
                    {renderEdit(trip)}
                    {renderDelete(trip)}
                  </p>
                  <p className="text-center">{renderView(trip)}</p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return <p className="text-center">No trips available</p>;
      }
    }
  };

  const renderView = (trip) => {
    return (
      <>
        <IconButton
          variant="outlined"
          onClick={() => {
            handleSelectedTrip(trip);
          }}
        >
          <RemoveRedEye
            sx={{
              color: colors.link,
            }}
          />
        </IconButton>
      </>
    );
  };

  const renderEdit = (trip) => {
    return (
      <>
        <EditTrip
          trip={trip}
          onSuccess={() => {
            getTrips();
          }}
        />
      </>
    );
  };

  const renderDelete = (trip) => {
    if (user) {
      if (user.role_name === "Clerk" || user.role_name === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteTripAction
              trip={trip}
              onSuccess={() => {
                getTrips();
              }}
            />
          </>
        );
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <TripsProgressCard
            title={"Trips in progress"}
            value={progressTripsArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4}>
          <TripsCompletedCard
            title={"Trips completed"}
            value={completedTripsArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4}>
          <AddTrip />
        </Grid>
        <Grid item sm={4}>
          <MonthlyTripsCard
            title={"Trips this month"}
            value={monthTripsArray.length}
          />
        </Grid>
        <Grid item sm={4}>
          <TripsFailedCard
            title={"Trips failed"}
            value={failedTripsArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4}>
          <div className="bg-[white] h-[140px] w-[100%] rounded-md border-2 border-black shadow-xl">
            <p className="py-4 px-2 text-sm">
              Filter trucks trips based on year recorded
            </p>
            <div className="flex flex-row gap-2 justify-center px-4 py-2">
              <div className="w-[40%]">
                <h4 className="py-2 px-2">Filters</h4>
              </div>
              <div className="w-full">
                <Space direction="vertical">
                  <DatePicker onChange={onYearChange} picker="year" />
                </Space>
              </div>
              <div className="w-full">
                <Space direction="vertical">
                  <Button
                    className="bg-black rounded-md"
                    onClick={() => handleClearFilter()}
                  >
                    Clear filter
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item sm={12}>
          <div className="py-4 w-[100%]">
            <div className="w-[100%]">
              <div className="w-[100%]">{renderTrips()}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TripsAdmin;
