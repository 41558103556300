import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { Grid } from "@mui/material";
import AdminExpenses from "./AdminExpenses";
import PersonalExpenses from "./PersonalExpenses";
import MonthlyExpenses from "./MonthlyExpenses";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const Expenses = () => {
  const [value, setValue] = React.useState(0);

  const [userData] = useAtom(authenticationAtom);

  const renderUserTabs = () => {
    const { userInfo: user } = userData;

    if (!isEmpty(user)) {
      if (
        user.role_name.toLowerCase() === "accountant" ||
        user.role_name.toLowerCase() === "clerk" ||
        user.role_name.toLowerCase() === "admin"
      ) {
        return (
          <>
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
              }}
              className="flex flex-row justify-between"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor={`${primary}`}
                indicatorColor="primary"
                sx={{ color: "#F24E1E" }}
              >
                <Tab label="TRIPS EXPENSES" {...a11yProps(0)} />
                <Tab label="PERSONAL EXPENSES" {...a11yProps(1)} />
                <Tab label="TRUCK INDIRECT EXPENSES" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <AdminExpenses />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PersonalExpenses />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <MonthlyExpenses/>
            </TabPanel>
          </>
        );
      } else {
        return <div className="text-center text-lg">UNAUTHORIZED</div>;
      }
    } else {
      return <div className="text-center text-lg">UNAUTHORIZED</div>;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        {renderUserTabs()}
      </Grid>
    </Grid>
  );
};

export default Expenses;
