import React, { useRef, Fragment, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import AddSuppliers from "./AddSuppliers";
import { useAtom } from "jotai";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteSupplier,
  updateSupplier,
  toggleSupplierStatus,
  getAllSuppliers,
} from "../../api/users/userAPI";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { Chip, CircularProgress, Divider, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient, useQuery } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addSearchedSuppliers,
  addSupplierDetails,
  addSuppliers,
  selectSearchedSuppliers,
} from "../../features/userSlice";
import { authenticationAtom } from "../../atoms/auth";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Input, Popover, Space } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { selectRouteExpenses } from "../../features/tripsSlice";

const DeleteSupplier = ({ supplier, onSuccess = () => {} }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addSuppliers(response.data.data));
      }
    }
  };

  const handleDeleteSupplier = async (supplier) => {
    if (!isEmpty(supplier)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteSupplier({
        supplierId: supplier?.id,
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
        queryClient.invalidateQueries({ queryKey: ["suppliers"] });
      } else {
        notifyToastError({
          message: "Failed to delete supplier, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Supplier is successfully deleted",
          icon: "✅",
        });
        getSuppliers();
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={28} />
            </>
          ) : (
            <>
              <IconButton
                variant="outlined"
                onClick={handleClickOpen}
                disabled={isLoading}
              >
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </IconButton>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete supplier"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete {supplier?.supplier_name || ""}{" "}
              supplier?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleDeleteSupplier(supplier);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const ChangeSupplierStatusActions = ({
  supplierId,
  status,
  handleClose = () => {},
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addSuppliers(response.data.data));
      }
    }
  };

  const { mutate, isLoading } = useMutation(toggleSupplierStatus, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["suppliers"] });
        queryClient.invalidateQueries({ queryKey: ["suppliers", supplierId] });

        notifyToastSuccess({
          message: "Supplier status is changed successfully",
          icon: "✅",
        });
        getSuppliers();
        handleClose();
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong in creating changeing customer status");
      console.log(error);
    },
  });

  const toggleStatus = () => {
    if (
      window.confirm(
        "Are you sure you want to " +
          (status === "active"
            ? "deactivate this supplier ?"
            : "activate this supplier ?")
      )
    ) {
      mutate({ supplierId, token });
    } else {
      alert("Action not permitted");
    }
  };

  return (
    <div className="">
      <div className="mt-2">
        <button
          onClick={() => {
            toggleStatus();
          }}
          disabled={isLoading}
          className={clsx(
            "px-2 py-1 font-semibold rounded-md bg-violet-400 text-gray-900",
            isLoading && "opacity-60"
          )}
        >
          {isLoading ? (
            <span className="flex items-center space-x-2">
              <p>Please Wait</p>
              <CircularProgress size={24} sx={{ color: "white" }} />
            </span>
          ) : (
            <>{status === "active" ? "Deactivate" : "Activate"}</>
          )}
        </button>
      </div>
    </div>
  );
};

const EditSupplier = ({
  supplier = {
    id: "",
    supplier_name: "",
    phone_number: "",
    email: "",
    location: "",
    supplier_type: "",
    contact_person_phone: " ",
    contact_person_name: " ",
    status: "",
  },
}) => {
  const [supplier_type, setSupplierType] = useState(
    supplier.supplier_type || ""
  );

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addSuppliers(response.data.data));
      }
    }
  };

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      fullName: supplier.supplier_name || "",
      phoneNumber: supplier.phone_number || "",
      email: supplier.email || "",
      contactPersonName: supplier.contact_person_name || "",
      contactPersonPhone: supplier.contact_person_phone || "",
      location: supplier.location || "",
      id: supplier.id || "",
    },
  });

  const { mutate, isLoading } = useMutation(updateSupplier, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["suppliers"] });

        notifyToastSuccess({
          message: "Supplier is updated sucessfully",
          icon: "✅",
        });

        getSuppliers();
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong in creating a customer");
      console.log(error);
    },
  });

  React.useEffect(() => {
    if (supplier_type === "individual") {
      setValue("contactPersonName", "");
      setValue("contactPersonPhone", "");
    }
  }, [supplier_type]);

  const onSubmit = (data) => {
    mutate({
      supplier_name: data?.fullName || "",
      phone_number: data?.phoneNumber || "",
      email: data?.email || "",
      supplier_type: supplier_type || "",
      contact_person_name: data?.contactPersonName || "",
      contact_person_phone: data?.contactPersonPhone || "",
      location: data?.location || "",
      supplier_id: data?.id || "",
      token,
    });
  };

  return (
    <div>
      <div>
        <div className="w-full py-2 flex justify-center">
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Full name"
                variant="outlined"
                className="w-[82%]"
                {...field}
              />
            )}
          />
        </div>
        <div className="w-full py-2 flex flex-row gap-4 justify-center">
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Phone number"
                variant="outlined"
                className="w-[40%]"
                {...field}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                className="w-[40%]"
                {...field}
              />
            )}
          />
        </div>
        {supplier_type === "company" ? (
          <>
            <div className="w-full py-2 flex flex-row gap-2 justify-center">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label="Supplier type"
                className="w-[82%]"
                value={supplier_type}
                onChange={(e) => {
                  setSupplierType(e.target.value || "");
                }}
              >
                <MenuItem value={"company"}>Company</MenuItem>
                <MenuItem value={"individual"}>Individual</MenuItem>
              </TextField>
            </div>
            <div className="w-full py-2 flex flex-row gap-4 justify-center">
              <Controller
                name="contactPersonName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Contact Person Name"
                    variant="outlined"
                    className="w-[40%]"
                    {...field}
                  />
                )}
              />

              <Controller
                name="contactPersonPhone"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Contact Person Phone Number"
                    variant="outlined"
                    className="w-[40%]"
                    {...field}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <div className="w-full py-2 flex justify-center">
            <TextField
              size="small"
              id="outlined-select-currency"
              select
              label="Supplier type"
              className="w-[82%]"
              value={supplier_type}
              onChange={(e) => {
                setSupplierType(e.target.value || "");
              }}
            >
              <MenuItem value={"company"}>Company</MenuItem>
              <MenuItem value={"individual"}>Individual</MenuItem>
            </TextField>
          </div>
        )}
        <div className="w-full py-2 flex justify-center">
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Location"
                variant="outlined"
                className="w-[82%]"
                {...field}
              />
            )}
          />
        </div>
        <div className="w-full py-2 flex justify-center">
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            size="large"
            variant="contained"
            className="w-[82%]"
          >
            {isLoading ? "UPDATING SUPPLIER ... " : "UPDATE SUPPLIER"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const Suppliers = () => {
  const auth = useAuth();
  const token = auth?.token || "";
  const { isLoading, error, data } = useQuery(["suppliers"], () =>
    getAllSuppliers({ token })
  );

  const [activeSupplier, setActiveSupplier] = useState({});
  const [activeForm, setActiveForm] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchSupplier, setSearchUser] = useState(false);
  const searchInput = useRef(null);

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleSelectedSupplier = (supplier) => {
    //get supplier details the navigate to supplier page
    dispatch(addSupplierDetails(supplier));
    navigate(`/users/suppliers/${supplier?.id}`);
  };

  const suppliers = data?.data?.data || [];
  const searchedSuppliers = useSelector(selectSearchedSuppliers);
  const expenses = useSelector(selectRouteExpenses);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let expenseTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;

  if (Array.isArray(expenses)) {
    if (expenses.length !== 0) {
      for (const value of expenses) {
        expenseTotalAmount += value.total_amount;
        paidTotalAmount += value.paid_amount;
      }

      unpaidTotalAmount = expenseTotalAmount - paidTotalAmount;
    }
  }

  const handleSearch = () => {
    if (suppliers.length !== 0) {
      if (searchText) {
        const results = suppliers.filter((supplier) => {
          //filtering array value
          return supplier.supplier_name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

        dispatch(addSearchedSuppliers(results));
        setSearchUser(true);
      }
    }
  };

  const handleReset = () => {
    setSearchUser(false);
    dispatch(addSearchedSuppliers([]));
    setSearchText("");
  };

  const supplierData = () => {
    return (
      <div
        style={{
          padding: 4,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search supplier`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() => handleSearch()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={hide}>
            close
          </Button>
        </Space>
      </div>
    );
  };

  const renderSuppliers = () => {
    if (isLoading) return "Loading...";

    if (error) return "Something went wrong in loading suppliers";

    // const suppliers = data?.data?.data || [];

    return (
      <div className="">
        <Transition appear show={isOpen} as={Fragment}>
          <HeadlessDialog
            as="div"
            className="relative z-10"
            onClose={closeModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <HeadlessDialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <HeadlessDialog.Title
                      as="h3"
                      className="text-lg font-medium text-center my-4 leading-6 text-gray-900"
                    >
                      {activeForm === "view" && "View Supplier Details"}
                      {activeForm === "update" && "Update Supplier Details"}
                    </HeadlessDialog.Title>
                    <div className="mt-2">
                      {activeForm === "update" && activeSupplier && (
                        <EditSupplier supplier={activeSupplier} />
                      )}
                    </div>

                    <div className="mt-4">
                      <div className="absolute top-4 right-4">
                        <button
                          type="button"
                          className="text-white bg-red-700 hover:bg-red-800  font-medium rounded-sm text-sm px-5 py-2 mb-2 "
                          onClick={closeModal}
                        >
                          <XMarkIcon className="h-6 w-6 text-white" />
                        </button>
                      </div>
                    </div>
                  </HeadlessDialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </HeadlessDialog>
        </Transition>

        <div className="text-slate-900">
          <div className="overflow-x-auto">
            <table className="min-w-full  bg-[#F0F0F0]">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead className="bg-[#EEDEDE]">
                <tr className="text-left">
                  <th className="p-3">#</th>
                  <th className="p-3 flex -flex-row justify-between">
                    <span>Supplier</span>{" "}
                    <Popover
                      placement="leftTop"
                      content={supplierData()}
                      title="Search Supplier"
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <p className="px-4 py-2 cursor-pointer">
                        <AiOutlineSearch />
                      </p>
                    </Popover>
                  </th>
                  <th className="p-3">Type</th>
                  <th className="p-3">Contact Person</th>
                  <th className="p-3">Location</th>
                  <th className="p-3">Status</th>
                  <th className="p-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {suppliers && suppliers?.length === 0 && (
                  <td className="p-3">
                    <td
                      colSpan={5}
                      className="flex items-center justify-center py-4"
                    >
                      No suppliers saved
                    </td>
                  </td>
                )}
                {searchSupplier ? (
                  <>
                    {searchedSuppliers.length > 0 ? (
                      <>
                        {searchedSuppliers.map((supplier, index) => {
                          return (
                            <tr
                              key={supplier?.id || ""}
                              className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                            >
                              <td className="p-3">
                                <p>{index + 1}</p>
                              </td>
                              <td className="p-3">
                                <p>{supplier?.supplier_name}</p>
                                <p className="">{supplier?.phone_number}</p>
                                <p className="">{supplier?.email}</p>
                              </td>
                              <td className="p-3">
                                <p>{supplier?.supplier_type}</p>
                              </td>
                              <td className="p-3">
                                <p>{supplier?.contact_person_name}</p>
                                <p>{supplier?.contact_person_phone}</p>
                              </td>
                              <td className="p-3">{supplier?.location}</td>
                              <td className="p-3 space-y-2">
                                <p className="">
                                  {supplier?.status !== "active" ? (
                                    <span>Deactivated</span>
                                  ) : (
                                    <span>Activated</span>
                                  )}
                                </p>
                                <p>
                                  <ChangeSupplierStatusActions
                                    supplierId={supplier?.id}
                                    status={supplier?.status}
                                  />
                                </p>
                              </td>

                              <td className="p-3 flex items-center space-x-2">
                                <IconButton
                                  onClick={() => {
                                    handleSelectedSupplier(supplier);
                                  }}
                                >
                                  <RemoveRedEye />
                                </IconButton>

                                <div>
                                  {user?.role_name !== "Clerk" ? (
                                    <IconButton
                                      onClick={() => {
                                        // setActiveSupplierId(supplier?.id);
                                        setActiveSupplier(supplier);
                                        setActiveForm("update");
                                        openModal();
                                      }}
                                    >
                                      <MdEdit />
                                    </IconButton>
                                  ) : (
                                    <span>
                                      <MdEdit className="cursor-not-allowed opacity-25 text-xl" />
                                    </span>
                                  )}
                                </div>

                                <div>
                                  {user?.role_name !== "Clerk" ? (
                                    <DeleteSupplier supplier={supplier} />
                                  ) : (
                                    <MdDelete className="cursor-not-allowed opacity-25 text-[#F24E1E] text-xl ml-0" />
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <td className="p-3">
                        <td
                          colSpan={5}
                          className="flex items-center justify-center py-4"
                        >
                          No suppliers available
                        </td>
                      </td>
                    )}
                  </>
                ) : (
                  <>
                    {suppliers &&
                      suppliers?.length > 0 &&
                      suppliers.map((supplier, index) => {
                        return (
                          <tr
                            key={supplier?.id || ""}
                            className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                          >
                            <td className="p-3">
                              <p>{index + 1}</p>
                            </td>
                            <td className="p-3">
                              <p>{supplier?.supplier_name}</p>
                              <p className="">{supplier?.phone_number}</p>
                              <p className="">{supplier?.email}</p>
                            </td>
                            <td className="p-3">
                              <p>{supplier?.supplier_type}</p>
                            </td>
                            <td className="p-3">
                              <p>{supplier?.contact_person_name}</p>
                              <p>{supplier?.contact_person_phone}</p>
                            </td>
                            <td className="p-3">{supplier?.location}</td>
                            <td className="p-3 space-y-2">
                              <p className="">
                                {supplier?.status !== "active" ? (
                                  <span>Deactivated</span>
                                ) : (
                                  <span>Activated</span>
                                )}
                              </p>
                              <p>
                                <ChangeSupplierStatusActions
                                  supplierId={supplier?.id}
                                  status={supplier?.status}
                                />
                              </p>
                            </td>

                            <td className="p-3 flex items-center space-x-2">
                              <IconButton
                                onClick={() => {
                                  handleSelectedSupplier(supplier);
                                }}
                              >
                                <RemoveRedEye />
                              </IconButton>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <IconButton
                                    onClick={() => {
                                      // setActiveSupplierId(supplier?.id);
                                      setActiveSupplier(supplier);
                                      setActiveForm("update");
                                      openModal();
                                    }}
                                  >
                                    <MdEdit />
                                  </IconButton>
                                ) : (
                                  <span>
                                    <MdEdit className="cursor-not-allowed opacity-25 text-xl" />
                                  </span>
                                )}
                              </div>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <DeleteSupplier supplier={supplier} />
                                ) : (
                                  <MdDelete className="cursor-not-allowed opacity-25 text-[#F24E1E] text-xl ml-0" />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row justify-center items-center w-[100%]">
        <AddSuppliers />
      </div>
      <div className="w-[100%] py-3">
        <Divider>
          <Chip
            className="font-semibold"
            label={`TOTAL DEBT: ${formatter.format(expenseTotalAmount)}`}
          />{" "}
          <Chip
            className="font-semibold"
            label={`TOTAL PAID AMOUNT: ${formatter.format(paidTotalAmount)}`}
          />{" "}
          <Chip
            className="font-semibold"
            label={`TOTAL UNPAID AMOUNT: ${formatter.format(
              unpaidTotalAmount
            )}`}
          />
        </Divider>
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>All Suppliers</h5>
          <div className="w-[100%]">{renderSuppliers()}</div>
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
