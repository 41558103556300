import React, { useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { MdEdit } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { getSingleStockOrder, updateOrderPayment } from "../../api/stock/stockAPI";
import { addOrderDetails } from "../../features/stockSlice";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditOrderPayment = ({ payment }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [amount_paid, setAmount] = useState(payment?.amount_paid);
  const [payment_date, setDate] = useState(moment(payment?.payment_date));
  const [description, setDescription] = useState(payment?.description);

  const dispatch = useDispatch();
  const { orderID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  const getOrder = async () => {
    const response = await getSingleStockOrder({
      order_id: orderID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addOrderDetails(response.data.data));
      }
    }
  };


  const transactionRegistration = async (e) => {
    e.preventDefault();

    if (!amount_paid) {
      notifyToastError({
        message: "Please enter paid amount",
        icon: "❗",
      });
    } else if (!payment_date) {
      notifyToastError({
        message: "Please select date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(payment_date).format("YYYY-MM-DD");
      if (!isEmpty(payment)) {
        //start registration
        setLoading(true);
        const response = await updateOrderPayment({
          amount_paid,
          payment_date: date,
          description,
          order_payment_id: payment.id,
          token,
        });

        if (response) {
          checkResponse(response);
        } else {
          setLoading(false);
          notifyToastError({
            message: "No response returned",
            icon: "❗",
          });
        }
      } else {
        notifyToastError({
          message: "Sorry refresh page and try again",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getOrder();

        notifyToastSuccess({
          message: "Order payment is updated successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => transactionRegistration(e)}
        >
          UPDATE ORDER PAYMENT
        </Button>
      );
    }
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleOpen}>
        <MdEdit className="text-[#F24E1E] text-xl" />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">
              Edit Order Payment Details
            </h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Paid Amount"
                  variant="outlined"
                  type={"number"}
                  className="w-[82%]"
                  value={amount_paid}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select payment date"
                    value={payment_date}
                    size="small"
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[82%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditOrderPayment;
