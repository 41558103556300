import React from "react";
import moment from "moment/moment";
import tables from "../styles/tables.module.css";
import TodayTripsCard from "./cards/TodayTripsCard";
import TodayRoutesCard from "./cards/TodayRoutesCard";
import TodayLocationsCard from "./cards/TodayLocationsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRoutes,
  getAllTrips,
  getRoutePositions,
} from "../api/trips/tripsAPI";
import { isEmpty } from "lodash";
import {
  addRoutePositions,
  addTripRoutes,
  addTrips,
  selectRoutePositions,
  selectTripRoutes,
  selectTrips,
} from "../features/tripsSlice";
import { useEffect } from "react";
import AddTrackerPosition from "./trips/AddTrackerPosition";
import { Box, Grid, IconButton } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../assets/utils/colors";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const TrackerOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth?.token || "";

  const getPositions = async () => {
    const response = await getRoutePositions({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRoutePositions(response.data.data));
      }
    }
  };

  const getTrips = async () => {
    const response = await getAllTrips({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrips(response.data.data));
      }
    }
  };

  const getRoutes = async () => {
    const response = await getAllRoutes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTripRoutes(response.data.data));
      }
    }
  };

  useEffect(() => {
    getPositions();
    getRoutes();
    getTrips();
  }, [dispatch]);

  const positions = useSelector(selectRoutePositions);
  const trips = useSelector(selectTrips);
  const routes = useSelector(selectTripRoutes);

  const handleSelectedRoute = (route) => {
    //get route details the navigate to route page;
    navigate(`/dashboard/${route?.id}`);
  };

  const now = moment();

  let tripsArray = [];
  if (Array.isArray(trips)) {
    if (trips.length !== 0) {
      tripsArray = trips.filter((trip) => {
        //filtering array value
        return moment(trip.start_date).isSame(now, "day");
      });
    }
  }

  let positionsArray = [];
  if (Array.isArray(positions)) {
    if (positions.length !== 0) {
      positionsArray = positions.filter((position) => {
        //filtering array value
        return moment(position.created_at).isSame(now, "day");
      });
    }
  }

  let routesArray = [];
  let activeRoutes = [];
  if (Array.isArray(routes)) {
    if (routes.length !== 0) {
      routesArray = routes.filter((route) => {
        //filtering array value
        return moment(route.start_date).isSame(now, "day");
      });

      activeRoutes = routes.filter((route) => {
        return route.status === "open";
      });

      if (activeRoutes.length !== 0) {
        activeRoutes.reverse();
      }
    }
  }

  const renderCurrentTrips = () => {
    return (
      <div className="py-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.trackerRouteTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Initial Location</p>
          <p className="py-2">Final Location</p>
          <p className="py-2">Truck</p>
          <p className="py-2">Customer Name</p>
          <p className="py-2">Current Position</p>
          <p className="py-2">Date</p>
          <p className="py-2">Action</p>
        </div>
        <div className="bg-[#F0F0F0] h-60 overflow-y-scroll">
          {renderRoutes()}
        </div>
        <div className="h-12 w-[100%] bg-gradient-to-r from-[#EEDEDE] to-[#F0F0F0] rounded-b-md"></div>
      </div>
    );
  };

  const renderRoutes = () => {
    if (activeRoutes.length !== 0) {
      return (
        <div>
          {activeRoutes.map((route, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.trackerRouteTable} rounded-md text-sm my-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 pl-8">{route.initial_location}</p>
                <p className="py-2 pl-8">{route.final_location}</p>
                <p className="py-2 text-center">
                  {route.route_trip ? (
                    <span>{route.route_trip.truck_registration_number}</span>
                  ) : (
                    <span></span>
                  )}
                </p>
                <p className="py-2 text-center">
                  {route.route_customer?.customer_name}
                </p>
                <p className="py-2 pl-8">{route.current_position}</p>
                <p className="py-2 text-center">{renderDate(route)}</p>
                <p className="flex flex-row justify-center pl-4">
                  <AddTrackerPosition route={route} />
                  {renderViewRoute(route)}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p className="text-center">There are no active routes</p>;
    }
  };

  const renderViewRoute = (route) => {
    return (
      <>
        <IconButton
          variant="outlined"
          onClick={() => {
            handleSelectedRoute(route);
          }}
        >
          <RemoveRedEye
            sx={{
              color: colors.link,
            }}
          />
        </IconButton>
      </>
    );
  };

  const renderDate = (route) => {
    if (!isEmpty(route)) {
      if (route.route_positions) {
        const positions = route.route_positions;

        const positionArray = positions.filter((position) => {
          return position.position === route.current_position;
        });

        const position = positionArray[0];

        if (!isEmpty(position)) {
          const date = moment(position).format("DD-MM-YYYY");
          return <span>{date}</span>;
        }
      } else {
        return <span></span>;
      }
    } else {
      return <span></span>;
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{px: 2}}>
        <Grid item sm={4} xs={12}>
          <TodayTripsCard value={tripsArray.length} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TodayRoutesCard value={routesArray.length} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TodayLocationsCard value={positionsArray.length} />
        </Grid>
        <Grid item sm={12}>
          <Box className="w-[100%]">
            <h5>Current Routes</h5>
            <div className="">{renderCurrentTrips()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TrackerOverview;
