import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, Grid } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { getAllStocks } from "../../api/stock/stockAPI";
import { addStockDetails, addStocks, selectStocks } from "../../features/stockSlice";

const Stocks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all stocks
  const getStocks = async () => {
    const response = await getAllStocks({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addStocks(response.data.data));
      }
    }
  };

  useEffect(() => {
    getStocks();
  }, [dispatch]);

  const stocks = useSelector(selectStocks);
  // console.log(expenses);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let totalQuantity = 0;
  let totalCost = 0;

  let stocksArray = [];

  if (Array.isArray(stocks)) {
    stocksArray = stocks;

    if (stocks.length !== 0) {
      for (const value of stocks) {
        totalCost += value.total_cost;
        totalQuantity += value.in_stock;
      }

    }
  }

  const handleStock = (stock) => {
    //add stock data to store
    dispatch(addStockDetails(stock));
    navigate(`/inventory/stocks/${stock?.id}`);
  };

  const renderExpenses = () => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.stockTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Item Name</p>
              <p className="py-2">Available Stock</p>
              <p className="py-2">Total Cost</p>
              <p className="py-2">View</p>
            </div>
            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
              {renderExpense()}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderExpense = () => {
    if (stocksArray.length !== 0) {
      return (
        <div>
          {stocksArray.map((stock, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.stockTable} rounded-md text-sm my-2 mx-1`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center capitalize">
                  {stock.item ? (
                    <span>{stock.item.type_name}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-14">{stock.in_stock}</p>
                <p className="py-2 text-center">
                  {formatter.format(stock?.total_cost||0)}
                </p>
                <p
                  className="cursor-pointer py-2 text-center pl-4"
                  onClick={() => handleStock(stock)}
                >
                  <RemoveRedEye
                    sx={{
                      color: colors.link,
                    }}
                  />
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no items</p>
        </div>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            title={"Total Items"}
            value={stocksArray.length}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            title={"Total Items Quantity"}
            value={totalQuantity}
            trip={true}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            title={"Total Items Value"}
            value={formatter.format(totalCost)}
            trip={true}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Stock Items</h5>
            <div className="pb-8 w-[100%]">{renderExpenses()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Stocks;
