import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Divider,
  Chip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getSupplier, getSupplierAllBalances } from "../../api/users/userAPI";
import {
  addSupplierBalances,
  addSupplierDetails,
  selectBalances,
  selectSupplierDetails,
} from "../../features/userSlice";
import { Modal } from "antd";
import useAuth from "../../hooks/useAuth";
import { MdDelete } from "react-icons/md";
import EditBalance from "./EditBalance";
import { notifyToastError, notifyToastSuccess } from "../toast";
import TransferBalance from "./TransferBalance";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { deleteSupplierPaymentTransfer } from "../../api/expenses/expensesAPI";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DeleteBalanceAction = ({ balance, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteBalance = async (balance) => {
    if (!isEmpty(balance)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteSupplierPaymentTransfer({
        transfer_id: balance?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete transfer balance , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Balance Transfer is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={24} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Balance Transfer ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete supplier balance transfer{" "}
            {balance?.amount || ""} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteBalance(balance);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ViewBalanceAction = (balance) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const addedTime = moment(balance?.balance.created_at).format("DD-MM-YYYY");
  const updateTime = moment(balance?.balance.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Supplier Balance Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {balance.balance.description ? (
              <span>{balance.balance.description}</span>
            ) : null}
          </p>
          <br />
          <br />
          {user?.role_name === "admin" || user?.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {balance.balance.added_by ? (
                  <span>
                    {balance.balance.added_by.first_name}{" "}
                    {balance.balance.added_by.middle_name}{" "}
                    {balance.balance.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({balance.balance.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {balance.balance.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {balance.balance.updated_by ? (
                  <span>
                    {balance.balance.updated_by.first_name}{" "}
                    {balance.balance.updated_by.middle_name}{" "}
                    {balance.balance.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({balance.balance.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {balance.balance.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const SupplierBalanceTransfer = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { supplierID } = useParams();

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const auth = useAuth();
  const token = auth?.token || "";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //fetch all balances
  const getBalances = async () => {
    const response = await getSupplierAllBalances({
      supplier_id: supplierID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSupplierBalances(response.data.data));
    } else {
      dispatch(addSupplierBalances([]));
    }
  };

  const fetchSupplier = async () => {
    const response = await getSupplier({ supplierId: supplierID, token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSupplierDetails(response.data.data));
    }
  };

  useEffect(() => {
    getBalances();
    fetchSupplier();
  }, [dispatch]);

  const balances = useSelector(selectBalances);
  const supplier = useSelector(selectSupplierDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderView = (balance) => {
    return (
      <>
        <ViewBalanceAction balance={balance} />
      </>
    );
  };

  const renderEdit = (balance) => {
    return (
      <>
        <EditBalance balance={balance} />
      </>
    );
  };

  const renderDelete = (balance) => {
    if (user) {
      if (user?.role_name.toLowerCase() === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteBalanceAction
              balance={balance}
              onSuccess={() => {
                getBalances();
              }}
            />
          </>
        );
      }
    }
  };

  const renderBalances = ({ sent }) => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.balanceTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Total Amount</p>
              {sent ? (
                <p className="py-2">Receiver Suppplier</p>
              ) : (
                <p className="py-2">Sender Supplier</p>
              )}
              <p className="py-2">Actions</p>
            </div>
            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
              {renderBalance({ sent })}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderBalance = ({ sent }) => {
    if (!isEmpty(balances)) {
      if (sent) {
        if (balances.out_transfered_balance) {
          //sort by date
          const sortedbalances = balances.out_transfered_balance
            .slice()
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

          return (
            <div>
              {sortedbalances.map((balance, index) => {
                const position = index + 1;
                return (
                  <div
                    key={index}
                    className={`bg-white h-10 ${tables.balanceTable} rounded-md text-sm my-2`}
                  >
                    <p className="py-2 text-center">{position}</p>
                    <p className="py-2 text-center">
                      {formatter.format(balance?.amount)}
                    </p>
                    <p className="py-2 text-center">
                      {balance?.to_supplier?.supplier_name}
                    </p>
                    <p className="flex flex-row justify-center pl-4">
                      {renderEdit(balance)}
                      {renderView(balance)}
                      {renderDelete(balance)}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div>
              <p className="text-center">
                There are no sent balances transfers
              </p>
            </div>
          );
        }
      } else {
        if (balances.in_transfered_balance) {
          //sort by date
          const sortedbalancesIn = balances.in_transfered_balance
            .slice()
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

          return (
            <div>
              {sortedbalancesIn.map((balance, index) => {
                const position = index + 1;
                return (
                  <div
                    key={index}
                    className={`bg-white h-10 ${tables.balanceTable} rounded-md text-sm my-2`}
                  >
                    <p className="py-2 text-center">{position}</p>
                    <p className="py-2 text-center">
                      {formatter.format(balance?.amount)}
                    </p>
                    <p className="py-2 text-center">
                      {balance?.from_supplier?.supplier_name}
                    </p>
                    <p className="flex flex-row justify-center pl-4">
                      {renderView(balance)}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div>
              <p className="text-center">
                There are no received balances transfers
              </p>
            </div>
          );
        }
      }
    } else {
      return (
        <div>
          <p className="text-center">There are no balances transfers</p>
        </div>
      );
    }
  };

  let transferredInAmount = 0;
  let transferredOutAmount = 0;

  if (!isEmpty(balances)) {
    if (balances.out_transfered_balance) {
      const balanceArray = balances.out_transfered_balance;
      if (balanceArray.length === 1) {
        transferredOutAmount = balanceArray[0].amount;
      } else {
        transferredOutAmount = balanceArray.reduce(
          (accumulator, currentValue) => accumulator + currentValue.amount,
          0
        );
      }
    }

    if (balances.in_transfered_balance) {
      const balanceArray = balances.in_transfered_balance;
      if (balanceArray.length === 1) {
        transferredInAmount = balanceArray[0].amount;
      } else {
        transferredInAmount = balanceArray.reduce(
          (accumulator, currentValue) => accumulator + currentValue.amount,
          0
        );
      }
    }
  }

  const primary = "#F24E1E";

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="flex flex-row justify-between pb-2">
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_names || ""}
            </h5>
          </Box>
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_name || ""}
            </h5>
          </Box>
          <Box>
            <TransferBalance />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Divider>
            <Chip
              label={`TRANSFERRED IN BALANCE: ${formatter.format(
                transferredInAmount
              )}`}
            />{" "}
            <Chip
              label={`TRANSFERRED OUT BALANCE: ${formatter.format(
                transferredOutAmount
              )}`}
            />
          </Divider>
          <Box
            sx={{
              // flexGrow: 2,
              bgcolor: "background.paper",
              display: "flex",
              height: 300,
              width: "100%",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              textColor={`${primary}`}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                color: "#F24E1E",
                height: 375,
                pt: 2,
                width: "30%",
              }}
            >
              <Tab label="Sent Balances" {...a11yProps(0)} />
              <Tab label="Received Balances" {...a11yProps(1)} />
            </Tabs>
            <Box className="w-[100%]">
              <TabPanel value={value} index={0}>
                <h5>Balances Transfers (OUT)</h5>
                <div className="pb-8 w-[100%]">
                  {renderBalances({ sent: true })}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <h5>Balances Transfers (IN)</h5>
                <div className="pb-8 w-[100%]">
                  {renderBalances({ sent: false })}
                </div>
              </TabPanel>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SupplierBalanceTransfer;
