import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import Login from "../auth/Login";
import TrucksOwner from "./TrucksOwner";
import TrucksAdmin from "./TrucksAdmin";
import { getAllTrucks } from "../../api/trucks/trucksAPI";
import { addTrucks } from "../../features/truckSlice";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import useAuth from "../../hooks/useAuth";

const Trucks = () => {
  const dispatch = useDispatch();
  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    //fetch all trucks
    const getTrucks = async () => {
      const response = await getAllTrucks({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addTrucks(response.data.data));
        }
      }
    };

    getTrucks();
  }, [dispatch]);

  const renderUserTrucks = () => {
    const { userInfo: user } = userData;
    if (!isEmpty(user)) {
      switch (true) {
        case user.role_name.toLowerCase() === "admin" ||
          user.role_name.toLowerCase() === "accountant" ||
          user.role_name.toLowerCase() === "clerk":
          return <TrucksAdmin />;
        case user.role_name.toLowerCase() === "truck owner":
          return <TrucksOwner />;

        default:
          return <div className="text-center">NOT AUTHORIZED</div>;
      }
    } else {
      return <Login />;
    }
  };

  return <div>{renderUserTrucks()}</div>;
};

export default Trucks;
