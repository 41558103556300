import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Autocomplete,
  Modal,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { getAllSuppliers, getAllUsers } from "../../api/users/userAPI";
import { addAllUsers, addSuppliers, selectAllUsers, selectSuppliers } from "../../features/userSlice";
import useAuth from "../../hooks/useAuth";
import { MdEdit } from "react-icons/md";
import {
  getAllPersonalRevenues,
  updatePersonalRevenue,
} from "../../api/revenue/revenueAPI";
import { addPersonalRevenues } from "../../features/revenueSlice";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditPersonalRevenue = ({ revenue }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ownerName = `${revenue?.owner?.first_name} ${revenue?.owner?.middle_name} ${revenue?.owner?.last_name}`;

  const [amount, setAmount] = useState(revenue?.amount);
  const [owner, setOwner] = useState({ id: revenue?.owner_id, label: ownerName });
  const [supplier, setSupplier] = useState({ id: revenue?.supplier_id, label: revenue?.supplier?.supplier_name });
  const [revenue_date, setDate] = useState(moment(revenue?.revenue_date));
  const [description, setDescription] = useState(revenue?.description);

  const dispatch = useDispatch();
  const auth = useAuth();

  const ownerOnChange = (e, value) => {
    setOwner(value);
  };

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  //fetch all personal revenue
  const getRevenues = async () => {
    const response = await getAllPersonalRevenues({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addPersonalRevenues(response.data.data));
    }
  };

  //fetch all owners
  const getOwners = async () => {
    const response = await getAllUsers({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addAllUsers(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  useEffect(() => {
    getOwners();
    getSuppliers();
  }, [dispatch]);

  const allUsers = useSelector(selectAllUsers);
  const suppliers = useSelector(selectSuppliers);

  const sortedOwners = allUsers
    .filter(({ role_name }) => role_name.toLowerCase() === "truck owner")
    .map(({ id, first_name, middle_name, last_name }) => ({
      id,
      label: `${first_name} ${middle_name} ${last_name}`,
    }));

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));

  const saveRevenue = async (e) => {
    e.preventDefault();

    if (!amount) {
      notifyToastError({
        message: "Please enter paid amount",
        icon: "❗",
      });
    } else if (!owner) {
      notifyToastError({
        message: "Please select revenue depositor",
        icon: "❗",
      });
    } else if (!supplier) {
      notifyToastError({
        message: "Please select supplier receiver",
        icon: "❗",
      });
    } else if (!revenue_date) {
      notifyToastError({
        message: "Please select payment date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(revenue_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await updatePersonalRevenue({
        amount,
        revenue_date: date,
        owner_id: owner.id,
        supplier_id: supplier?.id,
        description,
        revenue_id: revenue?.id,
        token: auth?.token || "",
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getRevenues();

        notifyToastSuccess({
          message: "Personal Revenue is updated successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => saveRevenue(e)}
        >
          UPDATE PERSONAL REVENUE
        </Button>
      );
    }
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleOpen}>
        <MdEdit className="text-[#F24E1E] text-xl" />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">
              Edit Personal Revenue Details
            </h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Paid Amount"
                  variant="outlined"
                  type={"number"}
                  className="w-[40%]"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[40%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedOwners}
                  size="large"
                  className="w-[40%]"
                  value={owner}
                  onChange={ownerOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Truck Owner" />
                  )}
                />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select payment date"
                    value={revenue_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[40%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditPersonalRevenue;
