import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { getInvoiceReport } from "../../api/revenue/revenueAPI";
import useAuth from "../../hooks/useAuth";
import {
  addInvoiceReport,
  selectInvoiceReport,
} from "../../features/revenueSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Create styles for PDF document
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 30,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 15,
  },
  owner: {
    fontSize: 13,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
  customer: {
    fontSize: 12,
    marginBottom: 5,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader1: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableColHeader2: {
    width: "54%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol1: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
  tableCol2: {
    width: "54%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightColor: "#000",
    borderBottomColor: "#000",
    padding: 5,
  },
});

const InvoicePDF = ({ invoice }) => {
  // Sample invoice data
  let phone_numbers = [];

  if (invoice?.invoice_person) {
    phone_numbers = JSON.parse(invoice?.invoice_person?.phone_number);
  }

  const renderNumber = () => {
    if (phone_numbers.length > 1) {
      return (
        <Text>
          {phone_numbers[0]} / {phone_numbers[1]}
        </Text>
      );
    } else {
      <Text>{phone_numbers[0]}</Text>;
    }
  };

  let formatterNum = new Intl.NumberFormat("en-uS");

  let totalAmount = 0;

  if (invoice?.invoice_type === "tzs") {
    totalAmount = formatterNum.format(invoice?.total_amount || 0);
  } else {
    totalAmount = formatterNum.format(invoice?.total_amount_usd || 0);
  }

  const particulars = [
    {
      quantity: `${invoice?.quantity}`,
      particular: `Being transport charge of goods from ${invoice?.route?.initial_location} to ${invoice?.route?.final_location}`,
      value: `${invoice?.rate}`,
      amount: totalAmount,
    },
    {
      quantity: "",
      particular: `TRUCK: ${invoice?.truck_reg_number} / ${invoice?.trailer_reg_number}`,
      value: "",
      amount: "",
    },
    {
      quantity: "",
      particular: `${invoice?.description || ""}`,
      value: "",
      amount: "",
    },
  ];

  return (
    <PDFViewer width="1000" height="600">
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>INVOICE</Text>
            <Text style={{ fontSize: 20, textAlign: "center" }}>
              {invoice?.invoice_person?.name.toUpperCase()}
            </Text>
            <View style={styles.owner}>
              <Text>{invoice?.invoice_person?.company}</Text>
              <Text>{invoice?.invoice_person?.address}</Text>
              <Text>{renderNumber()}</Text>
            </View>
            <View style={{ textAlign: "right", marginBottom: 5, fontSize: 13 }}>
              <Text>INVOICE NO: {invoice?.invoice_number}</Text>
              <Text>TIN: {invoice?.invoice_person?.tin}</Text>
              <Text>VRN: {invoice?.invoice_person?.vrn}</Text>
            </View>
            <View style={styles.customer}>
              <Text style={{ fontSize: 13 }}>TO: </Text>
              <Text>{invoice?.customer?.customer_name}</Text>
              <Text>{invoice?.customer?.location}</Text>
              <View style={{ fontSize: 11, marginTop: 5 }}>
                <Text>TIN: {invoice?.customer?.tin || ""}</Text>
                <Text>VRN: {invoice?.customer?.vrn || ""}</Text>
              </View>
            </View>
            <View style={{ textAlign: "right" }}>
              <Text>Date: {invoice?.invoice_date}</Text>
            </View>
            <Text
              style={{ fontSize: 13, marginBottom: 5, textAlign: "center" }}
            >
              PARTICULARS
            </Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColHeader1}>Qty</Text>
                <Text style={styles.tableColHeader2}>Particular</Text>
                <Text style={styles.tableColHeader1}>@</Text>
                <Text style={styles.tableColHeader}>
                  Amount {invoice?.invoice_type === "usd" ? "USD" : "TZS"}
                </Text>
              </View>
              {particulars.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.tableCol1}>{item.quantity}</Text>
                  <Text style={styles.tableCol2}>{item.particular}</Text>
                  <Text style={styles.tableCol1}>{item.value}</Text>
                  <Text style={styles.tableCol}>{item.amount}</Text>
                </View>
              ))}
            </View>
            <Text
              style={{
                fontSize: 13,
                marginBottom: 5,
                marginTop: 10,
                textAlign: "right",
              }}
            >
              TOTAL : {invoice?.invoice_type.toUpperCase()} {totalAmount}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const InvoicePDFCard = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";
  const dispatch = useDispatch();

  const getInvoice = async () => {
    setLoading(true);
    const response = await getInvoiceReport({
      invoice_id: data?.id,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addInvoiceReport(response.data.data));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
    getInvoice();
  };

  const invoiceReport = useSelector(selectInvoiceReport);
  //console.log(invoiceReport);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="text-base text-[#F24E1E] py-3 font-semibold cursor-pointer hover:underline"
      >
        <Download
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <div className="max-w-5xl mx-auto bg-white p-2 pt-4">
            <div className="flex items-center justify-center">
              <h1 className="text-center text-2xl font-semibold mb-2">
                GENERATE INVOICE
              </h1>
            </div>
            {loading ? (
              <span>Loading ...</span>
            ) : (
              <span>
                {invoiceReport ? <InvoicePDF invoice={invoiceReport} /> : null}
              </span>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InvoicePDFCard;
