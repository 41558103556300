import { configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import settingReducer from "../features/settingSlice";
import authenticationReducer from "../features/authSlice";
import truckReducer from '../features/truckSlice';
import userReducer from '../features/userSlice';
import tripReducer from "../features/tripsSlice";
import revenueReducer from "../features/revenueSlice";
import expensesReducer from "../features/expensesSlice";
import stockReducer from "../features/stockSlice";


const authPersistConfig = {
  key: 'auth',
  storage,
}

const persistedAuthenticationReducer = persistReducer(authPersistConfig, authenticationReducer)


export const store = configureStore({
  reducer: {
    settings: settingReducer,
    authentication: persistedAuthenticationReducer,
    truck: truckReducer,
    user: userReducer,
    trip: tripReducer,
    revenue: revenueReducer,
    expenses: expensesReducer,
    stock: stockReducer,
  },
  // middleware: [thunk]
});

export const persistor = persistStore(store)
