import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import tables from "../../styles/tables.module.css";
import { MdDelete } from "react-icons/md";
import AddExpenseType from "./AddExpenseType";
import {
  addExpenseTypes,
  selectExpenseTypes,
} from "../../features/settingSlice";
import { isEmpty } from "lodash";
import {
  deleteExpenseType,
  getAllExpenseTypes,
} from "../../api/settings/settingAPI";
import { useDispatch, useSelector } from "react-redux";
import { notifyToastError, notifyToastSuccess } from "../toast";
import EditExpenseType from "./EditExpenseType";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const DeleteExpenseTypeAction = ({ type, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExpenseType = async (type) => {
    if (!isEmpty(type)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteExpenseType({
        expenseType_id: type.id,
        token,
      });
      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
      } else {
        notifyToastError({
          message: "Failed to delete expense type, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Expense Type is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={26} />
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={handleClickOpen}
                disabled={isLoading}
              >
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </Button>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete expense type"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete {type?.type_name || ""} expense
              type
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleExpenseType(type);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const SettingExpenseType = () => {
 
  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const token = auth?.token || "";

  const dispatch = useDispatch();

  const { userInfo: user } = userData;
  const getExpenseTypes = async () => {
    const response = await getAllExpenseTypes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addExpenseTypes(response.data.data));
      }
    }
  };

  useEffect(() => {
    getExpenseTypes();
  }, [dispatch]);

  const expenseTypes = useSelector(selectExpenseTypes);

  const renderEdit = (expenseType) => {
    if (user) {
      return (
        <>
          <EditExpenseType expenseType={expenseType} />
        </>
      );
    }
  };

  const renderDelete = (expenseType) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteExpenseTypeAction
              type={expenseType}
              onSuccess={() => {
                getExpenseTypes();
              }}
            />
          </>
        );
      }
    }
  };

  const renderExpense = () => {
    if (!isEmpty(expenseTypes)) {
      return (
        <div>
          {expenseTypes.length !== 0
            ? expenseTypes.map((expenseType, index) => {
                const position = index + 1;
                return (
                  <div
                    key={index}
                    className={`bg-white h-10 ${tables.brandTable} rounded-md text-sm my-2 mx-2`}
                  >
                    <p className="py-2 text-center">{position}</p>
                    <p className="py-2">
                      <span className="pl-20 capitalize">
                        {expenseType.type_name}
                      </span>
                    </p>
                    <p className="py-2">{expenseType.type_description}</p>
                    {renderEdit(expenseType)}
                    {renderDelete(expenseType)}
                  </div>
                );
              })
            : null}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">No expense types available</p>
        </div>
      );
    }
  };

  const renderExpenseTypes = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.brandTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Name</p>
          <p className="py-2">Expense Type Description</p>
          <p className="py-2">Edit</p>
          <p className="py-2">Delete</p>
        </div>
        <div className="bg-[#F0F0F0] h-[150px] overflow-y-scroll">
          {renderExpense()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row gap-4 justify-center items-center w-[100%]">
        <AddExpenseType />
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>Expense Types</h5>
          <div className="w-[100%]">{renderExpenseTypes()}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingExpenseType;
