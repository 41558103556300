import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { DatePicker, Space, Segmented } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteMonthlyExpense,
  getAllMonthlyExpenses,
} from "../../api/expenses/expensesAPI";
import { authenticationAtom } from "../../atoms/auth";
import {
  addMonthlyExpenseDetails,
  addMonthlyExpenses,
  selectMonthlyExpenses,
} from "../../features/expensesSlice";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import { colors } from "../../assets/utils/colors";
import { notifyToastError, notifyToastSuccess } from "../toast";
import moment from "moment";
import AddMonthlyExpense from "./AddMonthlyExpenses";
import EditMonthlyExpense from "./EditMonthlyExpenses";


const DeleteExpenseAction = ({ expense, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const auth = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteExpense = async (expense) => {
    if (!isEmpty(expense)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteMonthlyExpense({
        expense_id: expense?.id || "",
        token: auth?.token || "",
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to delete truck monthly expense",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Truck indirect expense is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl ml-0" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete truck indirect expense ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete{" "}
            {expense.truck ? (
              <span>{expense.truck.registration_number}</span>
            ) : null}{" "}
            {expense?.expense_name} indirect expense ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteExpense(expense);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MonthlyExpenses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const month = moment().format("MMMM");
  const monthNumber = moment().month(month).format("M");
  const year = moment().format("YYYY");

  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const [value, setValue] = useState(month);
  const [monthValue, setMonthValue] = useState(monthNumber);
  const [yearValue, setYear] = useState(year);

  //fetch all
  const getExpenses = async () => {
    const response = await getAllMonthlyExpenses({ token: auth?.token || "" });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addMonthlyExpenses(response.data.data));
      // setExpenses(response.data.data);
    }
  };

  useEffect(() => {
    getExpenses();
  }, [dispatch]);

  const expenses = useSelector(selectMonthlyExpenses);
  const monthExpenses = expenses.filter((expense) => {
    return (
      expense.month === parseInt(monthValue) &&
      moment(expense.expense_date).format("YYYY") === yearValue.toString()
    );
  });

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const { userInfo: user } = userData;

  const onMonthChange = (e) => {
    const eNumber = moment().month(e).format("M");
    setValue(e);
    setMonthValue(eNumber);
    //filter truck expense
  };

  const onYearChange = (date, dateString) => {
    // console.log(date);
    setYear(dateString);
  };

  const handleClearFilter = () => {
    setYear(year);
  };

  const handleExpense = (expense) => {
    //add expense data to store
    dispatch(addMonthlyExpenseDetails(expense));
    navigate(`/expenses/indrect-expenses/${expense?.id}`);
  };

  const renderEdit = (expense) => {
    return (
      <>
        <EditMonthlyExpense expense={expense} />
      </>
    );
  };

  const renderDelete = (expense) => {
    if (user) {
      if (user.role_name.toLowerCase() === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteExpenseAction
              expense={expense}
              onSuccess={() => {
                getExpenses();
              }}
            />
          </>
        );
      }
    }
  };

  const renderExpenses = () => {
    return (
      <div>
        <div className="py-3 flex justify-center">
          <Segmented
            size="large"
            options={[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ]}
            value={value}
            onChange={onMonthChange}
          />
        </div>
        <div className="w-[100%] flex flex-row justify-center">
          <div className="w-[30%]"></div>
          <div className="bg-[white] w-[40%] shadow-xl">
            <div className="flex flex-row gap-2 justify-center px-4 py-2">
              <div className="w-[100%]">
                <h4 className="py-2 px-2">Filter by Year :</h4>
              </div>
              <div className="w-full">
                <Space direction="vertical">
                  <DatePicker onChange={onYearChange} picker="year" />
                </Space>
              </div>
              <div className="w-full">
                <Space direction="vertical">
                  <Button
                    className="bg-black rounded-md"
                    onClick={() => handleClearFilter()}
                  >
                    Clear filter
                  </Button>
                </Space>
              </div>
            </div>
          </div>
          <div className="w-[30%]"></div>
        </div>
        <h5 className="py-1">Truck Indirect Expenses</h5>
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.monthlyExpensesTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Truck</p>
          <p className="py-2">Expense Name</p>
          <p className="py-2">Expense Amount</p>
          <p className="py-2">Expense Supplier</p>
          <p className="py-2">Expense Date</p>
          <p className="py-2">Actions</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderExpense()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderExpense = () => {
    if (monthExpenses.length !== 0) {
      return (
        <div>
          {monthExpenses.map((expense, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.monthlyExpensesTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {expense.truck ? (
                    <span>{expense.truck?.registration_number}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-2">{expense.expense_name}</p>
                <p className="py-2 text-center">
                  {formatter.format(expense.amount)}
                </p>
                <p className="py-2 text-center">
                  {expense.supplier ? (
                    <span>{expense.supplier?.supplier_name}</span>
                  ) : null}
                </p>
                <p className="py-2 text-center">{expense.expense_date}</p>
                <p className="flex flex-row justify-center space-x-1 pl-4">
                  {renderEdit(expense)}
                  {renderDelete(expense)}
                  <IconButton
                    variant="outlined"
                    onClick={() => handleExpense(expense)}
                  >
                    <RemoveRedEye
                      sx={{
                        color: colors.link,
                      }}
                    />
                  </IconButton>
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no truck indirect expenses</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 2, py: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <AddMonthlyExpense />
      </Grid>
      <Grid item sm={12}>
        <Box>
          <div className="w-[100%] pb-6">{renderExpenses()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MonthlyExpenses;
