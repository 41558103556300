import React from "react";
import { DirectionsBus } from "@mui/icons-material";

const TripsProgressCard = ({ title, value, valueUSD, trip, car, revenue }) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let formatterUS = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (trip) {
    return (
      <div className="bg-gradient-to-r from-[#323232] to-[#478C67] h-[140px] w-auto rounded-md border-2">
        <div className="px-4 py-4">
          <div>
            <h4 className="text-white text-lg font-semibold">
              {title ? <span>{title}</span> : <span></span>}
            </h4>
          </div>
          <div className="pt-3 pb-2 flex flex-row gap-1">
            <p className="text-3xl md:text-xl sm:text-lg text-white font-bold">
              {value ? <span>{value}</span> : <span>0</span>}
            </p>
            {revenue ? <p className="text-xs text-white py-2">(EXPENSES)</p> : null}
          </div>
          {revenue ? (
            <div className="flex flex-row gap-1">
              <p className="text-base text-white font-bold">
                {revenue ? <span>{revenue}</span> : <span>0</span>}
              </p>
              <p className="text-xs text-white py-1">(REVENUE)</p>
            </div>
          ) : null}
          <div className="flex flex-row gap-6 justify-between">
            <div className="flex flex-row"></div>
            <div>
              {car ? null : (
                <p>
                  <DirectionsBus
                    sx={{
                      color: "#CCCCCC",
                    }}
                  />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-gradient-to-r from-[#323232] to-[#478C67] h-[180px] w-auto rounded-md border-2">
        <div className="px-4 py-2">
          <div>
            <h4 className="text-white text-lg font-semibold">
              {title ? <span>{title}</span> : <span></span>}
            </h4>
          </div>
          <div className="py-2">
            <p className="text-2xl md:text-xl sm:text-lg text-white font-bold">
              {valueUSD ? (
                <span>{formatterUS.format(valueUSD)}</span>
              ) : (
                <span>{formatterUS.format(0)}</span>
              )}
            </p>
          </div>
          <div className="py-3">
            <p className="text-3xl md:text-xl sm:text-lg text-white font-bold">
              {value ? (
                <span>{formatter.format(value)}</span>
              ) : (
                <span>{formatter.format(0)}</span>
              )}
            </p>
          </div>
          <div className="flex flex-row gap-6 justify-between">
            <div className="flex flex-row"></div>
            <div>
              <p>
                <DirectionsBus
                  sx={{
                    color: "#CCCCCC",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default TripsProgressCard;
