import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { DatePicker, Modal, Space, Segmented } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getTruckMonthlyExpense } from "../../api/expenses/expensesAPI";
import { authenticationAtom } from "../../atoms/auth";
import {
  addTruckMonthlyExpenses,
  selectTruckMonthlyExpenses,
} from "../../features/expensesSlice";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import { colors } from "../../assets/utils/colors";
import moment from "moment";
import { useParams } from "react-router-dom";
import { selectTruckDetails } from "../../features/truckSlice";

const ViewExpenseAction = ({ expense }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const addedTime = moment(expense?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(expense?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Personal Expense Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {expense.description ? <span>{expense.description}</span> : null}
          </p>
          <br />
          <br />
          {user?.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {expense.added_by ? (
                  <span>
                    {expense.added_by.first_name} {expense.added_by.middle_name}{" "}
                    {expense.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({expense.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {expense.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {expense.updated_by ? (
                  <span>
                    {expense.updated_by.first_name}{" "}
                    {expense.updated_by.middle_name}{" "}
                    {expense.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({expense.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {expense.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const TruckMonthlyExpenses = () => {
  const dispatch = useDispatch();

  const { truckID } = useParams();

  const month = moment().format("MMMM");
  const monthNumber = moment().month(month).format("M");
  const year = moment().format("YYYY");

  const auth = useAuth();

  const [value, setValue] = useState(month);
  const [monthValue, setMonthValue] = useState(monthNumber);
  const [yearValue, setYear] = useState(year);

  //fetch all
  const getExpenses = async () => {
    const response = await getTruckMonthlyExpense({
      truck_id: truckID,
      token: auth?.token || "",
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTruckMonthlyExpenses(response.data.data));
    }
  };

  useEffect(() => {
    getExpenses();
  }, [dispatch]);

  const expenses = useSelector(selectTruckMonthlyExpenses);
  const truck = useSelector(selectTruckDetails);

  const monthExpenses = expenses.filter((expense) => {
    return (
      expense.month === parseInt(monthValue) &&
      moment(expense.expense_date).format("YYYY") === yearValue.toString()
    );
  });

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const onMonthChange = (e) => {
    const eNumber = moment().month(e).format("M");
    setValue(e);
    setMonthValue(eNumber);
    //filter truck expense
  };

  const onYearChange = (date, dateString) => {
    // console.log(date);
    setYear(dateString);
  };

  const handleClearFilter = () => {
    setYear(year);
  };

  const renderView = (expense) => {
    return (
      <>
        <ViewExpenseAction expense={expense} />
      </>
    );
  };

  const renderExpenses = () => {
    return (
      <div>
        <div className="py-3 flex justify-center">
          <Segmented
            size="large"
            options={[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ]}
            value={value}
            onChange={onMonthChange}
          />
        </div>
        <div className="w-[100%] flex flex-row justify-center">
          <div className="w-[30%]"></div>
          <div className="bg-[white] w-[40%] shadow-xl">
            <div className="flex flex-row gap-2 justify-center px-4 py-2">
              <div className="w-[100%]">
                <h4 className="py-2 px-2">Filter by Year :</h4>
              </div>
              <div className="w-full">
                <Space direction="vertical">
                  <DatePicker onChange={onYearChange} picker="year" />
                </Space>
              </div>
              <div className="w-full">
                <Space direction="vertical">
                  <Button
                    className="bg-black rounded-md"
                    onClick={() => handleClearFilter()}
                  >
                    Clear filter
                  </Button>
                </Space>
              </div>
            </div>
          </div>
          <div className="w-[30%]"></div>
        </div>
        <h5 className="py-1">Truck Indirect Expenses</h5>
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.monthlyExpensesTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Truck</p>
          <p className="py-2">Expense Name</p>
          <p className="py-2">Expense Amount</p>
          <p className="py-2">Expense Supplier</p>
          <p className="py-2">Expense Date</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderExpense()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderExpense = () => {
    if (monthExpenses.length !== 0) {
      return (
        <div>
          {monthExpenses.map((expense, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.monthlyExpensesTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {expense.truck ? (
                    <span>{expense.truck?.registration_number}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-2">{expense.expense_name}</p>
                <p className="py-2 text-center">
                  {formatter.format(expense.amount)}
                </p>
                <p className="py-2 text-center">
                  {expense.supplier ? (
                    <span>{expense.supplier?.supplier_name}</span>
                  ) : null}
                </p>
                <p className="py-2 text-center">{expense.expense_date}</p>
                <p className="flex flex-row justify-center space-x-2 pl-4">
                  {renderView(expense)}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no truck indirect expenses</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 2, py: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        TRUCK <span className="font-semibold px-1">{truck?.registration_number}</span> MONTHLY INDIRECT EXPENSES
      </Grid>
      <Grid item sm={12}>
        <Box>
          <div className="w-[100%] pb-6">{renderExpenses()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TruckMonthlyExpenses;
