import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roles: [],
  role: "",
  brands: [],
  brand: "",
  paymentTypes: [],
  expenseTypes: [],
  designationTypes: [],
  itemTypes: [],
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    addUserRoles(state, action) {
      state.roles = action.payload;
    },

    addTruckBrands(state, action) {
      state.brands = action.payload;
    },

    addPaymentTypes(state, action) {
      state.paymentTypes = action.payload;
    },

    addExpenseTypes(state, action) {
      state.expenseTypes = action.payload;
    },

    addDesignationTypes(state, action) {
      state.designationTypes = action.payload;
    },

    addItemTypes(state, action) {
      state.itemTypes = action.payload;
    },

  },
});

export const { addUserRoles, addTruckBrands, addPaymentTypes, addExpenseTypes, addDesignationTypes, addItemTypes } = settingSlice.actions;

export const selectUserRoles = (state) => state.settings.roles;
export const selectTruckBrands = (state) => state.settings.brands;
export const selectPaymentTypes = (state) => state.settings.paymentTypes;
export const selectExpenseTypes = (state) => state.settings.expenseTypes;
export const selectDesignationTypes = (state) => state.settings.designationTypes;
export const selectItemTypes = (state) => state.settings.itemTypes;

export default settingSlice.reducer;
