import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdEdit } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../api/users/userAPI";
import { isEmpty } from "lodash";
import { addCustomers, selectCustomers } from "../../features/userSlice";
import { getAllRoutes, updateRoute } from "../../api/trips/tripsAPI";
import { addTripRoutes, selectTripDetails } from "../../features/tripsSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditRoute = ({ route, onSuccess = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [initial_location, setInitial] = useState(route.initial_location);
  const [final_location, setDestination] = useState(route.final_location);
  const [customer, setCustomer] = useState({id: route.customer_id, label: route?.route_customer?.customer_name});
  const [start_date, setStartDate] = useState(moment(route.start_date));
  const [end_date, setEndDate] = useState(moment(route.end_date));
  const [route_description, setDescription] = useState(route.route_description);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getCustomers = async () => {
    const response = await getAllCustomers({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addCustomers(response.data.data));
      }
    }
  };

  const getRoutes = async () => {
    const response = await getAllRoutes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTripRoutes(response.data.data));
      }
    }
  };

  useEffect(() => {
    //fetch all customers
    getCustomers();
  }, [dispatch]);

  const customerOnChange = (e, value) => {
    setCustomer(value);
  };

  const customers = useSelector(selectCustomers);
  const trip = useSelector(selectTripDetails);

  let sortedCustomers = [];

  if (customers.length !== 0) {
    customers.map((customer) => {
      //filtering array value
      sortedCustomers.push({
        id: customer.id,
        label: customer.customer_name,
      });
    });
  }

  const routeRegistration = async (e) => {
    e.preventDefault();

    if (!initial_location) {
      notifyToastError({
        message: "Please enter initial point",
        icon: "❗",
      });
    } else if (!final_location) {
      notifyToastError({
        message: "Please enter destination point",
        icon: "❗",
      });
    } else if (!customer) {
      notifyToastError({
        message: "Please select customer",
        icon: "❗",
      });
    } else if (!start_date) {
      notifyToastError({
        message: "Please select start date",
        icon: "❗",
      });
    } else if (!end_date) {
      notifyToastError({
        message: "Please select end date",
        icon: "❗",
      });
    } else {
      //change date format
      const sdate = moment(start_date).format("YYYY-MM-DD");
      const edate = moment(end_date).format("YYYY-MM-DD");
      //verify start and end date
      if (moment(end_date).isBefore(moment(start_date))) {
        //
        notifyToastError({
          message: "Sorry! End date can not be earlier than start date",
          icon: "❗",
        });
      } else {
        //start registration
        setLoading(true);
        const response = await updateRoute({
          initial_location,
          final_location,
          customer_id: customer.id,
          start_date: sdate,
          end_date: edate,
          route_description,
          route_id: route.id,
          trip_id: route.trip_id,
          token,
        });

        if (response) {
          checkResponse(response);
        } else {
          setLoading(false);
          notifyToastError({
            message: "No response returned",
            icon: "❗",
          });
        }
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getRoutes();

        notifyToastSuccess({
          message: "Route is updated successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => routeRegistration(e)}
        >
          UPDATE ROUTE
        </Button>
      );
    }
  };

  return (
    <>
      <IconButton variant="outlined" onClick={handleOpen}>
        <MdEdit className="text-[#F24E1E] text-xl" />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {trip?.trip_status === "not_started" || trip?.trip_status === "open" ? (
          <Box sx={style}>
            <div>
              <h3 className="text-center text-xl py-4">Edit Route Details</h3>
              <div>
                <div className="w-full py-2 flex flex-row gap-2 justify-center">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Initial point"
                    variant="outlined"
                    className="w-[40%]"
                    value={initial_location}
                    onChange={(e) => setInitial(e.target.value)}
                  />
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Destination point"
                    variant="outlined"
                    className="w-[40%]"
                    value={final_location}
                    onChange={(e) => setDestination(e.target.value)}
                  />
                </div>
                <div className="w-full flex py-2 justify-center">
                <Autocomplete
                    id="combo-box-demo"
                    options={sortedCustomers}
                    size="small"
                    className="w-[82%]"
                    value={customer}
                    onChange={customerOnChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Customer" />
                    )}
                  />
                </div>
                <div className="w-full py-2 flex flex-row gap-2 justify-center">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    dateLibInstance={moment.utc}
                  >
                    <DatePicker
                      label="Select start date"
                      value={start_date}
                      onChange={(newValue) => setStartDate(newValue)}
                      className="w-[40%]"
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    dateLibInstance={moment.utc}
                  >
                    <DatePicker
                      label="Select end date"
                      value={end_date}
                      onChange={(newValue) => setEndDate(newValue)}
                      className="w-[40%]"
                    />
                  </LocalizationProvider>
                </div>
                <div className="w-full py-2 flex justify-center">
                  <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    className="w-[82%]"
                    value={route_description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="w-full py-2 flex justify-center">
                  {renderButton()}
                </div>
              </div>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div>
              <h3 className="text-center text-xl py-4">Add Route Details</h3>
              <div>
                <h3 className="text-center text-base py-4">
                  Sorry! Trip is closed, you can't edit an existing route to a
                  closed trip.
                </h3>
              </div>
            </div>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default EditRoute;
