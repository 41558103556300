import React from "react";
import { DirectionsBus } from "@mui/icons-material";

const ExpensesCard = ({ value, title, owner, car, indirect }) => {
  if (!owner) {
    return (
      <div className="bg-gradient-to-r from-[#D0D0D0] to-[#F0D8D8] h-[200px] w-auto rounded-md border-2">
        <div className="px-4 py-4">
          <div className="flex flex-row gap-6 justify-between">
            <div>
              <h4 className="text-black text-lg font-semibold">
                {title ? <span>{title}</span> : "EXPENSES"}
              </h4>
            </div>
            <p className="text-sm font-semibold cursor-pointer"></p>
          </div>
          <div className="py-3">
            <p className="text-3xl md:text-xl sm:text-xl text-black font-bold">
              {value ? <span>{value}</span> : 0}
            </p>
          </div>
          <div className="flex flex-row gap-6 justify-between py-12">
            <div className="flex flex-row"></div>
            <div className="">
              <p>
                {car ? (
                  <DirectionsBus
                    sx={{
                      color: "#CCCCCC",
                    }}
                  />
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-gradient-to-r from-[#D0D0D0] to-[#F0D8D8] h-[140px] w-auto rounded-md border-2">
        <div className="px-4 py-4">
          <div className="flex flex-row gap-6 justify-between">
            <div>
              <h4 className="text-black text-lg font-semibold">
                {title ? <span>{title}</span> : "EXPENSES"}
              </h4>
            </div>
            <p className="text-sm font-semibold cursor-pointer"></p>
          </div>
          <div className="pt-3 pb-2 flex flex-row gap-1 ">
            <p className="text-3xl md:text-xl sm:text-xl text-black font-bold">
              {value ? <span>{value}</span> : 0}
            </p>
            {indirect ? (
              <p className="text-xs text-black py-2">(DIRECT)</p>
            ) : null}
          </div>
          {indirect ? (
            <div className="flex flex-row gap-1">
              <p className="text-base text-black font-bold">
                {indirect ? <span>{indirect}</span> : <span>0</span>}
              </p>
              <p className="text-xs text-black py-1">(INDIRECT)</p>
            </div>
          ) : null}
          <div className="flex flex-row gap-6 justify-between py-12">
            <div className="flex flex-row"></div>
            <div className="">
              <p>
                {car ? (
                  <DirectionsBus
                    sx={{
                      color: "#CCCCCC",
                    }}
                  />
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ExpensesCard;
