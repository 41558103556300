import {
  CREATE_PERSONAL_REVENUE,
  CREATE_TRANSACTION,
  DELETE_PERSONAL_REVENUE,
  GET_ALL_PERSONAL_REVENUES,
  GET_ALL_TRANSACTIONS,
  GET_INVOICE_REPORT,
  GET_INVOICE_TRANSACTION,
  GET_OWNER_PERSONAL_REVENUE,
  GET_SINGLE_PERSONAL_REVENUE,
  GET_SINGLE_TRANSACTION,
  GET_SUPPLIER_INVOICES_TRANSACTION,
  UPDATE_PERSONAL_REVENUE,
  UPDATE_TRANSACTION,
} from "../apiEndPoints";
import client from "../client";

export const createTransaction = async ({
  amount_paid,
  amount_paid_usd,
  payment_date,
  payment_type_id,
  supplier_id,
  invoice_id,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_TRANSACTION}/${invoice_id}`,
    {
      amount_paid,
      amount_paid_usd,
      payment_date,
      payment_type_id,
      supplier_id,
      invoice_id,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllTransactions = async ({ token }) => {
  const response = await client.get(
    GET_ALL_TRANSACTIONS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleTransactions = async ({ transaction_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_TRANSACTION}/${transaction_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getInvoiceTransactions = async ({ invoice_id, token }) => {
  const response = await client.get(
    `${GET_INVOICE_TRANSACTION}/${invoice_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSupplierInvoicesTransactions = async ({ supplier_id, token }) => {
  const response = await client.get(
    `${GET_SUPPLIER_INVOICES_TRANSACTION}/${supplier_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateTransactions = async ({
  amount_paid,
  amount_paid_usd,
  payment_date,
  payment_type_id,
  supplier_id,
  transaction_id,
  description,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_TRANSACTION}/${transaction_id}`,
    {
      amount_paid,
      amount_paid_usd,
      payment_date,
      payment_type_id,
      supplier_id,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createPersonalRevenue = async ({
  amount,
  owner_id,
  supplier_id,
  revenue_date,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_PERSONAL_REVENUE}`,
    {
      amount,
      owner_id,
      supplier_id,
      revenue_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllPersonalRevenues = async ({ token }) => {
  const response = await client.get(
    GET_ALL_PERSONAL_REVENUES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSinglePersonalRevenue = async ({ revenue_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_PERSONAL_REVENUE}/${revenue_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getOwnerPersonalRevenue = async ({ owner_id, token }) => {
  const response = await client.get(
    `${GET_OWNER_PERSONAL_REVENUE}/${owner_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updatePersonalRevenue = async ({
  amount,
  owner_id,
  supplier_id,
  revenue_date,
  description,
  revenue_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_PERSONAL_REVENUE}/${revenue_id}`,
    {
      amount,
      owner_id,
      supplier_id,
      revenue_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deletePersonalRevenue = async ({ revenue_id, token }) => {
  const response = await client.delete(
    `${DELETE_PERSONAL_REVENUE}/${revenue_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getInvoiceReport = async ({ invoice_id, token }) => {
  const response = await client.get(
    `${GET_INVOICE_REPORT}/${invoice_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};
