import * as React from "react";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { FaMapMarkerAlt, FaBriefcase } from "react-icons/fa";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import ChangePassword from "../settings/ChangePassword";
import { isMobileOnly } from "react-device-detect";

const UserProfile = () => {
  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const mobileProfile = () => {
    return (
      <>
        <section className="pt-6 pb-4 w-[100%]">
          <div className="flex flex-row justify-center">
            <div className="relative flex flex-col break-words bg-gradient-to-r from-[#EBD1D1] to-[#FEFEFE] w-[650px] mb-6 shadow-xl rounded-lg mt-16">
              <div className="px-6">
                <div className="flex flex-row justify-between gap-2">
                  <div className="w-full lg:w-4/12 px-4 lg:order-1"></div>
                  <div className="w-[100%] lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={require("../../assets/media/images/userImg.png")}
                        className="shadow-xl rounded-full h-auto align-middle border-b border-teal-300 absolute -m-16 -ml-20 lg:-ml-16"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-1 px-3">
                      <ChangePassword />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-8">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2 capitalize">
                    {user?.first_name} {user?.middle_name} {user?.last_name}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase flex flex-row gap-1 justify-center">
                    <FaMapMarkerAlt className="text-lg" />
                    <p>Dar es salaam, Tanzania</p>
                  </div>
                  <div className="mb-2 text-gray-700 mt-10 flex flex-row gap-1 justify-center">
                    <MdLocalPhone className="mt-1 text-lg" />
                    {user?.phone_number}
                  </div>
                  <div className="mb-2 text-gray-700 flex flex-row gap-1 justify-center capitalize">
                    <FaBriefcase className="text-lg" />
                    {user?.role_name}
                  </div>
                  <div className="mb-2 text-gray-700 flex flex-row gap-1 justify-center pb-4">
                    <MdEmail className="mt-1 text-lg" />
                    {user?.email}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const renderProfile = () => {
    return (
      <>
        <section className="pt-6 pb-4 w-[100%]">
          <div className="flex flex-row justify-center">
            <div className="relative flex flex-col break-words bg-gradient-to-r from-[#EBD1D1] to-[#FEFEFE] w-[650px] mb-6 shadow-xl rounded-lg mt-16">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-[100%] lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={require("../../assets/media/images/userImg.png")}
                        className="shadow-xl rounded-full h-auto align-middle border-b border-teal-300 absolute -m-16 -ml-20 lg:-ml-16"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      <ChangePassword />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1"></div>
                </div>
                <div className="text-center mt-8">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2 capitalize">
                    {user?.first_name} {user?.middle_name} {user?.last_name}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase flex flex-row gap-1 justify-center">
                    <FaMapMarkerAlt className="text-lg" />
                    <p>Dar es salaam, Tanzania</p>
                  </div>
                  <div className="mb-2 text-gray-700 mt-10 flex flex-row gap-1 justify-center">
                    <MdLocalPhone className="mt-1 text-lg" />
                    {user?.phone_number}
                  </div>
                  <div className="mb-2 text-gray-700 flex flex-row gap-1 justify-center capitalize">
                    <FaBriefcase className="text-lg" />
                    {user?.role_name}
                  </div>
                  <div className="mb-2 text-gray-700 flex flex-row gap-1 justify-center pb-4">
                    <MdEmail className="mt-1 text-lg" />
                    {user?.email}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <div className="py-2 px-4 w-screen md:w-[calc(100vw-84px)] mx-auto">
      {isMobileOnly ? (
        <div className="py-2 flex flex-row justify-center">
          {mobileProfile()}
        </div>
      ) : (
        <div className="py-2 flex flex-row justify-center">
          {renderProfile()}
        </div>
      )}
    </div>
  );
};

export default UserProfile;
