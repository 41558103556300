import {
  CREATE_ROUTE,
  CREATE_ROUTE_EXPENSE,
  CREATE_ROUTE_INVOICE,
  CREATE_ROUTE_POSITION,
  CREATE_TRIP,
  DELETE_ROUTE,
  DELETE_ROUTE_EXPENSE,
  DELETE_ROUTE_INVOICE,
  DELETE_ROUTE_POSITION,
  DELETE_TRIP,
  END_ROUTE,
  END_TRIP,
  FAIL_ROUTE,
  GET_ALL_ROUTES,
  GET_ALL_ROUTE_EXPENSES,
  GET_ALL_ROUTE_INVOICES,
  GET_ALL_ROUTE_POSITIONS,
  GET_ALL_TRIPS,
  GET_CUSTOMER_ROUTES_POSITIONS,
  GET_SINGLE_ROUTE,
  GET_SINGLE_ROUTE_EXPENSE,
  GET_SINGLE_ROUTE_INVOICE,
  GET_SINGLE_ROUTE_POSITION,
  GET_SINGLE_TRIP,
  START_ROUTE,
  UPDATE_ROUTE,
  UPDATE_ROUTE_EXPENSE,
  UPDATE_ROUTE_INVOICE,
  UPDATE_ROUTE_POSITION,
  UPDATE_TRIP,
  UPDATE_TRIP_DRIVER,
  UPDATE_TRIP_TRUCK,
} from "../apiEndPoints";
import client from "../client";

export const createTrip = async ({
  driver_id,
  truck_id,
  start_date,
  description,
  token,
}) => {
  const response = await client.post(
    CREATE_TRIP,
    {
      driver_id,
      truck_id,
      start_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllTrips = async ({ token }) => {
  const response = await client.get(
    GET_ALL_TRIPS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleTrip = async ({ trip_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_TRIP}/${trip_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateTrip = async ({
  driver_id,
  truck_id,
  start_date,
  description,
  trip_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_TRIP}/${trip_id}`,
    {
      driver_id,
      truck_id,
      start_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteTrip = async ({ trip_id, token }) => {
  const response = await client.delete(
    `${DELETE_TRIP}/${trip_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createRoute = async ({
  trip_id,
  customer_id,
  initial_location,
  final_location,
  start_date,
  end_date,
  route_description,
  token,
}) => {
  const response = await client.post(
    CREATE_ROUTE,
    {
      trip_id,
      customer_id,
      initial_location,
      final_location,
      start_date,
      end_date,
      route_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllRoutes = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ROUTES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleRoute = async ({ route_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ROUTE}/${route_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateRoute = async ({
  trip_id,
  customer_id,
  initial_location,
  final_location,
  start_date,
  end_date,
  route_description,
  route_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ROUTE}/${route_id}`,
    {
      trip_id,
      customer_id,
      initial_location,
      final_location,
      start_date,
      end_date,
      route_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteRoute = async ({ route_id, token }) => {
  const response = await client.delete(
    `${DELETE_ROUTE}/${route_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createRouteExpense = async ({
  route_id,
  supplier_id,
  expense_type_id,
  item_number,
  item_price,
  total_amount,
  description,
  expense_date,
  token,
}) => {
  const response = await client.post(
    `${CREATE_ROUTE_EXPENSE}/${route_id}`,
    {
      supplier_id,
      expense_type_id,
      item_number,
      item_price,
      total_amount,
      description,
      expense_date,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getRouteExpenses = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ROUTE_EXPENSES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleRouteExpense = async ({ expense_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ROUTE_EXPENSE}/${expense_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateRouteExpense = async ({
  expense_id,
  supplier_id,
  expense_type_id,
  item_number,
  item_price,
  total_amount,
  description,
  expense_date,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ROUTE_EXPENSE}/${expense_id}`,
    {
      supplier_id,
      expense_type_id,
      item_number,
      item_price,
      total_amount,
      description,
      expense_date,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteRouteExpense = async ({ expense_id, token }) => {
  const response = await client.delete(
    `${DELETE_ROUTE_EXPENSE}/${expense_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createRouteInvoice = async ({
  invoice_number,
  invoice_person,
  total_amount,
  total_amount_usd,
  quantity,
  rate,
  invoice_type,
  invoice_date,
  route_id,
  description,
  token,
}) => {
  const response = await client.post(
    `${CREATE_ROUTE_INVOICE}/${route_id}`,
    {
      invoice_number,
      invoice_person,
      total_amount,
      total_amount_usd,
      quantity,
      rate,
      invoice_type,
      invoice_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getRouteInvoices = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ROUTE_INVOICES,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleRouteInvoice = async ({ invoice_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ROUTE_INVOICE}/${invoice_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateRouteInvoice = async ({
  invoice_number,
  invoice_person,
  total_amount,
  total_amount_usd,
  quantity,
  rate,
  invoice_type,
  invoice_date,
  invoice_id,
  description,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ROUTE_INVOICE}/${invoice_id}`,
    {
      invoice_number,
      invoice_person,
      total_amount,
      total_amount_usd,
      quantity,
      rate,
      invoice_type,
      invoice_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteRouteInvoice = async ({ invoice_id, token }) => {
  const response = await client.delete(
    `${DELETE_ROUTE_INVOICE}/${invoice_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createRoutePosition = async ({
  position,
  position_date,
  description,
  route_id,
  token,
}) => {
  const response = await client.post(
    `${CREATE_ROUTE_POSITION}/${route_id}`,
    {
      position,
      position_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getRoutePositions = async ({ token }) => {
  const response = await client.get(
    GET_ALL_ROUTE_POSITIONS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleRoutePosition = async ({ position_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_ROUTE_POSITION}/${position_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateRoutePosition = async ({
  position,
  position_date,
  description,
  position_id,
  token,
}) => {
  const response = await client.put(
    `${UPDATE_ROUTE_POSITION}/${position_id}`,
    {
      position,
      position_date,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteRoutePosition = async ({ position_id, token }) => {
  const response = await client.delete(
    `${DELETE_ROUTE_POSITION}/${position_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateTripTruck = async ({ trip_id, truck_id, token }) => {
  const response = await client.put(
    `${UPDATE_TRIP_TRUCK}/${trip_id}/${truck_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateTripDriver = async ({ trip_id, driver_id, token }) => {
  const response = await client.put(
    `${UPDATE_TRIP_DRIVER}/${trip_id}/${driver_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const endTrip = async ({ trip_id, token }) => {
  const response = await client.put(
    `${END_TRIP}/${trip_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const starRoute = async ({ route_id, token }) => {
  const response = await client.put(
    `${START_ROUTE}/${route_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const endRoute = async ({ route_id, token }) => {
  const response = await client.put(
    `${END_ROUTE}/${route_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const failRoute = async ({ route_id, token }) => {
  const response = await client.put(
    `${FAIL_ROUTE}/${route_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getCustomerRoutesPosition = async ({ customer_id, token }) => {
  const response = await client.get(
    `${GET_CUSTOMER_ROUTES_POSITIONS}/${customer_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};
