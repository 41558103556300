import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Select } from "antd";
import useAuth from "../../hooks/useAuth";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Modal } from "@mui/material";
import { notifyToastError } from "../toast";
import { useParams } from "react-router-dom";
import { getCustomerInvoices } from "../../api/users/userAPI";
import {
  addCustomerInvoices,
  selectCustomerDetails,
  selectCustomerInvoices,
} from "../../features/userSlice";
import { getSingleRoute } from "../../api/trips/tripsAPI";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    padd: 4,
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColOneThird: {
    width: "33.333%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  text: {
    fontSize: 12,
  },
  textBold: {
    fontWeight: "bold",
  },
  titleData: {},
  tableCol2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
  },
  weeklySmallColumn: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  weeklyMegaColumn: {
    width: "17.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const CustomerInvoicePositionsReport = ({ data, title = "" }) => {
//   console.log("expense data passed");
//   console.log(data);

  return (
    <PDFViewer style={{ width: "100%", height: 450 }}>
      <Document style={{ width: "100%", height: 420 }}>
        <Page size="A4">
          <View style={{ flex: 1, padding: 12 }}>
            <View
              style={{
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{title}</Text>
              <Text style={{ fontSize: 12 }}>
                Generated at {moment().format("Do MMMM YYYY h:mm a")}{" "}
              </Text>
            </View>

            <View style={{ marginTop: 18 }}>
              <View style={{ marginTop: 4 }}>
                <View>
                  <Text style={[styles.text, styles.bold]}>Route</Text>
                  <View style={{ marginRight: 4, marginBottom: 4 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={[styles.text, { width: 120 }]}>Truck </Text>
                      <Text style={[styles.text, {}]}>
                        : {data?.truck.registration_number}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={[styles.text, { width: 120 }]}>
                        Trailer{" "}
                      </Text>
                      <Text style={[styles.text, {}]}>
                        : {data?.truck.registration_number}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={[styles.text, { width: 120 }]}>
                        Initial Location{" "}
                      </Text>
                      <Text style={[styles.text, {}]}>
                        : {data?.initial_location}
                      </Text>
                    </View>

                    <View
                      style={{
                        marginTop: 2,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={[styles.text, { width: 120 }]}>
                        Final Location{" "}
                      </Text>
                      <Text style={[styles.text, {}]}>
                        : {data?.final_location}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={{ marginTop: 6 }}>
                  <Text style={[styles.text, styles.bold]}>
                    Route Positions
                  </Text>
                  <View style={{ marginTop: 4 }}>
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={[styles.tableColOneThird]}>
                          <Text
                            style={[styles.tableCell, { fontWeight: "bold" }]}
                          >
                            No
                          </Text>
                        </View>
                        <View style={[styles.tableColOneThird]}>
                          <Text
                            style={[styles.tableCell, { fontWeight: "bold" }]}
                          >
                            Position
                          </Text>
                        </View>

                        <View style={[styles.tableColOneThird]}>
                          <Text
                            style={[styles.tableCell, { fontWeight: "bold" }]}
                          >
                            Date
                          </Text>
                        </View>
                      </View>
                      {data.route_positions ? (
                        <View>
                          {data.route_positions?.map((position, index) => {
                            return (
                              <View style={styles.tableRow}>
                                <View style={[styles.tableColOneThird]}>
                                  <Text style={styles.tableCell}>
                                    {index + 1}
                                  </Text>
                                </View>
                                <View style={[styles.tableColOneThird]}>
                                  <Text style={styles.tableCell}>
                                    {position?.position}
                                  </Text>
                                </View>

                                <View style={[styles.tableColOneThird]}>
                                  <Text style={styles.tableCell}>
                                    {moment(position?.created_at).format(
                                      "Do MMMM YYYY"
                                    )}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      ) : (
                        <View></View>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View></View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const CustomerRouteReport = ({ route_id, name }) => {
  const auth = useAuth();
  const token = auth?.token || "";

  const { isLoading, error, data } = useQuery(
    [],
    () => getSingleRoute({ route_id, token })
  );

  const renderReport = () => {
    if (error) {
      return <p>Something went wrong fetching data</p>;
    }
    if (isLoading) return "Loading report";
    if (data) {
      const positions = data.data;
      if (positions.success) {
        // console.log(positions.data);
        const route = positions?.data || {};
        return (
          <>
            <CustomerInvoicePositionsReport
              title={`${name}'s Route Positions Report`}
              data={route}
            />
          </>
        );
      } else {
        <>
          <p className="text-red-500">{positions.message}</p>
        </>;
      }
    }
  };

  return <div className="mb-24">{renderReport()}</div>;
};

const CustomerPositionsGeneratedReport = () => {
  const dispatch = useDispatch();

  const auth = useAuth();
  const token = auth?.token || "";

  const { customerID } = useParams();

  const [customerInvoice, setCustomerInvoice] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getInvoices = async () => {
    const response = await getCustomerInvoices({
      customer_id: customerID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addCustomerInvoices(response.data.data));
      }
    }
  };

  useEffect(() => {
    getInvoices();
  }, [dispatch]);

  const customerInvoices = useSelector(selectCustomerInvoices);
  const customer = useSelector(selectCustomerDetails);

  let sortedInvoices = [];

  if (customerInvoices.length !== 0) {
    customerInvoices.map((invoice) => {
      sortedInvoices.push({
        id: invoice.id,
        value: invoice.invoice_number,
        route_id: invoice.route_id,
      });
    });
  }

  const generateReport = async (e) => {
    e.preventDefault();

    if (customerInvoice) {
      //return
      setOpen(true);
    } else {
      notifyToastError({
        message: "Please select customer invoice",
        icon: "❗",
      });
    }
  };

  const handleOnChange = (value, id) => {
    // console.log(id);
    setCustomerInvoice(id);
  };

  const customerInvoiceSearch = () => {
    return (
      <div>
        <h4 className="text-center text-xl py-2">
          GENERATE CUSTOMER' ROUTE POSITIONS USING INVOICE NUMBER
        </h4>
        <div className="pt-8 flex justify-center">
          <Select
            showSearch
            style={{
              width: 450,
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            size="middle"
            filterOption={(input, option) =>
              (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.value ?? "")
                .toLowerCase()
                .localeCompare((optionB?.value ?? "").toLowerCase())
            }
            onChange={handleOnChange}
            options={sortedInvoices}
          />
        </div>

        <div className="py-2 flex justify-center">
          <Button
            size="large"
            variant="contained"
            className="w-[75%]"
            onClick={(e) => generateReport(e)}
          >
            SUBMIT
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Grid container sx={{ px: 2 }}>
      <Grid
        item
        sm={12}
        sx={{ display: "flex", justifyContent: "center", py: 2 }}
      >
        <Box sx={{ width: 620, height: 250 }} className="rounded-md shadow-xl">
          {customerInvoiceSearch()}
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style}>
            <div className="mx-auto bg-white p-2 pt-4">
              <div className="flex items-center justify-center">
                <h1 className="text-center text-xl font-semibold mb-2"></h1>
              </div>
              <CustomerRouteReport route_id={customerInvoice?.route_id} name={customer?.customer_name} />
            </div>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default CustomerPositionsGeneratedReport;
