import React from 'react';
import { DirectionsBus } from '@mui/icons-material';
import { FaRoute } from 'react-icons/fa';

const TodayTripsCard = ({value}) => {
  return (
    <div className="bg-gradient-to-r from-[#323232] to-[#478C5A] h-[140px] w-[100%] rounded-md border-2">
      <div className="px-4 py-4">
        <div className="flex flex-row gap-4">
          <p className="text-2xl">
            <FaRoute className="text-white" />
          </p>
          <div>
            <h4 className="text-white text-lg font-semibold">
              Trips started today
            </h4>
          </div>
        </div>
        <div className="py-3">
          <p className="text-3xl md:text-xl sm:text-xl text-white font-bold">{value? <span>{value}</span> : 0}</p>
        </div>
        <div className="flex flex-row gap-6 justify-between">
          <div className="flex flex-row"></div>
          <div>
            <p>
              <DirectionsBus
                sx={{
                  color: "#CCCCCC",
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayTripsCard;
