import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import tables from "../../styles/tables.module.css";
import AddStockTransfer from "./AddStockTransfer";
import { useSelector } from "react-redux";
import { selectStockDetails } from "../../features/stockSlice";
import { isEmpty } from "lodash";

const StockTransfers = () => {
  const stock = useSelector(selectStockDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderItems = () => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.transferItemsTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Quantity</p>
              <p className="py-2">Item Price</p>
              <p className="py-2">Total Amount</p>
              <p className="py-2">Dispatch</p>
              <p className="py-2">Date</p>
              <p className="py-2">Truck No</p>
              <p className="py-2">Trip No</p>
              <p className="py-2">Route</p>
            </div>
            <div className="bg-[#F0F0F0] h-[300px] overflow-y-scroll">
              {renderTransferredItems()}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderTransferredItems = () => {
    if (!isEmpty(stock)) {
      if (stock?.dispatches && stock?.dispatches.length > 0) {
        //sort by date
        const sortedStocks = stock.dispatches
          .slice()
          .sort((a, b) => new Date(b.date) - new Date(a.date));

        return (
          <div>
            {sortedStocks.map((item, index) => {
              const position = index + 1;
              return (
                <div
                  key={index}
                  className={`bg-white h-18 ${tables.transferItemsTable} rounded-md text-sm my-2`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 text-center">{item?.quantity}</p>
                  <p className="py-2 pl-4">{formatter.format(item?.amount)}</p>
                  <p className="py-2 pl-4">
                    {formatter.format(item.total_amount)}
                  </p>
                  <p className="py-2 pl-5">{item?.dispatch}</p>
                  <p className="py-2 text-center">{item?.date}</p>
                  <p className="py-2 text-center">
                    {item?.truck?.registration_number}
                  </p>
                  <p className="py-2 text-center">{item?.trip?.trip_number}</p>
                  <p className="py-2 pl-4">
                    {item?.route ? (
                      <span>
                        {item?.route?.initial_location} -{" "}
                        {item?.route?.final_location}
                      </span>
                    ) : null}
                  </p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div>
            <p className="text-center">There are no orders</p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p className="text-center">There are no orders</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 2, py: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <AddStockTransfer />
      </Grid>
      <Grid item sm={12}>
        <Box>
          <h5>Stock Item Transfer List</h5>
          <div className="w-[100%] pb-6">{renderItems()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StockTransfers;
