import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import tables from "../../styles/tables.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdEdit, MdRemoveCircle } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, IconButton, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { addExpenseTypes, addItemTypes, selectExpenseTypes, selectItemTypes } from "../../features/settingSlice";
import {
  addRegisteredNames,
  addSuppliers,
  selectRegisteredNames,
  selectSuppliers,
} from "../../features/userSlice";
import {
  getAllRegisteredNames,
  getAllSuppliers,
} from "../../api/users/userAPI";
import { getAllExpenseTypes, getAllItemsTypes } from "../../api/settings/settingAPI";
import useAuth from "../../hooks/useAuth";
import { getAllStockOrders, updateStockOrder } from "../../api/stock/stockAPI";
import {
  addOrderList,
  addStockOrders,
  selectOrderList,
} from "../../features/stockSlice";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const AddItem = ({ sortedItemTypes }) => {
  const [openChild, setOpenChild] = useState(false);
  const handleOpenChild = () => {
    setOpenChild(true);
  };
  const handleCloseChild = () => {
    setOpenChild(false);
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [item_type, setType] = useState("");
  const [quantity, setNumber] = useState("");
  const [item_price, setPrice] = useState("");
  const [amount, setOrderAmount] = useState("");
  const [item_amount, setItemAmount] = useState("");

  const typeOnChange = (e, value) => {
    setType(value);
  };

  let formatter = new Intl.NumberFormat("en-uS");

  const getTotal = () => {
    let total = quantity * item_price;
    setItemAmount(total);
    if (isNumber(total)) {
      setOrderAmount(formatter.format(total));
    }
  };

  useEffect(() => {
    getTotal();
  }, [quantity, item_price]);

  let orderItems = useSelector(selectOrderList);

  const storeItems = async (e) => {
    e.preventDefault();

    if (!item_type) {
      notifyToastError({
        message: "Please select item type",
        icon: "❗",
      });
    } else if (!quantity) {
      notifyToastError({
        message: "Please enter items quantity",
        icon: "❗",
      });
    } else if (!item_price) {
      notifyToastError({
        message: "Please enter item price",
        icon: "❗",
      });
    } else if (!item_amount) {
      notifyToastError({
        message: "Please enter item total amount",
        icon: "❗",
      });
    } else {
      //store details
      setLoading(true);
      const data = {
        id: item_type.id,
        item_type,
        quantity,
        price: item_price,
        total_price: item_amount,
      };
      orderItems = [...orderItems, data];
      if (orderItems.length > 0) {
        setType("");
        setNumber("");
        setPrice("");
        setOrderAmount("");
        setItemAmount("");

        dispatch(addOrderList(orderItems));
        setLoading(false);

        notifyToastSuccess({
          message: "Item is stored successfully",
          icon: "✅",
        });
      }
    }
  };

  const renderItemButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[90%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[90%]"
          onClick={(e) => storeItems(e)}
        >
          UPDATE ORDER ITEM TO LIST
        </Button>
      );
    }
  };

  return (
    <div>
      <Button
        onClick={handleOpenChild}
        size="small"
        variant="contained"
        className="w-[100%]"
      >
        Edit Item
      </Button>
      <Modal
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 700 }}>
          <div>
            <h3 className="text-center text-xl py-4">Edit Order Items</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedItemTypes}
                  size="small"
                  className="w-[45%]"
                  value={item_type}
                  onChange={typeOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Item" />
                  )}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item price"
                  variant="outlined"
                  className="w-[45%]"
                  type={"number"}
                  value={item_price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item quantity"
                  variant="outlined"
                  className="w-[45%]"
                  type={"number"}
                  value={quantity}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Total amount"
                  variant="outlined"
                  className="w-[45%]"
                  value={amount}
                  onChange={(e) => setOrderAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderItemButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const EditOrder = ({ order }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOrderItems();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(addOrderList([]));
  };

  const [order_type, setOrderType] = useState(order.order_type);
  const [supplier, setSupplier] = useState({
    id: order.supplier_id,
    label: order.supplier.supplier_name,
  });
  const [order_name, setOrderName] = useState({
    id: order.order_person?.id,
    label: order.order_person?.name,
  });
  const [order_date, setDate] = useState(moment(order.order_date));
  const [description, setDescription] = useState(order.description);

  const dispatch = useDispatch();

  const auth = useAuth();
  const token = auth?.token || "";

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  const nameOnChange = (e, value) => {
    setOrderName(value);
  };

  const getOrders = async () => {
    const response = await getAllStockOrders({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addStockOrders(response.data.data));
    }
  };

  const getTypes = async () => {
    const response = await getAllExpenseTypes({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addExpenseTypes(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  const getNames = async () => {
    const response = await getAllRegisteredNames({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRegisteredNames(response.data.data));
    }
  };

  let itemsArray = [];

  const setOrderItems = () => {
    if (order.ordered_items.length > 0) {
      order.ordered_items.map((item) => {
        const data = {
          id: item.item_id,
          item_type: { id: item.item_id, label: item?.item_name },
          quantity: item.quantity,
          price: item.price,
          total_price: item.total_price,
        };
        itemsArray = [...itemsArray, data];
      });

      if (itemsArray.length > 0) {
        dispatch(addOrderList(itemsArray));
      }
    }
  };

  useEffect(() => {
    getSuppliers();
    getTypes();
    getNames();
  }, [dispatch]);

  const suppliers = useSelector(selectSuppliers);
  const allItemTypes = useSelector(selectExpenseTypes);
  const orderItems = useSelector(selectOrderList);
  const names = useSelector(selectRegisteredNames);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let orderTotalAmount = 0;

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));

  const sortedItemTypes = allItemTypes.map((item) => ({
    id: item.id,
    label: item.item_name,
  }));

  const sortedNames = names.map((name) => ({
    id: name.id,
    label: name.name,
  }));

  if (orderItems.length !== 0) {
    if (orderItems.length === 1) {
      orderTotalAmount = orderItems[0].total_price;
    } else {
      orderTotalAmount = orderItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total_price,
        0
      );
    }
  }

  const saveOrder = async (e) => {
    e.preventDefault();

    if (!supplier) {
      notifyToastError({
        message: "Please select supplier",
        icon: "❗",
      });
    } else if (!order_type) {
      notifyToastError({
        message: "Please select order type",
        icon: "❗",
      });
    } else if (!order_name) {
      notifyToastError({
        message: "Please select order name",
        icon: "❗",
      });
    } else if (orderItems.length < 0) {
      notifyToastError({
        message: "Please add order items",
        icon: "❗",
      });
    } else if (!order_date) {
      notifyToastError({
        message: "Please select expense date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(order_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await updateStockOrder({
        supplier_id: supplier.id,
        order_type,
        order_person: order_name?.id,
        total_order_price: orderTotalAmount,
        order_date: date,
        truck_id: order?.truck_id || "",
        trip_id: order?.trip_id || "",
        route_id: order?.route_id || "",
        ordered_items: orderItems,
        description,
        order_id: order?.id || "",
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getOrders();

        notifyToastSuccess({
          message: "Order is updated successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[98%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[98%]"
          onClick={(e) => saveOrder(e)}
        >
          EDIT ORDER
        </Button>
      );
    }
  };

  const renderRemove = (order) => {
    if (!isEmpty(order)) {
      const handleRemoveItem = () => {
        const itemsList = orderItems.filter(
          (orderItem) => orderItem?.id !== order.id
        );

        dispatch(addOrderList(itemsList));
      };

      return (
        <div onClick={handleRemoveItem} className="cursor-pointer">
          <MdRemoveCircle className="text-[#F24E1E] text-xl ml-0" />
        </div>
      );
    }
  };

  const renderOrderItems = () => {
    if (orderItems.length !== 0) {
      return (
        <div>
          {orderItems.map((order, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.itemsList} rounded-md text-sm my-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">{order?.item_type?.label}</p>
                <p className="py-2 text-center">
                  {formatter.format(order?.price || 0)}
                </p>
                <p className="py-2 text-center">{order?.quantity}</p>
                <p className="py-2 text-center">
                  {formatter.format(order?.total_price || 0)}
                </p>
                <p className="py-2 flex justify-center cursor-pointer">
                  {renderRemove(order)}
                </p>
              </div>
            );
          })}
          <div className="flex justify-center py-2">
            <AddItem sortedItemTypes={sortedItemTypes} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center py-2">
          <AddItem sortedItemTypes={sortedItemTypes} />
        </div>
      );
    }
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleOpen}>
        <MdEdit className="text-[#F24E1E] text-xl" />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Edit Order Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
              <Autocomplete
                  id="combo-box-demo"
                  options={sortedNames}
                  size="small"
                  className="w-[50%]"
                  value={order_name}
                  onChange={nameOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Order Name" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[50%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="large"
                  id="outlined-select-currency"
                  select
                  label="Order Type"
                  className="w-[50%]"
                  value={order_type}
                  onChange={(e) => setOrderType(e.target.value)}
                >
                  <MenuItem value={"stock"}>Stock</MenuItem>
                </TextField>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select order date"
                    value={order_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[50%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="pb-2">
                <div
                  className={`bg-[#EEDEDE] h-10 text-center ${tables.itemsList} rounded-t-md font-semibold`}
                >
                  <p className="py-2">No</p>
                  <p className="py-2">Item Name</p>
                  <p className="py-2">Item Price</p>
                  <p className="py-2">Quantity</p>
                  <p className="py-2">Total</p>
                  <p className="py-2">Actions</p>
                </div>
                <div className="bg-[#F0F0F0] h-[100px] overflow-y-scroll">
                  {renderOrderItems()}
                </div>
                <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md flex justify-center">
                  <p className="font-semibold py-2">
                    {formatter.format(orderTotalAmount)}
                  </p>
                </div>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={2}
                  variant="outlined"
                  className="w-[98%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditOrder;
