import React from "react";
import { Copyright, DirectionsBus } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import { colors } from "../assets/utils/colors";
import moment from "moment";
import { isMobile } from "react-device-detect";

const Footer = () => {
  const year = moment().format("YYYY");
  return (
    <>
      {isMobile ? (
        <div className="fixed bg-[#323232] z-1 h-[35px] bottom-0 right-0 left-0">
          <div className="flex flex-row text-xs justify-between px-4">
            <Copyright sx={{ mr: 1, color: colors.bgColor2, }} />
            <div className="text-white">
              Copyright {year}. All right reserved.
              <br/>
              Developed by Buibui technologies
            </div>
            <DirectionsBus sx={{ color: colors.bgColor2, }} />
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: 35,
            bgcolor: colors.primary,
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box></Box>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xl: 12,
                  lg: 12,
                  md: 10,
                  sm: 10,
                  xs: 10,
                },
                color: colors.bgColor2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Copyright sx={{ mr: 1 }} />
              Copyright {year}. All right reserved. Developed by&nbsp;
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://www.buibuitech.co.tz"
              >
                <span className="text-white">Buibui technologies</span>
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography>
              <DirectionsBus sx={{ color: colors.bgColor2, mr: 5, mt: 1 }} />
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Footer;
