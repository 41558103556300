import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaUserPlus } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  createEmployee,
  getAllDesignations,
  getAllEmployees,
} from "../../api/users/userAPI";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  addDesignations,
  addEmployees,
  selectDesignations,
} from "../../features/userSlice";
import { Toaster } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddEmployee = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  // TODO  : to write the following states using react-hook-form
  const [first_name, setFirstName] = useState();
  const [middle_name, setMiddleName] = useState();
  const [last_name, setLastName] = useState();
  const [phone_number, setPhoneNumber] = useState([]);
  const [gender, setGender] = useState();
  const [passport_number, setPassport] = useState();
  const [salary_amount, setSalary] = useState();
  const [license_number, setLicense] = useState();
  const [designation_id, setDesignation] = useState();

  const queryClient = useQueryClient();

  const getDesignations = async () => {
    const response = await getAllDesignations({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addDesignations(response.data.data));
      }
    }
  };

  const getEmployees = async () => {
    const response = await getAllEmployees({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployees(response.data.data));
      }
    }
  };

  useEffect(() => {
    getDesignations();
  }, [dispatch]);

  const designations = useSelector(selectDesignations);

  const { mutate, isLoading, isError } = useMutation(createEmployee, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["employees"] });

        notifyToastSuccess({
          message: "Employee is registered successfully",
          icon: "✅",
        });
        getEmployees();
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setPhoneNumber([]);
        setGender("");
        setDesignation("");
        setPassport("");
        setLicense("");
        setSalary("");
        handleClose();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
    },
  });

  const employeeRegistration = async (e) => {
    e.preventDefault();

    if (!first_name) {
      notifyToastError({
        message: "Please enter employee first name",
        icon: "❗",
      });
    } else if (!middle_name) {
      notifyToastError({
        message: "Please enter employee middle name",
        icon: "❗",
      });
    } else if (!last_name) {
      notifyToastError({
        message: "Please enter employee last name",
        icon: "❗",
      });
    } else if (!phone_number) {
      notifyToastError({
        message: "Please enter employee phone numbers",
        icon: "❗",
      });
    } else if (!passport_number) {
      notifyToastError({
        message: "Please enter employee passport number",
        icon: "❗",
      });
    } else if (!gender) {
      notifyToastError({
        message: "Please select employee gender",
        icon: "❗",
      });
    } else if (!salary_amount) {
      notifyToastError({
        message: "Please enter employee salary amount",
        icon: "❗",
      });
    } else if (!license_number) {
      notifyToastError({
        message: "Please enter employee licence number",
        icon: "❗",
      });
    } else if (!designation_id) {
      notifyToastError({
        message: "Please enter employee designation",
        icon: "❗",
      });
    } else {
      //start registration
      mutate({
        designation_id,
        first_name,
        middle_name,
        last_name,
        phone_number: JSON.stringify(phone_number),
        gender,
        passport_number,
        salary_amount,
        license_number,
        token,
      });
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <p className="flex justify-center items-center">
            <FaUserPlus className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">Add Employee</p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new employee to existing employees list
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Toaster />
            <h3 className="text-center text-xl py-4">Add Employee Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="First name"
                  variant="outlined"
                  className="w-[27%]"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Middle name"
                  variant="outlined"
                  className="w-[25%]"
                  value={middle_name}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Last name"
                  variant="outlined"
                  className="w-[27%]"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  className="w-[40%]"
                  onChange={(e) => {
                    let p = [...phone_number];
                    p[0] = e.target.value;
                    setPhoneNumber(p);
                  }}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  className="w-[40%]"
                  onChange={(e) => {
                    let p = [...phone_number];
                    p[1] = e.target.value;
                    setPhoneNumber(p);
                  }}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  className="w-[40%]"
                  onChange={(e) => {
                    let p = [...phone_number];
                    p[2] = e.target.value;
                    setPhoneNumber(p);
                  }}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  className="w-[40%]"
                  onChange={(e) => {
                    let p = [...phone_number];
                    p[3] = e.target.value;
                    setPhoneNumber(p);
                  }}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Gender"
                  className="w-[40%]"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value={""}></MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                </TextField>
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Designation"
                  className="w-[40%]"
                  value={designation_id}
                  onChange={(e) => setDesignation(e.target.value)}
                >
                  {designations.length !== 0
                    ? designations.map((designation, index) => (
                        <MenuItem key={index} value={designation.id}>
                          {designation.designation_name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Passport number"
                  variant="outlined"
                  className="w-[82%]"
                  value={passport_number}
                  onChange={(e) => setPassport(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="License number"
                  variant="outlined"
                  className="w-[40%]"
                  value={license_number}
                  onChange={(e) => setLicense(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Salary amount"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={salary_amount}
                  onChange={(e) => setSalary(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <Button
                  size="large"
                  variant="contained"
                  className="w-[82%]"
                  onClick={(e) => employeeRegistration(e)}
                  disabled={isLoading}
                >
                  {isLoading ? "SAVING EMPLOYEE ..." : "SAVE EMPLOYEE"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddEmployee;
