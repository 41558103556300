import React from "react";
import { Grid } from "@mui/material";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const DeviceInfo = () => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(`/`);
  };

  return (
    <Grid container spacing={2} sx={{px: 2}}>
      <Grid item sm={12} xs={12}>
        <div className="py-2">
          <IoArrowBackCircle
            onClick={() => handleBackButton()}
            className="text-3xl cursor-pointer text-[#F24E1E]"
          />
        </div>
        <div className="shadow-md mt-4 border border-slate-800 px-2 py-4">
          <h4 className="text-xl text-center">
            Sorry! MSA-TRANSPORT SYSTEM has no mobile version for users with
            your role.
          </h4>
        </div>
      </Grid>
    </Grid>
  );
};

export default DeviceInfo;
