import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trips: [],
  filteredTrips: [],
  tripRoutes: [],
  tripExpenses: [],
  tripInvoices: [],
  tripPositions: [],
  tripDetails: "",
  routeDetails: "",
};

const tripSlice = createSlice({
  name: "trip",
  initialState,
  reducers: {
    addTrips(state, action) {
      state.trips = action.payload;
    },

    addFilteredTrips(state, action) {
      state.filteredTrips = action.payload;
    },

    addTripRoutes(state, action) {
      state.tripRoutes = action.payload;
    },

    addRouteExpenses(state, action) {
      state.tripExpenses = action.payload;
    },

    addRouteInvoices(state, action) {
      state.tripInvoices = action.payload;
    },

    addRoutePositions(state, action) {
      state.tripPositions = action.payload;
    },

    addTripDetails(state, action) {
      state.tripDetails = action.payload;
    },

    addRouteDetails(state, action) {
      state.routeDetails = action.payload;
    },
  },
});

export const { addTrips, addTripRoutes, addRouteExpenses, addRouteInvoices, addRoutePositions, addTripDetails, addRouteDetails, addFilteredTrips  } = tripSlice.actions;

export const selectTrips = (state) => state.trip.trips;
export const selectFilteredTrips = (state) => state.trip.filteredTrips;
export const selectTripRoutes = (state) => state.trip.tripRoutes;
export const selectRouteExpenses = (state) => state.trip.tripExpenses;
export const selectRouteInvoices = (state) => state.trip.tripInvoices;
export const selectRoutePositions = (state) => state.trip.tripPositions;
export const selectTripDetails = (state) => state.trip.tripDetails;
export const selectRouteDetails = (state) => state.trip.routeDetails;

export default tripSlice.reducer;
