import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdEdit } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { getAllPaymentTypes, updatePaymentType } from "../../api/settings/settingAPI";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { addPaymentTypes } from "../../features/settingSlice";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditPaymentType = ({paymentType}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState(paymentType.payment_name);
  const [description, setDescription] = useState(paymentType.description);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getPaymentTypes = async () => {
    const response = await getAllPaymentTypes({token});

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addPaymentTypes(response.data.data));
      }
    }
  };

  const paymentTypeUpdate = async (e) => {
    e.preventDefault();

    if (!name) {
      notifyToastError({
        message: "Please enter payment type name",
        icon: "❗",
      });
    } else {
      if (!isEmpty(paymentType)) {
        //start registration
        let paymentTypeName = name.toLowerCase();
        setLoading(true);

        const response = await updatePaymentType({
          payment_name: paymentTypeName,
          description,
          paymentType_id: paymentType.id,
          token
        });

        if (response) {
          checkResponse(response);
        } else {
          setLoading(false);
          notifyToastError({
            message: "No response returned",
            icon: "❗",
          });
        }
      } else {
        notifyToastError({
          message: "Please select payment type to update",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setName("");
        setDescription("");
        getPaymentTypes();

        notifyToastSuccess({
          message: "Payment type is updated successfully",
          icon: "✅",
        });

        handleClose();
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%] cursor-not-allowed"
            disabled
          >
            <svg
              class="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
            Loading...
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%]"
            onClick={(e) => paymentTypeUpdate(e)}
          >
            UPDATE PAYMENT TYPE
          </Button>
        </>
      );
    }
  };


  return (
    <div>
      <div
        onClick={handleOpen}
        className="cursor-pointer py-2 flex justify-center"
      >
        <MdEdit className="text-[#F24E1E] text-xl ml-5" />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Toaster/>
            <h3 className="text-center text-xl py-4">Edit Payment Type</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  className="w-[82%]"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditPaymentType;
