import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdEdit } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllTrucks } from "../../api/trucks/trucksAPI";
import { isEmpty } from "lodash";
import { addTrucks, selectTrucks } from "../../features/truckSlice";
import { getAllEmployees } from "../../api/users/userAPI";
import { addEmployees, selectEmployees } from "../../features/userSlice";
import {
  getAllTrips,
  updateTrip,
  updateTripDriver,
  updateTripTruck,
} from "../../api/trips/tripsAPI";
import { addTrips } from "../../features/tripsSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditTrip = ({ trip, onSuccess = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [truck, setTruck] = useState({
    id: trip.truck_id,
    label: trip.truck_registration_number,
  });
  const [driver, setDriver] = useState({
    id: trip.driver_id,
    label: trip.driver,
  });
  const [start_date, setDate] = useState(moment(trip.start_date));
  const [description, setDescription] = useState(trip.description);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all trucks
  const getTrucks = async () => {
    const response = await getAllTrucks({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrucks(response.data.data));
      }
    }
  };

  //fetch all employees
  const getDriver = async () => {
    const response = await getAllEmployees({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployees(response.data.data));
      }
    }
  };

  //fetch all trips
  const getTrip = async () => {
    const response = await getAllTrips({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTrips(response.data.data));
      }
    }
  };

  useEffect(() => {
    getTrucks();
    getDriver();
  }, [dispatch]);

  const truckOnChange = (e, value) => {
    setTruck(value);
  };

  const driverOnChange = (e, value) => {
    setDriver(value);
  };

  const trucks = useSelector(selectTrucks);
  const employees = useSelector(selectEmployees);

  const sortedTrucks = trucks
    .filter((truck) => truck.trailer && truck.isOnTrip < 1)
    .map((truck) => ({
      id: truck.id,
      label: truck.registration_number,
    }));

  const sortedDrivers = employees
    .filter(
      (driver) =>
        driver.designation_name.toLowerCase() === "driver" &&
        driver.isOnTrip < 1
    )
    .map((driver) => ({
      id: driver.id,
      label: `${driver.first_name} ${driver.middle_name} ${driver.last_name}`,
    }));

  const tripRegistration = async (e) => {
    e.preventDefault();

    if (!truck) {
      notifyToastError({
        message: "Please select truck",
        icon: "❗",
      });
    } else if (!driver) {
      notifyToastError({
        message: "Please select driver",
        icon: "❗",
      });
    } else if (!start_date) {
      notifyToastError({
        message: "Please select date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(start_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);

      let driverResponse;
      if (driver.id !== trip.driver_id) {
        driverResponse = await updateTripDriver({
          driver_id: driver.id,
          trip_id: trip.trip_id,
          token,
        });
      }

      let truckResponse;
      if (truck.id !== trip.truck_id) {
        truckResponse = await updateTripTruck({
          truck_id: truck.id,
          trip_id: trip.trip_id,
          token,
        });
      }

      const response = await updateTrip({
        start_date: date,
        description,
        trip_id: trip.trip_id,
        token,
      });

      if (response && truckResponse) {
        checkResponse(truckResponse);
      } else if (response && driverResponse) {
        checkResponse(driverResponse);
      } else if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getTrip();
        getDriver();
        getTrucks();

        notifyToastSuccess({
          message: "Trip is updated successfully",
          icon: "✅",
        });
        onSuccess();
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => tripRegistration(e)}
        >
          UPDATE TRIP
        </Button>
      );
    }
  };

  return (
    <>
      <IconButton variant="outlined" onClick={handleOpen}>
        <MdEdit className="text-[#F24E1E] text-xl" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            {/* <Toaster /> */}
            <h3 className="text-center text-xl py-0">Edit Trip Details</h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedTrucks}
                  size="small"
                  className="w-[82%]"
                  value={truck}
                  onChange={truckOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Truck" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedDrivers}
                  size="middle"
                  className="w-[40%]"
                  value={driver}
                  onChange={driverOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Driver" />
                  )}
                />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select start date"
                    value={start_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[40%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EditTrip;
