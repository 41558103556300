import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import { Modal } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllMonthlyExpenses, getOwnerPersonalExpense } from "../../api/expenses/expensesAPI";
import { getOwnerPersonalRevenue } from "../../api/revenue/revenueAPI";
import { getAllTrips } from "../../api/trips/tripsAPI";
import { getUser } from "../../api/users/userAPI";
import { colors } from "../../assets/utils/colors";
import { authenticationAtom } from "../../atoms/auth";
import {
  addMonthlyExpenses,
  addOwnerExpenses,
  selectMonthlyExpenses,
  selectOwnerExpenses,
} from "../../features/expensesSlice";
import { addOwnerRevenues, selectOwnerRevenues } from "../../features/revenueSlice";
import { addTrips, selectTrips } from "../../features/tripsSlice";
import {
  addUserCustomDetails,
  selectUserCustomDetails,
} from "../../features/userSlice";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import ExpensesCard from "../cards/ExpensesCard";
import IncomeCard from "../cards/IncomeCard";
import ProfitCard from "../cards/ProfitCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import { getAllTrucks } from "../../api/trucks/trucksAPI";
import { addTrucks } from "../../features/truckSlice";

const ViewExpenseAction = (expense) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addedTime = moment(expense?.expense.created_at).format("DD-MM-YYYY");
  const updateTime = moment(expense?.expense.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="User Personal Expense Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Added by :{" "}
            {expense.expense.added_by ? (
              <span>
                {expense.expense.added_by.first_name}{" "}
                {expense.expense.added_by.middle_name}{" "}
                {expense.expense.added_by.last_name}{" "}
                <span className="text-xs">
                  ({expense.expense.added_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Added at :{" "}
            {expense.expense.created_at ? <span>{addedTime}</span> : null}
          </p>
          <p>
            Updated by :{" "}
            {expense.expense.updated_by ? (
              <span>
                {expense.expense.updated_by.first_name}{" "}
                {expense.expense.updated_by.middle_name}{" "}
                {expense.expense.updated_by.last_name}{" "}
                <span className="text-xs">
                  ({expense.expense.updated_by.role_name})
                </span>
              </span>
            ) : null}
          </p>
          <p>
            Updated at :{" "}
            {expense.expense.updated_at ? <span>{updateTime}</span> : null}
          </p>
        </div>
      </Modal>
    </>
  );
};

const UserExpenses = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();

  const [userData] = useAtom(authenticationAtom);
  const auth = useAuth();
  const { userInfo: user } = userData;
  const token = auth?.token || "";

  //fetch all

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUser({ user_id: userID, token: auth?.token });
  
      if (response.ok && !isEmpty(response.data)) {
        dispatch(addUserCustomDetails(response.data.data));
      }
    };

    const getTrucks = async () => {
      const response = await getAllTrucks({ token });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addTrucks(response.data.data));
      }
    };

    const getTrips = async () => {
      const response = await getAllTrips({ token });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addTrips(response.data.data));
      }
    };

    const getExpenses = async () => {
      const response = await getOwnerPersonalExpense({
        owner_id: userID,
        token,
      });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addOwnerExpenses(response.data.data));
      } else {
        dispatch(addOwnerExpenses([]));
      }
    };

    const getRevenue = async () => {
      const response = await getOwnerPersonalRevenue({
        owner_id: userID,
        token,
      });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addOwnerRevenues(response.data.data));
      } else {
        dispatch(addOwnerRevenues([]));
      }
    };

    const getIndirectExpenses = async () => {
      const response = await getAllMonthlyExpenses({ token });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addMonthlyExpenses(response.data.data));
      }
    };

    fetchUser();
    getTrucks();
    getTrips();
    getExpenses();
    getRevenue();
    getIndirectExpenses();
  }, [dispatch]);

  const trips = useSelector(selectTrips);
  const expenses = useSelector(selectOwnerExpenses);
  const revenues = useSelector(selectOwnerRevenues);
  const indirectExpenses = useSelector(selectMonthlyExpenses);
  const userDetails = useSelector(selectUserCustomDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const userTripsArray = trips.filter((trip) => {
    //filtering array value
    return trip.owner_id === userID;
  });

  const userIndirectExpensesArray = indirectExpenses.filter((expense) => {
    //filtering array value
    return expense?.truck?.owner_id === userID;
  });

  let invoicesTotalAmount = 0;
  let expenseTotalAmount = 0;
  let profitTotalAmount = 0;
  let personalExpensesTotalAmount = 0;
  let personalRevenueTotalAmount = 0;
  let balanceTotalAmount = 0;
  let unpaidAmount = 0;
  let availableBalance = 0;
  let indirectAmount = 0;

  if (userTripsArray.length !== 0) {
    for (const value of userTripsArray) {
      invoicesTotalAmount += value.total_trip_invoice;
      expenseTotalAmount += value.total_trip_expenses;
    }
  }

  if (expenses.length !== 0) {
    for (const value of expenses) {
      personalExpensesTotalAmount += value.amount;
    }
  }

  if (revenues.length !== 0) {
    for (const value of revenues) {
      personalRevenueTotalAmount += value.amount;
    }
  }

  if (userIndirectExpensesArray.length !== 0) {
    for (const value of userIndirectExpensesArray) {
      indirectAmount += value.amount;
    }
  }

  let amountArray = [];

  userTripsArray.map((trip) => {
    //filtering array value
    if (trip.total_trip_invoice > 0) {
      if (trip.trip_routes) {
        const routes = trip.trip_routes;

        routes.map((route) => {
          if (route.route_invoice) {
            amountArray.push(route.route_invoice.unpaid_amount);
          }
        });
      }
    }
    return 0;
  });

  if (amountArray !== 0) {
    unpaidAmount = amountArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  }

  profitTotalAmount = invoicesTotalAmount - expenseTotalAmount - indirectAmount;
  balanceTotalAmount = personalRevenueTotalAmount + profitTotalAmount - personalExpensesTotalAmount;
  availableBalance = balanceTotalAmount - unpaidAmount;

  const renderView = (expense) => {
    if (user) {
      if (user.role_name.toLowerCase() === "admin") {
        return (
          <>
            <ViewExpenseAction expense={expense} />
          </>
        );
      } else {
        return (
          <>
            <IconButton variant="outlined" className="cursor-not-allowed">
              <RemoveRedEye
                sx={{
                  color: colors.link,
                }}
                className="opacity-25"
              />
            </IconButton>
          </>
        );
      }
    }
  };

  const renderExpenses = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.userPersonalExpenseTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Owner Name</p>
          <p className="py-2">Paid Amount</p>
          <p className="py-2">Date</p>
          <p className="py-2">Description</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[350px] overflow-y-scroll">
          {renderExpense()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderExpense = () => {
    if (expenses.length !== 0) {
      return (
        <div>
          {expenses.map((expense, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.userPersonalExpenseTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {expense.owner ? (
                    <span>
                      {expense.owner.first_name} {expense.owner.middle_name}{" "}
                      {expense.owner.last_name}
                    </span>
                  ) : null}
                </p>
                <p className="py-2 text-center">
                  {formatter.format(expense.amount)}
                </p>
                <p className="py-2 text-center">{expense.expense_date}</p>
                <p className="py-2 text-center">
                  {expense.description ? (
                    <span>{expense.description}</span>
                  ) : null}
                </p>
                <p className="flex flex-row justify-center pl-4">
                  {renderView(expense)}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no personal expenses</p>
        </div>
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <h4 className="text-xl text-center">
          {userDetails?.first_name} {userDetails?.middle_name}{" "}
          {userDetails?.last_name} FINANCIAL DETAILS
        </h4>
      </Grid>
      <Grid item sm={4} xs={12}>
        <IncomeCard
          value={formatter.format(invoicesTotalAmount)}
          owner={true}
          car={false}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <ExpensesCard
          value={formatter.format(expenseTotalAmount)}
          owner={true}
          car={false}
          indirect={formatter.format(indirectAmount)}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <TripsCompletedCard
          trip={true}
          car={true}
          title={"PROFITS"}
          value={formatter.format(profitTotalAmount)}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <TripsProgressCard
          trip={true}
          car={true}
          title={"PERSONAL EXPENSES / REVENUE"}
          value={formatter.format(personalExpensesTotalAmount)}
          revenue={formatter.format(personalRevenueTotalAmount)}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <TripsFailedCard
          trip={true}
          car={true}
          title={"BALANCE"}
          balance={availableBalance}
          value={formatter.format(balanceTotalAmount)}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <ProfitCard
          title={"UNPAID INVOICES AMOUNT"}
          value={formatter.format(unpaidAmount)}
          owner={true}
          car={false}
        />
      </Grid>
      <Grid item sm={12}>
        <Box>
          <h5>Personal Expenses</h5>
          <div className="w-[100%] pb-6">{renderExpenses()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserExpenses;
