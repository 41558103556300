import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { BsCashCoin } from "react-icons/bs";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";
import {
  getAllSuppliers,
  getSupplier,
  getSupplierAllBalances,
} from "../../api/users/userAPI";
import {
  addSupplierBalances,
  addSupplierDetails,
  addSuppliers,
  selectSuppliers,
} from "../../features/userSlice";
import { createSupplierPaymentTransfer } from "../../api/expenses/expensesAPI";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TransferBalance = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [receiver, setReceiver] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { supplierID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const supplierOnChange = (e, value) => {
    setReceiver(value);
  };

  const getBalances = async () => {
    const response = await getSupplierAllBalances({
      token,
      supplier_id: supplierID,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSupplierBalances(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  useEffect(() => {
    getSuppliers();
  }, [dispatch]);

  const fetchSupplier = async () => {
    const response = await getSupplier({ supplierId: supplierID, token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSupplierDetails(response.data.data));
    }
  };

  const suppliers = useSelector(selectSuppliers);

  const sortedSuppliers = suppliers.reduce((accumulator, supplier) => {
    if (supplier.id !== supplierID) {
      accumulator.push({
        id: supplier.id,
        label: supplier.supplier_name,
      });
    }
    return accumulator;
  }, []);
  

  // let sortedSuppliers = [];

  // if (suppliers.length !== 0) {
  //   suppliers.map((supplier) => {
  //     //filtering array value
  //     if(supplier.id !== supplierID){
  //       sortedSuppliers.push({
  //         id: supplier.id,
  //         label: supplier.supplier_name,
  //       });

  //       return null;
  //     }
  //   });
  // }

  const transferBalance = async (e) => {
    e.preventDefault();

    if (!amount) {
      notifyToastError({
        message: "Please enter amount",
        icon: "❗",
      });
    } else if (!receiver) {
      notifyToastError({
        message: "Please select receiver supplier",
        icon: "❗",
      });
    } else {
      //start registration
      setLoading(true);
      const response = await createSupplierPaymentTransfer({
        from_supplier_id: supplierID,
        to_supplier_id: receiver.id,
        amount,
        description,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setAmount("");
        setReceiver("");
        setDescription("");
        getBalances();
        fetchSupplier();

        notifyToastSuccess({
          message: "Supplier balance is transfered successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => transferBalance(e)}
        >
          TRANSFER BALANCE
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[85px] w-[160px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="flex flex-row justify-center items-center pt-4">
          <BsCashCoin className="text-xl" />
        </div>
        <p className="text-lg font-semibold text-center">Transfer Balance</p>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">
              Transfer Balance Details
            </h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Total Amount"
                  variant="outlined"
                  className="w-[82%]"
                  type={"number"}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[82%]"
                  value={receiver}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Receiver Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TransferBalance;
