import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Users from "./Users";
import Customers from "./Customers";
import Suppliers from "./Suppliers";
import Employees from "./Employees";
import { isEmpty } from "lodash";
import { authenticationAtom } from "../../atoms/auth";
import { useAtom } from "jotai";
import { Grid } from "@mui/material";
import Login from "../auth/Login";
import { getRouteExpenses, getRouteInvoices } from "../../api/trips/tripsAPI";
import { addRouteExpenses, addRouteInvoices } from "../../features/tripsSlice";
import { useDispatch } from "react-redux";
import useAuth from "../../hooks/useAuth";
import TruckOwners from "./TruckOwners";
import RegistrationNames from "./RegistrationNames";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const Customer = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [userData] = useAtom(authenticationAtom);

  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    //fetch all trucks
    const getExpenses = async () => {
      const response = await getRouteExpenses({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addRouteExpenses(response.data.data));
        }
      }
    };

    //fetch all invoices
    const getInvoices = async () => {
      const response = await getRouteInvoices({ token });

      if (response.ok) {
        if (!isEmpty(response.data)) {
          dispatch(addRouteInvoices(response.data.data));
        }
      }
    };

    getExpenses();
    getInvoices();
  }, [dispatch]);

  const renderUserTabs = () => {
    const { userInfo: user } = userData;
    if (!isEmpty(user)) {
      if (
        user.role_name.toLowerCase() === "accountant" ||
        user.role_name.toLowerCase() === "clerk"
      ) {
        return (
          <>
            <Grid container>
              <Grid item sm={12}>
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                  className="flex flex-row justify-between"
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    textColor={`${primary}`}
                    indicatorColor="primary"
                    sx={{ color: "#F24E1E" }}
                  >
                    <Tab label="Customers" {...a11yProps(0)} />
                    <Tab label="Employees" {...a11yProps(1)} />
                    <Tab label="Suppliers" {...a11yProps(2)} />
                    <Tab label="Registration Names" {...a11yProps(3)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Customers />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Employees />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Suppliers />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <RegistrationNames/>
                </TabPanel>
              </Grid>
            </Grid>
          </>
        );
      } else if (user?.role_name.toLowerCase() === "admin") {
        return (
          <div className="w-[100]">
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
              }}
              className="flex flex-row justify-between"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor={`${primary}`}
                indicatorColor="primary"
                sx={{ color: "#F24E1E" }}
              >
                <Tab label="Users" {...a11yProps(0)} />
                <Tab label="Customers" {...a11yProps(1)} />
                <Tab label="Employees" {...a11yProps(2)} />
                <Tab label="Suppliers" {...a11yProps(3)} />
                <Tab label="Truck Owners" {...a11yProps(4)} />
                <Tab label="Registration Names" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Users />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Customers />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Employees />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Suppliers />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <TruckOwners />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <RegistrationNames/>
            </TabPanel>
          </div>
        );
      } else {
        return <div className="text-center">NOT AUTHORIZED</div>;
      }
    } else {
      return <Login />;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container sx={{ px: 2 }}>
        <Grid item sm={12}>
          {renderUserTabs()}
        </Grid>
      </Grid>
    </>
  );
};

export default Customer;
