import React, { Fragment, useState, useRef, useEffect } from "react";
import { RemoveRedEye, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  getAllRegisteredNames,
  deleteRegisteredName,
} from "../../api/users/userAPI";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { activeCustomerIdAtom } from "../../atoms/user";
import { useAtom } from "jotai";
import { useQueryClient } from "react-query";
import { notifyToastError, notifyToastSuccess } from "../toast";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import { isEmpty } from "lodash";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  addRegisteredNames,
  addSearchedNames,
  selectRegisteredNames,
  selectSearchedNames,
} from "../../features/userSlice";
import { authenticationAtom } from "../../atoms/auth";
import useAuth from "../../hooks/useAuth";
import { Popover, Space, Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import AddRegistrationName from "./AddRegistrationName";
import EditRegistrationName from "./EditRegistrationName";

const DeleteName = ({ name, onSuccess = () => {} }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getNames = async () => {
    const response = await getAllRegisteredNames({ token });

    if (response) {
      if (!isEmpty(response.data)) {
        dispatch(addRegisteredNames(response.data.data));
      }
    }
  };

  const handleDeleteName = async (name) => {
    if (!isEmpty(name)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteRegisteredName({
        name_id: name?.id,
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
        queryClient.invalidateQueries({ queryKey: ["registeredNames"] });
      } else {
        notifyToastError({
          message: "Failed to delete name, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Name is successfully deleted",
          icon: "✅",
        });
        getNames();
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={20} />
            </>
          ) : (
            <>
              <IconButton onClick={handleClickOpen} disabled={isLoading}>
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </IconButton>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete customer"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete name {name?.name || ""} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleDeleteName(name);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const RegistrationNames = () => {
  const auth = useAuth();
  const token = auth?.token || "";

  //   const [isOpen, setIsOpen] = useState(false);
  //   const [modalForm, setModalForm] = useState("");
  //   const [activeName, setActiveName] = useState();

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchName, setSearchName] = useState(false);
  const searchInput = useRef(null);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  //   function closeModal() {
  //     setModalForm("");
  //     setIsOpen(false);
  //   }

  //   function openModal() {
  //     setIsOpen(true);
  //   }

  //   const handleSelectedName = (name) => {
  //     //get customer details the navigate to customer page
  //     dispatch(addCustomerDetails(name));
  //     navigate(`/users/customers/${name?.id}`);
  //   };

  //   let formatter = new Intl.NumberFormat("en-US", {
  //     style: "currency",
  //     currency: "TZS",
  //   });

  const getNames = async () => {
    setLoading(true);
    const response = await getAllRegisteredNames({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRegisteredNames(response.data.data));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNames();
  },[dispatch])

  const names = useSelector(selectRegisteredNames);
  const searchedNames = useSelector(selectSearchedNames);
  //   const invoices = useSelector(selectRouteInvoices);

  //   const unpaidInvoicesArray = invoices.filter((invoice) => {
  //     //filtering array value
  //     return invoice.payment_status !== "paid";
  //   });

  //   let invoicesTotalAmount = 0;
  //   let paidTotalAmount = 0;
  //   let unpaidTotalAmount = 0;

  //   if (invoices.length !== 0) {
  //     for (const value of invoices) {
  //       invoicesTotalAmount += value.total_amount;
  //       paidTotalAmount += value.paid_amount;
  //     }
  //   }

  //   if (unpaidInvoicesArray.length !== 0) {
  //     for (const value of invoices) {
  //       unpaidTotalAmount += value.unpaid_amount;
  //     }
  //   }

  const handleSearch = () => {
    if (names.length !== 0) {
      if (searchText) {
        const results = names.filter((name) => {
          //filtering array value
          return name.name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });

        dispatch(addSearchedNames(results));
        setSearchName(true);
      }
    }
  };

  const handleReset = () => {
    setSearchName(false);
    dispatch(addSearchedNames([]));
    setSearchText("");
  };

  const nameData = () => {
    return (
      <div
        style={{
          padding: 4,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search name`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() => handleSearch()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset()}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={hide}>
            close
          </Button>
        </Space>
      </div>
    );
  };

  const renderNames = () => {
    if (loading) return "Loading...";

    return (
      <div className="">
        <div className="text-slate-900">
          <div className="overflow-x-auto">
            <table className="min-w-full  bg-[#F0F0F0]">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24" />
              </colgroup>
              <thead className="bg-[#EEDEDE]">
                <tr className="text-left">
                  <th className="p-3">#</th>
                  <th className="p-3 flex flex-row justify-between">
                    <span>Registration Name</span>{" "}
                    <Popover
                      placement="leftTop"
                      content={nameData()}
                      title="Search Name"
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <p className="px-4 py-2 cursor-pointer">
                        <AiOutlineSearch />
                      </p>
                    </Popover>
                  </th>
                  <th className="p-3">Company</th>
                  <th className="p-3">Phone</th>
                  <th className="p-3">TIN</th>
                  <th className="p-3">VRN</th>
                  <th className="p-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {names && names?.length === 0 && (
                  <td className="p-3">
                    <td className="flex items-center justify-center py-4">
                      No names saved
                    </td>
                  </td>
                )}
                {searchName ? (
                  <>
                    {searchedNames.length !== 0 ? (
                      <>
                        {searchedNames.map((name, index) => {
                          const phone_numbers = JSON.parse(name?.phone_number);
                          return (
                            <tr
                              key={name?.id || ""}
                              className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                            >
                              <td className="p-3">
                                <p>{index + 1}</p>
                              </td>
                              <td className="p-3">
                                <p>{name?.name}</p>
                                <p>{name?.address}</p>
                              </td>
                              <td className="p-3">{name?.company}</td>
                              <td className="p-3">
                                <p>{phone_numbers[0]}</p>
                                <p>
                                  {phone_numbers[1] ? (
                                    <span>{phone_numbers[1]}</span>
                                  ) : null}
                                </p>
                              </td>
                              <td className="p-3">
                                <p>{name?.tin}</p>
                              </td>

                              <td className="p-3">{name?.vrn}</td>

                              <td className="p-3 flex items-center space-x-2">
                                <div>
                                  {user?.role_name !== "Clerk" ? (
                                    <EditRegistrationName name={name} />
                                  ) : (
                                    <span>
                                      <Edit className="cursor-not-allowed opacity-25" />
                                    </span>
                                  )}
                                </div>

                                <div>
                                  {user?.role_name !== "Clerk" ? (
                                    <DeleteName name={name} />
                                  ) : (
                                    <MdDelete className="text-[#F24E1E] text-xl ml-0 cursor-not-allowed opacity-25" />
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <td className="p-3">
                        <td className="flex items-center justify-center py-4">
                          No customer available
                        </td>
                      </td>
                    )}
                  </>
                ) : (
                  <>
                    {names &&
                      names?.length > 0 &&
                      names.map((name, index) => {
                        const phone_numbers = JSON.parse(name?.phone_number);
                        return (
                          <tr
                            key={name?.id || ""}
                            className="border-b border-opacity-20 border-gray-700 bg-[#F0F0F0]"
                          >
                            <td className="p-3">
                              <p>{index + 1}</p>
                            </td>
                            <td className="p-3">
                              <p>{name?.name}</p>
                              <p>{name?.address}</p>
                            </td>
                            <td className="p-3">{name?.company}</td>
                            <td className="p-3">
                              <p>{phone_numbers[0]}</p>
                              <p>
                                {phone_numbers[1] ? (
                                  <span>{phone_numbers[1]}</span>
                                ) : null}
                              </p>
                            </td>
                            <td className="p-3">
                              <p>{name?.tin}</p>
                            </td>

                            <td className="p-3">{name?.vrn}</td>

                            <td className="p-3 flex items-center space-x-2">
                               <div>
                                {user?.role_name !== "Clerk" ? (
                                  <EditRegistrationName name={name} />
                                ) : (
                                  <span>
                                    <Edit className="cursor-not-allowed opacity-25" />
                                  </span>
                                )}
                              </div>

                              <div>
                                {user?.role_name !== "Clerk" ? (
                                  <DeleteName name={name} />
                                ) : (
                                  <MdDelete className="text-[#F24E1E] text-xl ml-0 cursor-not-allowed opacity-25" />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row justify-center items-center w-[100%]">
        <AddRegistrationName />
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>Registration Names</h5>
          <div className="w-[100%] mb-12">{renderNames()}</div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationNames;
