import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import tables from "../../styles/tables.module.css";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  addDesignationTypes,
  selectDesignationTypes,
} from "../../features/settingSlice";
import { isEmpty } from "lodash";
import {
  deleteDesignationType,
  getAllDesignationTypes,
} from "../../api/settings/settingAPI";
import { useDispatch, useSelector } from "react-redux";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { CircularProgress } from "@mui/material";
import AddDesignationType from "./AddDesignationType";
import EditDesignationType from "./EditDesignationType";
import useAuth from "../../hooks/useAuth";

const DeleteDesignationTypeAction = ({ designation, onSuccess = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDesignationType = async (designation) => {
    if (!isEmpty(designation)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteDesignationType({
        designationType_id: designation.id,
        token,
      });
      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
      } else {
        notifyToastError({
          message: "Failed to delete designation, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Designation is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={28} />
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={handleClickOpen}
                disabled={isLoading}
              >
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </Button>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete designation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete{" "}
              {designation?.designation_name || ""} designation type
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleDesignationType(designation);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const SettingDesignationType = () => {
  const [userData] = useAtom(authenticationAtom);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const { userInfo: user } = userData;
  const getDesignationTypes = async () => {
    const response = await getAllDesignationTypes({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addDesignationTypes(response.data.data));
      }
    }
  };

  useEffect(() => {
    getDesignationTypes();
  }, [dispatch]);

  const designationTypes = useSelector(selectDesignationTypes);

  const renderEdit = (designation) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdEdit className="text-[#F24E1E] text-xl ml-5 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <EditDesignationType designation={designation} />
          </>
        );
      }
    }
  };

  const renderDelete = (designation) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteDesignationTypeAction
              designation={designation}
              onSuccess={() => {
                getDesignationTypes();
              }}
            />
          </>
        );
      }
    }
  };

  const renderExpense = () => {
    if (!isEmpty(designationTypes)) {
      return (
        <div>
          {designationTypes.length !== 0
            ? designationTypes.map((designation, index) => {
                const position = index + 1;
                return (
                  <div
                    key={index}
                    className={`bg-white h-10 ${tables.brandTable} rounded-md text-sm my-2 mx-2`}
                  >
                    <p className="py-2 text-center">{position}</p>
                    <p className="py-2">
                      <span className="pl-20 capitalize">
                        {designation.designation_name}
                      </span>
                    </p>
                    <p className="py-2">
                      {designation?.designation_description || ""}
                    </p>
                    {renderEdit(designation)}
                    {renderDelete(designation)}
                  </div>
                );
              })
            : null}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">No designation available</p>
        </div>
      );
    }
  };

  const renderExpenseTypes = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.brandTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Name</p>
          <p className="py-2">Designation Description</p>
          <p className="py-2">Edit</p>
          <p className="py-2">Delete</p>
        </div>
        <div className="bg-[#F0F0F0] h-[150px] overflow-y-scroll">
          {renderExpense()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div className="flex flex-row gap-4 justify-center items-center w-[100%]">
        <AddDesignationType />
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>Designations</h5>
          <div className="w-[100%]">{renderExpenseTypes()}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingDesignationType;
