import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaUserPlus } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getAllPaymentTypes } from "../../api/settings/settingAPI";
import {
  getRouteInvoices,
  getSingleRouteInvoice,
} from "../../api/trips/tripsAPI";
import {
  addPaymentTypes,
  selectPaymentTypes,
} from "../../features/settingSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  createTransaction,
  getInvoiceTransactions,
} from "../../api/revenue/revenueAPI";
import { useParams } from "react-router-dom";
import { addRouteInvoices } from "../../features/tripsSlice";
import {
  addInvoiceDetails,
  addInvoiceTransactions,
} from "../../features/revenueSlice";
import useAuth from "../../hooks/useAuth";
import { getAllSuppliers } from "../../api/users/userAPI";
import { addSuppliers, selectSuppliers } from "../../features/userSlice";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddTransaction = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [amount_paid, setAmount] = useState("");
  const [supplier, setSupplier] = useState("");
  const [amount_paid_usd, setAmountUSD] = useState("");
  const [payment_type_id, setType] = useState("");
  const [payment_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { invoiceID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  //fetch all transactions
  const getTransactions = async () => {
    const response = await getInvoiceTransactions({
      invoice_id: invoiceID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addInvoiceTransactions(response.data.data));
    }
  };

  const getType = async () => {
    const response = await getAllPaymentTypes({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addPaymentTypes(response.data.data));
    }
  };

  const getInvoice = async () => {
    const response = await getSingleRouteInvoice({
      invoice_id: invoiceID,
      token,
    });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addInvoiceDetails(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  const getInvoices = async () => {
    const response = await getRouteInvoices({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRouteInvoices(response.data.data));
    }
  };

  useEffect(() => {
    getType();
    getSuppliers();
  }, [dispatch]);

  const paymentTypes = useSelector(selectPaymentTypes);
  const suppliers = useSelector(selectSuppliers);

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));

  const transactionRegistration = async (e) => {
    e.preventDefault();

    if (!amount_paid) {
      notifyToastError({
        message: "Please enter paid amount",
        icon: "❗",
      });
    } else if (!amount_paid_usd) {
      notifyToastError({
        message: "Please enter paid amount in usd",
        icon: "❗",
      });
    } else if (!payment_type_id) {
      notifyToastError({
        message: "Please select payment type",
        icon: "❗",
      });
    } else if (!supplier) {
      notifyToastError({
        message: "Please select receiver supplier",
        icon: "❗",
      });
    } else if (!payment_date) {
      notifyToastError({
        message: "Please select date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(payment_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createTransaction({
        amount_paid,
        amount_paid_usd,
        payment_date: date,
        payment_type_id,
        supplier_id: supplier?.id || "",
        invoice_id: invoiceID,
        description,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setAmount("");
        setAmountUSD("");
        setType("");
        setSupplier("");
        setDate(null);
        setDescription("");
        getTransactions();
        getInvoice();
        getInvoices();

        notifyToastSuccess({
          message: "Transaction is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => transactionRegistration(e)}
        >
          SAVE TRANSACTION
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <p className="flex justify-center items-center">
            <FaUserPlus className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">
            Add Transaction
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new transaction to an existing invoice
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">
              Add Transaction Details
            </h3>
            <div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Paid Amount TZS"
                  variant="outlined"
                  type={"number"}
                  className="w-[82%]"
                  value={amount_paid}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Paid Amount USD"
                  variant="outlined"
                  type={"number"}
                  className="w-[82%]"
                  value={amount_paid_usd}
                  onChange={(e) => setAmountUSD(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[82%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="large"
                  id="outlined-select-currency"
                  select
                  label="Payment Type"
                  className="w-[40%]"
                  value={payment_type_id}
                  onChange={(e) => setType(e.target.value)}
                >
                  {paymentTypes.length !== 0
                    ? paymentTypes.map((payment, index) => (
                        <MenuItem key={index} value={payment.id}>
                          {payment.payment_name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select payment date"
                    value={payment_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[40%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={2}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddTransaction;
