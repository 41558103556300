import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdEdit } from "react-icons/md";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { getAllBrands, updateBrand } from "../../api/settings/settingAPI";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { addTruckBrands } from "../../features/settingSlice";
import { Toaster } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditBrand = ({ brand }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [brand_name, setBrandName] = useState(brand.brand_name);
  const [brand_type, setBrandType] = useState(brand.brand_type);
  const [brand_description, setBrandDescription] = useState(
    brand.brand_description
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getBrands = async () => {
    const response = await getAllBrands({token});

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTruckBrands(response.data.data));
      }
    }
  };

  const brandRegistration = async (e) => {
    e.preventDefault();

    if (!brand_name) {
      notifyToastError({
        message: "Please select brand name",
        icon: "❗",
      });
    } else if (!brand_type) {
      notifyToastError({
        message: "Please enter brand type",
        icon: "❗",
      });
    } else {
      if (!isEmpty(brand)) {
        //start registration
        let brandName = brand_name.toLowerCase();
        setLoading(true);

        const response = await updateBrand({
          brand_name: brandName,
          brand_type,
          brand_description,
          brand_id: brand.id,
          token
        });

        if (response) {
          checkResponse(response);
        } else {
          setLoading(false);
          notifyToastError({
            message: "No response returned",
            icon: "❗",
          });
        }
      } else {
        notifyToastError({
          message: "Please select brand to update",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        getBrands();

        notifyToastSuccess({
          message: "Brand is updated successfully",
          icon: "✅",
        });

        handleClose();
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (loading) {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%] cursor-not-allowed"
            disabled
          >
            <svg
              class="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
            Loading...
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            size="large"
            variant="contained"
            className="w-[82%]"
            onClick={(e) => brandRegistration(e)}
          >
            UPDATE BRAND
          </Button>
        </>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="cursor-pointer py-2 flex justify-center"
      >
        <MdEdit className="text-[#F24E1E] text-xl ml-5" />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div>
              <Toaster />
            </div>
            <h3 className="text-center text-xl py-4">Edit Truck Brand</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Brand name"
                  variant="outlined"
                  className="w-[40%]"
                  value={brand_name}
                  onChange={(e) => setBrandName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label="Brand Type"
                  className="w-[40%]"
                  value={brand_type}
                  onChange={(e) => setBrandType(e.target.value)}
                >
                  <MenuItem value={"truck"}>Truck</MenuItem>
                  <MenuItem value={"trailer"}>Trailer</MenuItem>
                </TextField>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={brand_description}
                  onChange={(e) => setBrandDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditBrand;
