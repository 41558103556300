import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createRouteInvoice,
  getRouteInvoices,
  getSingleRoute,
} from "../../api/trips/tripsAPI";
import { isEmpty } from "lodash";
import {
  addRouteDetails,
  addRouteInvoices,
} from "../../features/tripsSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";
import {
  addRegisteredNames,
  selectRegisteredNames,
} from "../../features/userSlice";
import { getAllRegisteredNames } from "../../api/users/userAPI";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddInvoice = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [invoice_number, setInvoice] = useState("");
  const [name, setName] = useState("");
  const [total_amount, setAmount] = useState("");
  const [total_amount_usd, setAmountUsd] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rate, setRate] = useState("");
  const [invoice_type, setInvoiceType] = useState("");
  const [invoice_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { routeID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const nameOnChange = (e, value) => {
    setName(value);
  };

  const getInvoices = async () => {
    const response = await getRouteInvoices({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRouteInvoices(response.data.data));
    }
  };

  const getRoute = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRouteDetails(response.data.data));
    }
  };

  const getNames = async () => {
    const response = await getAllRegisteredNames({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRegisteredNames(response.data.data));
    }
  };

  useEffect(() => {
    getNames();
  }, [dispatch]);

  const names = useSelector(selectRegisteredNames);

  const sortedNames = names.map((name) => ({
    id: name.id,
    label: name.name,
  }));

  const saveInvoice = async (e) => {
    e.preventDefault();

    if (!total_amount) {
      notifyToastError({
        message: "Please enter amount",
        icon: "❗",
      });
    } else if (!invoice_number) {
      notifyToastError({
        message: "Please enter invoice number",
        icon: "❗",
      });
    } else if (!name) {
      notifyToastError({
        message: "Please enter invoice name",
        icon: "❗",
      });
    } else if (!quantity) {
      notifyToastError({
        message: "Please enter invoice quantity",
        icon: "❗",
      });
    } else if (!invoice_type) {
      notifyToastError({
        message: "Please select invoice type",
        icon: "❗",
      });
    } else if (!invoice_date) {
      notifyToastError({
        message: "Please select invoice date",
        icon: "❗",
      });
    } else if (!rate) {
      notifyToastError({
        message: "Please enter invoice rate",
        icon: "❗",
      });
    } else if (!total_amount_usd) {
      notifyToastError({
        message: "Please enter amount in usd",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(invoice_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createRouteInvoice({
        invoice_number,
        invoice_person: name?.id,
        total_amount,
        total_amount_usd,
        description,
        rate,
        quantity,
        invoice_type,
        invoice_date: date,
        route_id: routeID,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setAmount("");
        setName("");
        setInvoice("");
        setRate("");
        setInvoiceType("");
        setQuantity("");
        setAmountUsd("");
        setDescription("");
        getInvoices();
        setDate("");
        getRoute();

        notifyToastSuccess({
          message: "Route Invoice is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => saveInvoice(e)}
        >
          SAVE INVOICE
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-2">
          <p className="flex justify-center items-center pt-1">
            <FaFileInvoiceDollar className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">
            Add Route Invoice
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new invoices to existing routes
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Add Invoice Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Invoice Number"
                  variant="outlined"
                  className="w-[40%]"
                  value={invoice_number}
                  onChange={(e) => setInvoice(e.target.value)}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedNames}
                  size="small"
                  className="w-[40%]"
                  value={name}
                  onChange={nameOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Invoice Name" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Total Amount"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={total_amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Total Amount In USD"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={total_amount_usd}
                  onChange={(e) => setAmountUsd(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Rate"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="large"
                  id="outlined-select-currency"
                  select
                  label="Invoice Type"
                  className="w-[40%]"
                  value={invoice_type}
                  onChange={(e) => setInvoiceType(e.target.value)}
                >
                  <MenuItem value={"tzs"}>TZS</MenuItem>
                  <MenuItem value={"usd"}>USD</MenuItem>
                </TextField>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select invoice date"
                    value={invoice_date}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[40%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddInvoice;
