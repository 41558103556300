import App from "./App";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./assets/sass/app.scss";
import "./index.css";

// * redux configuration
import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as JotaiProvider, useAtom } from "jotai"
import { authenticationAtom } from "./atoms/auth"

const JotaiAtomsPreloader = () => {
  useAtom(authenticationAtom)
  return null
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <JotaiProvider>
            <Suspense fallback={<div>loading state...</div>}>
              <JotaiAtomsPreloader />
              <App />
            </Suspense>
          </JotaiProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
