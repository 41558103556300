import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";
import { MdLibraryAdd } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createRouteExpense,
  getRouteExpenses,
  getSingleRoute,
} from "../../api/trips/tripsAPI";
import { isEmpty, isNumber } from "lodash";
import {
  addRouteDetails,
  addRouteExpenses,
} from "../../features/tripsSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  addExpenseTypes,
  selectExpenseTypes,
} from "../../features/settingSlice";
import { addSuppliers, selectSuppliers } from "../../features/userSlice";
import { getAllSuppliers } from "../../api/users/userAPI";
import { getAllExpenseTypes } from "../../api/settings/settingAPI";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddExpense = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [expense_type, setType] = useState("");
  const [supplier, setSupplier] = useState("");
  const [item_number, setNumber] = useState("");
  const [item_price, setPrice] = useState("");
  const [total_amount, setAmount] = useState(0);
  const [amount, setExpenseAmount] = useState("");
  const [expense_date, setDate] = useState(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { routeID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  const supplierOnChange = (e, value) => {
    setSupplier(value);
  };

  const typeOnChange = (e, value) => {
    setType(value);
  };

  const getExpenses = async () => {
    const response = await getRouteExpenses({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRouteExpenses(response.data.data));
    }
  };

  const getTypes = async () => {
    const response = await getAllExpenseTypes({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addExpenseTypes(response.data.data));
    }
  };

  const getSuppliers = async () => {
    const response = await getAllSuppliers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addSuppliers(response.data.data));
    }
  };

  const getRoute = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addRouteDetails(response.data.data));
    }
  };

  let formatter = new Intl.NumberFormat('en-uS');

  const getTotal = () => {
    let total = item_number * item_price;
    setAmount(total);
    if(isNumber(total)){
      setExpenseAmount(formatter.format(total));
    }  
  };

  useEffect(() => {
    getSuppliers();
    getTypes();
  }, [dispatch]);

  useEffect(() => {
    getTotal();
  }, [item_number, item_price]);

  const suppliers = useSelector(selectSuppliers);
  const allExpenseTypes = useSelector(selectExpenseTypes);

  const sortedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    label: supplier.supplier_name,
  }));
  
  const sortedExpenseTypes = allExpenseTypes.map((expense) => ({
    id: expense.id,
    label: expense.type_name,
  }));

  const saveExpense = async (e) => {
    e.preventDefault();

    if (!expense_type) {
      notifyToastError({
        message: "Please select expense type",
        icon: "❗",
      });
    } else if (!supplier) {
      notifyToastError({
        message: "Please select supplier",
        icon: "❗",
      });
    } else if (!item_number) {
      notifyToastError({
        message: "Please enter items number",
        icon: "❗",
      });
    } else if (!item_price) {
      notifyToastError({
        message: "Please enter item price",
        icon: "❗",
      });
    } else if (!total_amount) {
      notifyToastError({
        message: "Please enter total amount",
        icon: "❗",
      });
    } else if (!expense_date) {
      notifyToastError({
        message: "Please select expense date",
        icon: "❗",
      });
    } else {
      //change date format
      const date = moment(expense_date).format("YYYY-MM-DD");
      //start registration
      setLoading(true);
      const response = await createRouteExpense({
        expense_type_id: expense_type.id,
        supplier_id: supplier.id,
        item_number,
        item_price,
        total_amount,
        description,
        expense_date: date,
        route_id: routeID,
        token,
      });

      if (response) {
        checkResponse(response);
      } else {
        setLoading(false);
        notifyToastError({
          message: "No response returned",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setType("");
        setSupplier("");
        setNumber("");
        setPrice("");
        setAmount("");
        setDate(null);
        setDescription("");
        getExpenses();
        getRoute();

        notifyToastSuccess({
          message: "Route expense is saved successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => saveExpense(e)}
        >
          SAVE EXPENSE
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-2">
          <p className="flex justify-center items-center pt-1">
            <MdLibraryAdd className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">
            Add Route Expense
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new expenses to existing routes
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="text-center text-xl py-4">Add Expense Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedExpenseTypes}
                  size="small"
                  className="w-[40%]"
                  value={expense_type}
                  onChange={typeOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Expense Type" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo"
                  options={sortedSuppliers}
                  size="small"
                  className="w-[40%]"
                  value={supplier}
                  onChange={supplierOnChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Supplier" />
                  )}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item number"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={item_number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Item price"
                  variant="outlined"
                  className="w-[40%]"
                  type={"number"}
                  value={item_price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="large"
                  id="outlined-basic"
                  label="Total amount"
                  variant="outlined"
                  className="w-[40%]"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateLibInstance={moment.utc}
                >
                  <DatePicker
                    label="Select expense date"
                    value={expense_date}
                    // minDate={moment.utc()}
                    onChange={(newValue) => setDate(newValue)}
                    className="w-[40%]"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className="w-[82%]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddExpense;
