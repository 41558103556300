import React, { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import tables from "../../styles/tables.module.css";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, Grid, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { selectSupplierDetails } from "../../features/userSlice";
import useAuth from "../../hooks/useAuth";
import TripsFailedCard from "../cards/TripsFailedCard";
import TripsProgressCard from "../cards/TripsProgressCard";
import TripsCompletedCard from "../cards/TripsCompletedCard";
import {
  addOrderDetails,
  addSupplierOrders,
  selectSupplierOrders,
} from "../../features/stockSlice";
import { getSupplierOrders } from "../../api/stock/stockAPI";
import SupplierOrdersPayment from "./SupplierOrdersPayment";

const SupplierOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { supplierID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    //fetch all orders
    const getOrders = async () => {
      const response = await getSupplierOrders({
        supplier_id: supplierID,
        token,
      });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addSupplierOrders(response.data.data));
      } else {
        dispatch(addSupplierOrders([]));
      }
    };

    getOrders();
  }, [dispatch]);

  const orders = useSelector(selectSupplierOrders);
  const supplier = useSelector(selectSupplierDetails);

  const sortedOrders = orders
    .slice()
    .sort((a, b) => new Date(b.order_date) - new Date(a.order_date));

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let ordersTotalAmount = 0;
  let paidTotalAmount = 0;
  let unpaidTotalAmount = 0;

  let ordersArray = [];

  if (Array.isArray(orders)) {
    ordersArray = sortedOrders;

    if (orders.length !== 0) {
      for (const value of orders) {
        ordersTotalAmount += value.total_order_price;
        paidTotalAmount += value.paid_amount;
      }

      unpaidTotalAmount = ordersTotalAmount - paidTotalAmount;
    }
  }

  const handleOrder = (order) => {
    //add order data to store
    dispatch(addOrderDetails(order));
    navigate(`/inventory/orders/${order?.id}`);
  };

  const renderOrders = () => {
    return (
      <div>
        <div className="">
          <div className="pb-6">
            <div
              className={`bg-[#EEDEDE] h-10 text-center ${tables.orderTable} rounded-t-md font-semibold`}
            >
              <p className="py-2">No</p>
              <p className="py-2">Order No</p>
              <p className="py-2">Order Name</p>
              <p className="py-2">Items</p>
              <p className="py-2">Total Amount</p>
              <p className="py-2">Order Supplier</p>
              <p className="py-2">Date</p>
              <p className="py-2">Status</p>
              <p className="py-2">Actions</p>
            </div>
            <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
              {renderOrder()}
            </div>
            <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = () => {
    if (ordersArray.length !== 0) {
      return (
        <div>
          {ordersArray.map((order, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`bg-white h-10 ${tables.orderTable} rounded-md text-sm my-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 pl-4">
                  {order.order_number ? (
                    <span>{order.order_number}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-1">
                  {order.order_person ? (
                    <span>{order?.order_person?.name}</span>
                  ) : null}
                </p>
                <p className="py-2 pl-6">{ordersArray?.length}</p>
                <p className="py-2 pl-4">
                  {formatter.format(order.total_order_price)}
                </p>
                <p className="py-2 text-center">
                  {order.supplier ? (
                    <span>{order.supplier.supplier_name}</span>
                  ) : null}
                </p>
                <p className="py-2 text-center">
                  {order.order_date ? <span>{order.order_date}</span> : null}
                </p>
                <p className="py-2 pl-6">
                  {order.payment_status === "not_paid" ? (
                    <span>Not paid</span>
                  ) : (
                    <span className="capitalize">{order.payment_status}</span>
                  )}
                </p>
                <p className="flex flex-row justify-center pl-4">
                  <IconButton
                    variant="outlined"
                    onClick={() => handleOrder(order)}
                  >
                    <RemoveRedEye
                      sx={{
                        color: colors.link,
                      }}
                    />
                  </IconButton>
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">There are no orders</p>
        </div>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex flex-row justify-between">
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_names || ""}
            </h5>
          </Box>
          <Box>
            <h5 className="text-xl text-center">
              {supplier?.supplier_name || ""}
            </h5>
          </Box>
          <Box>
            <SupplierOrdersPayment balance={supplier?.available_amount || 0} />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsCompletedCard
            trip={true}
            title={"Total Orders Amount"}
            value={formatter.format(ordersTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsProgressCard
            trip={true}
            title={"Total Paid Amount"}
            value={formatter.format(paidTotalAmount)}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TripsFailedCard
            trip={true}
            title={"Total Debt Amount"}
            value={formatter.format(unpaidTotalAmount)}
          />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Orders</h5>
            <div className="pb-8 w-[100%]">{renderOrders()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SupplierOrders;
