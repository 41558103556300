import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  stocks: [],
  orderDetails: "",
  stockDetails: "",
  orderItems: [],
  orderList: [],
  supplierOrders: [],
  summary: [],
};

const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    addStockOrders(state, action) {
      state.orders = action.payload;
    },

    addStocks(state, action) {
        state.stocks = action.payload;
      },

    addOrderDetails(state, action) {
      state.orderDetails = action.payload;
    },

    addStockDetails(state, action) {
      state.stockDetails = action.payload;
    },

    addOrderItems(state, action) {
      state.orderItems = action.payload;
    },

    addOrderList(state, action) {
      state.orderList = action.payload;
    },

    addSupplierOrders(state, action) {
      state.supplierOrders = action.payload;
    },

    addTrucksSummary(state, action) {
      state.summary = action.payload;
    },
  },
});

export const { addStockOrders, addStocks, addOrderDetails, addStockDetails, addOrderItems, addOrderList, addSupplierOrders, addTrucksSummary } = stockSlice.actions;

export const selectOrders = (state) => state.stock.orders;
export const selectStocks = (state) => state.stock.stocks;
export const selectOrderDetails = (state) => state.stock.orderDetails;
export const selectStockDetails = (state) => state.stock.stockDetails;
export const selectOrderItems = (state) => state.stock.orderItems;
export const selectOrderList = (state) => state.stock.orderList;
export const selectSupplierOrders = (state) => state.stock.supplierOrders;
export const selectTruckSummary = (state) => state.stock.summary;

export default stockSlice.reducer;
