import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { getAllCustomers, updateCustomer } from "../../api/users/userAPI";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { addCustomers } from "../../features/userSlice";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditCustomer = ({
  customer = {
    id: "",
    customer_name: "",
    phone_number: "",
    email: "",
    tin: "",
    vrn: "",
    location: "",
    customer_type: "",
    contact_person_phone: "",
    contact_person_name: "",
    status: "",
    customer_type: "",
  },
  formType = "create",
  handleClose = () => {},
}) => {
  const [customer_type, setCustomerType] = useState(customer.customer_type);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const getCustomers = async () => {
    const response = await getAllCustomers({ token });

    if (response) {
      if (!isEmpty(response.data)) {
        dispatch(addCustomers(response.data.data));
      }
    }
  };

  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      fullName: customer.customer_name,
      phoneNumber: customer.phone_number,
      email: customer.email,
      tin: customer?.tin || "",
      vrn: customer?.vrn || "",
      contactPersonName: customer.contact_person_phone,
      contactPersonPhone: customer.contact_person_name,
      location: customer.location,
    },
  });

  const { mutate, isLoading, isError } = useMutation(updateCustomer, {
    onSuccess: (data) => {
      if (data?.data.success) {
        queryClient.invalidateQueries({ queryKey: ["customers"] });

        notifyToastSuccess({
          message: "Customer is updated successfully",
          icon: "✅",
        });
        handleClose();
        getCustomers();
      }
    },
    onError: (error) => {
      notifyToastError({
        message: "No response returned",
        icon: "❗",
      });
      console.log("Something went wrong in updating a a customer");
      console.log(error);
    },
  });

  React.useEffect(() => {
    if (customer_type === "Individual") {
      setValue("contactPersonName", "");
      setValue("contactPersonPhone", "");
    }
  }, [customer_type]);

  const onSubmit = (data) => {
    mutate({
      customerId: customer.id,
      customer_name: data?.fullName || "",
      phone_number: data?.phoneNumber || "",
      email: data?.email || "",
      tin: data?.tin || "",
      vrn: data?.vrn || "",
      customer_type: customer_type || "",
      contact_person_name: data?.contactPersonName || "",
      contact_person_phone: data?.contactPersonPhone || "",
      location: data?.location || "",
      token,
    });
  };

  return (
    <div>
      <Box sx={style}>
        <div>
          <h3 className="text-center text-xl py-4">Update Customer Details</h3>
          <div>
            <div className="w-full py-2 flex justify-center">
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Full name"
                    variant="outlined"
                    className="w-[82%]"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="w-full py-2 flex flex-row gap-4 justify-center">
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Phone number"
                    variant="outlined"
                    className="w-[40%]"
                    {...field}
                  />
                )}
              />

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    className="w-[40%]"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="w-full py-2 flex flex-row gap-4 justify-center">
                <Controller
                  name="tin"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="TIN"
                      variant="outlined"
                      className="w-[40%]"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="vrn"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="VRN"
                      variant="outlined"
                      className="w-[40%]"
                      {...field}
                    />
                  )}
                />
              </div>
            {customer_type === "company" ? (
              <>
                <div className="w-full py-2 flex flex-row gap-4 justify-center">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    value={customer_type}
                    select
                    label="Customer type"
                    className="w-[82%]"
                    onChange={(e) => {
                      setCustomerType(e.target.value || "");
                    }}
                  >
                    <MenuItem value={"company"}>Company</MenuItem>
                    <MenuItem value={"individual"}>Individual</MenuItem>
                  </TextField>
                </div>

                <div className="w-full py-2 flex flex-row gap-4 justify-center">
                  <Controller
                    name="contactPersonName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Contact Person Name"
                        variant="outlined"
                        className="w-[40%]"
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="contactPersonPhone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Contact Person Phone Number"
                        variant="outlined"
                        className="w-[40%]"
                        {...field}
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  value={customer_type}
                  select
                  label="Customer type"
                  className="w-[82%]"
                  onChange={(e) => {
                    setCustomerType(e.target.value || "");
                  }}
                >
                  <MenuItem value={"company"}>Company</MenuItem>
                  <MenuItem value={"individual"}>Individual</MenuItem>
                </TextField>
              </div>
            )}
            <div className="w-full py-2 flex justify-center">
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Location"
                    variant="outlined"
                    className="w-[82%]"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="w-full py-2 flex justify-center">
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
                size="large"
                variant="contained"
                className="w-[82%]"
              >
                {isLoading ? "UPDATING CUSTOMER ... " : "UPDATE CUSTOMER"}
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default EditCustomer;
