import React, { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import { Modal } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { colors } from "../../assets/utils/colors";
import { authenticationAtom } from "../../atoms/auth";
import { selectExpenseDetails } from "../../features/expensesSlice";
import useAuth from "../../hooks/useAuth";
import tables from "../../styles/tables.module.css";
import { getSingleStockOrder } from "../../api/stock/stockAPI";
import { addOrderDetails, selectOrderDetails } from "../../features/stockSlice";
import AddOrderPayment from "./AddOrderPayment";
import EditOrderPayment from "./EditOrderPayment";

const ViewPaymentAction = ({ payment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(transaction);
  const addedTime = moment(payment?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(payment?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>
      <Modal
        title="Expense Payment Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Description :{" "}
            {payment.description ? <span>{payment.description}</span> : null}
          </p>
          <br />
          <br />
          {user?.role_name.toLowerCase() === "admin" ? (
            <>
              <p>
                Added by :{" "}
                {payment.added_by ? (
                  <span>
                    {payment.added_by.first_name} {payment.added_by.middle_name}{" "}
                    {payment.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({payment.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {payment.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {payment.updated_by ? (
                  <span>
                    {payment.updated_by.first_name}{" "}
                    {payment.updated_by.middle_name}{" "}
                    {payment.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({payment.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {payment.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const OrderPayments = () => {
  const dispatch = useDispatch();

  const { orderID } = useParams();
  const auth = useAuth();
  const token = auth?.token || "";

  //fetch all
  const getOrderInfo = async () => {
    const response = await getSingleStockOrder({
      order_id: orderID,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addOrderDetails(response.data.data));
      } else {
        dispatch(addOrderDetails([]));
      }
    } else {
      dispatch(addOrderDetails([]));
    }
  };

  useEffect(() => {
    getOrderInfo();
  }, [dispatch]);

  const order = useSelector(selectOrderDetails);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const renderEdit = (payment) => {
    return (
      <>
        <EditOrderPayment payment={payment} />
      </>
    );
  };

  const renderView = (payment) => {
    return (
      <>
        <ViewPaymentAction payment={payment} />
      </>
    );
  };

  const renderTransactions = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.transactionTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Order Number</p>
          <p className="py-2">Order Name</p>
          <p className="py-2">Paid Amount</p>
          <p className="py-2">Date</p>
          <p className="py-2">Actions</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderTransaction()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  const renderTransaction = () => {
    if (!isEmpty(order)) {
      if (order.order_payments) {
        return (
          <div>
            {order.order_payments.map((payment, index) => {
              const position = index + 1;
              return (
                <div
                  key={index}
                  className={`bg-white h-10 ${tables.transactionTable} rounded-md text-sm my-2 mx-1`}
                >
                  <p className="py-2 text-center">{position}</p>
                  <p className="py-2 text-center capitalize">
                    {order?.order_number}
                  </p>
                  <p className="py-2 text-center capitalize">
                    {order?.order_person}
                  </p>
                  <p className="py-2 pl-10">
                    {formatter.format(payment?.amount_paid || 0)}
                  </p>
                  <p className="py-2 text-center">{payment.payment_date}</p>
                  <p className="flex flex-row justify-center space-x-2 pl-4">
                    {renderEdit(payment)}
                    {renderView(payment)}
                  </p>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div>
            <p className="text-center">Order has no payments</p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p className="text-center">Order has no payments</p>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ px: 2, py: 2 }}>
      <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
        <AddOrderPayment />
      </Grid>
      <Grid item sm={12}>
        <Box>
          <h5>Order Payments</h5>
          <div className="w-[100%] pb-6">{renderTransactions()}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderPayments;
