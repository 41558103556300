import React, { useEffect, useState } from "react";
import AddBrand from "./AddBrand";
import tables from "../../styles/tables.module.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import EditBrand from "./EditBrand";
import { deleteBrand, getAllBrands } from "../../api/settings/settingAPI";
import { isEmpty, } from "lodash";
import { addTruckBrands, selectTruckBrands } from "../../features/settingSlice";
import { notifyToastError, notifyToastSuccess } from "../toast";
import { Toaster } from "react-hot-toast";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const DeleteBrandAction = ({ brand, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBrand = async (brand) => {
    if (!isEmpty(brand)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteBrand({ brand_id: brand.id, token });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
      } else {
        notifyToastError({
          message: "Failed to delete brand, Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Brand is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <div>
      <p className="cursor-pointer py-2 flex justify-center">
        <button>
          {isLoading ? (
            <>
              <CircularProgress size={28} />
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={handleClickOpen}
                disabled={isLoading}
              >
                <MdDelete className="text-[#F24E1E] text-xl ml-0" />
              </Button>
            </>
          )}
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete brand"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Area you sure you want to delete {brand?.brand_name || ""} brand
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                handleBrand(brand);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    </div>
  );
};

const SettingBrands = () => {
  const [open, setOpen] = useState(false);
  const [userData] = useAtom(authenticationAtom);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const { userInfo: user } = userData;

  const getBrands = async () => {
    const response = await getAllBrands({ token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addTruckBrands(response.data.data));
      }
    }
  };

  useEffect(() => {
    getBrands();
  }, [dispatch]);

  const brands = useSelector(selectTruckBrands);

  const renderEdit = (brand) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdEdit className="text-[#F24E1E] text-xl ml-5 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <EditBrand brand={brand} />
          </>
        );
      }
    }
  };

  const renderDelete = (brand) => {
    if (user) {
      if (user.role_name === "Clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteBrandAction
              brand={brand}
              onSuccess={() => {
                getBrands();
              }}
            />
          </>
        );
      }
    }
  };

  const renderBrands = () => {
    if (!isEmpty(brands)) {
      return (
        <div>
          {brands.map((brand, index) => {
            const position = index + 1;
            return (
              <div
                key={brand.id}
                className={`bg-white h-10 ${tables.brandsTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2">
                  <span className="pl-12 capitalize">{brand.brand_name}</span>
                </p>
                <p className="py-2">
                  <span className="pl-12 capitalize">{brand.brand_type}</span>
                </p>
                <p className="py-2">{brand.brand_description}</p>
                {renderEdit(brand)}
                {renderDelete(brand)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center">No brands available</p>
        </div>
      );
    }
  };

  const renderTruckBrands = () => {
    return (
      <div className="pb-6">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.brandsTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Brand Name</p>
          <p className="py-2">Brand Type</p>
          <p className="py-2">Brand Description</p>
          <p className="py-2">Edit</p>
          <p className="py-2">Delete</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderBrands()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <div className="py-2">
      <div>
        <Toaster />
      </div>
      <div className="flex flex-row gap-4 justify-center items-center w-[100%]">
        <AddBrand />
      </div>
      <div className="py-4 w-[100%]">
        <div className="w-[100%]">
          <h5>Truck Brands</h5>
          <div className="w-[100%]">{renderTruckBrands()}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingBrands;
