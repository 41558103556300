import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddTruck from "./AddTruck";
import TruckSummaryCard from "../cards/TruckSummaryCard";
import AddTrailer from "./AddTrailer";
import { useDispatch, useSelector } from "react-redux";
import {
  addTrailers,
  selectTrailers,
  selectTrucks,
} from "../../features/truckSlice";
import { isEmpty, _ } from "lodash";
import { addAllUsers } from "../../features/userSlice";
import { getAllUsers } from "../../api/users/userAPI";
import { getAllTrailers } from "../../api/trucks/trucksAPI";
import TrailerSummaryCard from "../cards/TrailerSummaryCard";
import { Grid } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const primary = "#F24E1E";

const TrucksAdmin = () => {
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  //fetch all trialers
  const getTrailers = async () => {
    const response = await getAllTrailers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addTrailers(response.data.data));
    }
  };

  //fetch all owners
  const getOwners = async () => {
    const response = await getAllUsers({ token });

    if (response.ok && !isEmpty(response.data)) {
      dispatch(addAllUsers(response.data.data));
    }
  };

  useEffect(() => {
    getOwners();
    getTrailers();
  }, [dispatch]);

  const allTrucks = useSelector(selectTrucks);
  const allTrailers = useSelector(selectTrailers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTruckOwners = () => {
    //filter trucks by owner id and assign trucks to owner object
    //find owner details into users array using id
    //update user details into trucks owner object

    let trucksOwnersObj = {};

    //loop all trucks details
    allTrucks.map((truck) => {
      //check if truck owner already exists in trucks owners object or not
      if (`${truck.owner_id}` in trucksOwnersObj) {
        //truck owner already exists
        let trucks = trucksOwnersObj[`${truck.owner_id}`].trucks;
        trucks.push(truck);

        //set the truck owner object properties
        trucksOwnersObj[`${truck.owner_id}`].trucks = trucks;
      } else {
        if (truck.owner) {
          trucksOwnersObj[`${truck.owner_id}`] = {
            owner_id: truck.owner_id,
            owner_name: truck.owner.first_name
              .concat(" ", truck.owner.middle_name.charAt(0).toUpperCase())
              .concat(" ", truck.owner.last_name),
            trucks: [truck],
          };
        }
      }
    });

    const ownersArray = Object.values(trucksOwnersObj);

    //check user access
    if (user?.role_name.toLowerCase() === "clerk") {
      return (
        <div className="h-40">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                height: 100,
                borderBottom: 1,
                borderColor: "divider",
              }}
              className="flex flex-row justify-between"
            >
              <Box sx={{ maxWidth: 1000 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  textColor={`${primary}`}
                  indicatorColor="primary"
                  sx={{ color: "#F24E1E", height: 100 }}
                >
                  <Tab label="Trucks" {...a11yProps(0)} sx={{ height: 100 }} />
                  <Tab label="Trailers" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <div className="w-[30%] flex flex-row gap-3 justify-end px-2">
                <div>
                  <AddTruck />
                </div>
                <div>
                  <AddTrailer />
                </div>
              </div>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="w-[100%]">{renderTrucks()}</div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {renderTrailers()}
            </TabPanel>
          </Box>
        </div>
      );
    } else {
      return (
        <div className="h-40">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                height: 100,
                borderBottom: 1,
                borderColor: "divider",
              }}
              className="flex flex-row justify-between"
            >
              <Box sx={{ maxWidth: 900 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  textColor={`${primary}`}
                  indicatorColor="primary"
                  sx={{ color: "#F24E1E", height: 100 }}
                >
                  <Tab label="Trucks" {...a11yProps(0)} sx={{ height: 100 }} />
                  <Tab label="Trailers" {...a11yProps(1)} />
                  {ownersArray.length !== 0 ? (
                    ownersArray.map((owner, index) => {
                      const num = index + 2;
                      return (
                        <Tab
                          label={`${owner.owner_name}`}
                          {...a11yProps(num)}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Tab label="" {...a11yProps(19)} sx={{ height: 100 }} />
                      <Tab label="" {...a11yProps(20)} />
                    </>
                  )}
                </Tabs>
              </Box>
              <div className="w-[30%] flex flex-row gap-3 justify-end px-2">
                <div>
                  <AddTruck />
                </div>
                <div>
                  <AddTrailer />
                </div>
              </div>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="w-[100%]">{renderTrucks()}</div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {renderTrailers()}
            </TabPanel>
            {ownersArray.length !== 0
              ? ownersArray.map((owner, i) => {
                  const num = i + 2;
                  return (
                    <TabPanel value={value} index={num}>
                      {renderOwnedTrucks(owner.trucks)}
                    </TabPanel>
                  );
                })
              : null}
          </Box>
        </div>
      );
    }
  };

  const renderTrucks = () => {
    if (!isEmpty(allTrucks)) {
      return (
        <div className="grid grid-cols-3 gap-6 pb-8">
          {allTrucks.map((truck, index) => {
            return (
              <div key={index}>
                <TruckSummaryCard truck={truck} />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="w-[100%]">
          <div>
            <p className="text-center">No trucks available</p>
          </div>
        </div>
      );
    }
  };

  const renderTrailers = () => {
    if (allTrailers.length !== 0) {
      return (
        <div className="grid grid-cols-3 gap-6 pb-8">
          {allTrailers.map((trailer, index) => {
            return (
              <div key={index}>
                <TrailerSummaryCard trailer={trailer} />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="w-[100%]">
          <div>
            <p className="text-center">No trailers available</p>
          </div>
        </div>
      );
    }
  };

  const renderOwnedTrucks = (ownedTrucks) => {
    if (ownedTrucks.length !== 0) {
      return (
        <div className="grid grid-cols-3 gap-6 pb-8">
          {ownedTrucks.map((truck, index) => {
            return (
              <div key={index}>
                <TruckSummaryCard truck={truck} />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="w-[100%]">
          <div>
            <p className="text-center">No trucks available</p>
          </div>
        </div>
      );
    }
  };

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sm={12}>
        <Box>
          <div className="w-[100%] px-2">
            <div>{renderTruckOwners()}</div>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TrucksAdmin;
