import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: [],
  invoiceTransactions: [],
  invoiceDetails: "",
  personalRevenues: [],
  personalRevenueDetails: "",
  invoiceReport: "",
  ownerRevenues: [],
  supplierInvoicesTransactions: [],
};

const revenueSlice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    addTransactions(state, action) {
      state.transactions = action.payload;
    },

    addInvoiceTransactions(state, action) {
      state.invoiceTransactions = action.payload;
    },

    addInvoiceDetails(state, action) {
      state.invoiceDetails = action.payload;
    },

    addPersonalRevenues(state, action) {
      state.personalRevenues = action.payload;
    },

    addPersonalRevenueDetails(state, action) {
      state.personalRevenueDetails = action.payload;
    },

    addInvoiceReport(state, action) {
      state.invoiceReport = action.payload;
    },

    addOwnerRevenues(state, action) {
      state.ownerRevenues = action.payload;
    },

    addSupplierInvoicesTransactions(state, action) {
      state.supplierInvoicesTransactions = action.payload;
    },
  },
});

export const {
  addTransactions,
  addInvoiceTransactions,
  addInvoiceDetails,
  addPersonalRevenues,
  addPersonalRevenueDetails,
  addInvoiceReport,
  addOwnerRevenues,
  addSupplierInvoicesTransactions,
} = revenueSlice.actions;

export const selectTransactions = (state) => state.revenue.transactions;
export const selectInvoiceTransactions = (state) =>
  state.revenue.invoiceTransactions;
export const selectInvoiceDetails = (state) => state.revenue.invoiceDetails;
export const selectPersonalRevenues = (state) => state.revenue.personalRevenues;
export const selectPersonalRevenueDetails = (state) =>
  state.revenue.personalRevenueDetails;
export const selectInvoiceReport = (state) => state.revenue.invoiceReport;
export const selectOwnerRevenues = (state) => state.revenue.ownerRevenues;
export const selectSupplierInvoicesTransaction = (state) =>
  state.revenue.supplierInvoicesTransactions;

export default revenueSlice.reducer;
