import client from "../client";
import {
  CREATE_TRAILER,
  CREATE_TRUCK,
  UPDATE_TRUCK_DETAILS,
  UPDATE_TRUCK_IMAGE,
  // UPDATE_TRAILER_IMAGE,
  UPDATE_TRAILER_DETAILS,
  GET_ALL_TRUCKS,
  GET_ALL_TRAILERS,
  // GET_SINGLE_TRAILER,
  GET_SINGLE_TRUCK,
  ASSIGN_TRAILER_TO_TRUCK,
  UNASSIGN_TRAILER_TO_TRUCK,
  DELETE_TRUCK,
  DELETE_TRAILER,
} from "../apiEndPoints";


export const createTrailer = async ({
  brand_id,
  registration_name,
  registration_number,
  trailer_description,
  token
}) => {
  const response = await client.post(
    CREATE_TRAILER,
    {
      brand_id,
      registration_name,
      registration_number,
      trailer_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const createTruck = async ({
  brand_id,
  owner_id,
  registration_name,
  registration_number,
  truck_description,
  token
}) => {
  const response = await client.post(
    CREATE_TRUCK,
    {
      brand_id,
      owner_id,
      registration_name,
      registration_number,
      truck_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllTrailers = async ({token}) => {
  const response = await client.get(
    GET_ALL_TRAILERS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getAllTrucks = async ({token}) => {
  const response = await client.get(
    GET_ALL_TRUCKS,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleTrailer = async ({ trailer_id, token }) => {
  const response = await client.get(
    `${GET_ALL_TRAILERS}/${trailer_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const getSingleTruck = async ({ truck_id, token }) => {
  const response = await client.get(
    `${GET_SINGLE_TRUCK}/${truck_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateTrailer = async ({
  brand_id,
  registration_name,
  registration_number,
  trailer_description,
  trailer_id,
  token
}) => {
  const response = await client.put(
    `${UPDATE_TRAILER_DETAILS}/${trailer_id}`,
    {
      brand_id,
      registration_name,
      registration_number,
      trailer_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateTruck = async ({
  brand_id,
  owner_id,
  registration_name,
  registration_number,
  truck_description,
  truck_id,
  token
}) => {
  const response = await client.put(
    `${UPDATE_TRUCK_DETAILS}/${truck_id}`,
    {
      brand_id,
      owner_id,
      registration_name,
      registration_number,
      truck_description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const assignTrailer = async ({ truck_id, trailer_id, token }) => {
  const response = await client.put(
    `${ASSIGN_TRAILER_TO_TRUCK}/${truck_id}/${trailer_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const unassignTrailer = async ({ truck_id, trailer_id, token }) => {
  const response = await client.put(
    `${UNASSIGN_TRAILER_TO_TRUCK}/${truck_id}/${trailer_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteTruck = async ({ truck_id, token }) => {
  const response = await client.delete(
    `${DELETE_TRUCK}/${truck_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteTrailer = async ({ trailer_id, token }) => {
  const response = await client.delete(
    `${DELETE_TRAILER}/${trailer_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const updateTruckImage = async ({ truck_id, formData, token }) => {
  const response = await client.post(
    `${UPDATE_TRUCK_IMAGE}/${truck_id}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};
