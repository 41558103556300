import client from "../client";
import {
  LOGIN_URL,
  CHANGE_PASSWORD_URL,
  LOGOUT_URL,
  RESET_PASSWORD,
} from "../apiEndPoints";

export const loginUser = async ({ phone_number, password }) => {
  const response = await client.post(LOGIN_URL, {
    phone_number,
    password,
  });
  return response;
};

export const logoutUser = async (token) => {
  const response = await client.post(
    LOGOUT_URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const changeUserPassword = async ({ old_password, password, token }) => {
  const response = await client.post(
    CHANGE_PASSWORD_URL,
    {
      old_password,
      password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const resetPassword = async ({ phone_number, token }) => {
  const response = await client.post(
    RESET_PASSWORD,
    {
      phone_number,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
  return response;
};
