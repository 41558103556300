import React, { useEffect, useState } from "react";
import tables from "../../styles/tables.module.css";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import AddInvoice from "./AddInvoice";
import { getSingleRoute, deleteRouteInvoice } from "../../api/trips/tripsAPI";
import { addRouteDetails, selectRouteDetails } from "../../features/tripsSlice";
import EditInvoice from "./EditInvoice";
import { useAtom } from "jotai";
import { authenticationAtom } from "../../atoms/auth";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Grid, IconButton, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { notifyToastError, notifyToastSuccess } from "../toast";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import { Modal } from "antd";
import InvoicePDFCard from "../cards/InvoicePDFCard";


const DeleteRouteInvoiceAction = ({ invoice, onSuccess = () => {} }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();
  const token = auth?.token || "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRouteInvoice = async (invoice) => {
    if (!isEmpty(invoice)) {
      setIsLoading(true);
      handleClose();
      const response = await deleteRouteInvoice({
        invoice_id: invoice?.id || "",
        token,
      });

      if (response) {
        //handle response
        checkResponse(response);
        setIsLoading(false);
        onSuccess();
      } else {
        notifyToastError({
          message: "Failed to route invoice , Please try again",
          icon: "❗",
        });
        setIsLoading(false);
      }
    } else {
      notifyToastError({
        message: "Something went wrong, Please try again",
        icon: "❗",
      });
      setIsLoading(false);
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        notifyToastSuccess({
          message: "Route invoice is successfully deleted",
          icon: "✅",
        });
        onSuccess();
      } else {
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
    } else {
      if (response.status > 210) {
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <>
            <CircularProgress size={24} />
          </>
        ) : (
          <>
            <IconButton
              variant="outlined"
              onClick={handleClickOpen}
              disabled={isLoading}
            >
              <MdDelete className="text-[#F24E1E] text-xl" />
            </IconButton>
          </>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete route invoices?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Area you sure you want delete route expense with ID{" "}
            {invoice?.id || ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              handleRouteInvoice(invoice);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewInvoiceAction = ({ invoice }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  // console.log(trip);
  const addedTime = moment(invoice?.created_at).format("DD-MM-YYYY");
  const updateTime = moment(invoice?.updated_at).format("DD-MM-YYYY");

  return (
    <>
      <IconButton variant="outlined" onClick={showModal}>
        <RemoveRedEye
          sx={{
            color: colors.link,
          }}
        />
      </IconButton>

      <Modal
        title="Invoice Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        zIndex={800}
        okType="default"
        cancelButtonProps={{
          className: "hidden",
        }}
      >
        <div className="flex flex-col">
          <p>
            Invoice Description :{" "}
            {invoice.description ? <span>{invoice.description}</span> : null}
          </p>
          <br />
          <br />
          {user.role_name === "admin" || user.role_name === "Admin" ? (
            <>
              <p>
                Added by :{" "}
                {invoice.added_by ? (
                  <span>
                    {invoice.added_by.first_name} {invoice.added_by.middle_name}{" "}
                    {invoice.added_by.last_name}{" "}
                    <span className="text-xs">
                      ({invoice.added_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Added at :{" "}
                {invoice.created_at ? <span>{addedTime}</span> : null}
              </p>
              <p>
                Updated by :{" "}
                {invoice.updated_by ? (
                  <span>
                    {invoice.updated_by.first_name}{" "}
                    {invoice.updated_by.middle_name}{" "}
                    {invoice.updated_by.last_name}{" "}
                    <span className="text-xs">
                      ({invoice.updated_by.role_name})
                    </span>
                  </span>
                ) : null}
              </p>
              <p>
                Updated at :{" "}
                {invoice.updated_at ? <span>{updateTime}</span> : null}
              </p>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const RouteInvoices = () => {
  const dispatch = useDispatch();
  const [userData] = useAtom(authenticationAtom);
  const { routeID } = useParams();

  const auth = useAuth();
  const token = auth?.token || "";

  const getRoute = async () => {
    const response = await getSingleRoute({ route_id: routeID, token });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addRouteDetails(response.data.data));
      }
    }
  };

  useEffect(() => {
    getRoute();
  }, [dispatch]);

  const route = useSelector(selectRouteDetails);

  const { userInfo: user } = userData;

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  let formatterUS = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const renderEdit = (invoice) => {
    return (
      <>
        <EditInvoice
          invoice={invoice}
          onSuccess={() => {
            getRoute();
          }}
        />
      </>
    );
  };

  const renderDelete = (invoice) => {
    if (user) {
      if (user.role_name === "Clerk" || user.role_name === "clerk") {
        return (
          <>
            <p className="cursor-not-allowed py-2 flex justify-center">
              <MdDelete className="text-[#F24E1E] text-xl ml-6 opacity-25" />
            </p>
          </>
        );
      } else {
        return (
          <>
            <DeleteRouteInvoiceAction
              invoice={invoice}
              onSuccess={() => {
                getRoute();
              }}
            />
          </>
        );
      }
    }
  };

  const renderViewInvoice = (invoice) => {
    return (
      <>
        <ViewInvoiceAction invoice={invoice} />
      </>
    );
  };

  const renderInvoices = () => {
    if (!isEmpty(route)) {
      if (route.route_invoice) {
        const invoice = route.route_invoice;
        return (
          <div
            className={`h-10 bg-white ${tables.routeInvoiceTable} rounded-md text-sm my-2 mx-1`}
          >
            <p className="py-2 text-center">1</p>
            <p className="py-2 pl-2">{invoice.invoice_number}</p>
            <p className="py-2 pl-2">
              {formatter.format(invoice.total_amount)}
            </p>
            <p className="py-2 pl-3">
              {formatterUS.format(invoice.total_amount_usd)}
            </p>
            <p className="py-2 text-center">
              {invoice?.invoice_date ? <>{invoice.invoice_date}</> : <>{moment(invoice.created_at).format("YYYY-MM-DD")}</>}
            </p>
            <p className="py-2 pl-2">
              {invoice.status === "unpaid" ? (
                <span>Not paid</span>
              ) : (
                <span className="capitalize">{invoice.status}</span>
              )}
            </p>
            <p className="py-2 pl-4">{formatter.format(invoice.paid_amount)}</p>
            <p className="py-2 text-center">{invoice.payment_date}</p>
            <div className="flex flex-row justify-center space-x-2 pl-4">
              {renderEdit(invoice)}
              {renderDelete(invoice)}
            </div>
            <div className="flex flex-row justify-center">
              <InvoicePDFCard data={invoice}/>
              {renderViewInvoice(invoice)}
            </div>
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <p>Route has no issued invoices</p>
          </div>
        );
      }
    } else {
      return (
        <div className="text-center">
          <p>Route has no issued invoices</p>
        </div>
      );
    }
  };

  const renderRouteInvoices = () => {
    return (
      <div className="">
        <div
          className={`bg-[#EEDEDE] h-10 text-center ${tables.routeInvoiceTable} rounded-t-md font-semibold`}
        >
          <p className="py-2">No</p>
          <p className="py-2">Invoice No</p>
          <p className="py-2">Amount TZS</p>
          <p className="py-2">Amount USD</p>
          <p className="py-2">Issued Date</p>
          <p className="py-2">Status</p>
          <p className="py-2">Paid Amount</p>
          <p className="py-2">Payment Date</p>
          <p className="py-2">Actions</p>
          <p className="py-2">View</p>
        </div>
        <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
          {renderInvoices()}
        </div>
        <div className="h-9 w-[100%] bg-gradient-to-r from-[#F0F0F0] to-[#FFFFFF] shadow-xl rounded-b-md"></div>
      </div>
    );
  };

  return (
    <>
      <Grid container sx={{ px: 2, py: 2 }}>
        <Grid item sm={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AddInvoice />
        </Grid>
        <Grid item sm={12}>
          <Box>
            <h5>Route Invoices</h5>
            <div className="w-[100%]">{renderRouteInvoices()}</div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RouteInvoices;
