import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaUserPlus } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { _, isEmpty, map } from "lodash";
import { notifyToastError, notifyToastSuccess } from "../toast";
import {
  createEmployeeGuarantor,
  getEmployeeGuarantor,
} from "../../api/users/userAPI";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { addEmployeeGuarantors } from "../../features/userSlice";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddGuarantor = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [first_name, setFirstName] = useState();
  const [middle_name, setMiddleName] = useState();
  const [last_name, setLastName] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [location, setLocation] = useState();

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  const { employeeId } = useParams();

  //fetch all
  const getGuarantors = async () => {
    const response = await getEmployeeGuarantor({
      employee_id: employeeId,
      token,
    });

    if (response.ok) {
      if (!isEmpty(response.data)) {
        dispatch(addEmployeeGuarantors(response.data.data));
      }
    }
  };

  const userRegistration = async (e) => {
    e.preventDefault();

    if (!first_name) {
      notifyToastError({
        message: "Please enter first name",
        icon: "❗",
      });
    } else if (!middle_name) {
      notifyToastError({
        message: "Please enter middle name",
        icon: "❗",
      });
    } else if (!last_name) {
      notifyToastError({
        message: "Please enter last name",
        icon: "❗",
      });
    } else if (!phone_number) {
      notifyToastError({
        message: "Please enter phone number",
        icon: "❗",
      });
    } else if (!location) {
      notifyToastError({
        message: "Please enter location",
        icon: "❗",
      });
    } else {
      if (employeeId) {
        //start registration
        setLoading(true);
        const response = await createEmployeeGuarantor({
          first_name,
          middle_name,
          last_name,
          phone_number,
          location,
          token,
          employee_id: employeeId
        });

        if (response) {
          checkResponse(response);
        } else {
          setLoading(false);
          notifyToastError({
            message: "No response returned",
            icon: "❗",
          });
        }
      } else {
        notifyToastError({
          message:
            "Sorry! There is no employee to add details, please refresh and try again",
          icon: "❗",
        });
      }
    }
  };

  const checkResponse = (response) => {
    if (response.ok) {
      if (response.data.success === true) {
        setLoading(false);
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setPhoneNumber("");
        setLocation("");
        getGuarantors();

        notifyToastSuccess({
          message: "Employee guarantor is registered successfully",
          icon: "✅",
        });
      } else {
        setLoading(false);
        notifyToastError({
          message: "Something went wrong, Please try again!",
          icon: "❗",
        });
      }
      setLoading(false);
    } else {
      if (response.status > 210) {
        setLoading(false);
        notifyToastError({
          message: response.data.message,
          icon: "❗",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const renderButton = () => {
    if (loading) {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%] cursor-not-allowed"
          disabled
        >
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
          Loading...
        </Button>
      );
    } else {
      return (
        <Button
          size="large"
          variant="contained"
          className="w-[82%]"
          onClick={(e) => userRegistration(e)}
        >
          SAVE GUARANTOR
        </Button>
      );
    }
  };

  return (
    <div>
      <div
        onClick={handleOpen}
        className="h-[160px] w-[290px] border-dashed border-2 border-black rounded-md text-center cursor-pointer hover:bg-[#F24E1E] hover:text-white hover:border-none"
      >
        <div className="py-4">
          <p className="flex justify-center items-center">
            <FaUserPlus className="text-4xl" />
          </p>
          <p className="text-lg font-semibold text-center pb-2">
            Add Guarantor
          </p>
          <div className="flex flex-row justify-center items-center pb-1">
            <p className="text-center text-xs w-[50%]">
              Add new guarantor to employee' guarantors list
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            {/* <Toaster /> */}
            <h3 className="text-center text-xl py-4">Add Guarantor Details</h3>
            <div>
              <div className="w-full py-2 flex flex-row gap-2 justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="First name"
                  variant="outlined"
                  className="w-[27%]"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Middle name"
                  variant="outlined"
                  className="w-[25%]"
                  value={middle_name}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Last name"
                  variant="outlined"
                  className="w-[27%]"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  className="w-[82%]"
                  type={"number"}
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="w-full py-2 flex justify-center">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Location"
                  variant="outlined"
                  className="w-[82%]"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>

              <div className="w-full py-2 flex justify-center">
                {renderButton()}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddGuarantor;
